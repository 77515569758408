import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseData',
})
export class ParseDataPipe implements PipeTransform {
  transform(value: any, ...args: any): any {
    let data: any = JSON.parse(value);

    return data;
  }
}
