<p-toast></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"[style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->

<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<!-- <app-spinner [msg]="spinnerMsg"></app-spinner> -->
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- ********************** Main Content ******************** -->

<!-- <div class="main-content d-flex flex-column"> -->

<!-- <app-dashboard-navbar></app-dashboard-navbar> -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item"><a routerLink="/user-panel/dashboard">{{Content.subTitle}}</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>

<div class="add-listings-box">
    <form (ngSubmit)="saveTemplate(template)" #template="ngForm">
        <div class="row m-0">
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label class="p-0">Page Name : </label>
                    <input type="text" class="form-control" name="page_name" #page_name="ngModel"
                        [(ngModel)]="templateData.page_name" (blur)="getSlug(page_name?.value)"
                        placeholder="Enter Page Name" required>
                    <div *ngIf="template.submitted || page_name.touched" class="alert alert-danger">
                        <div *ngIf="page_name.errors?.['required']">
                            Page Name is Required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label class="p-0">URL Slug : </label>
                    <input type="text" class="form-control" name="slug" #slug="ngModel" [(ngModel)]="templateData.slug"
                        placeholder="Enter Slug Name" required>
                    <div *ngIf="template.submitted || slug.touched" class="alert alert-danger">
                        <div *ngIf="slug.errors?.['required']">
                            Slug is Required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label class="p-0">Banner Image (1440px X 580px) : </label>

                    <ng-container *ngIf="templateData.banner_image">
                        <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                            <span pTooltip="Click to Show Logo Preview"
                                (click)="onImageClick(templateData.banner_image)"
                                tooltipPosition="bottom">{{templateData.banner_image | slice:0:10}}</span>
                            <span (click)="logoPreview('banner')" style="color: red"
                                pTooltip="Remove Preview and Change Logo" tooltipPosition="bottom">x</span>
                        </span>
                        <ng-container *ngIf="imageData.boolean">
                            <app-image-preview [imageData]="imageData"></app-image-preview>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!templateData.banner_image">
                        <!-- {{templateData.banner_image == ''}}{{templateData.banner_image == undefined}}{{templateData.banner_image == null}} -->
                        <input type="file" class="form-control px-2 py-1" name="banner_image"
                            (change)="getImageURL($event,'banner')" [(ngModel)]="template.banner_image">
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="templateData.banner_image == '' || templateData.banner_image == undefined">
                                Banner Image is Required.
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label class="p-0">Is Table : </label>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="templateData.sections[0].isTable"
                            name="isTable" value="yes" id="isTable">
                        <label class="form-check-label" for="isTable" style="width: fit-content;">
                            Want table
                        </label>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row m-0" *ngFor="let section of templateData.sections; index as sectionIndex">
            <div class="d-flex align-items-center justify-content-between mb-2"
                style="border-bottom: 1px dashed #eeeeee;">
                <h3 class="border-white m-0" style="padding-left: 0;">{{section.title}}</h3>
                <div class="actionButton">
                    <button *ngIf="templateData?.sections?.length == sectionIndex+1" class="add_button p-button mx-2"
                        type="button" (click)="addSection(sectionIndex, 'section')">+</button>
                    <button *ngIf="sectionIndex != 0 && templateData?.sections?.length == sectionIndex+1"
                        class="add_button p-button" type="button"
                        (click)="removeSection(sectionIndex, 'section')">-</button>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="p-0">Section {{sectionIndex+1}} Heading : </label>
                    <input type="text" class="form-control" [name]="'heading'+sectionIndex"
                        [(ngModel)]="section.heading" placeholder="Enter Heading">
                    <div *ngIf="template.submitted" class="alert alert-danger">
                        <div *ngIf="section.heading == ''">
                            Heading is Required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="p-0">Section {{sectionIndex+1}} Content : </label>
                    <input type="text" class="form-control" [name]="'content'+sectionIndex"
                    [(ngModel)]="section.content" placeholder="Enter Content">
                    <!-- <p-editor [(ngModel)]="section.content" [name]="'content'+sectionIndex" #section_content="ngModel"
                        placeholder="Enter Content"></p-editor> -->

                    <!-- <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                        </ngx-editor-menu>
                        <ngx-editor [editor]="editor" [disabled]="false" [id]="sectionIndex+'content_editor'"
                            [name]="'content_editor'+sectionIndex" placeholder="Enter Content" required
                            [(ngModel)]="section.content">
                        </ngx-editor> -->
                    <!-- <div *ngIf="template.submitted" class="alert alert-danger">
                        <div *ngIf="section_content?.errors?.['required']">
                            Content is Required.
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label class="p-0">CTA Button : </label>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="section.cta"
                            [name]="'cta'+sectionIndex" value="yes" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault" style="width: fit-content;">
                            Call to Action
                        </label>
                    </div>
                </div>
            </div> -->
            <div class="row m-0" *ngFor="let grid of section.grid; index as gridIndex">
                <div class="d-flex align-items-center justify-content-between mb-2"
                    style="border-bottom: 1px dashed #eeeeee;">
                    <h3 class="border-white m-0">{{grid.title}}</h3>
                    <div class="actionButton">
                        <button *ngIf="templateData?.sections[sectionIndex]?.grid.length == gridIndex+1"
                            class="add_button p-button mx-2" type="button"
                            (click)="addSection(sectionIndex, 'grid')">+</button>
                        <button
                            *ngIf="gridIndex != 0 && templateData?.sections[sectionIndex]?.grid.length == gridIndex+1"
                            class="add_button p-button" type="button"
                            (click)="removeSection(sectionIndex, 'grid')">-</button>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Company Name : </label>
                        <p-dropdown [options]="memberData" [name]="'company_name' + sectionIndex + gridIndex" [id]="'company_name' + sectionIndex + gridIndex" filterBy="company_name" [filter]="true"
                            placeholder="Please Select" (onChange)="onSelectionOfCompany($event, sectionIndex, gridIndex)" optionLabel="company_name" optionValue="company_name"
                            [showClear]="false" [resetFilterOnHide]="true" [(ngModel)]="grid.company_name" #company_name="ngModel"></p-dropdown>
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.company_name == ''">
                                Company Name is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Nominee Name : </label>
                        <p-dropdown [options]="nomineeData[sectionIndex][gridIndex]" (onChange)="onSelectionOfNominee($event, sectionIndex, gridIndex)" [name]="'nominee_name' + sectionIndex + gridIndex" [id]="'nominee_name' + sectionIndex + gridIndex" filterBy="name" [filter]="true"
                            placeholder="Please Select" optionLabel="name" optionValue="name"
                            [showClear]="false" [resetFilterOnHide]="true" [(ngModel)]="grid.nominee_name" #nominee_name="ngModel"></p-dropdown>
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.nominee_name == ''">
                                Nominee Name is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Grid {{gridIndex+1}} Name : </label>
                        <input type="text" class="form-control" [name]="'grid_heading' + sectionIndex + gridIndex"
                            [(ngModel)]="grid.grid_heading" placeholder="Enter Grid Name" >
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.grid_heading == ''">
                                Heading is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Grid {{gridIndex+1}} Email : </label>
                        <input type="text" class="form-control" [name]="'grid_sub_heading' + sectionIndex + gridIndex"
                            [(ngModel)]="grid.grid_sub_heading" placeholder="Enter Grid Email" >
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.grid_sub_heading == ''">
                                Sub Heading is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Grid {{gridIndex+1}} Contact : </label>
                        <input type="text" class="form-control" [name]="'grid_contact' + sectionIndex + gridIndex"
                            [(ngModel)]="grid.grid_contact" placeholder="Enter Grid Contact" >
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.grid_contact == ''">
                                Contact is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Grid {{gridIndex+1}} Designation : </label>
                        <input type="text" class="form-control" [name]="'designation' + sectionIndex + gridIndex"
                            [(ngModel)]="grid.designation" placeholder="Enter Grid Contact" >
                        <div *ngIf="template.submitted" class="alert alert-danger">
                            <div *ngIf="grid.designation == ''">
                                Designation is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Grid {{gridIndex+1}} Image (313px X 370px) : </label>

                        <ng-container *ngIf="grid.grid_image">
                            <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                                <span pTooltip="Click to Show Logo Preview" (click)="onImageClick(grid.grid_image)"
                                    tooltipPosition="bottom">{{grid.grid_image | slice:0:10}}</span>
                                <span (click)="logoPreview('gridImage', sectionIndex, gridIndex)" style="color: red"
                                    pTooltip="Remove Preview and Change Logo" tooltipPosition="bottom">x</span>
                            </span>
                            <ng-container *ngIf="imageData.boolean">
                                <app-image-preview [imageData]="imageData"></app-image-preview>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!grid.grid_image">
                            <input type="file" class="form-control px-2 py-1"
                                [name]="'grid_Image'+sectionIndex+gridIndex" [(ngModel)]="template.grid_image"
                                (change)="getImageURL($event,'grid', sectionIndex, gridIndex)">
                            <div *ngIf="template.submitted" class="alert alert-danger">
                                <div *ngIf="grid.grid_image == '' || grid.grid_image == undefined">
                                    Grid Image is Required.
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div> -->
                <!-- <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Redirection URL : </label>

                        <p-dropdown [options]="allPages" name="{{'grid' + gridIndex}}" id="{{'grid' + gridIndex}}" filterBy="name" [filter]="true"
                            placeholder="Please Select" optionLabel="name" optionValue="slug"
                            [showClear]="false" [resetFilterOnHide]="true" [(ngModel)]="grid.slug" #gridRedirection="ngModel"></p-dropdown>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row m-0" style="padding-top: 12px;">
            <h3 class="border-white m-0">SEO Section</h3>
            <div class="row m-0">
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Meta Title : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here" [(ngModel)]="templateData.meta_title"  #meta_title="ngModel" name="meta_title">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
                    <div *ngIf="contactContent1.errors?.['required']">
                        Content is Required.
                    </div>
                </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Meta Description : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here" [(ngModel)]="templateData.meta_description"  #meta_description="ngModel" name="meta_description">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
                    <div *ngIf="contactContent1.errors?.['required']">
                        Content is Required.
                    </div>
                </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Focus Keywords : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.focus_keywords"  #focus_keywords="ngModel" name="focus_keywords">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Canonical Tags : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.canonical_tags"  #canonical_tags="ngModel" name="canonical_tags">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Schema Markup : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.schema_markup"   #schema_markup="ngModel" name="schema_markup  ">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Meta Robots : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.meta_robots"  #meta_robots="ngModel" name="meta_robots">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Og Title : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here" [(ngModel)]="templateData.og_title"  #og_title="ngModel" name="og_title">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Og Description : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here" [(ngModel)]="templateData.og_description"  #og_description="ngModel" name="og_description">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Og Image : </label>
                        <ng-container *ngIf="templateData.og_image">
                            <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                                <span pTooltip="Click to Show Logo Preview"
                                    (click)="onImageClick(templateData.og_image)"
                                    tooltipPosition="bottom">{{templateData.og_image | slice:0:10}}</span>
                                <span (click)="logoPreview('og_image')" style="color: red"
                                    pTooltip="Remove Preview and Change Logo" tooltipPosition="bottom">x</span>
                            </span>
                            <ng-container *ngIf="imageData.boolean">
                                <app-image-preview [imageData]="imageData"></app-image-preview>
                            </ng-container>
                        </ng-container>
    
                        <ng-container *ngIf="!templateData.og_image">
                            <!-- {{templateData.og_image == ''}}{{templateData.og_image == undefined}}{{templateData.og_image == null}} -->
                            <input type="file" class="form-control px-2 py-1" name="og_image" (change)="getImage($event)"
                               [(ngModel)]="template.og_image">
                        </ng-container>
                        <!-- <input type="text" class="form-control" placeholder="Enter Content Here" [(ngModel)]="templateData.og_image"  #og_image="ngModel" required name="og_image"> -->
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Og Alt : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.og_alt"  #og_alt="ngModel" name="og_alt">
                        
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
            <div *ngIf="contactContent1.errors?.['required']">
                Content is Required.
            </div>
        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="p-0"> Geo Location Meta Tags : </label>
                        <input type="text" class="form-control" placeholder="Enter Content Here"  [(ngModel)]="templateData.geo_location_meta_tags"  #geo_location_meta_tags="ngModel" name="geo_location_meta_tags">
                        <!-- <div *ngIf="template.submitted || contactContent1.touched" class="alert alert-danger">
                            <div *ngIf="contactContent1.errors?.['required']">
                                Content is Required.
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="defaultCheck1" [(ngModel)]="templateData.no_follow"  name="no_follow">
                        <label class="form-check-label" for="defaultCheck1">
                            No Follow 
                        </label>
                      </div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <div class="form-group">
                    <button class="p-button" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>
</div>