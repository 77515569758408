<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->

<ng-container *ngIf="displayModal">
    <app-dialog-box [dialogData]="customFromData" [displayBasic]="displayModal"
        (getDialogData)="getDialogData($event)"></app-dialog-box>
</ng-container>

<!-- <p-dialog header="Form With Value" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ng-container *ngIf="customFromData?.length > 0">
        <lib-renderer [sections]="customFromData"></lib-renderer>
    </ng-container>
</p-dialog> -->

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- ********************** Main Content ******************** -->

<!-- <div class="main-content d-flex flex-column"> -->

<!-- <app-dashboard-navbar></app-dashboard-navbar> -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
        <li class="item">{{Content.title}}</li>
    </ol>
</div>

<!-- ********************** End Breadcrumb Area  ******************** -->
<!-- ********************** Add Master Form Area  ******************** -->
<div class="add-listings-box">
    <!-- <div> -->
    <div class="theader">
        <!-- <h3>Facilities Approval</h3> -->
        <!-- <ng-template pTemplate="caption"> -->
        <div class="flex">
            <div class="d-flex">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                            'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                </span>
                <!-- <p-calendar [(ngModel)]="date2" dateFormat="dd-mm-yy" inputId="dateformat" placeholder="Select Date"
                    (onSelect)="newFilter($event)"></p-calendar> -->
                <p-button type="button" class="mx-2" (click)="reset(dt2)" pTooltip="Clear Filter"
                    tooltipPosition="bottom" icon="bx bx-reset"></p-button>
                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXlExport()"
                    class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPdfClick()"
                    class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            </div>
            <!-- <div>
                <p-button type="button" class="mr-2" routerLink="/user-panel/event-form" icon="bx bx-plus" label="Add"
                    iconPos="right">
                </p-button>
            </div> -->
        </div>
    </div>
    <p-table #dt2 [value]="enrolledEvent" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [tableStyle]="{'min-width': '50rem', 'width' : '100%'}" styleClass="p-datatable-sm p-datatable-customers"
        [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"  scrollable="true" scrollHeight="600px" [paginator]="true" currentPageReportTemplate="{totalRecords} of {{enrolledEvent.length == 0 ? 0 : enrolledEvent.length}}
            records" [globalFilterFields]="['event_name','status']">

        <ng-template pTemplate="header" style="min-height: 500px;">
            <tr>
                <th style="width: 71px;">S. No.</th>
                <th style="text-align: center;">Actions</th>
                <th>Payment Status
                </th>
                <th style="max-width: 135px;">Event Name
                </th>
                <th style="max-width: 135px;">Event Fee
                </th>
                <!-- <th>Title</th> -->
                <th class="text-center">Date</th>
                <!-- <th style="text-align: center;">Feedback</th> -->
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-forms let-i="rowIndex">
            <tr>
                <td class="text-center">
                    {{i+1}}
                </td>
                <td style="text-align: center;">
                    <div class="d-flex align-items-center justify-content-center">
                        <button pButton pRipple icon="far fa-eye"
                            class="p-element p-button-rounded p-button-text p-button-info p-button p-component"
                            pTooltip="Event Responses" tooltipPosition="bottom" (click)="onViewPress(i)"></button>
                    </div>
                </td>
                <td class="status">
                    <span *ngIf="forms?.status == 'Paid'" style="padding : 3px 48.71px;"
                        class="approved">{{forms?.status}}</span>
                    <span (click)="payment(forms)" *ngIf="forms?.status == 'Pay Now'" style="padding : 3px 48.71px;"
                        class="rejected" style="padding: 3px 36.8px;cursor:pointer;">{{forms?.status}}</span>
                    <span *ngIf="forms?.status == ''">-</span>
                    <!-- {{forms?.status}} -->
                </td>
                <td style="max-width: 135px;">
                    {{forms?.event_name}}
                </td>
                <td style="max-width: 135px;">
                    <span style="font-weight:500">
                        <!-- {{(forms?.status == 'Paid') ? ((forms?.payment?.amount | currency : 'INR')) : '-'}} -->
                        {{(localStorage?.role == 'Nominee 1' || localStorage?.role == 'Nominee 2') ?
                        (forms?.event?.member_fee | currency : 'INR') : (forms?.event?.non_member_fee | currency :
                        'INR')}}
                    </span>
                </td>
                <td class="text-center">
                    {{forms?.created_at | date: 'dd-MM-yyyy'}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No Enrolled Event.</td>
            </tr>
        </ng-template>
    </p-table>
    <!-- </div> -->
    <!-- <p-table *ngIf="tableSwitchState" #responseTable [value]="responses" dataKey="id" [rows]="10"
            [showCurrentPageReport]="true" styleClass="p-datatable-sm p-datatable-customers p-datatable-striped"
            [rowsPerPageOptions]="[10,25,50]" [paginator]="true" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['name']" [columns]="headers">
            <ng-template pTemplate="header" style="min-height: 500px;" let-columns>
                <tr>
                    <th>S. No.</th>
                    <th *ngFor="let col of columns">
                        <span *ngIf="col.title == 'userName'">User Name</span>
                        <span *ngIf="col.title == 'userEmailId'">User E-mail</span>
                        <span *ngIf="col.title != 'userName' && col.title != 'userEmailId'">{{col.title}}</span>
                    </th>

                     <th>User Name</th>
                    <th>User E-mail</th> -->
    <!-- <th>Actions</th> -->
    <!-- </tr>
            </ng-template> -->

    <!-- <ng-template pTemplate="body" let-items let-header="columns" let-i="rowIndex">
                <tr>
                    <td>
                        {{i+1}}
                    </td> -->
    <!-- <td>
                        {{items.userName}}
                    </td>
                    <td>
                        {{items.userEmailId}}
                    </td> -->
    <!-- <td *ngFor="let item of header">
                        {{items[item.title]}}
                    </td> -->
    <!-- <td> -->
    <!-- <button pButton pRipple icon="bx bx-pencil"
                            class="p-button-rounded p-button-text p-button-primary mr-2"
                            (click)="editEvent(forms)"></button>

                        <button pButton pRipple icon="bx bx-trash"
                            class="p-button-rounded p-button-text p-button-danger mr-2"
                            (click)="deleteEvent(forms.id)"></button> -->
    <!-- <button pButton pRipple icon="far fa-eye"
                            class="p-button-rounded p-button-text p-button-success"
                            (click)="onUserView(items)"></button>
                    </td> -->
    <!-- </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" class="text-center">No Data Found.</td>
                </tr>
            </ng-template>
        </p-table> -->
</div>
<ng-container *ngIf="payStatus">
    <app-payment-form [RecivedData]="paymentResponse"></app-payment-form>
</ng-container>
<!-- ********************** End Master View Area  ******************** -->