import { Injectable } from '@angular/core';
import { HTTPApi } from './httpapi.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  constructor(private http: HTTPApi) { }
  // ***************************************************CATEGORY API****************************************
  // post category form data
  postCategoryForm(data?: any) {
    return this.http.post('createCategory', data);
  }
  // get marketplace category list 
  getMarketplaceCategoryList() {
    return this.http.get('getCategory')
  }
  //get category by id
  getCategoryById(id?: any) {
    return this.http.get('getCategory/' + id);
  }
  // update category
  updateCategoryMaster(id: any, data?: any) {
    return this.http.post(`updateCategory/${id}`, data);
  }
  // delete category master
  deleteCategory(id: any) {
    return this.http.delete(`deleteCategory/${id}`);
  }
  // ***************************************************SUB CATEGORY API****************************************
  // post sub category form data
  postSubCategoryForm(data?: any) {
    return this.http.post('createSubCategory', data);
  }
  // get marketplace sub category list
  getMarketplaceSubCategoryList(id: any) {
    return this.http.get(`getSubCategory/${id}`)
  }
  //get sub category by id
  getSubCategoryById(id?: any) {
    return this.http.get('getCategory/' + id).toPromise();
  }
  // update subcategory
  updateSubCategoryMaster(id: any, data?: any) {
    return this.http.post(`updateSubCategory/${id}`, data);
  }
  // delete subcategory master
  deleteSubCategory(id: any) {
    return this.http.delete(`deleteSubCategory/${id}`);
  }
  // ***************************************************BRAND API****************************************
  // post sub category form data
  postBrandForm(data?: any) {
    return this.http.post('createBrand', data);
  }
  // get marketplace sub category list
  getBrandMasterList() {
    return this.http.get(`getBrand`)
  }
  //get brand by id
  getBrandById(id?: any) {
    return this.http.get('getBrand/' + id);
  }
  // update subcategory
  updateBrandMaster(id: any, data?: any) {
    return this.http.post(`updateBrand/${id}`, data);
  }
  // delete subcategory master
  deleteBrand(id: any) {
    return this.http.delete(`deleteBrand/${id}`);
  }
  // ***************************************************ADD PRODUCT API****************************************
  addProduct(data: any) {
    return this.http.post('createProduct', data);
  }

  addmarketplaceproduct(object: any) {
    return this.http.post('createMarketPlace', object);
  }
  updatemarketplaceproducts(id:any , object:any){
    return this.http.post(`updateMarketPlaceListing/${id}`, object);
  }
  getProducts() {
    return this.http.get('getMyProducts');
  }
  getAllProducts() {
    return this.http.get('getProducts');
  }
  editProduct(id?: any, data?: any) {
    console.log(id);
    return this.http.post(`updateProduct/${id}`, data);
  }
  deleteMaster(id?: any) {
    return this.http.delete(`deleteProduct/${id}`);
  }
  approveProduct(id?: any, data?: any) {
    return this.http.post(`approveProducts/${id}`, data);
  }

  deleteProduct(id){
    return this.http.delete(`deleteListing/${id}`)
  }
  // get product by id
  getMarketPlaceProductById(id: any) {
    return this.http.get(`getProductsById/${id}`);
  }
  
  getMarketproductsById(id:any){
    return this.http.get(`getMarketPlaceById/${id}`);
  }
  // enquiry form api
  enquiryProduct(data?: any) {
    return this.http.post('enquire', data);
  }
  // get my generated enquiry list for marketplace product
  getGeneratedEnquiry() {
    return this.http.get('enquiredFor');
  }
  // get all enquiries list genetraed by user (how many enquiries i got for my products)
  getEnquiries() {
    return this.http.get('enquiries');
  }
  // new get products api
  getMarketplaceProducts(id?:any) {
    return this.http.get(`getMarketPlaceListings`)
  }
  getMarketplaceProductsById(id?:any) {
    return this.http.get(`getMarketPlaceById/${id}`)
  }
  approveMarketplace(data?: any) {
    return this.http.post(`approvedMarketPlaces`, data)
  }

  approveMarketplaceProducts(id?: any, data?: any) {
    return this.http.post(`approveMarketPlaceListing/${id}`, data,)
  }
  getmyListing() {
    return this.http.get(`getMyListings`);
  }
  // ***************************************************APPROVE PRODUCT API****************************************




  // filter api

  // approvedProducts
  getMarketPlaceProductFilters(object: any) {
    return this.http.post('approvedProducts', object);
  }
  
  getSubCategoryUsingId(id){
    return this.http.get('getSubCategory/'+ id );
  }
}
