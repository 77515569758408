import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit {
  // @ViewChild('paymentForm') paymentForm: any;

  @ViewChild('paymentForm', { static: true }) paymentForm;
  @Input() RecivedData: any;
  @Output() SendingData: any = new EventEmitter<any>();
  displayBasic: boolean = false;
  MID: string = environment.MID;
  trustMID: string = environment.TrustMID;
  merchantRequest: any;
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    if (this.RecivedData?.formState == true) {
      this.displayBasic = true;
      console.log(this.RecivedData)
      this.merchantRequest = this.RecivedData.merchantRequest;
      // this.form.patchValue
      setTimeout(() => {
        this.testMethod();
      }, 5000);
    }
  }

  public testMethod(): void {
    // debugger;
    this.paymentForm.nativeElement.submit();
    console.log(this.paymentForm.value)
  }
}
