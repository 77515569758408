import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  pdfData: any[];
  filteredPDFData: any[];
  cols: { field: string; header: string; }[];
  exportColumns: { title: string; dataKey: string; }[];

  constructor(
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public apiService: ApiService
  ) { }

  @ViewChild('dt1') dt1 : Table;

  filterVal : string = ''

  settingData : any = {};

  clonedSetting : any = []

  settingTableData : any = []

  breadcrumb: any[] = [
    {
      title: 'Settings',
      subTitle: 'Masters',
    },
  ];

  ngOnInit() {
    this.getSettingData();

    this.cols = [
      { field: 'sr_no', header: 'S No' },
      { field: 'name', header: 'Name' },
      { field: 'value', header: 'Value' }
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  async getSettingData() {
    this.spinner.show();
    await this.apiService.getSettings().then((res:any) => {
      console.log(res);
      this.settingTableData = res?.data
    })
    this.spinner.hide();
  }

  async createSetting(form:NgForm) {
    if(form.valid) {
      let object = {
        name : this.settingData?.setting_name,
        value : this.settingData?.setting_value
      }
      await this.apiService.createSetting(object).then((res:any) => {
        console.log(res);
        this.getSettingData();
        this.messageService.add({
          severity: 'success',
          detail: 'Setting Created Successfully!',
        });
      })
    }
    else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill Required Value!',
      });
    }
  }

  reset(dt1) {
    dt1.reset();
    this.filterVal = '';
  }

  onXlExport() {
    let data : any = []
    let filteredBy
    let dataForExcel : any = []
    if(this.dt1.filteredValue != null) {
      data = this.dt1.filteredValue;
      filteredBy = 'Global'
    }
    else {
      data = this.settingTableData;
    }
    data.forEach((response:any, index:any) => {
      dataForExcel.push({'Sr No' : index+1, Name : response?.name, Value : response?.value})
    });
    this.apiService.exportExcel(dataForExcel, 'Setting' , filteredBy)
  }

  exportPdf() {
    this.pdfData = [];
    let filteredBy
    this.filteredPDFData = [];
    if (this.dt1.filteredValue != null) {
      this.filteredPDFData = this.dt1.filteredValue;
      filteredBy = "Global"

    } else if (this.dt1.filteredValue == null) {
      this.filteredPDFData = this.settingTableData;
    }
    console.log(this.filteredPDFData);
    
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      console.log(this.filteredPDFData[i]);
      
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name,
        value: this.filteredPDFData[i]?.value
      });
    }
    this.apiService.exportPdf(
      this.pdfData,
      this.exportColumns,
      'settings',
      filteredBy
    );
  }

  bloackAlphabet(event: any) {
    if (event.key == 'Tab') {
      return;
    }
    const keyCode = event.keyCode;
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  onRowEditCancel(data: any, i: number) {
    console.log(this.clonedSetting);
    let spliceIndex : any;
    this.clonedSetting.forEach((element:any, index :any) => {
      console.log(index);
      if(element.index == i) {
        spliceIndex = index;
        this.settingTableData[i] = JSON.parse(element?.data);
      }
    });
    this.clonedSetting.splice(spliceIndex, 1)
    this.dt1.reset();
    console.log(this.clonedSetting);
  }

  async editSetting(setting: any, i:any) {
    console.log(setting.name);
    if(setting?.name) {
      let object = {
        name : setting?.name,
        value : setting?.value
      }
      await this.apiService.updateSetting(setting?.id, object).then((res:any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });

        this.getSettingData();

        let spliceIndex : any;
        this.clonedSetting.forEach((element:any, index :any) => {
          console.log(index);
          if(element.index == i) {
            this.clonedSetting.splice(spliceIndex, 1);
          }
        });

      })
    }
  }

  editSettingConfirm(event: any, i:any) {
    let object = {
      index : i,
      data : JSON.stringify(event)
    }
    this.clonedSetting.push(object);
    console.log(this.clonedSetting);
  }

  enableAction(event:any, i:any) {
    console.log(event.target.value);
    let object = {
      flag : this.settingTableData[i].flag == 0 ? 1 : 0
    }
    this.apiService.updateSetting(this.settingTableData[i]?.id, object).then((res:any) => {
      console.log(res);
      this.getSettingData();
    })
  }

}
