import { Injectable } from '@angular/core';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HTTPApi) { }
  //get all event list
  getEventsList() {
    return this.http.get('event');
  }

  //to create event 
  createEvent(formData: any) {
    return this.http.post('event', formData)
  }

  //get event by id
  getEventById(id: any) {
    return this.http.get('event/' + id).toPromise();
  }

  //to update event form 
  updateEventForm(id: any, formData: any) {
    return this.http.post('event/' + id, formData)
  }

  //to delete an event
  deleteEvent(id: any) {
    return this.http.delete('event/' + id)
  }

  //to get event response
  getEventResponses(id: number) {
    return this.http.get(`response/${id}`).toPromise();
  }

  //get member list
  getMemberList() {
    return this.http.get('get-member-list');
  }

  //store selected member list
  storeSelectedMember(data: any) {
    return this.http.post('store-event-members', data).toPromise();
  }

  //get selected Member for particular event
  getAttachedMembers(id: any) {
    return this.http.get('get-event-members/' + id).toPromise();
  }

  //get all users for offline event registration
  getAllUsers() {
    return this.http.get('get_all_user').toPromise();
  }

  //send feedback form to user
  sendFeedback(id: any): Promise<any> {
    return this.http.get('send_feedback_form_link/' + id).toPromise();
  }

  //get feedback form 
  getFeedbackFrom(id: any) {
    return this.http.get('get_feedback_form/' + id).toPromise();
  }

  //store feedback form response
  storeFeedbackResponse(formData: any) {
    return this.http.post('store_feedback_response', formData).toPromise();
  }

  //get all nominee list
  getAllMemberList() {
    return this.http.get('get-nominee-list').toPromise();
  }

  //get all selected member
  getSelectedMemberList(id: any) {
    return this.http.get('get-event-nominee-list/' + id).toPromise();
  }

  //store member invite list
  sendMemberInvite(data: any) {
    return this.http.post('store-event-nominees', data).toPromise();
  }
  // get all past event list
  getPastEvents() {
    return this.http.get('dashboard/events');
  }
}
