import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HTTPApi } from './httpapi.service';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HTTPApi) {}

  //regular membership fees API
  regularMemberFees(order_id?: any, id?: any, transactionInfo?: any) {
    return this.http.post(
      `regular-members/pay/${order_id}/${id}`,
      transactionInfo
    );
  }
  // non member exporter payment
  nonmeberPayment(order_id?: any, id?: any, data?: any) {
    return this.http.post(`non-member-exporter/pay/${order_id}/${id}`, data);
  }
  // hwm payment fees API
  hwmPayment(order_id?: any, id?: any, data?: any) {
    return this.http.post(`hwm/pay/${order_id}/${id}`, data);
  }
  servicePayment(id?: number, order_id?: any, transactionInfo?: any) {
    // debugger;
    return this.http.post(`payment/${order_id}`, transactionInfo);
  }
  facilityPayment(transactionInfo?: any, id?: number, order_id ? : any) {
    // debugger;
    return this.http.put(`payFacility/${order_id}/${id}`, transactionInfo);
  }

  visaPaymentStatusUpdate(
    id: number,
    order_id: string,
    paymentStatusObject: any
  ): Observable<any> {
    return this.http.post(`payVisa/${order_id}/${id}`, paymentStatusObject);
  }
  memberDataPaymentStatusUpdate(id: number, data: any): Observable<any> {
    return this.http.post(`update-status/${id}`, data);
  }
  getPaymentData(order_id) {
    return this.http.get(`payment/${order_id}`);
  }
}
