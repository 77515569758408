import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonClass } from 'src/app/common';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
})
export class CallToActionComponent implements OnInit {
  @Input() callToAction: any;
  @Input() CTAURL: any;

  localStorage: any;
  pageSlug: any;
  modalCheck: boolean = false;

  constructor(
    private route: Router,
    private commonFunction: CommonClass,
    private websiteService: WebsiteService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log(this.router.snapshot.params.slug)
    this.pageSlug = this.router.snapshot.params.slug;
  }

  callToActionButton() {
    this.localStorage = this.commonFunction.getLocalStorage();
    if (this.localStorage) {
      console.log(this.modalCheck);
      this.route.navigateByUrl(this.CTAURL ?? '/user-panel/dashboard');
    } else {
      this.websiteService.modalCheck.next(true);
      // this.websiteService.modalCheck.subscribe((res:any) => {
      //   console.log(res);
      //   res = true;
      //   console.log(res);
      // })
      // this.modalCheck = false;
      // this.modalCheck = true;
    }
  }

  getModalValue(event: any) {
    alert('hello');
    console.log(event);
  }
}
