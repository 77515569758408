<!-- Breadcrumb Area -->
<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->
<!-- *ngIf="hasmember !== 'hwm-work-que' && hasmember !== 'membership-approved' && hasmember !== 'voter-list' && hasmember !== 'membership-status'" -->
<!-- Start -->
<div class="Master-view-box">
    <div class="theader">
        <div class="d-flex" >
            <!-- <span class="p-input-icon-left ml-auto mr-2">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value,
                        'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
            </span>
            <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
                [pTooltip]="tooltipText" tooltipPosition="bottom">
            </p-button> -->
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                class="p-button-success" pTooltip="XLS" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
                class="p-button-warning mx-2" pTooltip="PDF" tooltipPosition="bottom"></button>
        </div>
    </div>
    <!-- ----------------------------------------------------------------------- -->
    <!--                            HWM member tab                          -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- <div *ngFor="let item of renewalHistory; index as index">
        <p-accordion (onOpen)="getStringName(index, item?.key)">
            <p-accordionTab header="{{item?.key}}">
                <p-table #dt1 [value]="groupByData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                    styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]"
                    [paginator]="true" responsiveLayout="scroll"
                    currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records"
                    [globalFilterFields]="['company_name', 'amount', 'membership_year', 'email', 'membership_no']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 80px;">
                                S. No.</th>
                            <th style="width: 130px;" class="capitalize">Company Name</th>
                            <th style="width: 200px;" class="capitalize">Membership Number</th>
                            <th style="width: 170px;" class="capitalize">Pending Amount</th>
                            <th style="width: 190px;" class="capitalize">Membership Year</th>
                            <th style="width: 50px;" class="capitalize">Email</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-items let-i="rowIndex">
                        <tr class="p-selectable-row">
                            <td>{{i+1}}</td>
                            <td class="capitalize">{{items.company_name ?? '-'}}</td>
                            <td class="capitalize">{{items.membership_no ?? '-'}}</td>
                            <td class="capitalize">{{items.amount ?? '-'}}</td>
                            <td class="capitalize">{{items.membership_year ?? '-'}}</td>
                            <td>{{items?.email ?? '-'}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="3">Total</td>
                            <td>{{this.totalAmount | indianCurrency}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7">No Data Found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div> -->
    <!-- BOOTSTRAP ACCORDIAN -->
    <!-- { console.log(renewalHistory); } -->
    <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let item of renewalHistory; index as index">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" (click)="getData(item?.key)" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                    [attr.data-bs-target]="'#collapse'+index" [attr.aria-controls]="'collapse'+index">
                    {{item?.key}} 
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample" [id]="'collapse'+index" [attr.aria-labelledby]="'heading'+index">
                <div class="accordion-body">
                    <div class="totalRecords">
                        Total Records: {{ groupByData.length }}
                    </div>

                    <p-table #dt1 [value]="groupByData" dataKey="id" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead"                        styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]"
                        [paginator]="true" responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
                        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records"
                        [globalFilterFields]="['company_name', 'amount', 'membership_year', 'email', 'membership_no']">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 80px;">
                                    S. No.</th>
                                <th style="width: 130px;" class="capitalize">Company Name</th>
                                <th style="width: 200px;" class="capitalize">Membership Number</th>
                                <th style="width: 170px;" class="capitalize">Pending Amount</th>
                                <th style="width: 190px;" class="capitalize">Contact No.</th>
                                <th style="width: 50px;" class="capitalize">Email</th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-items let-i="rowIndex">
                            <tr class="p-selectable-row">
                                <td>{{i+1}}</td>
                                <td class="capitalize">{{items.company_name ?? '-'}}</td>
                                <td class="capitalize">{{items.membership_no ?? '-'}}</td>
                                <td class="capitalize">{{((items.amount.replace('.00', '')) | indianCurrency) ?? '-'}}
                                </td>
                                <td class="capitalize">{{items.mobile ?? '-'}}</td>
                                <td>{{items?.email ?? '-'}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="3">Total</td>
                                <td>{{this.totalAmount | indianCurrency}}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">No Data Found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <!-- HWM Work Queue start -->
    <p-table [value]="memberdata" [rows]="10" [showCurrentPageReport]="true"
        styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
        responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records"
        *ngIf="hasmember === 'hwm-work-que'">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 80px;">S. No.</th>
                <th style="width: 130px;" class="capitalize">Name of Company</th>
                <th style="width: 200px;" class="capitalize">Type of Request</th>
                <th style="width: 170px;" class="capitalize">M. No</th>
                <th colspan="4" style="min-width: 170px;" class="capitalize">Payment Status</th>
                <th style="width: 170px;" class="capitalize">Document missing.</th>
                <th style="width: 170px;" class="capitalize">Any communication made</th>
                <th style="width: 170px;" class="capitalize">Any specific remarks</th>
                <th style="width: 170px;" class="capitalize">Location</th>
                <th style="width: 190px;" class="capitalize">Remark</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>MF</th>
                <th>TDF</th>
                <th>RNF</th>
                <th>REF</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr>
                <td>{{ i+1 }}</td>
                <td class="capitalize">{{ rowData.name ?? '-' }}</td>
                <td class="capitalize">{{ rowData.request ?? '-' }}</td>
                <td class="capitalize">{{ rowData.membership_no ?? '-' }}</td>
                <td class="capitalize">{{ rowData.mf ?? '-' }}</td>
                <td class="capitalize">{{ rowData.tdf ?? '-' }}</td>
                <td class="capitalize">{{ rowData.rnf ?? '-' }}</td>
                <td class="capitalize">{{ rowData.ref ?? '-' }}</td>
                <td class="capitalize">{{ rowData.doc ?? '-' }}</td>
                <td class="capitalize">Not Available</td>
                <td class="capitalize">{{ rowData.remark ?? '-' }}</td>
                <td class="capitalize">{{ rowData.location ?? '-' }}</td>
                <td class="capitalize">{{ rowData.ref ?? '-' }}</td>
                <!-- Add more cells as needed -->
            </tr>
        </ng-template>
    </p-table>
    <!-- HWM Work Queue start -->



    <!-- voter-list start  -->
    <div *ngIf="hasmember === 'voter-list'" class="table-auto">
        <div style="margin-bottom: 25px; ">
            <div class="row">
                <div class="col-md-4"> <p-dropdown [options]="yearJson" placeholder="Please Select Year" [(ngModel)]="voterYear" optionLabel="name" optionValue="name"
                    [showClear]="false" [resetFilterOnHide]="true" (onChange)="changeEvent($event, 'voter-list')">
                </p-dropdown></div>
            </div>
         
        </div>
        <div class="totalRecords">
            Total Records: {{ memberdata.length }}
        </div>
    <p-table [value]="memberdata" [rows]="10" [showCurrentPageReport]="true" 
        styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
        responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records" *ngIf="status"
        >
        <ng-template pTemplate="header">
             
            <tr>
                <th style="width: 62px">S. No.</th>
                <th style="width: 200px">Membership No</th>
                <th style="width: 200px" pSortableColumn="company_name">
                    Company Name / Organisation<p-sortIcon field="name"> </p-sortIcon>
                </th>
                <th style="width: 150px">First Nominee</th>
                <th style="width: 150px">First Nominee Mobile No</th>
                <th style="width: 250px">First Nominee Email</th>
                <th style="width: 250px">Second Nominee</th>
                <th style="width: 200px">Second Nominee Mobile No</th>
                <th style="width: 250px">Second Nominee Email</th>
                <th style="width: 250px">Address</th>
                <th style="width: 100px">City</th>
                <th style="width: 50px">Grade</th>
                <th style="width: 250px">Category</th>
                <th style="width: 250px">Classification</th>
                <th style="width: 50px">Code</th>
                <th style="width: 250px">Year of Joining</th>


                <!-- <th style="width: 250px">Email</th>
                <th style="width: 200px">Website</th>
                <th style="width: 200px">
                    Office Telephone
                </th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr>
                <td>{{ i+1 }}</td>
                <td class="capitalize">{{ rowData.membership_no ?? '-' }}</td>
                <td class="capitalize">{{ rowData.company_name ?? '-' }}</td>

                <td class="capitalize">{{ rowData.nominee1.name ?? '-' }}</td>
                <td class="capitalize">{{ rowData.nominee1.mobile ?? '-' }}</td>
                <td class="capitalize">{{ rowData.nominee1.email?? '-' }}</td>
                <td class="capitalize">{{ rowData.nominee2.name ?? '-' }}</td>
                <td class="capitalize">{{ rowData.nominee2.mobile ?? '-' }}</td>
                <td class="capitalize">{{ rowData.nominee2.email?? '-' }}</td>

                <td class="capitalize">{{ rowData.company_address[0].address?? '-' }}</td>

                <td class="capitalize">{{ rowData.company_address[0].city ?? '-' }}</td>

                <td class="capitalize">{{ rowData.member_category.code ?? '-' }}</td>
                <td class="capitalize">{{ rowData.member_category.name ?? '-' }}</td>
                <td class="capitalize">{{ rowData.member_classification.name?? '-' }}</td>
                <td class="capitalize">{{ rowData.member_classification.code?? '-' }}</td>
                <td class="capitalize">{{ rowData?.member_since ? (rowData?.member_since | date:'dd-MM-yyyy') : '-' }}</td>               
                <!-- <td class="capitalize">{{ rowData.email ?? '-' }}</td>
                <td class="capitalize">{{ rowData.website ?? '-' }}</td>
                <td class="capitalize">{{ rowData.office_telephone ?? '-' }}</td> -->
            </tr>
        </ng-template>
    </p-table>
</div>
    <!-- voter-list  end -->



    <!-- hwm-renewal-fee  start -->
    <p-table [value]="memberdata" [rows]="10" [showCurrentPageReport]="true"
        styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
        responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records"
        *ngIf="hasmember === 'hwm-renewal-fee'">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 62px">S. No.</th>
                <th style="width: 200px">Name of Member </th>
                <th style="width: 200px" pSortableColumn="company_name">
                    M.No <p-sortIcon field="name"> </p-sortIcon>
                </th>
                <th style="width: 250px">Years pending</th>
                <th style="width: 200px">Due Amount</th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">


        </ng-template>
    </p-table>


    <!-- hwm-renewal-fee end -->
    <!-- membership-updates  -->
    <div *ngIf="hasmember === 'membership-status'"> 
        <div class="row mb-2">
            <div class="col-md-4">
                <p-dropdown [options]="yearJson" placeholder="Please Select Year" optionLabel="name" optionValue="name" class="mb-2"
                [showClear]="false" [resetFilterOnHide]="true" (onChange)="changeEvent($event, 'membership-status')">
            </p-dropdown>
            </div>
        </div>
       
    <div *ngIf="status">
        <p-table [value]="totalData" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead-withoutheader"
        styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"  scrollable="true" scrollHeight="600px">
        <ng-template pTemplate="header" let-rowdata class="member-status-table">
            <tr>
                <th colspan="4" style="background-color: white;">MEMBERSHIP STATUS</th>
            </tr>

            <tr style="border: 1px solid  lightgrey;">
                <th style="width: 80px" rowspan="2">S. No.</th>
                <th style="width: 62px" rowspan="2">Particulars</th>
                <th class="text-center" colspan="2"> Financial Year </th>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <th class="text-center" style="border: none">Previous</th>
                <th class="text-center" style="border: none">Current</th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowdata class="member-status-table">
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">1.</td>
                <td class="tabledata" style="font-weight: bold;">New Memberships </td>
                <td class="text-center tabledata">{{memberdata?.previous_fn_new_member_count}}</td>
                <td class="text-center tabledata">{{memberdata?.current_fn_new_member_count}}</td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">2.</td>
                <td class="tabledata" style="font-weight: bold;">Revision in Memberships </td>
                <td class="text-center tabledata">{{memberdata?.previous_fn_revision_count}}</td>
                <td class="text-center tabledata">{{memberdata?.current_fn_revision_count}}</td>

            </tr>

            <!-- <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">3.</td>
                <td class="tabledata" style="font-weight: bold;">Membership Withdrawal/
                    Termination </td>
                <td class="tabledata">123 </td>
                <td class="tabledata">199 </td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">4.</td>
                <td class="tabledata" style="font-weight: bold;">Latest Membership Number </td>
                <td class="tabledata">77 </td>
                <td class="tabledata">90</td>

            </tr> -->
        </ng-template>



    </p-table>
    <p-table [value]="totalData" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead-withoutheader"
        styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records">
        <ng-template pTemplate="header" let-rowdata>


            <tr>
                <th colspan="4" style="background-color: white;">FINANCIAL UPDATES</th>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <th>S.No </th>
                <th>Particulars </th>
                <th>Details </th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowdata class="member-status-table">
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">1.</td>
                <td class="tabledata" style="font-weight: bold;">New Membership Fee </td>
                <!-- <td class="tabledata">Rs. {{memberdata?.current_fn_new_membership_total_amount}}</td> -->
                <td class="tabledata">Rs. {{ ((memberdata?.current_fn_new_membership_total_amount.replace('.00', '')) | indianCurrency) ?? '-' }}</td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">2.</td>
                <td class="tabledata" style="font-weight: bold;">Membership Revision Amount </td>
                <!-- <td class="tabledata">Rs. {{memberdata?.current_fn_revision_total_amount}}</td> -->
                <td class="tabledata">Rs. {{ ((memberdata?.current_fn_revision_total_amount.replace('.00', '')) | indianCurrency) ?? '-' }}</td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">3.</td>
                <td class="tabledata" style="font-weight: bold;">Membership Renewal Amount </td>
                <!-- <td class="tabledata">Rs. {{memberdata?.current_fn_renewal_amt}}</td> -->
                <td class="tabledata">Rs. {{ ((memberdata?.current_fn_renewal_amt.replace('.00', '')) | indianCurrency) ?? '-' }}</td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">4.</td>
                <td class="tabledata" style="font-weight: bold;">Trust Development Fee </td>
                <!-- <td class="tabledata">Rs. {{memberdata?.current_fn_trust_development_total_amount}}</td> -->
                <td class="tabledata">Rs. {{ ((memberdata?.current_fn_trust_development_total_amount.replace('.00', '')) | indianCurrency) ?? '-' }}</td>
            </tr>

        </ng-template>

    </p-table>
    <p-table [value]="totalData" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead-withoutheader"
        styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
        currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records">
        <ng-template pTemplate="header">


            <tr>
                <th colspan="3" style="background-color: white;">MEMBERSHIP RENEWAL STATUS</th>
            </tr>

            <tr style="border: 1px solid  lightgrey; ">
                <th class="tabledata">S.No</th>
                <th class="tabledata">Particulars </th>
                <th class="tabledata">Details </th>
            </tr>
            </ng-template> 
            <ng-template pTemplate="body" let-rowdata class="member-status-table">
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">1.</td>
                <td class="tabledata" style="font-weight: bold;">Total Members </td>
                <td class="tabledata">{{memberdata?.total_members}}</td>
            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">2.</td>
                <td class="tabledata" style="font-weight: bold;">Fully Paid</td>
                <td class="tabledata">{{memberdata?.fully_paid}}</td>

            </tr>
            <tr style="border: 1px solid  lightgrey;">
                <td class="tabledata" style="font-weight: bold;">3.</td>
                <td class="tabledata" style="font-weight: bold;">
                    Due </td>
                <td class="tabledata">{{memberdata?.due}}</td>
            </tr>



        </ng-template>

    </p-table>
    </div>
        
</div>
   

<!-- membership-updates end -->

    <!-- New membership Approved start -->
    <div class="container" *ngIf="hasmember === 'membership-approved'">
        <div class="row mt-3 mb-3">
            <div class="col-md-6">
                <div class="form-group">
                    <!-- <label for="from">From</label> -->
                    <!-- <input type="date" class="form-control" id="from" [(ngModel)]="to"> -->
                    <!-- <p-calendar [(ngModel)]="rangeDates" selectionMode="range" showClear="true" dateFormat="dd/mm/yy"
                    placeholder="DD/MM/YYYY" [showIcon]="true" (onSelect)="GetMembership($event, 'getApprovedHwmMember')"></p-calendar> -->
                    <label>Select Date Range</label>
                    <p-calendar [(ngModel)]="rangeDates" (onSelect)="GetMembership($event, 'getApprovedHwmMember')" #calendar [readonlyInput]="true" selectionMode="range" [showIcon]="true" placeholder="Select Date Range"></p-calendar>
                    <!--  (input)="GetMembership($event)" -->
                </div>
            </div>
            <!-- <div class="col-md-6">
                <div class="form-group">
                    <label for="to">To</label>
                    <input type="date" class="form-control" id="to" [(ngModel)]="from" (input)="GetMembership($event, 'getApprovedHwmMember')">
                </div>
            </div> -->
        </div>
        <ng-container *ngIf="this.status">
            <div class="totalRecords">
                Total Records: {{ memberdata.length }}
            </div>
            <p-table [value]="memberdata" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead-withoutheader"
            styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
            responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
            currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 80px;">S. No.</th>
                    <!-- <th style="width: 130px;" class="capitalize">Month</th> -->
                    <!-- <th style="width: 200px;" class="capitalize">Mobile Number</th> -->
                    <th style="width: 170px;" class="capitalize">Name of Company</th>
                    <th style="width: 170px;" class="capitalize">Membership No.</th>
               
                    <th style="width: 170px;" class="capitalize">Place</th>
                    <th style="width: 170px;" class="capitalize">Membership Fee</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr>
                    <td>{{ i+1 }}</td>
                    <!-- <td class="capitalize">{{ getMonthName(rowData.created_at) ?? '-' }}</td> -->
                    <!-- <td class="capitalize">{{ rowData.request ?? '-' }}</td> -->
                    <td class="capitalize" style="min-width: 170px;">{{ rowData.company_name ?? '-' }}</td>
                    <td class="capitalize">{{ rowData.membership_no ?? '-' }}</td>
                    <td class="capitalize">{{ rowData.city ?? '-' }}</td>
                    <!-- <td class="capitalize">{{ rowData.amount ?? '-' }}</td> -->
                    <td class="capitalize">{{ ((rowData.amount.replace('.00', '')) | indianCurrency) ?? '-' }}</td>
                </tr>
            </ng-template>
        </p-table>
        <div>
            <p-table [value]="totalData" [rows]="10" [showCurrentPageReport]="true" class="sticky-thead-withoutheader"
            styleClass="p-datatable-sm p-datatable-customers" [paginator]="false"
            responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
            currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 80px;">S. No.</th>
                    <th style="width: 130px;" class="capitalize">Particulars</th>
                    <!-- <th style="width: 200px;" class="capitalize">Mobile Number</th> -->
                    <th style="width: 170px;" class="capitalize">Details</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr>
                    <td>{{ 1 }}</td>
                    <td class="capitalize">Total Amount Received</td>
                    <!-- <td class="capitalize">{{ rowData.request ?? '-' }}</td> -->
                    <td class="capitalize">{{rowData.total_amount ?? '-' }}</td>
                </tr>
                <tr>
                    <td>{{ 2 }}</td>
                    <td class="capitalize">20% Amount to be retained by UCCI</td>
                    <!-- <td class="capitalize">{{ rowData.request ?? '-' }}</td> -->
                    <td class="capitalize">{{rowData.retained_amount ?? '-' }}</td>
                </tr>
                <tr>
                    <td>{{ 3 }}</td>
                    <td class="capitalize">Balance amount payable to ReSL</td>
                    <!-- <td class="capitalize">{{ rowData.request ?? '-' }}</td> -->
                    <td class="capitalize">{{rowData.balance_amount ?? '-' }}</td>
                </tr>
                
            </ng-template>
        </p-table>
        </div>
        </ng-container>
      
    </div>
    <!-- HWM Work Queue end -->




    <!-- member data report start -->

<div *ngIf="hasmember === 'member-data'">
    <div class="row mt-3 mb-3">
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label for="from">From</label> -->
                <!-- <input type="date" class="form-control" id="from" [(ngModel)]="to"> -->
                <!-- <p-calendar [(ngModel)]="rangeDates" selectionMode="range" showClear="true" dateFormat="dd/mm/yy"
                placeholder="DD/MM/YYYY" [showIcon]="true" (onSelect)="GetMembership($event, 'getApprovedHwmMember')"></p-calendar> -->
                <label>Select Date Range</label>
                <p-calendar [(ngModel)]="rangeDates" (onSelect)="GetMembership($event, 'member-data')" #calendar [readonlyInput]="true" selectionMode="range" [showIcon]="true" placeholder="Select Date Range"></p-calendar>
                <!--  (input)="GetMembership($event)" -->
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="form-group">
                <label for="to">To</label>
                <input type="date" class="form-control" id="to" [(ngModel)]="from" (input)="GetMembership($event, 'getApprovedHwmMember')">
            </div>
        </div> -->
    </div>
    <p-table [value]="memberdata" [rows]="10" [showCurrentPageReport]="true"
    styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
    responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
    currentPageReportTemplate="{totalRecords} of {{Datalength == 0 ? 0 : Datalength}} records" *ngIf="status">
    <ng-template pTemplate="header">
        <tr style="border: 1px solid  lightgrey;">
            <th style="width: 80px" rowspan="2" class="memberData">M.No</th>
            <th style="width: 62px" rowspan="2" class="memberData">Name of Member</th>
            <th style="width: 62px" rowspan="2" class="memberData">Joining Date</th>
            <th colspan="4" class="memberData"> Communication Details </th>
            <th rowspan="2" class="memberData">Waste Disposal Details </th>
            <th colspan="2" class="memberData"> Amendment in Membership if any</th>
            <th rowspan="2" class="memberData">Documents uploaded(All documents uploaded) </th>
            <th rowspan="2" class="memberData">Existing an Previous Membership Letters </th>
            <th rowspan="2" class="memberData">Payment Invoices </th>
        </tr>
        <tr style="border: 1px solid  lightgrey;">
            <th class="memberData">Email</th>
            <th class="memberData">Contact</th>
            <th class="memberData">Factory
                Address</th>
            <th class="memberData">Registered
                Address </th>
            <th class="memberData">Year</th>
            <th class="memberData">Amendment done </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr>
            <td>{{ rowData?.member?.membership_no ?? '-' }}</td>
            <td>{{ parsedData(rowData?.member_data_json, 'member_data')?? '-' }}</td>
            <td>{{ rowData.member?.member_since ?? '-' }}</td>
            <td>{{parsedData(rowData?.member_data_json, 'email1')?? '-'}}, {{ parsedData(rowData?.member_data_json, 'email2')?? '-' }}</td>
            <td>{{parsedData(rowData?.member_data_json, 'contact1')?? '-' }},{{ parsedData(rowData?.member_data_json, 'contact2')?? '-' }}</td>
            <td>{{ rowData?.member?.hwm_details[0]?.factory_address?.address ?? '-' }}</td>
            <td>{{ rowData?.member?.company_address[1]?.address ?? '-' }}</td>
            <td>{{ parsedData(rowData.member?.hwm_details[0]?.hw_details, 'hwm_details')?? '-' }}</td>
            <td>{{ (rowData?.created_at | date: 'yyyy') || '-' }}</td>

            <td>{{ parsedData(rowData?.changed_in, 'amendment')?? '-' }}</td>
            <td><a>{{ rowData.member?.supporting_docs?.document ?? '-' }}</a></td>
            <td><a href="{{ucciBaseUrl}}/storage/hwm/revision/{{rowData.id}}revision_letter.pdf"
                style=" font-size: 0.8rem;
            color: var(--mainColor);  overflow-wrap: break-word;;
            font-weight: 700; text-decoration: underline;" target="_blank"
                download="{{ucciBaseUrl}}/storage/hwm/revision/{{rowData.id}}revision_letter.pdf">
                Revision Letter</a></td>
            <td><a href="{{ucciBaseUrl}}/storage/invoices/{{rowData?.invoice_pdf}}"
                style=" font-size: 0.8rem;
            color: var(--mainColor);  overflow-wrap: break-word;;
            font-weight: 700; text-decoration: underline;" target="_blank"
                download="{{ucciBaseUrl}}/storage/invoices/{{rowData?.invoice_pdf}}">
                Invoice</a></td>
        </tr>
    </ng-template>


</p-table>
</div>
   
    <!-- member data report end -->

</div>
<!-- End Main Content Wrapper Area -->