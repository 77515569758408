import { Injectable } from '@angular/core';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root'
})
export class NewsAndEventsServiceService {
  constructor(public http: HTTPApi) {
  }
  postNews(data?: any) {
    return this.http.post('add/news', data);
  }
  getNotifications() {
    return this.http.get('get/news');
  }
  getDataById(id: any) {
    return this.http.get(`getNewsById/${id}`)
  }
  updateNotification(id?: any, data?: any) {
    return this.http.post(`update/news/${id}`, data)
  }
  disableNotifications(id?: any) {
    return this.http.post(`delete/news/${id}`)
  }
}
