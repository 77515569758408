import { Directive, HostListener } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[scriptInjection]',
})
export class ScriptInjectionDirective {
  constructor(private control: NgControl) {}
  // private control: NgControl
  // control: NgControl
  @HostListener('change', ['$event'])

  onChange() {
    // alert();
    this.control.control.setValue(
      this.control.value.replace(/(<([^>]+)>)/gi, '').replace(/\r\n|\r|\n/g)
    );
    let string = this.control.control.value.trim();
    // console.log(string);
    if(!string) {
      this.control.control.setValue('');
    }
    else {
      this.control.control.setValue(string);
    }
  }
}
