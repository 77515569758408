// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
 //let doc_base_url = 'http://ucci.brandtalks.in';
let doc_base_url = 'https://backend.ucciudaipur.com';

export const environment = {
 
  // *! ***********************************************UCCI Udaipur Live URL's******************************************************
  
    // ucci_base_url: 'https://backend.ucciudaipur.com',
    // api_baseurl: 'https://backend.ucciudaipur.com/api',
    // marketplace_media_logo : 'https://backend.ucciudaipur.com/storage/marketplace/',
    // frontURL: 'https://ucciudaipur.com',
    
  // *! ***********************************************UCCI Udaipur Test URL's******************************************************
  // ucci_base_url: 'http://192.168.0.139:8000',
  // api_baseurl: 'http://192.168.0.139:8000/api',
  // marketplace_media_logo : 'http://192.168.0.139:8000/',
  // frontURL: 'http://192.168.0.139:8000',


  ucci_base_url: 'https://ucci.brandtalks.in',
  api_baseurl: 'https://ucci.brandtalks.in/api',
  marketplace_media_logo: 'https://ucciback.webanixsolutions.in/storage/marketplace/',
  frontURL: 'https://uccitest.brandtalks.in/user-panel/',


 


  // blogImgUrl: 'https://backend.ucciudaipur.com/storage/blog/images/',
  // bannerImgUrl: 'https://backend.ucciudaipur.com/storage/blog/banner_image/',

  
  blogImgUrl: 'https://ucci.brandtalks.in/storage/blog/images/',
  bannerImgUrl: 'https://ucci.brandtalks.in/storage/blog/banner_image/',


  // *?
  // *!
  
  // ************************************************UCCI Udaipur Award URL******************************************************
  award_URL: 'https://awards.ucciudaipur.com/',

  production: false,
  authModalState: false,
  MID: 'WL0000000032257',
  TrustMID: 'WL0000000032279',

  // *! old MID for test **************************************************
  // MID: 'WL0000000027698', 
  notification_meadia: doc_base_url + '/storage/news/media/',

  // marketplace category, sub category, brand image path
  marketplace_brandimage_url: doc_base_url + '/storage/brand',
  marketplace_catimage_url: doc_base_url + '/storage/category',
  marketplace_subcatimage_url: doc_base_url + '/storage/subcategory',
  supporting_doc_url: doc_base_url + '/storage/supporting_documents',

  // image url for marketplace add products (url's used in edit product)
  marketplace_media_url: doc_base_url + '/storage/marketplace',
  marketplace_coverage_url: doc_base_url + '/storage/events/',

  profile_baseUrl: doc_base_url + '/storage/app/public/events/coverage_image/',

  //regular member list images
  regularMemberImgs_url: doc_base_url + '/storage/company/logo/',
  regularMemberListURL: doc_base_url + '/storage/company/',
  regularMemberEnclosure: doc_base_url + '/storage/',
  enclosure: doc_base_url + '/storage/enclosure_documents/',
  HWMImgs_url: doc_base_url + '/storage/company/logo/',
  // HWMImgs_url: doc_base_url + '/storage/company//profile1/',
  nominee1Image: doc_base_url + '/storage/company/profile1/',
  nominee2Image: doc_base_url + '/storage/company/profile2/',
  gstCertificateURL: doc_base_url + '/storage/hwm/gst_certificate/',
  caCertificateURL: doc_base_url + '/storage/hwm/ca_certificate/',
  ecDocBaseURL: doc_base_url + '/storage/hwm/ec_documents/',
  cteDocBaseURL: doc_base_url + '/storage/hwm/cte_documents/',
  ctoDocBaseURL: doc_base_url + '/storage/hwm/cto_documents/',
  hwmDocBaseURL: doc_base_url + '/storage/hwm/hwm_documents/',
  appDocBaseURL: doc_base_url + '/storage/hwm/application/',
  utDocBaseURL: doc_base_url + '/storage/hwm/undertaking/',
  cooDocBaseURL: doc_base_url + '/storage/coo_invoice/',
  DocInvoiceBaseURL: doc_base_url + '/storage/doc_invoice/',
  DocAnotherBaseURL: doc_base_url + '/storage/doc_attestation_other/',
  DocReceiptBaseURL: doc_base_url + '/storage/invoices/',
  revisionLetterUrl: doc_base_url + '/storage/',
  rezorpay_key: 'rzp_test_5R3ifzCtFSn1j1',
  rezorpay_logo:
    'https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png',
  eventImageURL: doc_base_url + '/storage/events/',
  visaDoc: doc_base_url + '/storage/visa_doc/',



  // Filter reset tooltip text
  resetTooltip: 'Clear Filter',
  status: {
    pending: 'Pending',
    inProgress: 'In Progress',
    approved: 'Approved',
    rejected: 'Rejected',
    paymentPending: 'Payment Pending',
    onHold: 'On Hold',
    verificationrejected: 'Verification Rejected',
    verified: 'Verified',
    draft: 'Draft',
  },

  roles: {
    admin: 'Admin',
    nominee1: 'Nominee 1',
    nominee2: 'Nominee 2',
    nonMember: 'NM',
    hwmOwner: 'HWM Owner',
    hwmContact: 'HWM Contact Person',
    staff: 'UCCI Staff',
    guest: 'Guest',
    president: 'President',
    ceo: 'CEO',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
