// import { CommonModule } from '@angular/common';
// import { CommonModule } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CarouselModule as carouselngx } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ImagePreviewComponent } from '../components/common/image-preview/image-preview.component';
import { NonAuthorizedModalComponent } from '../components/common/non-authorized-modal/non-authorized-modal.component';
import { PaymentFormComponent } from '../components/common/payment-form/payment-form.component';
import { PdfPreviewComponent } from '../components/common/pdf-preview/pdf-preview.component';
import { SpinnerComponent } from '../components/common/spinner/spinner.component';
import { SuccessComponent } from '../components/common/success/success.component';
import { CopyrightsComponent } from '../components/pages/dashboard/copyrights/copyrights.component';
import { FileDragNDropDirectiveDirective } from '../directives/file-drag-ndrop-directive.directive';
import { ScriptInjectionDirective } from '../directives/script-injection.directive';
import { GroupByPipePipe } from '../pipes/group-by-pipe.pipe';
import { IndianCurrencyPipe } from '../pipes/indian-currency.pipe';
import { InterestPipePipe } from '../pipes/interest-pipe.pipe';
import { JsonParsePipe } from '../pipes/json-parse.pipe';
import { NonMemberCommaPipe } from '../pipes/non-member-comma.pipe';
import { ParseDataPipe } from '../pipes/parse-data.pipe';
import { TimePipePipe } from '../pipes/time-pipe.pipe';
import { TreeTableModule } from 'primeng/treetable';
import { DashboardInvoiceComponent } from '../components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
const sharedDeclarations: any = [
  ScriptInjectionDirective,
  CopyrightsComponent,
  DashboardInvoiceComponent,
  SpinnerComponent,
  GroupByPipePipe,
  InterestPipePipe,
  IndianCurrencyPipe,
  FileDragNDropDirectiveDirective,
  SuccessComponent,
  NonAuthorizedModalComponent,
  JsonParsePipe,
  ImagePreviewComponent,
  PdfPreviewComponent,
  ParseDataPipe,
  NonMemberCommaPipe,
  PaymentFormComponent,
  TimePipePipe,
];

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};
const exportsharedata: any = [
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  NgxPaginationModule,
  // PrimeNG Imports
  TableModule,
  ToastModule,
  TreeTableModule,
  ConfirmDialogModule,
  CalendarModule,
  SidebarModule,
  TooltipModule,
  MultiSelectModule,
  DialogModule,
  InputTextModule,
  AccordionModule,
  DropdownModule,
  InputNumberModule,
  ScrollPanelModule,
  CheckboxModule,
  SplitterModule,
  KeyFilterModule,
  carouselngx,

  // Vizard Form Imports
  NgWizardModule.forRoot(ngWizardConfig),

  // loader import

  NgxSpinnerModule,
  // LibFBModule,
  RadioButtonModule,
  NgxMaterialTimepickerModule
];
@NgModule({
  declarations: [...sharedDeclarations],
  imports: [...exportsharedata],
  exports: [...sharedDeclarations, ...exportsharedata],
})
export class SharedModule {}
