import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  constructor( private http:HTTPApi) { }

  //to get all custom forms 
  getCustomFormsList() : Observable<any> {
    return this.http.get('form');
  }
  //to delete custom form from from list
  deleteCustomForm(id:any) : Observable<any> {
    return this.http.delete('form/'+id);
  }
  //to create custom form
  createCustomForm(formData:any): Promise<any> {
    return this.http.post('form',formData).toPromise();
  }
  //to edit custom form
  editCustomForm(id?:any,formData?:any): Promise<any> {
    return this.http.put('form/'+id,formData).toPromise();
  }
  //to get form by slug
  getCustomFormBySlug(slug?:any) {
    return this.http.get('form/'+slug).toPromise();
  }
  //store form response
  saveFormResponse(data?:any) {
    return this.http.post('form-response',data);
  }
  //event payment
  updatePaymentStatus(order_id?: any, data?: any) {
    return this.http.post('payment/' + order_id,data);
  }
}
