import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { RegularMemberListComponent } from './components/pages/dashboard/approval-master/regular-member-list/regular-member-list.component';
import { DashboardMyListingsComponent } from './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
// import { VisaServiceComponent } from './components/pages/visa-service/visa-service.component';
// import { VisaFormComponent } from './components/pages/visa/visa-form/visa-form.component';
import { FeedbackFormComponent } from './components/common/feedback-form/feedback-form.component';
import { TemplateComponent } from './components/common/website/template/template.component';
import { HwmMemberListComponent } from './components/pages/dashboard/approval-master/hwm-member-list/hwm-member-list.component';
import { FeedbackGuard } from './feedback.guard';
import { SuccessComponent } from './components/common/success/success.component';

const routes: Routes = [
  // Here add new pages component

  {
    path: 'forgot-password',
    component: HomeDemoOneComponent,
  },
  { path: 'marketplace-products', component: GridListingsLeftSidebarComponent },

  { path: 'regular-member-list', component: RegularMemberListComponent },
  { path: 'hwm-member-list', component: HwmMemberListComponent },
  { path: 'dashboard-my-profile', component: DashboardMyProfileComponent },
  {path:'success',component:SuccessComponent},

  // *******************************************************Theme Routes********************************************************************8
  // Here add new pages component
  {
    path: 'user-panel',
    loadChildren: () =>
      import('./layouts/user-layout/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },
  { path: '', component: TemplateComponent },
  { path: 'index-2', component: HomeDemoTwoComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'gallery', component: GalleryComponent },
  // { path: 'faq', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'event-grid', component: BlogGridComponent },
  { path: 'blog-right-sidebar', component: BlogRightSidebarComponent },
  { path: 'event-details', component: BlogDetailsComponent },
  { path: 'event-details/:slug', component: BlogDetailsComponent },
  { path: 'event-details/:id/:order_id/:status', component: BlogDetailsComponent },
  { path: 'products-list', component: ProductsListComponent },
  { path: 'cart/:slug', component: CartComponent },
  { path: 'cart/:slug/:id', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'single-products', component: ProductsDetailsComponent },
  { path: 'user-profile', component: AuthorProfileComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'destinations', component: TopPlaceComponent },
  {
    path: 'vertical-listings-left-sidebar',
    component: VerticalListingsLeftSidebarComponent,
  },
  {
    path: 'vertical-listings-right-sidebar',
    component: VerticalListingsRightSidebarComponent,
  },
  {
    path: 'vertical-listings-full-width',
    component: VerticalListingsFullWidthComponent,
  },
  {
    path: 'grid-listings-left-sidebar',
    component: GridListingsLeftSidebarComponent,
  },
  {
    path: 'grid-listings-right-sidebar',
    component: GridListingsRightSidebarComponent,
  },
  {
    path: 'grid-listings-full-width',
    component: GridListingsFullWidthComponent,
  },
  { path: 'productDetail/:id', component: ListingsDetailsComponent },
  { path: 'dashboard-reviews', component: DashboardReviewsComponent },
  { path: 'dashboard-my-listings', component: DashboardMyListingsComponent },
  {
    path: 'feedback-form/:slug',
    component: FeedbackFormComponent,
    canActivate: [FeedbackGuard],
  },

  // // Award Path

  // This line will remain down from the whole pages component list
  { path: 'archive', component: HowItWorksPageComponent },
  { path: 'preview/:slug', component: TemplateComponent },
  { path: ':slug', component: TemplateComponent },
  { path: ':slug/:slug', component: TemplateComponent },
  { path: ':slug/:slug/:slug', component: TemplateComponent },
  { path: ':slug/:slug/:slug/:slug', component: TemplateComponent },
  { path: ':slug/:slug/:slug/:slug/:slug', component: TemplateComponent },
  { path: ':slug/:slug/:slug/:slug/:slug/:slug', component: TemplateComponent },
  // { path: 'Dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  // { path: 'Main', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
