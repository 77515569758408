import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'jspdf-autotable';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-member-permission',
    templateUrl: './member-permission.component.html',
    styleUrls: ['./member-permission.component.scss']
})
export class MemberPermissionComponent implements OnInit {
    @ViewChild ('dt1') FilteredData:Table;
  
    dateFilterVal:string;
    filterval: string;
  
    Data: any[];
    usersList: any[];
    memberList: any[];
    guestList: any[];
    slug: any;
    breadcrumb: any[] = [
        {
            title: 'Permissions',
        },
    ];
    totalRecords: number;
    constructor(private ApiService: ApiService, private router: ActivatedRoute) {

    }

    ngOnInit(): void {
        this.slug = this.router;
        this.getAllUsersData();
        // this.getUsersPermissions();        
    }

    getAllUsersData() {
        this.ApiService.getUserPermissionsApi().subscribe((res: any) => {
            console.log(res);
            this.Data = res?.data;  
            this.usersList =  this.Data.filter(res=> res.role_id != 1 && res.role_id != 8);
            console.log(this.usersList);
            // this.memberList = res?.data?.member_list;
            // this.guestList = res?.data?.guest_list;
            // this.usersList = [...this.memberList, ...this?.guestList]
            // console.log(this.usersList);
            // this.totalRecords = this.usersList.length
        })
    }

    reset(dt1) {
        dt1.reset();
        this.filterval = '';
        this.dateFilterVal = ''
      }

}