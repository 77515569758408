import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { utility } from 'src/app/services/utility';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [MessageService],
})
export class FooterComponent implements OnInit {
  constructor(
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private messageService: MessageService
  ) {}

  services: any = [
    { name: 'Certificate of Origin' },
    { name: 'Bussiness Visa' },
    { name: 'Document Attestation' },
  ];

  newsLetterEmail: any = '';

  // emailCheck : boolean = false;

  contactInfo: any = [
    { info: 'Chamber Bhawan, Mewar Industrial Area ,Madri , Udaipur-313003' },
    { info: '+91-9351917002' },
    { info: 'info@ucciudaipur.com' },
  ];

  footerData: any = {};

  async ngOnInit() {
    // this.spinner.show();
    // this.websiteService.getAllPages().then((res: any) => {
    //   console.log(res);
    //   res?.data.forEach((page) => {
    //     if (page.template_type == 'footer') {
    //       this.footerData = JSON.parse(page.page_json);
    //     }
    //   });
    //   console.log(this.footerData);
    // });
    this.websiteService.footerData.subscribe((res:any) => {
      console.log(res);
      if(res?.length > 0) {
        res?.forEach((page) => {
          if (page.template_type == 'footer') {
            this.footerData = JSON.parse(page.page_json);
          }
        });
      }
      else {
        this.websiteService.getAllPages().then((res: any) => {
          console.log(res);
          res?.data.forEach((page) => {
            if (page.template_type == 'footer') {
              this.footerData = JSON.parse(page.page_json);
            }
          });
          this.websiteService.footerData.next(res?.data);
          console.log(this.footerData);
        });
      }
    })
    this.spinner.hide();
  }

  submitNewsLetter(form: NgForm) {
    if (this.newsLetterEmail) {
      let object = {
        email: this.newsLetterEmail,
      };
      this.websiteService.newsletter(object).then((res: any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: 'Form Submitted Successfully!',
        });
        form.resetForm();
      });
    }
  }

  navigate(slug: any) {
    console.log(slug);
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([slug]);
  }
}
