<div class="main-content d-flex flex-column">

    <div class="template_one">
        <ng-container>
           
                    <div class="template_one_bottom_section">
                        <div class="row m-0">
                          
                            <div class="col-md-12 col-12" style="padding: 0 15px">
                                <h1 class="cardHeading" style="font-weight: 500;">
                                    {{data.title}}</h1>
                                   
                                <p class="cardHeading" style="font-size: 20px; font-weight: 300; text-align: justify;">{{data.short_desc}}</p>
                                <div class="col-md-12 col-12"
                                style="display: flex-start; align-items: center; margin-bottom: 40px;">
                                    <div class="image_div" style="width: 64%;">
                                        <img [src]="baseUrl + data.image" alt="" width="100%" >
                                       </div>
                                </div>
                                <div class="cardContent" [innerHTML]="data?.description" style="text-align: left;">
                                    
                                </div>
                              
                            </div>
                        </div>
                    </div>

        </ng-container>


       
        
    </div>
</div>