<p-toast></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<app-spinner [msg]="spinnerMsg"></app-spinner>

<!-- ********************** Main Content ******************** -->


<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>

<!-- ********************** End Breadcrumb Area  ******************** -->
<!-- create notification form -->
<div class="add-listings-box">
    <form [formGroup]="notificationForm" (ngSubmit)="createNotification(news)" #news="ngForm">
        <div class="row" style="justify-content: space-between;">
            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="form-group"><span class="mb-2">
                        News Type<span style="color:
                            red;">*</span>:
                    </span>
                    <p-dropdown [options]="newsType" placeholder="Please Select News Type" [class.is-invalid]="(news.submitted &&
                    notificationForm.get('news_type')?.invalid) || notificationForm.get('news_type')?.invalid &&
                    notificationForm.get('news_type').touched" formControlName="news_type" optionLabel="label"
                        optionValue="value" [showClear]="false" [resetFilterOnHide]="true">
                    </p-dropdown>
                    <div class="invalid-feedback">Name is Required</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="form-group">News Title<span style="color:
                            red;">*</span>:
                    <input type="text" scriptInjection class="form-control" formControlName="title"
                        placeholder="News Title" required [class.is-invalid]="(news.submitted &&
                            notificationForm.get('title')?.invalid)
                            ||
                            notificationForm.get('title')?.invalid
                            &&
                            notificationForm.get('title').touched">
                    <div class="invalid-feedback">News Title is Required</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="form-group">News Description<span style="color:
                            red;">*</span>:
                    <textarea type="text" scriptInjection class="form-control" formControlName="description"
                        placeholder="News Description" required [class.is-invalid]="(news.submitted &&
                            notificationForm.get('description')?.invalid)
                            ||
                            notificationForm.get('description')?.invalid
                            &&
                            notificationForm.get('description').touched">
                    </textarea>
                    <div class="invalid-feedback">News Description is Required</div>
                </div>
            </div>
            <div formArrayName="mediaArray">
                <ng-container *ngFor="let item of
                    getDocumentArray().controls; index as i; last as
                    last">
                    <div [formGroupName]="i" class="row">
                        <!-- {{slug.slug | json}} -->
                        <div class="col-lg-3 col-md-2 col-sm-12">
                            <div class="form-group">Media Type<span style="color:
                                red;">*</span>:
                                <p-dropdown [options]="mediaType" placeholder="Please Select Media Type"
                                    [class.is-invalid]="(news.submitted &&
                                notificationForm.get('mediaArray').controls[i].controls['media_type']?.invalid)
                                    ||
                                    notificationForm.get('mediaArray').controls[i].controls['media_type']?.invalid
                                &&
                                notificationForm.get('mediaArray').controls[i].controls['media_type']?.touched || (notificationForm.get('mediaArray').controls[i].controls['media_type']?.invalid && docErrorState == true)"
                                    (onChange)="onChange($event, i)" formControlName="media_type" optionLabel="label"
                                    optionValue="value" [showClear]="false" [resetFilterOnHide]="true" required>
                                </p-dropdown>
                            </div>
                            <div class="invalid-feedback">Media Type is Required</div>
                        </div>
                        <div class="col-lg-3 col-sm-12" *ngIf="getDocumentArray().value[i].media_type == 'File'">
                            <div class="form-group">Media<span style="color:
                                red;">*</span>:
                                <ng-container *ngIf="this.f.mediaArray.controls[i].value.docState == false">
                                    <input type="file" class="inputfile form-control px-2 py-1"
                                        placeholder="Invoice Document" required (change)="processImage($event, i)"
                                        [class.is-invalid]="(news.submitted &&
                                            notificationForm.get('mediaArray').controls[i].controls['media']?.invalid)
                                                ||
                                                notificationForm.get('mediaArray').controls[i].controls['media']?.invalid
                                            &&
                                            notificationForm.get('mediaArray').controls[i].controls['media']?.touched || (notificationForm.get('mediaArray').controls[i].controls['media']?.invalid && docErrorState == true)">
                                    <div class="invalid-feedback">Media is Required</div>
                                    <div class="alert alert-danger"
                                        *ngIf="this.f.mediaArray.controls[i].value.docErrorState">
                                        Document Size Should Not Larger Then 10MB
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.f.mediaArray.controls[i].value.docState == true">
                                    <span id="myImg"
                                        class="form-control d-flex align-items-center justify-content-between">
                                        <span pTooltip="Click to Show Preview"
                                            (click)="onImageClick(this.f.mediaArray.controls[i].value.localPath, this.f.mediaArray.controls[i].value.docType, this.f.mediaArray.controls[i].value.docName)"
                                            tooltipPosition="bottom">{{this.f.mediaArray.controls[i].value.docName ?
                                            (this.f.mediaArray.controls[i].value.docName | slice:0:20) :(
                                            this.f.mediaArray.controls[i].controls['media'].value
                                            | slice:0:20)}}</span>
                                        <span (click)="logoPreview(i)" style="color: red"
                                            pTooltip="Remove Preview and Change File" tooltipPosition="bottom">x</span>
                                    </span>
                                    <ng-container *ngIf="imageData.boolean">
                                        <app-image-preview [imageData]="imageData"></app-image-preview>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-12"
                            *ngIf="getDocumentArray().value[i].media_type == 'Link'">
                            <div class="form-group">Media<span style="color:
                                red;">*</span>:
                                <input type="text" scriptInjection class="form-control" formControlName="media"
                                    placeholder="Paste Link"
                                    [class.is-invalid]="(news.submitted &&
                                    notificationForm.get('mediaArray').controls[i].controls['media']?.invalid)
                                        ||
                                        notificationForm.get('mediaArray').controls[i].controls['media']?.invalid
                                    &&
                                    notificationForm.get('mediaArray').controls[i].controls['media']?.touched || (notificationForm.get('mediaArray').controls[i].controls['media']?.invalid && docErrorState == true)">
                                <div class="invalid-feedback">Media is Required</div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-12">
                            <div class="form-group">Media Title<span style="color:
                                red;">*</span>:
                                <input type="text" scriptInjection class="form-control" formControlName="media_title"
                                    [class.is-invalid]="(news?.submitted &&
                                            notificationForm?.get('mediaArray').controls[i]?.controls['media_title']?.invalid)
                                                ||
                                                notificationForm?.get('mediaArray').controls[i]?.controls['media_title']?.invalid
                                            &&
                                            notificationForm?.get('mediaArray')?.controls[i]?.controls['media_title']?.touched || (notificationForm?.get('mediaArray').controls[i]?.controls['media_title']?.invalid && docErrorState == true)"
                                    placeholder="Media Title" required>
                                <div class="invalid-feedback">Media Title is Required</div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-12"
                            *ngIf="getDocumentArray().value[i].media_type == 'File'">
                            <div class="form-group">Media Alt<span style="color:
                                red;">*</span>:
                                <input type="text" scriptInjection class="form-control" formControlName="media_alt"
                                    [class.is-invalid]="(news?.submitted &&
                                            notificationForm?.get('mediaArray').controls[i]?.controls['media_alt']?.invalid)
                                                ||
                                                notificationForm?.get('mediaArray').controls[i]?.controls['media_alt']?.invalid
                                            &&
                                            notificationForm?.get('mediaArray')?.controls[i]?.controls['media_alt']?.touched || (notificationForm?.get('mediaArray').controls[i]?.controls['media_alt']?.invalid && docErrorState == true)"
                                    placeholder="Media Alt" required>
                                <div class="invalid-feedback">Media Alt is Required</div>
                            </div>
                        </div>
                        <div class="col-lg-3 form-group d-flex mt-1" style="align-self: center;" *ngIf="last">
                            <p-button type="button" icon="bx bx-plus" (click)="onAddRow('add', last)" iconPos="right"
                                class="mr-2 mt-3"></p-button>
                            <div class="remove">
                                <p-button type="button" *ngIf="i!= 0" class="ml-2 mt-3" icon="bx bx-minus"
                                    (click)="onAddRow('delete', i)" iconPos="right"></p-button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- </ng-container> -->
            <!-- <ng-container *ngIf="showInput"> -->

            <!-- </ng-container> -->

            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="field-checkbox margin">
                    <p-checkbox name="ny" (onChange)="selectionNew($event)" [(ngModel)]="isNew"
                        [ngModelOptions]="{standalone: true}" [binary]="true" inputId="ny" class="mr-3">
                    </p-checkbox>
                    <label class="check-box" for="ny">Is New?</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-2 col-sm-12 add-btn">
                <p-button type="submit" class="ml-2" label="Submit" iconPos="right"></p-button>
            </div>
        </div>
    </form>
</div>