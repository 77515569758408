<!-- <app-navbar-style-two></app-navbar-style-two> -->
<!-- <app-header></app-header> -->
<!-- Start Page Title Area -->
<div class="page-title-bg" *ngFor="let Content of pageTitleContent;">
    <div class="container" style="padding-top: 130px; padding-bottom: 50px;">
        <h1>{{Content.title}}</h1>
        <!-- <form>
            <div class="row m-0 align-items-center">
                <div class="col-lg-4 col-md-12 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="form-control" placeholder="What are you looking for?">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-pin"></i></label>
                        <input type="text" class="form-control" placeholder="Location">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group category-select">
                        <label><i class="flaticon-category"></i></label>
                        <ngx-select-dropdown (searchChange)="searchChange($event)" name="category-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                    </div>
                </div>

                <div class="col-lg-2 col-md-12 p-0">
                    <div class="submit-btn">
                        <button type="submit">Search Now</button>
                    </div>
                </div>
            </div>
        </form> -->
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Listings Area -->
<section class="listings-area new__marketPlace ptb-100">
    <div class="container">
        <div class="listings-widget book_listings">
            <!-- <a routerLink="/user-panel/product-form" class="default-btn mx-2">List your Product</a> -->
            <!-- <a (click)="showInquiry('myinquiry')" class="default-btn">My Enquiry</a> -->
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="listings-widget-area">
                    <h3 class="widget-title">Filters</h3>
                    <div class="my-3 d-flex"> 
                        <input type="text" style="width: 300px;" class="form-control" (ngModelChange)="userQuestionUpdate.next($event)" [(ngModel)]="searchProduct" placeholder="Search Products..."><span  class="refresh-icon" (click)="clearFilter()"><i class="pi pi-refresh"></i></span>
                    </div>
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <div class="filters">
                            <ul *ngFor="let category of categoryList; index as index">
                                <li style="font-weight: 500;">
                                    <!-- <input class="mr-3" [id]="'category'+category.id" (change)="filterProducts($event ,'category')"
                                        [value]="category.id" [checked]="category.isChecked" type="checkbox" /> -->
                                    <label [for]="'category'+category.id">{{category.name}}</label> <i
                                        class="pi pi-angle-down mx-2" (click)="getSubCategory(category , index)"></i>
                                    <!-- <ng-container *ngIf="subCategory?.length > 0"> -->
                                    <div *ngIf="category?.isSubCategory">
                                        <ul *ngFor="let subcategory of category?.subcategory; index as index1">
                                            <li style="display: flex; align-items: center;">
                                                <input [id]="'subcategory'+subcategory.id"
                                                    [checked]="subcategory.isChecked"
                                                    (change)="filterProducts($event , 'subcategory' , subcategory , index , index1)"
                                                    [value]="subcategory.id" type="checkbox" />
                                                <label class="m-2" style="min-width: 100px; max-width: 150px;" [for]="'subcategory'+subcategory.id">{{subcategory.name}}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- </ng-container> -->
                                </li>
                            </ul>
                        </div>
                        <!-- <ul *ngIf="categoryList['length'] >= 5">
                            <li>
                                <a (click)="showAllData('category')" class="show_btn">{{categoryLength.category}}</a>
                            </li>
                        </ul> -->
                        <ul *ngIf="categoryList['length'] == 0">
                            <li>
                                <a class="show_btn">No Data Found</a>
                            </li>
                        </ul>
                    </section>

                    <!-- <section class="widget widget_features">
                        <h3 class="widget-title">Brand</h3>
                        <div class="filters">
                            <ul *ngFor="let brand of brandList; index as index">
                                <li *ngIf="index < brandLength['length']">
                                    <input [id]="'brand'+brand.id" [value]="brand.id"
                                        (change)="filterProducts($event , 'brand')" type="checkbox">
                                    <label [for]="'brand'+brand.id">{{brand.name}}</label>
                                </li>
                            </ul>
                        </div>

                        <ul *ngIf="brandLength['length'] >= 5">
                            <li>
                                <a (click)="showAllData('brand')" class="show_btn">{{brandLength.category}}</a>
                            </li>
                        </ul>
                        <ul *ngIf="brandLength['length'] == 0">
                            <li>
                                <a class="show_btn">No Data Found</a>
                            </li>
                        </ul>
                    </section> -->

                    <section class="widget widget_distance" *ngIf="this.localStorage">
                        <h3 class="widget-title">Manufacturer</h3>
                        <div class="filters">
                            <ng-container *ngFor="let menufacturer of menufacturerList; index as index">
                                <ul>
                                    <li style="font-weight: 500; white-space: nowrap;">
                                        <input class="mr-3" [id]="'menufacturer'+menufacturer.id" [checked]="menufacturer.isChecked" [value]="menufacturer.id"
                                            (change)="filterProducts($event ,'menufacturer')" type="checkbox">
                                        <label
                                            [for]="'menufacturer'+menufacturer.id">{{menufacturer.company_name}}</label>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>

                        <!-- <ul *ngIf="menufacturerLength['length'] >= 5">
                            <a (click)="showAllData('member')" class="show_btn">{{menufacturerLength.category}}</a>
                        </ul> -->
                        <ul *ngIf="menufacturerLength['length'] == 0">
                            <a class="show_btn">No Data Found</a>
                        </ul>
                    </section>
                </aside>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <!--********************************* All Products *******************************-->
                    <!-- <ng-container *ngFor="let Content of approvedProducts | paginate: { itemsPerPage: 6,
                        currentPage: gridListings }">
                        <ng-container>
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="single-listings-box">
                                    <div class="listings-image">
                                        <div class="listings-image-slides">
                                            <owl-carousel-o [options]="customOptions">
                                                <ng-template carouselSlide *ngFor="let
                                                    Image of Content.medias;">
                                                    <div class="single-image">
                                                        <img [src]="getImageUrl(Image.media)" alt="image" style="width:
                                                            349px; height: 231px;">
                                                        <a routerLink="/productDetail/{{Content.id}}"
                                                            class="link-btn"></a>
                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                        <a routerLink="/productDetail/{{Content.id}}" class="category"><i
                                                class="flaticon-cooking"></i></a>
                                    </div>

                                    <div class="content p-3">
                                        <div class="listings-content">
                                            <div class="div1">
                                                <h3 class="small-text"><a>{{Content.product_name}}</a></h3>
                                            </div>
                                            <div class="div2">
                                                <h3 *ngIf="Content.regular_price != null"><a>{{Content.regular_price | indianCurrency}}</a></h3>
                                            </div>
                                        </div>
                                        <p style="line-height: 1!important;" class="small-text">
                                            {{Content.short_description}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container> -->
                    <!-- Start Category Area -->
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 col-md-4"
                                *ngFor="let Content of approvedProducts | paginate: { itemsPerPage: 8, currentPage: gridListings }">
                                <div class="single-category-box" routerLink="/productDetail/{{Content.id}}">
                                    <div class="icon">
                                        <i><img [src]="getImageUrl(Content?.logo)" alt=""></i>
                                        <i class="{{Content.icon}}"></i>
                                    </div>
                                    <h3 tooltipPosition="bottom" pTooltip="{{Content?.business_name}}">{{Content?.business_name.slice(0,25)}}</h3>
                                    <span tooltipPosition="bottom" pTooltip="{{Content?.category?.name}}">{{Content?.description.slice(0,25)}}</span>
                                    <!-- <a routerLink="/" class="link-btn"></a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Category Area -->
                    <!-- <ng-container *ngIf="approvedProducts.length == 0">
                        <div class="col-12 text-center">
                            No Data Found
                        </div>
                    </ng-container> -->
                    <div class="col-lg-12 col-md-12" *ngIf="allProducts.length > 0">
                        <div class="pagination-area text-center d-flex align-items-center justify-content-center">
                            <div class="mx-3">
                                Total Products : {{allProducts?.length}}
                            </div>
                            <pagination-controls (pageChange)="gridListings=
                                $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->
<app-footer></app-footer>
<!-- <app-footer-style-two></app-footer-style-two> -->