import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter',
})
export class NameFilterPipe implements PipeTransform {
  transform(value: any, args: any): any {
    let recivedData: any = value.split('|');
    let filteredValue: string;
    if (args == 'company') {
      filteredValue = recivedData[0];
    } else if (args == 'role') {
      filteredValue = recivedData[1] == 'NM' ? 'Non Member' : recivedData[1];
    }
    return filteredValue;
  }
}
