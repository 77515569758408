<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog> -->

<p-dialog header="Session Timeout" [(visible)]="authModalState" [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" [modal]="true"
  [draggable]="false" [closable]="false" [resizable]="false">
  <p class="d-flex align-items-center"><span class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;"></span>
    Your session has been expired. Please
    Login again!</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="commonFunction.romoveToken()" label="Ok"></p-button>
  </ng-template>
</p-dialog>
<app-header></app-header>
<router-outlet></router-outlet>
<!-- <ng-container *ngIf="loadercheck"> -->
<!-- <app-spinner></app-spinner> -->
<!-- </ng-container> -->
<!-- <app-footer></app-footer> -->