import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-demo-one',
  templateUrl: './home-demo-one.component.html',
  styleUrls: ['./home-demo-one.component.scss'],
})
export class HomeDemoOneComponent implements OnInit {
  ucci_base_url: string = environment.ucci_base_url;

  constructor(private router: Router, private messageService: MessageService) {}

  ngOnInit(): void {
    console.log('router url', this.router.url);
    if (this.router.url == '/forgot-password') {
      this.messageService.add({
        sticky: true,
        // key: 'ut',
        severity: 'warn',
        detail: 'Please Click to Login / Register For Forgot Password!',
      });
    }
  }
}
