import { Injectable } from '@angular/core';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root',
})
export class NatureOfBusinessMasterService {
  constructor(public http: HTTPApi) {}
  // approve regular member api
  getNatureofBusinessList() {
    return this.http.get('getNOB');
  }
}
