<section class="events-area pt-100 pb-70">
    <div class="container">
        <div class="events-item-list" *ngIf="news?.length > 0">
            <div class="single-events-box1">
                <div class="row m-0">
                    <ng-container *ngFor="let Content of news;">
                        <div class="col-lg-6 col-md-6">
                            <div class="content single-events-box innerscroller">
                                <!-- <span class="meta"><i class="flaticon-calendar"></i> {{Content?.value[0]?.created_at |
                                    date:'dd-MM-yyyy'}}</span> -->
                                <h6 class="news_main_heading">{{Content.key}}</h6>
                                <ul *ngFor="let value of Content?.value">
                                    <!-- <p style="font-weight : 500;">{{value?.description}}</p> -->
                                    <p *ngIf="value?.news_media[0]?.media.includes('https://')">
                                        <li>
                                            <a href="{{value?.news_media[0]?.media}}" target="_blank"
                                                rel="noopener noreferrer">
                                                {{value.description}}
                                            </a>
                                        </li>
                                    </p>
                                    <div *ngIf="!value?.news_media[0]?.media.includes('https://')">
                                        <div *ngFor="let media of value?.news_media">
                                            <li>
                                                <a href="{{base_URL +'/' + media.media}}" style="cursor: pointer;"
                                                    target="_blank" rel="noopener noreferrer">
                                                    {{media.media}}
                                                </a>
                                            </li>
                                        </div>
                                    </div>
                                    <!-- <div class="links" *ngFor="let item of value?.news_media" style="display : block;">
                                        <p *ngIf="item?.media.includes('https://')"><a href="{{item.media}}"
                                                target="_blank" rel="noopener noreferrer"
                                                style="word-wrap:break-word">{{item?.media}}</a>
                                        </p>
                                        <p *ngIf="!item?.media.includes('https://')"><a href="{{base_URL + item.media}}"
                                                target="_blank" rel="noopener noreferrer"
                                                style="word-wrap:break-word">{{item?.media}}</a></p>
                                    </div> -->
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <p-accordion>
    <p-accordionTab *ngFor="let Content of news;" [header]="Content.title">
        <p>
        <h6>{{Content.title}}</h6>
        <p>{{Content.description}}</p>
        <div class="links" *ngFor="let item of Content?.news_media">

            <p *ngIf="item?.media.includes('https://')"><a href="{{item.media}}" target="_blank"
                    rel="noopener noreferrer" style="word-wrap:break-word">{{item?.media}}</a>
            </p>
            <p *ngIf="!item?.media.includes('https://')"><a href="{{base_URL + item.media}}" target="_blank"
                    rel="noopener noreferrer" style="word-wrap:break-word">{{item?.media}}</a></p>
        </div>
    </p-accordionTab>
</p-accordion> -->