import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-news-and-events-master',
  templateUrl: './news-and-events-master.component.html',
  styleUrls: ['./news-and-events-master.component.scss']
})
export class NewsAndEventsMasterComponent implements OnInit {
  @ViewChild('news') news: NgForm
  @ViewChild('dt1') dt1: Table
  breadcrumb: any[] = [
    {
      title: 'News & Announcements',
      subTitle: 'Masters',
    },
  ];
  newsType: any = [
    { label: 'UCCI News', value: 'UCCI News' },
    { label: 'Government News', value: 'Government News' }
  ]
  mediaType: any = [
    { label: 'File', value: 'File' },
    { label: 'Link', value: 'Link' }
  ]
  selectedFile: File;
  spinnerMsg: string;
  ImageURL: string = '';
  UploadFeatured: boolean;
  notificationFormFiles: any = {};
  imageData: any = {};
  baseUrl = environment;
  showInput: boolean = false;
  showDropdown: boolean = false;
  docErrorState: boolean = false;
  localUrl: any;
  documents: any = [];
  mediatype: any;
  filterVal: string;
  loading: boolean = true;
  datalength: any;
  submitButton: string = 'Submit'
  notificationList: any = [];
  localStorage: any;
  displayMaximizable: boolean = false;
  notificationDetails: any = [];
  cols: { field: string; header: string; }[];
  exportColumns: { title: string; dataKey: string; }[];
  pdfData: any[];
  filteredPDFData: any[];
  constructor(private newsService: NewsAndEventsServiceService,
    private messageService: MessageService,
    private _utility: utility,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService : ApiService
    ) { }

  ngOnInit(): void {
    this.spinnerMsg = 'loading...';
    this.localStorage = this.commonFunction.getLocalStorage();
    this.getNotificationList();
    this.getNewsTypeList();

    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'type', header: 'Type' },
      { field: 'description', header: 'Description' }
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }


  onImageClick(url: any) {
    this.imageData.url = url;
    this.imageData.boolean = true;
  }
  // reset filter
  reset(dt1) {
    dt1.reset();
    this.filterVal = '';
  }
  // get notificationlist 
  getNotificationList() {
    this.newsService.getNotifications().subscribe((res: any) => {
      console.log(res);
      this.notificationList = res.data;
      this.datalength = this.notificationList.length;
      this.spinner.hide();
      this.loading = false;
    })
  }
  editFormDetails(id: any) {
    this.router.navigateByUrl(`/user-panel/news-and-events-form/${id}`);
  }
  // soft delete notifications
  onDeletePress(id: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      accept: () => {
        this.spinner.show();
        this.newsService.disableNotifications(id).subscribe((res: any) => {
          console.log(res);
          this.getNotificationList();
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
        })
      },
    });

  }
  showMaximizableDialog(notificationList) {
    console.log(notificationList);
    this.notificationDetails = [];
    this.notificationDetails.push(notificationList);
    this.displayMaximizable = true;
  }
  getImage(path?: string) {
    console.log(true, path);
    
    let returnValue: any;
    returnValue = this.baseUrl.notification_meadia;
    returnValue = returnValue + path;
    console.log(returnValue);
    return returnValue;
    
  }

  onXlExport() {
    let data : any = []
    let filteredBy
    let dataForExcel : any = []
    if(this.dt1.filteredValue != null) {
      data = this.dt1.filteredValue;
    }
    else {
      data = this.notificationList;
    }
    data.forEach((response:any, index : any) => {
      dataForExcel.push({'Sr No' : index+1, Name : response?.title, Type : response?.news_type, Description : response?.description})
    });
    this.apiService.exportExcel(dataForExcel, 'News-Announcements-List', filteredBy)
  }

  exportPdf() {
    this.pdfData = [];
    let filteredBy
    this.filteredPDFData = [];
    if (this.dt1.filteredValue != null) {
      this.filteredPDFData = this.dt1.filteredValue;
      filteredBy = "Global"

    } else if (this.dt1.filteredValue == null) {
      this.filteredPDFData = this.notificationList;
    }
    console.log(this.filteredPDFData);
    
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      console.log(this.filteredPDFData[i]);
      
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.title,
        type: this.filteredPDFData[i]?.news_type,
        description: this.filteredPDFData[i]?.description
      });
    }
    this.apiService.exportPdf(
      this.pdfData,
      this.exportColumns,
      'News-Announcements-List',
      filteredBy
    );
  }
  getNewsTypeList() {
    this.apiService.getNewsTypeList().subscribe((res:any)=> {
      console.log(res);
    })
  }
}