import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { CategoryComponent } from './components/common/category/category.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { FormBuilderComponent } from './components/masters/form-builder/form-builder.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { HomeoneBlogComponent } from './components/pages/home-demo-one/homeone-blog/homeone-blog.component';
import { HomeoneDestinationsComponent } from './components/pages/home-demo-one/homeone-destinations/homeone-destinations.component';
import { HomeoneListingsComponent } from './components/pages/home-demo-one/homeone-listings/homeone-listings.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { RelatedProductsComponent } from './components/pages/products-details/related-products/related-products.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { ErrorInterceptor } from './helpers/error.interceptor';

// Diractives

// PrimeNg Modules Path
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
// Vizard Form Imports
// import { FormBuilderModule } from 'form-builder';
import { LibFBModule } from 'lib-fb';

// Loader
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgxEditorModule } from 'ngx-editor';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { DialogBoxComponent } from './components/common/dialog-box/dialog-box.component';
import { BannerComponent } from './components/common/website/banner/banner.component';
import { CallToActionComponent } from './components/common/website/call-to-action/call-to-action.component';
import { FooterComponent } from './components/common/website/footer/footer.component';
import { HeaderComponent } from './components/common/website/header/header.component';
import { HomeComponent } from './components/common/website/home/home.component';
import { TemplateOneComponent } from './components/common/website/template-one/template-one.component';
import { TemplateTwoComponent } from './components/common/website/template-two/template-two.component';
import { TemplateComponent } from './components/common/website/template/template.component';
import { EnrolledEventComponent } from './components/masters/enrolled-event/enrolled-event.component';
import { NewsAndEventsMasterComponent } from './components/masters/news-and-events-master/news-and-events-master.component';
import { FilterPipe } from './pipes/filter.pipe';
import { NameFilterPipe } from './pipes/name-filter.pipe';
import { SharedModule } from './shared/shared.module';
import { NotificationFormComponent } from './components/masters/notification-form/notification-form.component';
import { SettingComponent } from './components/masters/setting/setting.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AuditLogsComponent } from './components/pages/dashboard/audit-logs/audit-logs.component';
import { CommitteePagesListComponent } from './components/masters/committee-pages-list/committee-pages-list.component';
import { CreateCommitteeComponent } from './components/masters/create-committee/create-committee.component';
import { RepresentativesApprovalComponent } from './components/pages/dashboard/representatives-approval/representatives-approval.component';
import { PermissionComponent } from './components/masters/permission/permission.component';
import { EditRegularMemberByIdComponent } from './layouts/edit-regular-member-by-id/edit-regular-member-by-id.component';
import { EditHwmMemberByIdComponent } from './layouts/edit-hwm-member-by-id/edit-hwm-member-by-id.component';
import { EditNonMemberByIdComponent } from './layouts/edit-non-member-by-id/edit-non-member-by-id.component';
import { CommitteUIComponent } from './components/common/website/committe-ui/committe-ui.component';
import { MemberPermissionComponent } from './components/common/member-permission/member-permission.component';
import { UserPermissionActionPage } from './components/common/user-permission-action-page/user-permission-action-page.component';
import { SortByPipePipe } from './pipes/sort-by-pipe.pipe';
import { BlogTemplateComponent } from './components/common/website/blog-template/blog-template.component';
import { BlogCompleteDetailComponent } from './components/common/website/blog-complete-detail/blog-complete-detail.component';
import { UserCredentialComponent } from './components/masters/user-credential/user-credential.component';
import { SiteMapComponent } from './components/masters/site-map/site-map.component';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    SelectDropDownModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxTypedJsModule,
    CommonModule,
    CarouselModule,
    LibFBModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxEditorModule,
    ClipboardModule,
    NgxShimmerLoadingModule
  ],
  declarations: [
    AppComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    MemberPermissionComponent,
    UserPermissionActionPage,
    HomeoneBlogComponent,
    FeedbackStyleOneComponent,
    HomeoneDestinationsComponent,
    CategoryComponent,
    HomeoneListingsComponent,
    FeaturesComponent,
    HomeoneBannerComponent,
    NotFoundComponent,
    FaqComponent,
    ContactComponent,
    FooterStyleTwoComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ProductsListComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    RelatedProductsComponent,
    AuthorProfileComponent,
    CategoriesComponent,
    TopPlaceComponent,
    ListingsDetailsComponent,
    GridListingsLeftSidebarComponent,
    FormBuilderComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    BannerComponent,
    TemplateComponent,
    DialogBoxComponent,
    FilterPipe,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CallToActionComponent,
    NameFilterPipe,
    EnrolledEventComponent,
    NewsAndEventsMasterComponent,
    NotificationFormComponent,
    SettingComponent,
    AuditLogsComponent,
    CommitteePagesListComponent,
    CreateCommitteeComponent,
    RepresentativesApprovalComponent,
    PermissionComponent,
    EditRegularMemberByIdComponent,
    EditHwmMemberByIdComponent,
    EditNonMemberByIdComponent,
    CommitteUIComponent,
    SortByPipePipe,
    BlogTemplateComponent,
    BlogCompleteDetailComponent,
    UserCredentialComponent,
    SiteMapComponent,
  ],

  bootstrap: [AppComponent],
  providers: [
    MessageService,
    ConfirmationService,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}
