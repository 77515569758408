import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { IndustryAreaMasterService } from 'src/app/services/industry-area-master.service';
import { InterestMasterService } from 'src/app/services/interest-master.service';
import { PaymentService } from 'src/app/services/payment.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-edit-non-member-by-id',
  templateUrl: './edit-non-member-by-id.component.html',
  styleUrls: ['./edit-non-member-by-id.component.scss'],
})
export class EditNonMemberByIdComponent implements OnInit {
  today: any = new Date();
  invalidDates: Array<Date> = [];
  preCommunication: any = [];
  industryArea: any = [];
  interest: any = [];
  preferredCheckbox: any = [];

  state: any = [];
  selectedState: string;
  selectedState1: string;
  nonMemberRes: any = [];
  nonMemberFormData: any = {};
  turnoverData: any = {};
  nationalities: any = {};
  paymentModes: any = {};
  gstTypes: any = {};
  exportTypes: any = {};
  partyTypes: any = {};
  selectedFile: File;
  checkboxFormArray: any = [];
  interest1Checkbox: any = [];
  responseSubmission: any = [];
  communication: any = ['Whatsapp', 'Email', 'Telephone'];
  isShow: boolean = true;
  profileState: boolean = true;
  oldData: any;
  appliedByForm = this.fb.group({
    userId: [null, [Validators.required]],
  });
  otpState: boolean = false;
  otp: any;
  approveModalState: boolean = false;
  newData: any;
  form: NgForm;
  orderId: any;
  amount: number;
  successState: boolean = false;
  loggedInUserEmail: any;
  formData: FormData;
  selectedPre1: any;
  pre1: any;
  successMessage: any;
  role: string;
  slug: any;
  memberData: any;
  userId: any;
  constructor(
    private apiservice: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private paymentservice: PaymentService,
    private industryareamaster: IndustryAreaMasterService,
    private interestMasterService: InterestMasterService,
    private scroller: ViewportScroller,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private commonClass: CommonClass,
    private ActivatedRoute: ActivatedRoute,
    private utility: utility
  ) {}
  ngOnInit(): void {
    this.role = this.commonClass.getLocalStorage().role;
    if (this.role == 'Admin' || this.role == 'UCCI Staff') {
      this.slug = this.ActivatedRoute.snapshot.params.slug;
      this.nationalities = this.apiservice.getNationalityDropdownValue();

      this.paymentModes = this.apiservice.getpaymentModesDropdownValue();

      this.gstTypes = this.apiservice.getGstTypesDropdownValue();

      this.exportTypes = this.apiservice.getExportTypesDropdownValue();

      this.partyTypes = this.apiservice.getPartyTypesDropdownValue();

      this.apiservice.getTurnoverData().subscribe((res: any) => {
        this.turnoverData = res;
        console.log(this.turnoverData, res);
      });
      console.log(this.nationalities.data);
      this.getStates();
      this.getIndusrtArea();
      this.getAreaofInterest();
      //  if(localStorage.getItem('nonMemberForm')){
      //   this.nonMemberFormData.setValue(JSON.parse(localStorage.getItem('nonMemberForm')))
      //  }
      this.loggedInUserEmail = this.commonClass.getLocalStorage().useremail;
      this.invalidDates.push(this.today);
      this.getMemberData();
    }
  }
  getMemberData() {
    this.apiservice.getMemberById(this.slug).subscribe((res: any) => {
      // console.log(res);
      this.memberData = res.data;
      this.userId = this.memberData.nominee1.user_id;
      this.getMemberList();
    });
  }

  getMemberList() {
    this.spinner.show();
    // this.apiservice.getNonMemberList().subscribe((res: any) => {
    console.log(this.memberData);
    this.nonMemberRes = this.memberData;
    this.spinner.hide();

    this.pre1 = [];
    if (this.nonMemberRes?.nominee1?.preferred_communication?.includes('[')) {
      this.pre1 = JSON.parse(
        this.nonMemberRes?.nominee1?.preferred_communication
      );
    } else {
      this.pre1 =
        this.nonMemberRes?.nominee1?.preferred_communication?.split(', ');
      console.log(this.pre1);
    }

    this.oldData = {
      company: {
        company_name: this.nonMemberRes?.company_name,
      },
      nominee1: {
        name1: this.nonMemberRes?.nominee1?.name,
        email1: this.nonMemberRes?.nominee1?.email,
        mobile_no1: this.nonMemberRes?.nominee1?.mobile,
        preCommunication: this.pre1,
      },
    };
    // else if (
    //   this.nonMemberRes?.nominee1?.preferred_communication?.includes(',')
    // ) {
    //   this.pre1 =
    //     this.nonMemberRes?.nominee1?.preferred_communication?.split(',');
    // }

    for (let i = 0; i < this.pre1.length; i++) {
      this.onPreChange(true, this.pre1[i], 'pre1');
      // {

      // }
      // this.selectedPre1
    }

    this.nonMemberFormData = {
      company_name: this.nonMemberRes?.company_name,
      gst_number: this.nonMemberRes?.gst_number,
      export_type: this.nonMemberRes?.export_type,
      address: this.nonMemberRes?.company_address[0].address,
      office_telephone: this.nonMemberRes?.office_telephone,
      party_type: this.nonMemberRes?.party_type,
      nationality: this.nonMemberRes?.nationality,
      name1: this.nonMemberRes?.nominee1?.name,
      email1: this.nonMemberRes?.nominee1?.email,
      mobile_no1: this.nonMemberRes?.nominee1?.mobile,
      amendment_reason: this.nonMemberRes?.amendment_reason,
      supprting_doc: [{}],
    };
    // });
  }

  // onSendOtp() {
  //   if (this.otpState == false) {
  //     let emailObject = {
  //       email: this.otpForm.value.email,
  //     };
  //     this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
  //       // console.log(res);
  //       this.messageService.add({
  //         severity: 'success',
  //         detail: res.message,
  //       });
  //     });
  //     this.otpState = true;
  //     let preferred1: any = [];
  //     for (var i = 0; i < this.checkboxFormArray.length; i++) {
  //       preferred1.push(this.checkboxFormArray[i].value);
  //     }
  //     let formData = new FormData();
  //     formData.append('otp', preferred1);
  //     console.log(preferred1, formData);
  //     this.otpForm.controls['otp'].setValidators([Validators.required]);
  //     this.otpForm.controls['otp'].updateValueAndValidity();
  //   } else if (this.otpState == true) {
  //     // console.log(this.otpForm.valid);

  //     if (this.otpForm.valid) {
  //       this.otp = this.otpForm.value.otp;
  //       this.spinner.show();
  //       this.apiservice
  //         .OTPVerification(this.otpForm.value)
  //         .subscribe((res: any) => {
  //           console.log(res);
  //           this.spinner.hide();
  //           this.messageService.add({
  //             severity: 'success',
  //             detail: res.message,
  //           });
  //           this.submitData();
  //         });
  //     }
  //   }
  // }

  onClose() {
    this.otpState = false;
  }
  get o() {
    return this.appliedByForm.controls;
  }
  change(string: any) {
    if (string == 'state') {
      this.nonMemberFormData.state = this.selectedState;
      console.log(this.nonMemberFormData.state);
    } else if (string == 'nominee1_state') {
      this.nonMemberFormData.nominee1_state = this.selectedState1;
      console.log(this.nonMemberFormData.nominee1_state);
    }
  }
  bloackAlphabet(event: any) {
    if (event.key == 'Tab') {
      return;
    }
    const keyCode = event.keyCode;
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  removeDoc(i: number) {
    if (this.nonMemberFormData.supprting_doc?.length > 1) {
      this.nonMemberFormData.supprting_doc.splice(i, 1);
    }
  }

  onDocUppload(event: any, index: number) {
    let selectedFile = event.target.files[0];
    this.nonMemberFormData.supprting_doc[index].document = selectedFile;
    console.log('====================================');
    console.log(this.nonMemberFormData.supprting_doc);
    console.log('====================================');
  }

  submitData() {
    let selectedState: any = [];
    selectedState.push(this.form.value.state);
    console.log(this.form.value.state);

    let selectedState1: any = [];
    selectedState1.push(this.form.value.nominee1_state);
    console.log(this.form.value.nominee1_state);
    this.form.value.area_of_interest1 = this.interest1Checkbox;

    this.nonMemberFormData.preferred_communication1 = this.preferredCheckbox;
    this.form.value.preferred_communication1 = this.preferredCheckbox;
    this.form.value.area_of_interest1 =
      this.nonMemberFormData?.area_of_interest1;
    this.nonMemberFormData.preferred_communication1 = this.preferredCheckbox;

    this.formData = new FormData();

    let dob1filterDate = moment(this.nonMemberFormData.date_of_birth1).format(
      'YYYY/MM/DD'
    );

    this.nonMemberFormData.date_of_birth1 = dob1filterDate;
    // if(form.valid) {
    let data = this.nonMemberFormData;
    // console.log(this.nonMemberFormData);

    for (const [key, value] of Object.entries(this.nonMemberFormData)) {
      // console.log(`${key}: ${value}`);
      if (
        key != 'area_of_interest1' &&
        key != 'profile1' &&
        key != 'preferred_communication1' &&
        key != 'state' &&
        key != 'nominee1_state' &&
        key != 'supprting_doc'
      ) {
        this.formData.append(`${key}`, `${value}`);
      } else if (key == 'profile1') {
        this.formData.append(`${key}`, this.selectedFile);
      }
      // if (`${key}` == 'Cheque' || `${key}` == 'DD') {
      //   formData.append(`${key}`, `${value}`);
      // }
    }
    this.formData.append('state', selectedState[0]?.name);
    this.formData.append('nominee1_state', selectedState1[0]?.name);
    for (
      var i = 0;
      i < this.nonMemberFormData?.area_of_interest1?.length;
      i++
    ) {
      // console.log(this.nonMemberFormData.area_of_interest1[i].id);
      this.formData.append(
        'area_of_interest1[]',
        this.nonMemberFormData.area_of_interest1[i].id
      );
    }
    for (var i = 0; i < this.preferred1Checkbox.length; i++) {
      this.formData.append(
        'preferred_communication1[]',
        this.preferred1Checkbox[i]
      );
    }

    this.formData.append('membership_type', 'Non Member');
    // this.formData.append('otp', this.otpForm.value.otp);
    this.nonMemberFormData.supprting_doc.map((res: any, index: any) => {
      this.formData.append(
        `supporting_doc[document][${index}]`,
        res.document == '' ? null : res.document
      );
      this.formData.append(
        `supporting_doc[document_for][${index}]`,
        res.document_for
      );
      // console.log(res);
    });
    console.log(
      this.form.value,
      this.form.valid,
      Object.keys(this.form.value).length
    );

    // formData.append('preferred_communication1', this.checkboxFormArray)
    // for (var i = 0; i < this.checkboxFormArray.length; i++) {
    //   formData.append('preferred_communication1[]', this.checkboxFormArray.value[i]);
    // }
    this.newData = {
      company: {
        company_name: this.nonMemberFormData?.company_name,
      },
      nominee1: {
        name1: this.nonMemberFormData?.name1,
        email1: this.nonMemberFormData?.email1,
        mobile_no1: this.nonMemberFormData?.mobile_no1,
        preCommunication: this.preferred1Checkbox,
      },
    };
    let oldCompanyData = JSON.stringify(this.oldData.company);
    let newCompanyData = JSON.stringify(this.newData.company);
    let oldnominee1Data = JSON.stringify(this.oldData.nominee1);
    let newnominee1Data = JSON.stringify(this.newData.nominee1);
    let changedItemes: any = [];

    if (oldCompanyData !== newCompanyData) {
      changedItemes.push('company');
    }
    if (oldnominee1Data !== newnominee1Data) {
      changedItemes.push('nominee1');
    }

    // this.formData.append('otp', this.otp);
    // this.formData.append('changed_in', changedItemes);

    console.log(
      JSON.stringify(this.oldData.company) ==
        JSON.stringify(this.newData.company),
      JSON.stringify(this.oldData.nominee1) ==
        JSON.stringify(this.newData.nominee1)
    );

    // this.apiservice.registrationForNonMember(formData).subscribe(
    //     (res: any) => {
    //       console.log(res);
    //       this.responseSubmission = res.fee;
    //       this.nonMemberRes = res;
    //       // localStorage.setItem('nonMemberForm', JSON.stringify(this.nonMemberFormData.value));
    //       this.messageService.add({
    //         severity: 'success',
    //         // summary: 'Success',
    //         detail: res.message,
    //       });
    //       if (res.code == 201) {
    //         this.isShow = false;
    //       }
    //     }
    //     // (error: HttpErrorResponse) => {
    //     //   this.messageService.add({
    //     //     severity: 'error',
    //     //     summary: 'Error',
    //     //     detail: error?.error,
    //     //   });
    //     // }
    //   );
    var orderIdObj: any = {
      changed_in: changedItemes,
      membership_type: 'Non Member',
      member_id: this.memberData
    };
    console.log(this.oldData, this.newData);

    this.apiservice.getOrderId(orderIdObj).subscribe((res: any) => {
      // console.log(res);
      this.approveModalState = false;
      this.formData.append('payment_id', res.payment_id);
      this.spinner.hide();
      if (res.message != 'No order created') {
        setTimeout(() => {
          this.orderId = res.data.order_id;
          this.amount = res.data.amount;
          this.formData.append('order_id', this.orderId);
          console.log(this.amount, this.orderId);
          if (this.role != 'Admin' && this.role != 'UCCI Staff') {
            this.payment();
          } else if (this.role == 'Admin' || this.role == 'UCCI Staff') {
            this.formData.append('order_id', this.orderId);
            this.formData.append('user_id', this.userId);
            this.formData.append('member_id', this.memberData.id);
            this.apiservice
              .updateMemberInfo(this.formData)
              .subscribe((res: any) => {
                this.messageService.add({
                  severity: 'success',
                  detail: res.message,
                });
                this.successMessage = {
                  form: 'hwm-edit',
                  message: 'Form Submitted Successfully',
                };
                this.successState = true;
              });
          }
        }, 1500);
      } else if (res.message == 'No order created') {
        this.formData.append('order_id', '');
        if (this.role == 'Admin' || this.role == 'UCCI Staff') {
          this.formData.append('user_id', this.userId);
          this.formData.append('member_id', this.memberData.id);
        }
        this.apiservice
          .updateMemberInfo(this.formData)
          .subscribe((res: any) => {
            // console.log(res);
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.successMessage = {
              form: 'edit-non-member',
              message: 'Form Submitted Successfully',
            };
            this.successState = true;
          });
      }
    });
  }
  preferred1Checkbox: any = [];
  onPreChange(communication?: any, data?: any, string?: any) {
    if (communication) {
      // string == 'pre1'
      this.preferred1Checkbox.push(data);
    } else {
      let index: any;
      // string == 'pre1'
      index = this.preferred1Checkbox.find((x) => x == data);
      if (index) {
        // string == 'pre1'
        this.preferred1Checkbox.splice(
          this.preferred1Checkbox.indexOf(index),
          1
        );
        // : this.preferred2Checkbox.splice(
        //     this.preferred1Checkbox.indexOf(index),
        //     1
        //   );
      }
    }
    console.log(this.preferred1Checkbox);
    // console.log(this.preferred2Checkbox);
  }

  onOtpResend() {
    // let emailObject = {
    //   email: this.otpForm.value.email,
    // };
    // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
    //   this.messageService.add({
    //     severity: 'success',
    //     detail: res.message,
    //   });
    // });
  }
  onSendOtp() {
    if (this.appliedByForm.valid) {
      // this.userId = this.appliedByForm.value.userId;
      this.spinner.show();
      // this.apiservice
      //   .OTPVerification(this.otpForm.value)
      //   .subscribe((res: any) => {
      //     console.log(res);
      //     this.spinner.hide();
      //     this.messageService.add({
      //       severity: 'success',
      //       detail: res.message,
      //     });
      this.submitData();
      // });
    }
    // }
  }

  addDocument() {
    this.nonMemberFormData.supprting_doc.push({});
  }
  //register function for non member exporter
  Register(form?: NgForm) {
    this.form = form;
    console.log(form.valid);
    if (form.valid && this.profileState == true) {
      let selectedState: any = [];
      selectedState.push(form.value.state);
      console.log(form.value.state);

      let selectedState1: any = [];
      selectedState1.push(form.value.nominee1_state);
      console.log(form.value.nominee1_state);
      form.value.area_of_interest1 = this.interest1Checkbox;

      this.nonMemberFormData.preferred_communication1 = this.preferredCheckbox;
      form.value.preferred_communication1 = this.preferredCheckbox;
      form.value.area_of_interest1 = this.nonMemberFormData?.area_of_interest1;
      this.nonMemberFormData.preferred_communication1 = this.preferredCheckbox;

      let formData = new FormData();

      let dob1filterDate = moment(this.nonMemberFormData.date_of_birth1).format(
        'YYYY/MM/DD'
      );

      this.nonMemberFormData.date_of_birth1 = dob1filterDate;
      // if(form.valid) {
      let data = this.nonMemberFormData;
      // console.log(this.nonMemberFormData);

      for (const [key, value] of Object.entries(this.nonMemberFormData)) {
        // console.log(`${key}: ${value}`);
        if (
          key != 'area_of_interest1' &&
          key != 'profile1' &&
          key != 'preferred_communication1' &&
          key != 'state' &&
          key != 'nominee1_state'
        ) {
          formData.append(`${key}`, `${value}`);
        } else if (key == 'profile1') {
          formData.append(`${key}`, this.selectedFile);
        }
        // if (`${key}` == 'Cheque' || `${key}` == 'DD') {
        //   formData.append(`${key}`, `${value}`);
        // }
      }
      formData.append('state', selectedState[0]?.name);
      formData.append('nominee1_state', selectedState1[0]?.name);
      for (
        var i = 0;
        i < this.nonMemberFormData?.area_of_interest1?.length;
        i++
      ) {
        // console.log(this.nonMemberFormData.area_of_interest1[i].id);
        formData.append(
          'area_of_interest1[]',
          this.nonMemberFormData.area_of_interest1[i].id
        );
      }
      for (var i = 0; i < this.preferredCheckbox.length; i++) {
        formData.append(
          'preferred_communication1[]',
          this.preferredCheckbox[i]
        );
      }

      console.log(form.value, form.valid, Object.keys(form.value).length);

      // formData.append('preferred_communication1', this.checkboxFormArray)
      // for (var i = 0; i < this.checkboxFormArray.length; i++) {
      //   formData.append('preferred_communication1[]', this.checkboxFormArray.value[i]);
      // }
      this.confirmationService.confirm({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          // this.otpForm.patchValue({
          //   email: this.loggedInUserEmail,
          // });
          // let emailObject = {
          //   email: this.otpForm.value.email,
          // };
          // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          //   this.messageService.add({
          //     severity: 'success',
          //     detail: res.message,
          //   });
          // });
          // this.approveModalState = true;
          this.submitData();
          // this.apiservice.registrationForNonMember(formData).subscribe(
          //   (res: any) => {
          //     console.log(res);
          //     this.responseSubmission = res.fee;
          //     this.nonMemberRes = res;
          //     // localStorage.setItem('nonMemberForm', JSON.stringify(this.nonMemberFormData.value));
          //     this.messageService.add({
          //       severity: 'success',
          //       // summary: 'Success',
          //       detail: res.message,
          //     });
          //     if (res.code == 201) {
          //       this.isShow = false;
          //     }
          //   }
          //   // (error: HttpErrorResponse) => {
          //   //   this.messageService.add({
          //   //     severity: 'error',
          //   //     summary: 'Error',
          //   //     detail: error?.error,
          //   //   });
          //   // }
          // );
        },
      });
    } else if (this.profileState == false) {
      this.messageService.add({
        severity: 'error',
        detail: 'Image Size Should Not Larger Then 2MB!',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
  }
  // }
  // state dropdown
  getStates() {
    this.apiservice
      .getStateWithJson('assets/state.json')
      .subscribe((res: any) => {
        this.state = res.Data;
        // console.log(this.state);
      });
  }
  // get industry area list
  getIndusrtArea() {
    this.industryareamaster.getIndustryAreaList().subscribe((res: any) => {
      console.log(res);
      this.industryArea = res.Data;
    });
  }
  // get area of interest list api function
  getAreaofInterest() {
    this.interestMasterService.getInterestArea().subscribe((res: any) => {
      console.log(res);
      this.interest = res.data;
    });
  }
  // payment process razorpay
  payment(orderId?: string, amount?: number, id?: any): void {
    this.approveModalState = false;
    let options = {
      key: environment.rezorpay_key,
      currency: 'INR',
      base_amount: this.amount,
      name: 'UCCI',
      // description: 'Web Development',
      image: environment.rezorpay_key,
      order_id: this.orderId,
      handler: function (response: any) {
        var event = new CustomEvent('payment.success', {
          detail: response,
          bubbles: true,
          cancelable: true,
        });
        window.dispatchEvent(event);
      },
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#0ec6c6',
      },
    };
    // console.log(options);

    var rzp1 = new Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      // console.log(response);
      // console.log(response.error.code);
      // console.log(response.error.description);
      // console.log(response.error.source);
      // console.log(response.error.step);
      // console.log(response.error.reason);
      // console.log(response.error.metadata.order_id);
      // console.log(response.error.metadata.payment_id);
      let statusDetail = {
        status: 'Payment Failed',
        payment_id: response.error.metadata.payment_id,
        razorpay_signature: '',
        description: response.error.description,
        payment_for: 'Member Edit',
        payment_for_id: this.nonMemberRes?.id,
      };
      this.paymentservice.servicePayment(this.orderId, statusDetail);
      // .subscribe((res: any) => {
      //   // console.log(res);
      //   this.messageService.add({
      //     severity: 'success',
      //     detail: res.message,
      //   });
      // });
    });
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    // console.log(event);
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      payment_for: 'Member Edit',
      payment_for_id: this.nonMemberRes?.id,
    };
    this.paymentservice
      .servicePayment(this.orderId, statusDetail)
      .subscribe((res: any) => {
        // console.log(res);
        if (res.code == 200) {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          this.apiservice
            .updateMemberInfo(this.formData)
            .subscribe((res: any) => {
              // console.log(res);
              this.successState = true;
            });
          // setTimeout(() => {
          // this.router.navigateByUrl('/');
          this.successMessage = {
            form: 'edit-non-member',
            message: 'Form Submitted Successfully',
          };
          this.successState = true;
          // }, 2000);
        }
      });
  }
  //upload photo
  fileUpload(event: any) {
    if (event.target.files[0].size <= 2300000) {
      this.selectedFile = event.target.files[0];
      this.profileState = true;
    } else if (event.target.files[0].size > 2300000) {
      this.profileState = false;
    }
  }
  onChange(communication?: any, data?: any) {
    if (communication.target.checked) {
      this.preferredCheckbox.push(data);
    } else {
      let index: any;
      index = this.preferredCheckbox.find((x) => x == data);
      if (index) {
        this.preferredCheckbox.splice(this.preferredCheckbox.indexOf(index), 1);
      }
    }
    console.log(this.preferredCheckbox);
  }
  // got to top when form submitted
  goToTop() {
    this.scroller.scrollToAnchor('backToTop');
  }
  restrictSpecialChar(event: KeyboardEvent): void {
    this.utility.restrictSpecialChar(event);
  }
}
