<div class="main-content d-flex flex-column">
    <!-- <app-navbar-style-one></app-navbar-style-one> -->

    <div class="template_one" >
        <ng-container>
            <div *ngFor="let items of data">
                <div class="template_one_bottom_section" *ngIf="items.is_draft == 0">
                    <div class="row m-0">
                        <div class="col-md-4 col-12"
                            style="display: flex; align-items: center; justify-content: center;">
                            <div class="image_div" style="width: 64%;">
                                <img [src]="baseUrl + items.image" alt="" width="100%">

                            </div>
                        </div>
                        <div class="col-md-6 col-12" style="padding: 0 15px">
                            <h1 class="cardHeading" style="font-weight: 500;">
                                {{items.title}}</h1>
                            <p class="cardHeading" style="font-size: 20px; font-weight: 300;">{{items.short_desc}}</p>
                        </div>
                    </div>
                    <div class="cta_section text-center" *ngIf="items?.CTA">
                        <button class="homeBUtton" (click)="callToActionButton(items?.CTA, items.slug)">Read
                            More</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>




