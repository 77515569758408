import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-non-authorized-modal',
  templateUrl: './non-authorized-modal.component.html',
  styleUrls: ['./non-authorized-modal.component.scss']
})
export class NonAuthorizedModalComponent implements OnInit {
  displayBasic: boolean;
  constructor(public route: Router) {
  }
  ngOnInit() {
  }
  routeToDashboard() {
    this.route.navigateByUrl('user-panel')
  }
}
