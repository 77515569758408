<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: #05144B" > Loading... </p></ngx-spinner> -->
<!-- <app-header></app-header> -->
<ng-container *ngIf="!pageData">
    <div style="padding: 15px 60px;">
        <ngx-shimmer-loading></ngx-shimmer-loading>
    </div>
    <div style="padding: 15px 60px;">
        <ngx-shimmer-loading></ngx-shimmer-loading>
    </div>
    <div style="padding: 15px 60px;">
        <ngx-shimmer-loading></ngx-shimmer-loading>
    </div>
</ng-container>

<ng-container *ngIf="pageData">
    <ng-container *ngIf="template_type == 'grid'">
        <app-template-two [pageData]="pageData"></app-template-two>
    </ng-container>
    <ng-container *ngIf="template_type == 'list'">
        <app-template-one [pageData]="pageData"></app-template-one>
    </ng-container>
    <ng-container *ngIf="template_type == 'committe'">
        <app-committe-ui [pageData]="pageData"></app-committe-ui>
    </ng-container>
    <ng-container *ngIf="template_type == 'home'">
        <app-home [pageData]="pageData"></app-home>
    </ng-container>
    <ng-container *ngIf="template_type == 'blog'">
        <app-blog-template [pageData]="pageData"></app-blog-template>
    </ng-container>
    <!-- blog detail template -->
    <ng-container *ngIf="template_type == 'blog-detail'">
        <app-blog-complete-detail [pageData]="pageData"></app-blog-complete-detail>
    </ng-container>
    <ng-container *ngIf="template_type =='Aao Factory Dekhein'">
        <app-template-one [pageData]="pageData"></app-template-one>
    </ng-container>
    <!-- blog detail template -->
</ng-container>

<app-footer></app-footer>
<!-- <div style="display: flex">
    <img style="margin: auto; height: 600px;" src="assets/img/Screenshot 2023-09-04 114613.png" alt="">
</div> -->