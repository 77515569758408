<!-- <app-navbar-style-one></app-navbar-style-one> -->

<app-homeone-banner [image]="ucci_base_url + '/assets/img/main-banner-bg1.jpg'"></app-homeone-banner>

<app-features></app-features>

<!-- <app-homeone-listings></app-homeone-listings> -->

<section class="category-area">
    <app-category></app-category>
</section>

<!-- <app-homeone-destinations></app-homeone-destinations> -->

<section class="feedback-area pt-100">
    <app-feedback-style-one></app-feedback-style-one>
</section>

<!-- <section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
    <app-how-it-works></app-how-it-works>
</section> -->

<app-homeone-blog></app-homeone-blog>

<!-- <app-app-download></app-app-download>s -->

<!-- <app-footer-style-one></app-footer-style-one> -->

<app-copyrights></app-copyrights>