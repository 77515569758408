import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {
  breadcrumb: any[] = [
    {
      title: 'Audit Logs',
      subTitle: 'Masters',
    },
  ];

  @ViewChild('dt1') dt1: Table

  spinnerMsg: string;
  filterVal: string;
  datalength: any;
  list: any = [];
  displayMaximizable: boolean = false;
  logId: any;
  userLogs: any = [];
  pdfData: any[];
  filteredPDFData: any[];
  cols: any = [];
  exportColumns: any = [];
  localStorage: any;
  roleName: any;

  constructor(private _apiService: ApiService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show();
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'User Name' },
      { field: 'email', header: 'User Email' },
      { field: 'description', header: 'Description' },
      { field: 'created_on', header: 'Created On' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.localStorage = JSON.parse(localStorage.getItem('UserObject'));
    this.roleName = this.localStorage.roleName
  }
  loadData(event: LazyLoadEvent) {
    console.log(event)
    let data;
    if (event.first !== 0) {
      let divideNumber = event.first / 10
      data = divideNumber + 1
      console.log(data)
    }
    this._apiService.getAllUsersLogs(data || 1).subscribe((res: any) => {
      this.list = res.data.data;
      console.log(this.list);
      this.datalength = res.data.total;
      this.spinner.hide();
    })
  }
  showMaximizableDialog(res: any) {
    this.userLogs = [];
    this.userLogs.push(res);
    console.log(this.userLogs);
    this.displayMaximizable = true;
  }
  // reset filter
  reset(dt1) {
    dt1.reset();
    this.filterVal = '';
  }

  onXlExport() {
    let data: any = []
    let dataForExcel: any = []
    if (this.dt1.filteredValue != null) {
      data = this.dt1.filteredValue;
    }
    else {
      data = this.list;
    }
    data.forEach((response: any, index: any) => {
      let date = moment(response.created_at).format('YYYY/MM/DD');
      dataForExcel.push(
        {
          'Sr No': index + 1,
          'Activity': response?.event_action ?? '-',
          'Created On': date,
          'User Name': response?.user?.name ?? '-',
          'User Email': response?.user?.email ?? '-',
          'Contact No': response?.user?.contact_no ?? '-',
          'Role': response?.role?.name ?? '-',
          'Description': response?.event_description ?? '-',
        })
    });
    this._apiService.exportExcel(dataForExcel, 'Audit-Log')
  }

  exportPdf() {
    this.pdfData = [];
    this.filteredPDFData = [];
    if (this.dt1.filteredValue != null) {
      this.filteredPDFData = this.dt1.filteredValue;
    } else if (this.dt1.filteredValue == null) {
      this.filteredPDFData = this.list;
    }
    console.log(this.filteredPDFData);

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      console.log(this.filteredPDFData[i]);
      let date = moment(this.filteredPDFData[i].created_at).format('YYYY/MM/DD');
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.user?.name ?? '-',
        email: this.filteredPDFData[i]?.user?.email ?? '-',
        description: this.filteredPDFData[i]?.event_description ?? '-',
        created_on: date ?? '-',
      });
    }
    this._apiService.exportPdf(
      this.pdfData,
      this.exportColumns,
      'Audit-Log'
    );
  }
}
