import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, OnChanges {
  @Input() statusMsg: any;
  role: any;
  redirectMessage: string = '';
  state: boolean = true;
  response: any;
  constructor(private router: Router, private spinner: NgxSpinnerService, private apiService: ApiService, private paymentService: PaymentService, private activatedRoute: ActivatedRoute) { }
  ngOnChanges(changes: SimpleChanges): void {
    // if (this.role == 'Admin' || this.role == 'UCCI Staff') {
    console.log(changes)
    if (this.statusMsg?.form == 'Visa Form') {
      this.redirectMessage = 'Redirecting to Visa Approval List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/visa-approval');
      }, 4000);
    } else if (this.statusMsg?.form == 'facility booking') {
      this.redirectMessage = 'Redirecting to facility booking List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/facilities-approval');
      }, 4000);
    }
    // }
  }

  ngOnInit(): void {
    console.log('====================================');
    console.log(this.statusMsg);
    console.log('====================================');
    this.role = this.apiService.localStorageObject().role;
    let slug = this.activatedRoute.snapshot.params;
    if (slug?.order_id) {
      this.paymentService.getPaymentData(slug.order_id).subscribe((res: any) => {
        console.log(res)
        this.response = res?.data;
        this.response['status_api_res'] = JSON.parse(res?.data?.status_api_res);
        console.log(this.response);
      })
    }
    // if (true) {
    if (this.statusMsg?.form == 'Visa Form') {
      this.redirectMessage = 'Redirecting to Visa Approval List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/visa-approval');
      }, 4000);
    } else if (this.statusMsg?.form == 'facility booking') {
      this.redirectMessage = 'Redirecting to Facility Booking List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/facilities-approval');
      }, 4000);
    } else if (this.statusMsg?.form == 'coo') {
      this.redirectMessage = 'Redirecting to Certificate of Origin List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/certificate-of-origin-list');
      }, 4000);
    } else if (this.statusMsg?.form == 'document') {
      this.redirectMessage = 'Redirecting to Document Attestation List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/document-attestation-list');
      }, 4000);
    } else if (this.statusMsg?.form == 'raise issue') {
      this.redirectMessage = 'Redirecting to Issue List ....';
      setTimeout(() => {
        if (this.role == 'Admin' || this.role == 'UCCI Staff') {
          this.router.navigateByUrl('/user-panel/issues-lists');
        }
        else {
          this.router.navigateByUrl('/user-panel/my-issues');
        }
      }, 4000);
    }
    else if (this.statusMsg?.form == 'add product') {
      this.redirectMessage = 'Redirecting to Product List ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/add-product');
      }, 4000);
    }
    else if (this.statusMsg?.form == 'regular-member-edit') {
      this.spinner.show();
      this.redirectMessage = 'Redirecting to Listing Page ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/regular-member-list');
      }, 4000);
    }
    else if (this.statusMsg?.form == 'hwm-edit') {
      this.redirectMessage = 'Redirecting to Listing Page ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/hwm-member-list');
      }, 4000);
    }
    else if (this.statusMsg?.form == 'edit-non-member') {
      this.redirectMessage = 'Redirecting to Listing Page ....';
      setTimeout(() => {
        this.router.navigateByUrl('/user-panel/non-member-list');
      }, 4000);
    }
    // }
  }
}