import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { InterestMasterService } from 'src/app/services/interest-master.service';
import { NatureOfBusinessMasterService } from 'src/app/services/nature-of-business-master.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-edit-hwm-member-by-id',
  templateUrl: './edit-hwm-member-by-id.component.html',
  styleUrls: ['./edit-hwm-member-by-id.component.scss'],
})
export class EditHwmMemberByIdComponent implements OnInit {
  hwmMemberShipFormData: any = { waste_details: [], product_details: [] };
  hwmDocuments: any = {};
  productDetails: any = [];
  member_classification: any = {};
  selectedFile: File;
  turnoverData: any = {};
  categoryData: any = {};
  preferred1Checkbox: any = [];
  preferred2Checkbox: any = [];
  formResponse: any = [];
  isShow: boolean = true;
  adFee: any = [];
  fdsfds: any = [2, 4]
  trustFee: any = [];
  communication: any = ['Whatsapp', 'Email', 'Telephone'];
  today: any = new Date();
  invalidDates: Array<Date> = [];
  interest: any = [];
  interest1Checkbox: any = [];
  interest2Checkbox: any = [];
  natureofBusinessList: any = [];
  selectedState: string;
  selectedState1: string;
  selectedState2: string;
  selectedState3: string;
  turnoverRequired: boolean = true;
  certificateRequired: boolean = false;
  // @ViewChild('form') form: NgForm;
  local: any;
  state: any = [];
  HWMDetailsId: any = [];
  logoState: boolean = true;
  profile1State: boolean = true;
  profile2State: boolean = true;
  gstState: boolean = true;
  caState: boolean = true;
  ecState: boolean = true;
  cteState: boolean = true;
  ctoState: boolean = true;
  hwmState: boolean = true;
  underState: boolean = true;
  appState: boolean = true;
  logoPreviewState: boolean = true;
  modalStyle: string = 'none';
  HWMImgs_url: string = environment.HWMImgs_url;
  nominee1Image: string = environment.nominee1Image;
  nominee2Image: string = environment.nominee2Image;
  nominee1PreviewState: boolean = true;
  pre1: any = [];
  pre2: any = [];
  nominee2PreviewState: boolean = true;
  oldData: any;
  newData: any;
  otpState: boolean = false;
  otp: any;
  approveModalState: boolean = false;
  form: NgForm;
  orderId: any;
  amount: number;
  successState: boolean = false;
  gstDocState: boolean = true;
  caDocState: any = true;
  formData: FormData;
  loggedInUserEmail: any;
  logoName: string;
  oldNominee1State: boolean = true;
  oldNominee2State: boolean = true;
  profile1Name: string;
  profile2Name: string;
  oldAppState: boolean = true;
  oldUndertakingState: boolean = true;
  oldec4State: boolean = true;
  oldec3State: boolean = true;
  oldec2State: boolean = true;
  oldecState: boolean = true;
  cteDocBaseURL: string = environment.cteDocBaseURL;
  ecCertificateBaseURL: string = environment.ecDocBaseURL;
  ctoDocBaseURL: string = environment.ctoDocBaseURL;
  hwmDocBaseURL: string = environment.hwmDocBaseURL;
  appDocBaseURL: string = environment.appDocBaseURL;
  utDocBaseURL: string = environment.utDocBaseURL;
  applicationChanged: boolean = false;
  underTakingChanged: boolean = false;
  requiredAnyOneDoc: boolean = true;
  ec_document: any;
  cte_document: any;
  cto_document: any;
  hwm_document: any;
  successMessage: any;
  role: any;
  memberData: any;
  nominee: any;
  userId: any;
  RegisteraddressList: any = [];
  FactoryaddressList: any = [];
  memberId: any;
  selectedState4: any;
  selectedDistrict1: any;
  selectedDistrict2: any;
  selectedOffice: any;
  roDetails: any = [];
  scheduleList: any[];
  wasteList: any[];
  scheduleArray: any = [];
  memberShipStatus: any;

  scheduleNoArray: any = [
    { schedule_no: 1 },
    { schedule_no: 2 },
    { schedule_no: 3 },
    { schedule_no: 4 },
  ]
  constructor(
    private apiservice: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private paymentservice: PaymentService,
    private interestMasterService: InterestMasterService,
    private nobmaster: NatureOfBusinessMasterService,
    private commonFunction: CommonClass,
    private router: Router,
    private scroller: ViewportScroller,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private ActivatedRoute: ActivatedRoute
  ) { }
  ngOnInit(): void {
    // this.spinner.show();
    this.slug = this.ActivatedRoute.snapshot.params.slug;
    this.role = this.commonFunction.getLocalStorage().role;
    // if (this.role == 'Admin' || this.role == 'UCCI Staff') {

      this.getAreaofInterest();
      this.getNatureofBusiness();
      this.getScheduleData();
      this.getRODetails();
      this.apiservice.getTurnoverData().subscribe((res: any) => {
        this.turnoverData = res;
      });
      this.apiservice.getMemberClassfication().subscribe((res: any) => {
        this.member_classification = res;
      });
      this.apiservice.getMemberCategory().subscribe((res: any) => {
        this.categoryData = res;
      });
      this.getStates();
      this.invalidDates.push(this.today);

      this.loggedInUserEmail = this.commonFunction.getLocalStorage().useremail;
    // }
    // if (localStorage.role == 'HWM Owner' || localStorage.role == 'HWM Contact Person') {
    this.getMemberById();
    // }
  }
  getMemberById() {
    this.apiservice.getMemberById(this.slug).subscribe((res: any) => {
      this.memberData = res.data;
      this.memberShipStatus = res.data.status
      console.log(this.memberData);
      this.nominee = [];
      this.nominee.push(
        {
          nomineeName: this.memberData?.nominee1?.name ?? null,
          userId: this.memberData?.nominee1?.user_id ?? null,
        },
        {
          nomineeName: this.memberData?.nominee2?.name ?? null,
          userId: this.memberData?.nominee2?.user_id ?? null,
        }
      );
      // this.memberData = res?.data[0];
      // console.log(this.memberData);
      // this.nominee = [];
      // this.nominee.push(
      //   {
      //     nomineeName: this.memberData?.nominee1?.name,
      //     userId: this.memberData?.nominee1?.user_id,

      //   },
      //   {
      //     nomineeName: this.memberData?.nominee2?.name,
      //     userId: this.memberData?.nominee2?.user_id,
      //   }
      // );
      // console.log(this.nominee);
      this.RegisteraddressList = this.memberData?.company_address?.filter((response: any) => response?.type_of_address == 'Registered Address');
      this.FactoryaddressList = this.memberData?.company_address?.filter((response: any) => response?.type_of_address == 'Factory Address');
      console.log(this.RegisteraddressList, this.FactoryaddressList);
      this.memberId = this.memberData?.id ?? null;
      // if 
      let old_waste_details = JSON?.parse(
        this.memberData?.hwm_details[0]?.hw_details ?? null
        );
      // *! Filter factory state
      let factoryState: any = this.state?.filter((res: any) => {
        return (
          res?.name == this.memberData?.hwm_details[0]?.factory_address?.state ?? null
        );
      });
      this.selectedState3 = factoryState[0];
      // *! Filter register state
      let registerState: any = this.state?.filter((res: any) => {
        return (
          res?.name == this.RegisteraddressList[0]?.state ?? null
        );
      });
      this.selectedState4 = registerState[0];
      // *! Filter register district
      let registerDistrict: any = this.roDetails?.filter((res: any) => {
        return (
          res?.regional_office_name == this.RegisteraddressList[0]?.district ?? null
        );
      });
      this.selectedDistrict1 = registerDistrict[0];
      // *! Filter factory district
      let factoryDistrict: any = this.roDetails?.filter((res: any) => {
        return (
          res?.regional_office_name == this.FactoryaddressList[0]?.district ?? null
        );
      });
      this.selectedDistrict2 = factoryDistrict[0]?? null;
      // *! Filter regional office district
      let regionalOffice: any = this.roDetails?.filter((res: any) => {
        return (
          res?.regional_office_name == this.memberData?.regional_office ?? null
        );
      });
      this.selectedOffice = regionalOffice[0] ?? null;

      // *! Filter nominee 1 state
      let nominee1State: any = this.state.filter((res: any) => {
        return res?.name == this.memberData?.nominee1_address?.state ?? null;
      });
      this.selectedState1 = nominee1State[0] ?? null;

      if (
        this.memberData?.nominee1?.preferred_communication?.includes('[')
      ) {
        this.pre1 = JSON.parse(
          this.memberData?.nominee1?.preferred_communication ?? null
        );
        console.log(this.pre1);
      } else if (
        this.memberData?.nominee1?.preferred_communication?.includes(', ')
      ) {
        this.pre1 =
          this.memberData?.nominee1?.preferred_communication?.split(', ');
        console.log(this.pre1);
      } else if (
        this.memberData?.nominee1?.preferred_communication?.includes(',')
      ) {
        this.pre1 =
          this.memberData?.nominee1?.preferred_communication?.split(',');
        console.log(this.pre1);
      } else {
        this.pre1.push(this.memberData?.nominee1?.preferred_communication ?? null);
      }

      this.pre1?.forEach((element) => {
        this.onChange(true, element, 'pre1');
      });
      if (
        this.memberData?.nominee2?.preferred_communication?.includes('[')
      ) {
        this.pre2 = JSON.parse(
          this.memberData?.nominee2?.preferred_communication?? null
        );
      } else if (
        this.memberData?.nominee2?.preferred_communication?.includes(', ')
      ) {
        this.pre2 =
          this.memberData?.nominee2?.preferred_communication?.split(', ');
      } else if (
        this.memberData?.nominee2?.preferred_communication?.includes(',')
      ) {
        this.pre2 =
          this.memberData?.nominee2?.preferred_communication?.split(',');
      } else {
        this.pre2.push(this.memberData?.nominee2?.preferred_communication ?? null);
      }
      this.pre2.forEach((element) => {
        this.onChange(true, element, 'pre2');
      });

      let product_details = [];
      if (this.memberData?.hwm_details[0].products_details ?? null) {
        product_details = JSON.parse(
          this.memberData?.hwm_details[0].products_details ?? null
        );
      }

      let hm_details = [];
      if (this.memberData?.hwm_details[0].hw_details) {
        hm_details = JSON.parse(
          this.memberData?.hwm_details[0].hw_details ?? null
        );
      }

      console.log(this.newData);
      this.oldData = {
        company: {
          company_name: this.memberData?.company_name ?? null,
        },
        address: {
          factory_address:
            this.memberData?.hwm_details[0]?.factory_address?.address ?? null,
          // factory_city:
          //   this.memberData?.hwm_details[0]?.factory_address?.address,
          // factory_pin_code:
          //   this.memberData?.hwm_details[0]?.factory_address?.pin_code,
          // factory_state: factoryState[0]?.name,
          // factory_district: factoryDistrict[0]?.regional_office_name,
          // unit: this.memberData?.hwm_details[0]?.unit,
        },
        registeredAddress: {
          registered_address: this.RegisteraddressList[0]?.address ?? null,
          registered_state: registerState[0]?.name ?? null,
          registered_district: registerDistrict[0]?.regional_office_name ?? null,
          registered_city: this.RegisteraddressList[0]?.city ?? null,
          registered_pin_code: this.RegisteraddressList[0]?.pin_code ?? null,
        },
        // nominee1: {
        //   name1: this.memberData?.nominee1?.name,
        //   designation1: this.memberData?.nominee1?.designation,
        //   pan_number1: this.memberData?.nominee1?.pan_number,
        //   email1: this.memberData?.nominee1?.email,
        //   office_phone1: this.memberData?.nominee1?.office_phone,
        //   mobile_no1: this.memberData?.nominee1?.mobile,
        //   department1: this.memberData?.nominee1?.department,
        //   profile1: this.memberData?.nominee1?.media,
        //   nominee1_address: this.memberData?.nominee1_address?.address,
        //   nominee1_city: this.memberData?.nominee1_address?.city,
        //   nominee1_pin_code: this.memberData?.nominee1_address?.pin_code,
        //   biodata1: this.memberData?.nominee1?.biodata,
        //   area_of_interest1: this.memberData?.nominee1_interest,
        //   preferred1:
        //     this.memberData?.nominee1?.preferred_communication?.split(', '),
        // },
      };
      if (this.memberData?.hwm_details[0]?.hw_details) {
        this.oldData['waste_details'] = JSON.parse(this.memberData.hwm_details[0].hw_details ?? null);
        this.setWasteDetails(this.oldData['waste_details']);
      }

      let authData = [];
      if (this.memberData.hwm_details[0].ec_consents_status) {
        authData = JSON.parse(
          this.memberData.hwm_details[0].ec_consents_status ?? null
        );
      }
      console.log(authData);
      console.log("this.oldData", this.oldData);
      if (this.memberData?.turnover_id == 1) {
        this.certificateRequired = true;
      } else if (this.memberData?.turnover_id != 1) {
        this.certificateRequired = false;
      }
      if (!authData[0]?.ec_document) {
        this.ecCheck1 = false;
      }
      if (!authData[1]?.cte_document) {
        this.ecCheck2 = false;
      }
      if (!authData[2]?.cto_document) {
        this.ecCheck3 = false;
      }
      if (!authData[3]?.hwm_document) {
        this.ecCheck4 = false;
      }
      this.ec_document = authData[0]?.ec_document ?? null;
      this.cte_document = authData[1]?.cte_document ?? null;
      this.cto_document = authData[2]?.cto_document ?? null;
      this.hwm_document = authData[3]?.hwm_document ?? null;
      if (
        authData[0]?.ec_document ||
        authData[1]?.cte_document ||
        authData[2]?.cto_document ||
        authData[3]?.hwm_document
      ) {
        this.requiredAnyOneDoc = false;
      }
      this.hwmMemberShipFormData = {
        company_name: this.memberData?.company_name ?? null,
        gst_number: this.memberData?.gst_number ?? null,
        waste_details: old_waste_details ?? null,
        product_details: product_details ?? null,
        cin_number: (this.memberData?.cin_number == "null" ? '' : this.memberData?.cin_number ?? null),
        registered_district:this.selectedDistrict1?.regional_office_name ?? null,
        factory_district:this.selectedDistrict2?.regional_office_name ?? null,
        regional_office:this.selectedOffice?.regional_office_name ?? null,
        factory_address:
          this.memberData?.hwm_details[0]?.factory_address?.address ?? null,
        factory_city:
          this.memberData?.hwm_details[0]?.factory_address?.address ?? null,
        factory_pin_code:
          this.memberData?.hwm_details[0]?.factory_address?.pin_code ?? null,
        unit: this.memberData?.hwm_details[0]?.unit == "null" ? '' : this.memberData?.hwm_details[0]?.unit ?? null,
        registered_address: this.RegisteraddressList[0]?.address ?? null,
        registered_city: this.RegisteraddressList[0]?.city ?? null,
        registered_pin_code: JSON.parse(this.RegisteraddressList[0]?.pin_code ?? null),
        registration_number: this.memberData?.membership_no == "null" ? '' : this.memberData?.membership_no ?? null,
        // capital_investment: this.memberData?.capital_investment,
        website: this.memberData?.website == "null" ? '' : this.memberData?.website ?? null,
        email: this.memberData?.email ?? null,
        nature_of_business: this.memberData?.nob?.id ?? null,
        // logo: this.memberData?.media,
        name1: this.memberData?.nominee1?.name ?? null,
        designation1: this.memberData?.nominee1?.designation == "null" ? '' : this.memberData?.nominee1?.designation ?? null,
        pan_number1: this.memberData?.nominee1?.pan_number == "null" ? '' : this.memberData?.nominee1?.pan_number ?? null,
        email1: this.memberData?.nominee1?.email,
        office_phone1: this.memberData?.nominee1?.office_phone1 == "null" ? '' : this.memberData?.nominee1?.office_phone1 ?? null,
        mobile_no1: this.memberData?.nominee1?.mobile,
        department1: this.memberData?.nominee1?.department == "null" ? '' : this.memberData?.nominee1?.department ?? null,
        // profile1: this.memberData?.nominee1?.media,
        nominee1_address: this.memberData?.nominee1_address?.address ?? null,
        nominee1_city: this.memberData?.nominee1_address?.city ?? null,
        nominee1_pin_code: this.memberData?.nominee1_address?.pin_code ?? null,
        // biodata1: this.memberData?.nominee1?.biodata == "null" ? '' : this.memberData?.nominee1?.biodata,
        area_of_interest1: this.memberData?.nominee1_interest ?? null,
        name2: this.memberData?.nominee2?.name ?? null,
        designation2: this.memberData?.nominee2?.designation == "null" ? '' : this.memberData?.nominee2?.designation ?? null,
        // profile2: this.memberData?.nominee2?.media,
        pan_number2: this.memberData?.nominee2?.pan_number == "null" ? '' : this.memberData?.nominee2?.pan_number ?? null,
        email2: this.memberData?.nominee2?.email?? null,
        // office_phone2: this.memberData?.nominee2?.office_phone1,
        mobile_no2: this.memberData?.nominee2?.mobile ?? null,
        department2: this.memberData?.nominee2?.department == "null" ? '' : this.memberData?.nominee2?.department ?? null,
        nominee2_address: this.memberData?.nominee2_address?.address ?? null,
        // nominee2_city: this.memberData?.nominee2_address?.city,
        // nominee2_pin_code: this.memberData?.nominee2_address?.pin_code,
        // biodata2: this.memberData?.nominee2?.biodata,
        area_of_interest2: this.memberData?.nominee2_interest ?? null,
        investment: this.memberData?.hwm_details[0]?.investment ?? null,
        hw_disposal_expected_date: new Date(
          this.memberData?.hwm_details[0]?.hw_disposal_expected_date ?? null
        ),
        production_commencement_date: new Date(
          this.memberData?.hwm_details[0]?.hw_disposal_expected_date ?? null
        ),
        incineration_facility_required: this.memberData?.hwm_details[0]
          ?.incineration_facility_required
          ? 'yes'
          : 'no',
        productionStarted: this.memberData?.hwm_details[0]
          ?.is_production_started
          ? '1'
          : '0',
        gst_certificate: this.memberData?.hwm_details[0]?.gst_certificate ?? null,
        application: this.memberData?.hwm_details[0]?.application ?? null,
        undertaking: this.memberData?.hwm_details[0]?.undertaking ?? null,
        turnover_id: this.memberData?.turnover_id ?? null,
        ca_certificate: this.memberData?.hwm_details[0]?.ca_certificate ?? null,
        is_ec_applied: authData[0]?.is_ec_obtained ?? null,
        is_cte_applied: authData[1]?.is_cte_applied ?? null,
        is_cto_applied: authData[2]?.is_cto_applied ?? null,
        is_hwm_auth_applied: authData[3]?.is_hwm_auth_applied ?? null,
        is_ec_obtained: authData[0]?.is_ec_obtained ?? null,
        is_cte_obtained: authData[1]?.is_cte_obtained ?? null,
        is_cto_obtained: authData[2]?.is_cto_obtained ?? null,
        is_hwm_auth_obtained: authData[3]?.is_hwm_auth_obtained ?? null,
        ec_hold_reason: authData[0]?.ec_hold_reason ?? null,
        cte_hold_reason: authData[1]?.cte_hold_reason ?? null,
        cto_hold_reason: authData[2]?.cto_hold_reason ?? null,
        hwm_hold_reason: authData[3]?.hwm_hold_reason ?? null,
        registered_state:this.selectedState4?.name ?? null,
        // ec_document: authData[0]?.ec_document,
        // cte_document: authData[1]?.cte_document,
        // cto_document: authData[2]?.cto_document,
        // hwm_document: authData[3]?.hwm_document,
        remarks: this.memberData?.remark == "null" ? '' : this.memberData?.remark ?? null,
        supprting_doc: [{}],
        // amendment_reason: this.memberData?.amendment_reason,
        // area_of_interest1:
        // product_dex
        member_since: new Date(
          this.memberData?.member_since ?? null
        ),
      };


      console.log(this.hwmMemberShipFormData)
      //logo
      if (this.memberData?.media != '') {
        this.hwmMemberShipFormData.logo = this.memberData?.media ?? null;
      }
      else {
        this.logoPreviewState = false;
      }

      //profile1
      if (this.memberData?.nominee1?.media) {
        this.hwmMemberShipFormData.profile1 = this.memberData?.nominee1?.media ?? null;
      }

      else {
        this.photo1 = false;
      }

      //profile2
      if (this.memberData?.nominee2?.media) {
        this.hwmMemberShipFormData.profile2 = this.memberData?.nominee2?.media ?? null;
      }
      else {
        this.photo2 = false;
      }

      //ec_document 
      if (authData[0]?.ec_document) {
        this.hwmMemberShipFormData.ec_document = authData[0]?.ec_document ?? null;
      }
      else {
        this.ecCheck1 = false;
      }

      //cte_document
      if (authData[1]?.cte_document) {
        this.hwmMemberShipFormData.cte_document = authData[1]?.cte_document ?? null;
      }
      else {
        this.ecCheck2 = false;
      }

      //cto_document
      if (authData[2]?.cto_document) {
        this.hwmMemberShipFormData.cto_document = authData[2]?.cto_document ?? null;
      }
      else {
        this.ecCheck3 = false;
      }

      //hwm_document
      if (authData[3]?.hwm_document) {
        this.hwmMemberShipFormData.hwm_document = authData[3]?.hwm_document ?? null;
      }
      else {
        this.ecCheck4 = false;
      }




      if (this.memberData.hwm_details[0].products_details) {
        this.hwmMemberShipFormData['product_details'] = JSON.parse(
          this.memberData.hwm_details[0].products_details?? null
        )
      } else if (!this.memberData.hwm_details[0].products_details) {
        this.hwmMemberShipFormData.product_details.push({
          product_description: '',
          product_quantity: '',
        });
      }
      console.log(this.hwmMemberShipFormData, this.hwmMemberShipFormData['product_details']);
      this.spinner.hide();
    });
    if(this.hwmMemberShipFormData.ca_certificate !== '' && this.hwmMemberShipFormData.ca_certificate !== null && this.hwmMemberShipFormData.ca_certificate !== undefined){
      this.caCertificateCheck = true;
    }else{
      this.caCertificateCheck = false;
    }
  }

  setWasteDetails(waste_details) {
    console.log(waste_details, this.wasteList);
    waste_details.forEach((res: any, index: any) => {
      this.scheduleList = [];
      if (res.schedule_no == 1) {
        this.scheduleListProcess[index] = { showCodeInput: false, showProcessInput: false };
        this.wasteList?.forEach((list: any) => {
          if (res.waste_process_no == list?.process_no && list?.parent_id) {
            this.scheduleList.push(list);
          }
        })
        console.log(this.scheduleList)
      }

      else {
        this.scheduleListProcess[index] = { showCodeInput: true, showProcessInput: true };
      }
      this.newScheduleList[index] = { index: index, value: this.scheduleList };
      console.log(this.newScheduleList);
      // this.getHwmMemberList();
    });
  }
  slug(slug: any) {
    throw new Error('Method not implemented.');
  }
  // addProductDetailRow() {
  //   this.hwmMemberShipFormData?.waste_details.push({});
  // }
  //to add one row to product detail
  product_details_boolean: boolean = false;
  addProductDetailRow(string?: any) {
    if (string == 'product') {
      // let validation = this.getValidation();

      // console.log(validation);

      if (this.product_details_boolean == false) {
        this.hwmMemberShipFormData.product_details.push({
          product_description: '',
          product_quantity: '',
        });
      } else {
        this.messageService.add({
          severity: 'error',
          // summary: 'Some details are not filled!',
          detail: 'Please Fill All the Details!',
        });
      }
    } else {
      this.hwmMemberShipFormData.waste_details.push({});
    }
  }

  addDocument() {
    this.hwmMemberShipFormData.supprting_doc.push({});
  }

  downLoadPdf(url: string) {
    // console.log('====================================');
    // console.log(url);
    // console.log('====================================');
    if (url.endsWith('.pdf')) {
      let value = url;
      // console.log(data);
      var anchor = document.createElement('a');
      anchor.download = value;
      anchor.href = value;
      anchor.target = '_blank';
      anchor.click();
    }
  }
  // hwm member list
  async getHwmMemberList() {
    await this.apiservice.getHwmMemberList().subscribe((res: any) => {
      // if (this.localStorage.role == 'HWM Owner' || this.localStorage.role == 'HWM Contact Person') {
      // }
      // else if (this.localStorage.role == 'Admin' || this.localStorage.role == 'UCCI Staff') {
      //   this.memberData = this.hwmMemberData;
      // }

    })

    console.log(this.scheduleListProcess);
  }
  // otpForm = this.fb.group({
  //   email: [null, [Validators.required]],
  //   otp: [null],
  // });

  appliedByForm = this.fb.group({
    userId: [null, [Validators.required]],
  });
  get o() {
    return this.appliedByForm.controls;
  }
  onClose() {
    this.otpState = false;
  }
  submitData() {
    this.formData = new FormData();
    let selectedState: any = [];
    selectedState.push(this.form?.value?.state);
    // console.log(this.form.value.state);

    let selectedState1: any = [];

    selectedState1.push(this.form?.value?.nominee1_state);
    // console.log(this.form?.value?.nominee1_state);

    let selectedState2: any = [];
    selectedState2.push(this.form?.value?.nominee2_state);
    // console.log(this.form?.value?.nominee2_state);

    let selectedState3: any = [];
    selectedState3.push(this.form?.value?.factory_state);
    let wasteDisposalDate = moment(
      this.hwmMemberShipFormData.hw_disposal_expected_date
    ).format('YYYY/MM/DD');
    this.hwmMemberShipFormData.hw_disposal_expected_date = wasteDisposalDate;

    let production_commencement_date = moment(
      this.hwmMemberShipFormData.production_commencement_date
    ).format('YYYY/MM/DD');
    this.hwmMemberShipFormData.production_commencement_date =
      production_commencement_date;
    // console.log(this.hwmMemberShipFormData);
    let data = this.hwmMemberShipFormData;
    // console.log(data);
    for (const [key, value] of Object.entries(this.hwmMemberShipFormData)) {
      // // console.log(key, value);
      if (!(key in this.hwmDocuments)) {
        // // console.log(key);
        if (
          key != 'waste_details' &&
          key != 'product_details' &&
          key != 'area_of_interest1' &&
          key != 'area_of_interest2' &&
          key != 'state' &&
          key != 'nominee1_state' &&
          key != 'nominee2_state' &&
          key != 'factory_state' &&
          key != 'ec_consents_status' &&
          key != 'ec_document' &&
          key != 'cte_document' &&
          key != 'cto_document' &&
          key != 'hwm_document' &&
          key != 'undertaking' &&
          key != 'application' &&
          key != 'logo' &&
          key != 'profile1' &&
          key != 'profile2' &&
          key != 'gst_certificate' &&
          key != 'ca_certificate' &&
          key != 'nominee1_aoi' &&
          key != 'nominee2_aoi'
        ) {
          this.formData.append(key, `${value}`);
        }
      }
    }
    let ec_consents_status: any = [];
    let object: any = {};
    if (this.oldecState == false) {
      object = {
        is_ec_obtained: this.hwmMemberShipFormData.is_ec_obtained,
        is_ec_applied: this.hwmMemberShipFormData.is_ec_applied,
        ec_hold_reason: this.hwmMemberShipFormData.ec_hold_reason,
        ec_document: this.hwmDocuments.ec_document.name,
      };
    } else {
      object = {
        is_ec_obtained: this.hwmMemberShipFormData.is_ec_obtained,
        is_ec_applied: this.hwmMemberShipFormData.is_ec_applied,
        ec_hold_reason: this.hwmMemberShipFormData.ec_hold_reason,
        ec_document: this.ec_document,
      };
    }

    let object1: any = {};
    if (this.oldec2State == false) {
      object1 = {
        is_cte_applied: this.hwmMemberShipFormData.is_cte_applied,
        is_cte_obtained: this.hwmMemberShipFormData.is_cte_obtained,
        cte_hold_reason: this.hwmMemberShipFormData.cte_hold_reason,
        cte_document: this.hwmDocuments.cte_document.name,
      };
    } else {
      object1 = {
        is_cte_applied: this.hwmMemberShipFormData.is_cte_applied,
        is_cte_obtained: this.hwmMemberShipFormData.is_cte_obtained,
        cte_hold_reason: this.hwmMemberShipFormData.cte_hold_reason,
        cte_document: this.cte_document,
      };
    }

    let object2 = {};
    if (this.oldec3State == false) {
      object2 = {
        is_cto_applied: this.hwmMemberShipFormData.is_cto_applied,
        is_cto_obtained: this.hwmMemberShipFormData.is_cto_obtained,
        cto_hold_reason: this.hwmMemberShipFormData.cto_hold_reason,
        cto_document: this.hwmDocuments.cto_document.name,
      };
    } else {
      object2 = {
        is_cto_applied: this.hwmMemberShipFormData.is_cto_applied,
        is_cto_obtained: this.hwmMemberShipFormData.is_cto_obtained,
        cto_hold_reason: this.hwmMemberShipFormData.cto_hold_reason,
        cto_document: this.cto_document,
      };
    }

    let object3 = {};
    if (this.oldec4State == false) {
      object3 = {
        is_hwm_auth_applied: this.hwmMemberShipFormData.is_hwm_auth_applied,
        is_hwm_auth_obtained: this.hwmMemberShipFormData.is_hwm_auth_obtained,
        hwm_hold_reason: this.hwmMemberShipFormData.hwm_hold_reason,
        hwm_document: this.hwmDocuments.hwm_document.name,
      };
    } else {
      object3 = {
        is_hwm_auth_applied: this.hwmMemberShipFormData.is_hwm_auth_applied,
        is_hwm_auth_obtained: this.hwmMemberShipFormData.is_hwm_auth_obtained,
        hwm_hold_reason: this.hwmMemberShipFormData.hwm_hold_reason,
        hwm_document: this.hwm_document,
      };
    }
    ec_consents_status.push(object);
    ec_consents_status.push(object1);
    ec_consents_status.push(object2);
    ec_consents_status.push(object3);
    this.formData.append(
      'ec_consents_status',
      JSON.stringify(ec_consents_status ?? null)
    );
    this.formData.append(
      'waste_details',
      JSON.stringify(this.hwmMemberShipFormData.waste_details ?? null)
    );
    this.formData.append(
      'product_details',
      JSON.stringify(this.hwmMemberShipFormData.product_details ?? null)
    );
    this.formData.append('nominee1_state', selectedState1[0]?.name ?? null);
    // this.formData.append('nominee2_state', selectedState2[0]?.name);
    this.formData.append('factory_state', 'Rajasthan');
    if (this.ecCheck1 == false) {
      this.formData.append('ec_document', this.hwmDocuments?.ec_document ?? null);
    }
    if (this.ecCheck2 == false) {
      this.formData.append('cte_document', this.hwmDocuments?.cte_document ?? null);
    }
    if (this.ecCheck3 == false) {
      this.formData.append('cto_document', this.hwmDocuments?.cto_document?? null);
    }
    if (this.ecCheck4 == false) {
      this.formData.append('hwm_document', this.hwmDocuments?.hwm_document?? null);
    }
    if (this.underTakingChanged) {
      this.formData.append('undertaking', this.hwmDocuments?.undertaking?? null);
    }
    if (this.applicationChanged) {
      this.formData.append('application', this.hwmDocuments?.application ?? null);
    }
    if (this.oldLogoState == false) {
      this.formData.append('logo', this.hwmDocuments?.logo ?? null);
    }
    if (this.oldNominee1State == false) {
      this.formData.append('profile1', this.hwmDocuments?.profile1 ?? null);
    }
    if (this.oldNominee2State == false) {
      this.formData.append('profile2', this.hwmDocuments?.profile2 ?? null);
    }

    if (this.oldUndertakingState == false) {
      this.formData.append(
        'gst_certificate',
        this.hwmDocuments?.gst_certificate ?? null
      );
    }

    if (this.oldAppState == false) {
      this.formData.append('ca_certificate', this.hwmDocuments?.ca_certificate ?? null);
    }
    // if (!this.logoPreviewState) {
    //   this.formData.append('logo', this.hwmDocuments?.logo);
    // }

    // if (!this.nominee1PreviewState) {
    //   this.formData.append('profile1', this.hwmDocuments?.profile1);
    // }
    // if (!this.nominee2PreviewState) {
    //   this.formData.append('profile2', this.hwmDocuments?.profile2);
    // }
    // if (!this.gstDocState) {
    //   this.formData.append(
    //     'gst_certificate',
    //     this.hwmDocuments?.gst_certificate
    //   );
    // }
    // if (!this.caDocState) {
    //   this.formData.append('ca_certificate', this.hwmDocuments?.ca_certificate);
    // }
    // this.formData.append('preferred_communication1', this.preferred1Checkbox);
    for (var i = 0; i < this.preferred1Checkbox.length; i++) {
      // // console.log('hkdqVDWAE', this.preferred1Checkbox);
      this.formData.append(
        'preferred_communication1[]',
        this.preferred1Checkbox[i] ?? null
      );
    }
    // let aoi1: any = [];
    // for (var i = 0; i < this.hwmMemberShipFormData?.area_of_interest1?.length; i++) {
    //   aoi1.push(this.hwmMemberShipFormData?.area_of_interest1[i].id);
    // }
    console.log(this.hwmMemberShipFormData.area_of_interest1);
    this.formData.append('nominee1_aoi', this.hwmMemberShipFormData.area_of_interest1 ??  ' ');

    // let aoi2: any = [];
    // for (
    //   var i = 0;
    //   i < this.hwmMemberShipFormData?.area_of_interest2?.length;
    //   i++
    // ) {
    //   aoi2.push(this.hwmMemberShipFormData?.area_of_interest2[i].id);
    // }
    this.formData.append('nominee2_aoi', this.hwmMemberShipFormData?.area_of_interest2 ?? null);
    this.hwmMemberShipFormData.supprting_doc.map((res: any, index: any) => {
      this.formData.append(
        `supporting_doc[document][${index}]`,
         res.document ?? ' '
      );
      this.formData.append(
        `supporting_doc[document_for][${index}]`,
        res.document_for ?? ' '
      );
      console.log(res);
    });
    this.newData = {
      company: {
        company_name: this.hwmMemberShipFormData?.company_name,
      },
      address: {
        factory_address: this.hwmMemberShipFormData?.factory_address,
        factory_city: this.hwmMemberShipFormData?.factory_city,
        factory_pin_code: this.hwmMemberShipFormData?.factory_pin_code,
        // factory_state: selectedState3[0].name,
        unit: this.hwmMemberShipFormData?.unit,
      },
      nominee1: {
        name1: this.hwmMemberShipFormData?.name1,
        designation1: this.hwmMemberShipFormData?.designation1,
        pan_number1: this.hwmMemberShipFormData?.pan_number1,
        email1: this.hwmMemberShipFormData?.email1,
        office_phone1: this.hwmMemberShipFormData?.office_phone1,
        mobile_no1: this.hwmMemberShipFormData?.mobile_no1,
        department1: this.hwmMemberShipFormData?.department1,
        profile1: this.hwmMemberShipFormData?.profile1,
        nominee1_address: this.hwmMemberShipFormData?.nominee1_address,
        nominee1_city: this.hwmMemberShipFormData?.nominee1_city,
        nominee1_pin_code: this.hwmMemberShipFormData?.nominee1_pin_code,
        biodata1: this.hwmMemberShipFormData?.biodata1,
        area_of_interest1: this.hwmMemberShipFormData?.area_of_interest1,
        preferred1: this.preferred1Checkbox,
      },
      // nominee1: {
      //   name1: this.hwmMemberShipFormData?.name1,
      //   designation1: this.hwmMemberShipFormData?.designation1,
      //   pan_number1: this.hwmMemberShipFormData?.pan_number1,
      //   email1: this.hwmMemberShipFormData?.email1,
      //   office_phone1: this.hwmMemberShipFormData?.office_phone1,
      //   mobile_no1: this.hwmMemberShipFormData?.mobile_no1,
      //   department1: this.hwmMemberShipFormData?.department1,
      //   profile1: this.hwmMemberShipFormData?.profile1,
      //   nominee1_address: this.hwmMemberShipFormData?.nominee1_address,
      //   nominee1_city: this.hwmMemberShipFormData?.nominee1_city,
      //   nominee1_pin_code: this.hwmMemberShipFormData?.nominee1_pin_code,
      //   biodata1: this.hwmMemberShipFormData?.biodata1,
      //   area_of_interest1: this.hwmMemberShipFormData?.area_of_interest1,
      //   preferred1: this.preferred1Checkbox,
      // },
      waste_details: this.hwmMemberShipFormData?.waste_details,
    };

    console.log(this.oldData.nominee1, this.newData.nominee1);

    let oldCompanyData = JSON.stringify(this.oldData.company);
    let newCompanyData = JSON.stringify(this.newData.company);
    let oldnominee1Data = JSON.stringify(this.oldData.nominee1);
    let newnominee1Data = JSON.stringify(this.newData.nominee1);
    let oldAddressData = JSON.stringify(this.oldData.address);
    let newAddressData = JSON.stringify(this.newData.address);
    let oldWasteDetailData = JSON.stringify(this.oldData.waste_details);
    let newWasteDetailData = JSON.stringify(this.newData.waste_details);
    let changedItemes: any = [];

    console.log(this.oldData, this.newData);
    if (oldCompanyData != newCompanyData) {
      console.log('company array has same elements');

      changedItemes.push('company');
    }
    if (oldnominee1Data != newnominee1Data) {
      console.log('nominee1 array has same elements');
      changedItemes.push('nominee1');
    }
    if (oldAddressData != newAddressData) {
      console.log('Address array has same elements');
      changedItemes.push('address');
    }
    if (oldWasteDetailData != newWasteDetailData) {
      console.log('Waste Details array has same elements');
      changedItemes.push('waste_detail');
    }

    this.formData.append('otp', this.otp ?? null);
    this.formData.append('changed_in', changedItemes ?? null);
    this.formData.append('membership_type', 'HWM');

    for (var i = 0; i < this.preferred2Checkbox.length; i++) {
      this.formData.append(
        'preferred_communication2[]',
        this.preferred2Checkbox[i] ?? null
      );
    }

    var orderIdObj: any = {
      changed_in: changedItemes,
      membership_type: 'HWM',
      member_id: this.memberData.id
    };
    this.apiservice.getOrderId(orderIdObj).subscribe((res: any) => {
      // console.log(res);
      this.approveModalState = false;
      this.formData.append('payment_id', res.payment_id ?? null);
      this.spinner.hide();
      if (res.message != 'No order created') {
        setTimeout(() => {
          this.orderId = res.data.order_id;
          this.amount = res.data.amount;
          this.formData.append('order_id', this.orderId ?? null);
          console.log(this.amount, this.orderId);
          if (this.role != 'Admin' && this.role != 'UCCI Staff') {
            this.payment();
          } else if (this.role == 'Admin' || this.role == 'UCCI Staff') {
            this.formData.append('order_id', this.orderId ?? null);
            this.formData.append('user_id', this.userId ?? null);
            this.formData.append('member_id', this.memberData.id ?? null);
            this.apiservice
              .updateMemberInfo(this.formData)
              .subscribe((res: any) => {
                // console.log(res);
                this.messageService.add({
                  severity: 'success',
                  detail: res.message,
                });
                this.successMessage = {
                  form: 'hwm-edit',
                  message: 'Form Submitted Successfully',
                };
                this.successState = true;
                // setTimeout(() => {
                //   this.router.navigateByUrl('/');
                // }, 1500);
              });
          }
        }, 1500);
      } else if (res.message == 'No order created') {
        this.formData.append('order_id', '');
        if (this.role == 'Admin' || this.role == 'UCCI Staff') {
          this.formData.append('user_id', this.userId ?? null);
          this.formData.append('member_id', this.memberData.id ?? null);
        }
        this.apiservice
          .updateMemberInfo(this.formData)
          .subscribe((res: any) => {
            // console.log(res);
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.successMessage = {
              form: 'hwm-edit',
              message: 'Form Submitted Successfully',
            };
            this.successState = true;
            // setTimeout(() => {
            //   this.router.navigateByUrl('/');
            // }, 1500);
          });
      }
    });
  }
  change(string: any) {
    if (string == 'state') {
      this.hwmMemberShipFormData.state = this.selectedState;
      console.log(this.hwmMemberShipFormData.state);
    } else if (string == 'nominee1_state') {
      this.hwmMemberShipFormData.nominee1_state = this.selectedState1;
      console.log(this.hwmMemberShipFormData.nominee1_state);
    } else if (string == 'nominee2_state') {
      this.hwmMemberShipFormData.nominee2_state = this.selectedState2;
      console.log(this.hwmMemberShipFormData.nominee2_state);
    } else if (string == 'factory_state') {
      this.hwmMemberShipFormData.factory_state = this.selectedState3;
      console.log(this.hwmMemberShipFormData.factory_state);
    }
    console.log(this.selectedState4);
  }
  // state dropdown
  getStates() {
    this.apiservice
      .getStateWithJson('assets/state.json')
      .subscribe((res: any) => {
        this.state = res.Data;
        // // console.log(this.state);
      });
  }
  // on change for multiple preferred communication checkbox selection
  onChange(communication?: any, data?: any, string?: any) {
    if (communication) {
      string == 'pre1'
        ? this.preferred1Checkbox.push(data)
        : this.preferred2Checkbox.push(data);
    } else {
      let index: any;
      string == 'pre1'
        ? (index = this.preferred1Checkbox.find((x) => x == data))
        : (index = this.preferred2Checkbox.find((x) => x == data));
      if (index) {
        string == 'pre1'
          ? this.preferred1Checkbox.splice(
            this.preferred1Checkbox.indexOf(index),
            1
          )
          : this.preferred2Checkbox.splice(
            this.preferred1Checkbox.indexOf(index),
            1
          );
      }
    }
    // console.log(this.preferred1Checkbox);
    // console.log(this.preferred2Checkbox);
  }
  // on otp resend

  onOtpResend() {
    // let emailObject = {
    //   email: this.otpForm.value.email,
    // };
    // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
    //   this.messageService.add({
    //     severity: 'success',
    //     detail: res.message,
    //   });
    // });
  }
  onSendOtp() {
    // if (this.otpState == false) {
    //   let emailObject = {
    //     email: this.otpForm.value.email,
    //   };
    //   this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
    //     this.messageService.add({
    //       severity: 'success',
    //       detail: res.message,
    //     });
    //   });
    //   // this.otpState = true;
    //   // this.otpForm.controls['otp'].setValidators([Validators.required]);
    //   // this.otpForm.controls['otp'].updateValueAndValidity();
    // } else
    // if (this.otpState == true) {
    // console.log(this.otpForm.valid);

    if (this.appliedByForm.valid) {
      this.userId = this.appliedByForm.value.userId;
      // this.spinner.show();
      // this.apiservice
      //   .OTPVerification(this.otpForm.value)
      //   .subscribe((res: any) => {
      //     console.log(res);
      //     this.spinner.hide();
      //     this.messageService.add({
      //       severity: 'success',
      //       detail: res.message,
      //     });
      //   });

      this.submitData();
    }
    // }
  }

  //to register HWM membership candidate
  Register(form: NgForm) {
    // this.HWMDetailsId = data.hwm_details_id
    // // console.log(this.HWMDetailsId)
    // for(let i = 0; i < )

    for (const [key, value] of Object.entries(form.controls)) {
      console.log(`${key} : ${value.value}`, form.valid);
    }
    console.log(this.hwmMemberShipFormData, form.controls);

    this.form = form;

    localStorage.setItem('HWMForm', JSON.stringify(form.value));
    form.value.preferred_communication1 = this.preferred1Checkbox;
    form.value.preferred_communication2 = this.preferred2Checkbox;
    form.value.area_of_interest1 = this.interest1Checkbox;
    form.value.area_of_interest2 = this.interest2Checkbox;
    // console.log(form.value, form.valid, form.value.area_of_interest1);
    let formData = new FormData();

    if (
      form.valid
      //  &&
      // this.logoState == true &&
      // this.profile1State == true &&
      // this.profile2State == true &&
      // this.gstState == true &&
      // this.caState == true &&
      // this.ecState == true &&
      // this.cteState == true &&
      // this.ctoState == true &&
      // this.hwmState == true &&
      // this.appState == true &&
      // this.underState == true
    ) {
      let selectedState: any = [];
      // if (form.valid) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          // this.appliedByForm.patchValue({
          //   email: this.loggedInUserEmail,
          // });
          // let emailObject = {
          //   email: this.otpForm.value.email,
          // };
          // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          //   this.messageService.add({
          //     severity: 'success',
          //     detail: res.message,
          //   });
          // });
          if(this.role == 'Admin' || this.role == 'UCCI Staff' || this.memberShipStatus == 'In progress' || this.memberShipStatus == 'Pending'){
            // this.hwmMemberShipFormData.controls.amendment_reason.clearValidators();
            // this.hwmMemberShipFormData.controls.amendment_reason.updateValueAndValidity();
            this.adminSubmit();
          }else{
            this.approveModalState = true;
          }
         },
      });
    } else if (
      this.gstState == false ||
      this.caState == false ||
      this.ecState == false ||
      this.cteState == false ||
      this.ctoState == false ||
      this.hwmState == false ||
      this.appState == false ||
      this.underState == false
    ) {
      this.messageService.add({
        severity: 'error',
        detail: 'Document Size Should Not Larger Then 10MB',
      });
    } else if (
      this.logoState == false ||
      this.profile1State == false ||
      this.profile2State == false
    ) {
      this.messageService.add({
        severity: 'error',
        detail: 'Image Size Should Not Larger Then 2MB',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
  }

  // onImageClick() {
  //   // console.log('hwgsrgfyvkysdvu,sengijewrbku,fw3vg e');

  //   this.modalStyle = 'flex';
  // }
  onImageClose() {
    this.modalStyle = 'none';
  }

  bloackAlphabet(event: any) {
    if (event.key == 'Tab') {
      return;
    }
    const keyCode = event.keyCode;
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }
  //to remove one row to product detail
  removeProductDetailRow(index?: any, string?: any) {
    if (string == 'product') {
      if (this.hwmMemberShipFormData.product_details?.length > 1) {
        this.hwmMemberShipFormData.product_details.splice(index, 1);
      }
    } else {
      if (this.hwmMemberShipFormData.waste_details?.length > 1) {
        this.hwmMemberShipFormData.waste_details.splice(index, 1);
      }
    }
  }

  removeDoc(i: number) {
    if (this.hwmMemberShipFormData.supprting_doc?.length > 1) {
      this.hwmMemberShipFormData.supprting_doc.splice(i, 1);
    }
  }

  onDocUppload(event: any, index: number) {
    let selectedFile = event.target.files[0];
    this.hwmMemberShipFormData.supprting_doc[index].document = selectedFile;
    console.log('====================================');
    console.log(this.hwmMemberShipFormData.supprting_doc);
    console.log('====================================');
  }

  //to add one row to product detail
  // product_details_boolean: boolean = false;
  logoBaseURL: string = environment.HWMImgs_url;
  nominee1ImageBaseURL: string = environment.nominee1Image;
  nominee2ImageBaseURL: string = environment.nominee2Image;
  gstCertificateBaseURL: string = environment.gstCertificateURL;
  caCertificateBaseURL: string = environment.caCertificateURL;
  //upload files as binary
  fileUpload(event: any, string: any) {
    // console.log(event);
    this.selectedFile = event.target.files[0];
    var reader: any = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (string == 'logo') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.logo = event.target.result;
        };
        this.logoPreviewState = true;
        this.hwmDocuments.logo = this.selectedFile;
        this.logoState = true;
        this.logoName = this.selectedFile.name;
      } else if (this.selectedFile.size > 2300000) {
        this.logoState = false;
      }
    } else if (string == 'profile1') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.photoURL1 = event.target.result;
        };
        this.photo1 = true;
        this.hwmDocuments.profile1 = this.selectedFile;
        this.profile1State = true;
        this.profile1Name = this.selectedFile.name;
      } else if (this.selectedFile.size > 2300000) {
        this.profile1State = false;
      }
    } else if (string == 'profile2') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.photoURL2 = event.target.result;
          // console.log(this.photoURL2);
        };
        this.photo2 = true;
        this.hwmDocuments.profile2 = this.selectedFile;
        this.profile2State = true;
        this.profile2Name = this.selectedFile.name;
      } else if (this.selectedFile.size > 2300000) {
        this.profile2State = false;
      }
    } else if (string == 'gst_certificate') {
      this.gstDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.gstCertificateURL = base64String;
        };
        this.gstCertificateCheck = true;
        this.hwmDocuments.gst_certificate = this.selectedFile;
        this.gstState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.gstState = false;
      }
    } else if (string == 'ca_certificate') {
      this.caDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.caCertificateURL = base64String;
        };
        this.caCertificateCheck = true;
        this.hwmDocuments.ca_certificate = this.selectedFile;
        this.caState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.caState = false;
      }
    } else if (string == 'ec_document') {
      this.ecDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.ecCertificateURL = base64String;
        };
        this.hwmDocuments.ec_document = this.selectedFile;
        this.ecCheck1 = true;
        this.ecState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.ecState = false;
      }
    } else if (string == 'cte_document') {
      this.cteDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.cteCertificateURL = base64String;
        };
        this.ecCheck2 = true;
        this.hwmDocuments.cte_document = this.selectedFile;
        this.cteState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.cteState = false;
      }
    } else if (string == 'cto_document') {
      this.ctoDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.ctoCertificateURL = base64String;
        };
        this.ecCheck3 = true;
        this.hwmDocuments.cto_document = this.selectedFile;
        this.ctoState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.ctoState = false;
      }
    } else if (string == 'hwm_document') {
      this.hwmDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.hwmCertificateURL = base64String;
        };
        this.ecCheck4 = true;
        this.hwmDocuments.hwm_document = this.selectedFile;
        this.hwmState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.hwmState = false;
      }
    } else if (string == 'undertaking') {
      this.utDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.utCertificateURL = base64String;
        };
        this.underTakingCheck = true;
        this.underTakingChanged = true;
        this.hwmDocuments.undertaking = this.selectedFile;
        this.underState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.underState = false;
      }
    } else if (string == 'application') {
      this.appDocName = this.selectedFile.name;
      if (this.selectedFile.size <= 10000000) {
        reader.onloadend = (event: any) => {
          let base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.appCertificateURL = base64String;
        };
        this.applicationChanged = true;
        this.appDocCheck = true;
        this.hwmDocuments.application = this.selectedFile;
        this.appState = true;
      } else if (this.selectedFile.size > 10000000) {
        this.appState = false;
      }
    }
    if (
      string == 'ec_document' ||
      string == 'cte_document' ||
      string == 'cto_document' ||
      string == 'hwm_document'
    ) {
      this.requiredAnyOneDoc = false;
    }
    // console.log(this.selectedFile);
  }

  onImageClick(url: any, itemType?: any, name?: string, type?: string) {
    console.log('url :- ', url);
    alert();

    if (itemType == 'image') {
      if (type == 'company') {
        this.imageData.imageType = 'company';
        this.imageData.logoBoolean = true;
        this.imageData.url = this.oldLogoState
          ? this.logoBaseURL + this.hwmMemberShipFormData.logo
          : url;
      } else if (type == 'nominee1') {
        this.imageData.imageType = 'nominee1';
        this.imageData.nominee1Boolean = true;
        this.imageData.url = this.oldNominee1State
          ? this.nominee1ImageBaseURL + this.hwmMemberShipFormData.profile1
          : url;
        // this.imageData.url = url;
      } else if (type == 'nominee2') {
        this.imageData.imageType = 'nominee2';
        this.imageData.nominee2Boolean = true;
        this.imageData.url = this.oldNominee2State
          ? this.nominee2ImageBaseURL + this.hwmMemberShipFormData.profile2
          : url;
        // this.imageData.url = url;
      }
      // console.log(this.imageData);
    } else if (itemType == 'pdf') {
      var blob = this.b64toBlob(url, 'application/pdf');
      let a = document.createElement('a');
      document.body.appendChild(a);
      var pdf_url = window.URL.createObjectURL(blob);

      var anchor = document.createElement('a');
      // anchor.download = pdf_url;
      anchor.href = pdf_url;
      anchor.target = '_blank';
      anchor.click();

      // a.href = pdf_url;
      // a.download = String(name);
      // a.click();
      // window.URL.revokeObjectURL(pdf_url);
      // a.remove();
    }
  }

  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  imageData: any = {};
  logo: string;
  photoURL1: any;
  photoURL2: any;
  gstCertificateURL: any;
  ecURL1: string = '';
  ecURL2: string = '';
  ecURL3: string = '';
  ecURL4: string = '';
  underTakingURL: string = '';
  appURL: string = '';
  gstDocName: string;
  caCertificateCheck: boolean = true;
  caCertificateURL: string;
  caDocName: string;
  ecDocName: string;
  ecCertificateURL: any;
  cteDocName: string;
  cteCertificateURL: any;
  ctoCertificateURL: any;
  ctoDocName: string;
  hwmCertificateURL: any;
  hwmDocName: string;
  utCertificateURL: any;
  appDocName: string;
  appCertificateURL: any;
  utDocName: string;

  // logoPreviewState: boolean = true;
  photo1: boolean = true;
  photo2: boolean = true;
  ecCheck1: boolean = true;
  ecCheck2: boolean = true;
  ecCheck3: boolean = true;
  ecCheck4: boolean = true;
  underTakingCheck: boolean = true;
  appDocCheck: boolean = true;
  gstCertificateCheck: boolean = true;
  oldLogoState: boolean = true;
  // hwmDocuments: any = {};
  // productDetails: any = [];
  // member_classification: any = {};
  // selectedFile: File;
  // turnoverData: any = {};
  // categoryData: any = {};
  oldGstState: boolean = true;
  oldCaState: boolean = true;

  logoPreview(string: any) {
    this.confirmationService.confirm({
      message: 'Are you sure to change image?',
      header: 'Confirmation',
      accept: () => {
        if (string == 'company') {
          this.hwmMemberShipFormData.logo = '';
          this.logoPreviewState = false;
          this.oldLogoState = false;
          this.logo = '';
        } else if (string == 'photo1') {
          this.hwmMemberShipFormData.profile1 = '';
          this.photo1 = false;
          this.oldNominee1State = false;
          this.photoURL1 = '';
        } else if (string == 'photo2') {
          this.hwmMemberShipFormData.profile2 = '';
          this.photo2 = false;
          this.oldNominee2State = false;
          this.photoURL2 = '';
        } else if (string == 'gstCertificate') {
          this.hwmMemberShipFormData.gst_certificate = '';
          this.gstCertificateCheck = false;
          // this.gstState = false;
          this.oldGstState = false;
          this.gstCertificateURL = '';
        } else if (string == 'caCertificateCheck') {
          this.hwmMemberShipFormData.ca_certificate = '';
          this.caCertificateCheck = false;
          this.oldCaState = false;
          this.caCertificateURL = '';
        } else if (string == 'ec1') {
          this.hwmMemberShipFormData.ec_document = '';
          this.oldecState = false;
          this.ecCheck1 = false;
          this.ecURL1 = '';
          this.addValidators();
        } else if (string == 'ec2') {
          this.hwmMemberShipFormData.cte_document = '';
          this.ecCheck2 = false;
          this.oldec2State = false;
          this.ecURL2 = '';
          this.addValidators();
        } else if (string == 'ec3') {
          this.hwmMemberShipFormData.cto_document = '';
          this.ecCheck3 = false;
          this.oldec3State = false;
          this.ecURL3 = '';
          this.addValidators();
        } else if (string == 'ec4') {
          this.hwmMemberShipFormData.hwm_document = '';
          this.oldec4State = false;
          this.ecCheck4 = false;
          this.ecURL4 = '';
          this.addValidators();
        } else if (string == 'undertaking') {
          this.hwmMemberShipFormData.undertaking = '';
          this.underTakingCheck = false;
          this.oldUndertakingState = false;
          this.underTakingURL = '';
        } else if (string == 'application') {
          this.hwmMemberShipFormData.undertaking = '';
          this.appDocCheck = false;
          this.oldAppState = false;
          this.appURL = '';
        }
      },
    });
  }

  addValidators() {
    if (
      this.hwmMemberShipFormData.ec_document == '' &&
      this.hwmMemberShipFormData.cte_document == '' &&
      this.hwmMemberShipFormData.cto_document == '' &&
      this.hwmMemberShipFormData.hwm_document == ''
    ) {
      this.requiredAnyOneDoc = true;
    }
  }

  // payment process razorpay
  payment(value?: any, orderId?: string, amount?: number): void {
    this.approveModalState = false;
    let options = {
      key: environment.rezorpay_key,
      currency: 'INR',
      base_amount: this.amount,
      name: 'UCCI',
      // description: 'Web Development',
      image: environment.rezorpay_logo,
      order_id: this.orderId,
      handler: function (response: any) {
        var event = new CustomEvent('payment.success', {
          detail: response,
          bubbles: true,
          cancelable: true,
        });
        window.dispatchEvent(event);
      },
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#0ec6c6',
      },
    };
    // // console.log(options);

    var rzp1 = new Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      let statusDetail = {
        status: 'Payment Failed',
        payment_id: response.error.metadata.payment_id,
        razorpay_signature: '',
        description: response.error.description,
        payment_for: 'Member Edit',
        payment_for_id: this.memberData?.id,
      };
      this.paymentservice.servicePayment(this.orderId, statusDetail);
    });
  }
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    // // console.log(event);
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      payment_for: 'Member Edit',
      payment_for_id: this.memberData?.id,
    };
    this.paymentservice
      .servicePayment(this.orderId, statusDetail)
      .subscribe((res: any) => {
        // // console.log(res);
        if (res.code == 200) {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          this.apiservice
            .updateMemberInfo(this.formData)
            .subscribe((response: any) => {
              // // console.log(res);
              this.successMessage = {
                form: 'hwm-edit',
                message: 'Form Submited Successfully',
              };
              this.successState = true;
            });
        }
      });
  }
  // get area of interest list api function
  getAreaofInterest() {
    this.interestMasterService.getInterestArea().subscribe((res: any) => {
      // console.log(res);
      this.interest = res.data;
    });
  }
  // get nature of business list
  getNatureofBusiness() {
    this.nobmaster.getNatureofBusinessList().subscribe((res: any) => {
      // console.log(res);
      this.natureofBusinessList = res.data;
    });
  }
  // got to top when form submitted
  goToTop() {
    this.scroller.scrollToAnchor('backToTop');
  }
  // get turnover value on selection
  getValue(event: any) {
    // console.log(event.target.value);
    if (event.value == 1) {
      this.certificateRequired = true;
      // console.log('true', this.certificateRequired);
    } else {
      this.certificateRequired = false;
      // console.log('false', this.turnoverRequired);
    }
  }
  getRODetails() {
    this.apiservice.getRODetails().subscribe((res: any) => {
      console.log(res);
      this.roDetails = res?.data;
    })
  }
  getScheduleData() {
    this.apiservice.getWasteSchedule().subscribe((res: any) => {
      this.wasteList = res?.data;
      console.log("this.wasteList", this.wasteList);

      this.wasteList?.forEach((list: any) => {
        if (list?.sub_process_no == null) {
          this.scheduleArray.push(list);
        }
      })
    })

  }
  newScheduleList: any = {};
  scheduleListProcess: any = {};
  getScheduleList(event: any, value: string, index: any) {
    console.log(event?.value, this.wasteList);
    if (value == 'schNo') {
      if (event?.value != '1') {
        // this.showCodeInput = true;
        // this.showProcessInput = true;
        // this.scheduleList = [];
        this.scheduleListProcess[index] = { showCodeInput: true, showProcessInput: true };
        this.hwmMemberShipFormData.waste_details[index].waste_process_no = ''
        this.hwmMemberShipFormData.waste_details[index].waste_sub_process_no = ''
        this.hwmMemberShipFormData.waste_details[index].waste_type = ''
      } else {
        this.scheduleListProcess[index] = { showCodeInput: false, showProcessInput: false };
      }

      console.log(this.scheduleListProcess);
    }
    else if (value == 'process-no') {
      this.scheduleList = [];
      this.wasteList?.forEach((list: any) => {
        if (event?.value == list?.process_no && list?.parent_id) {
          this.scheduleList.push(list);
        }
      })
    }
    else if (value == 'code') {
      let value = this.newScheduleList[index]?.value.filter(res => res.sub_process_no == event?.value);
      console.log(value);
      this.hwmMemberShipFormData.waste_details[index].waste_type = value[0]?.process_waste_type;
    }
    this.newScheduleList[index] = { index: index, value: this.scheduleList };

    // this.hwmMemberShipFormData.waste_details[index].schedule_no = this.scheduleList[0]?.schedule_no;
    // console.log(this.scheduleList);
  }
  adminSubmit(){
    this.formData = new FormData();
    let selectedState: any = [];
    selectedState.push(this.form?.value?.state);
    // console.log(this.form.value.state);

    let selectedState1: any = [];

    selectedState1.push(this.form?.value?.nominee1_state);
    // console.log(this.form?.value?.nominee1_state);

    let selectedState2: any = [];
    selectedState2.push(this.form?.value?.nominee2_state);
    // console.log(this.form?.value?.nominee2_state);

    let selectedState3: any = [];
    selectedState3.push(this.form?.value?.factory_state);
    let wasteDisposalDate = moment(
      this.hwmMemberShipFormData.hw_disposal_expected_date
    ).format('YYYY/MM/DD');
    this.hwmMemberShipFormData.hw_disposal_expected_date = wasteDisposalDate;

    let production_commencement_date = moment(
      this.hwmMemberShipFormData.production_commencement_date
    ).format('YYYY/MM/DD');
    this.hwmMemberShipFormData.production_commencement_date =
      production_commencement_date;
    // console.log(this.hwmMemberShipFormData);
    let data = this.hwmMemberShipFormData;
    // console.log(data);
    for (const [key, value] of Object.entries(this.hwmMemberShipFormData)) {
      // // console.log(key, value);
      if (!(key in this.hwmDocuments)) {
        // // console.log(key);
        if (
          key != 'waste_details' &&
          key != 'product_details' &&
          key != 'area_of_interest1' &&
          key != 'area_of_interest2' &&
          key != 'state' &&
          key != 'nominee1_state' &&
          key != 'nominee2_state' &&
          key != 'factory_state' &&
          key != 'ec_consents_status' &&
          key != 'ec_document' &&
          key != 'cte_document' &&
          key != 'cto_document' &&
          key != 'hwm_document' &&
          key != 'undertaking' &&
          key != 'application' &&
          key != 'logo' &&
          key != 'profile1' &&
          key != 'profile2' &&
          key != 'gst_certificate' &&
          key != 'ca_certificate' &&
          key != 'nominee1_aoi' &&
          key != 'nominee2_aoi' &&
          key != 'member_since'
        ) {
          this.formData.append(key, `${value}`);
        }
      }
    }
    let ec_consents_status: any = [];
    let object: any = {};
    if (this.oldecState == false) {
      object = {
        is_ec_obtained: this.hwmMemberShipFormData.is_ec_obtained,
        is_ec_applied: this.hwmMemberShipFormData.is_ec_applied,
        ec_hold_reason: this.hwmMemberShipFormData.ec_hold_reason,
        ec_document: this.hwmDocuments.ec_document.name,
      };
    } else {
      object = {
        is_ec_obtained: this.hwmMemberShipFormData.is_ec_obtained,
        is_ec_applied: this.hwmMemberShipFormData.is_ec_applied,
        ec_hold_reason: this.hwmMemberShipFormData.ec_hold_reason,
        ec_document: this.ec_document,
      };
    }

    let object1: any = {};
    if (this.oldec2State == false) {
      object1 = {
        is_cte_applied: this.hwmMemberShipFormData.is_cte_applied,
        is_cte_obtained: this.hwmMemberShipFormData.is_cte_obtained,
        cte_hold_reason: this.hwmMemberShipFormData.cte_hold_reason,
        cte_document: this.hwmDocuments.cte_document.name,
      };
    } else {
      object1 = {
        is_cte_applied: this.hwmMemberShipFormData.is_cte_applied,
        is_cte_obtained: this.hwmMemberShipFormData.is_cte_obtained,
        cte_hold_reason: this.hwmMemberShipFormData.cte_hold_reason,
        cte_document: this.cte_document,
      };
    }

    let object2 = {};
    if (this.oldec3State == false) {
      object2 = {
        is_cto_applied: this.hwmMemberShipFormData.is_cto_applied,
        is_cto_obtained: this.hwmMemberShipFormData.is_cto_obtained,
        cto_hold_reason: this.hwmMemberShipFormData.cto_hold_reason,
        cto_document: this.hwmDocuments.cto_document.name,
      };
    } else {
      object2 = {
        is_cto_applied: this.hwmMemberShipFormData.is_cto_applied,
        is_cto_obtained: this.hwmMemberShipFormData.is_cto_obtained,
        cto_hold_reason: this.hwmMemberShipFormData.cto_hold_reason,
        cto_document: this.cto_document,
      };
    }

    let object3 = {};
    if (this.oldec4State == false) {
      object3 = {
        is_hwm_auth_applied: this.hwmMemberShipFormData.is_hwm_auth_applied,
        is_hwm_auth_obtained: this.hwmMemberShipFormData.is_hwm_auth_obtained,
        hwm_hold_reason: this.hwmMemberShipFormData.hwm_hold_reason,
        hwm_document: this.hwmDocuments.hwm_document.name,
      };
    } else {
      object3 = {
        is_hwm_auth_applied: this.hwmMemberShipFormData.is_hwm_auth_applied,
        is_hwm_auth_obtained: this.hwmMemberShipFormData.is_hwm_auth_obtained,
        hwm_hold_reason: this.hwmMemberShipFormData.hwm_hold_reason,
        hwm_document: this.hwm_document,
      };
    }
    ec_consents_status.push(object);
    ec_consents_status.push(object1);
    ec_consents_status.push(object2);
    ec_consents_status.push(object3);
    this.formData.append(
      'ec_consents_status',
      JSON.stringify(ec_consents_status ?? null)
    );
    this.formData.append(
      'waste_details',
      JSON.stringify(this.hwmMemberShipFormData.waste_details ?? null)
    );
    this.formData.append(
      'product_details',
      JSON.stringify(this.hwmMemberShipFormData.product_details ?? null)
    );
    this.formData.append('nominee1_state', selectedState1[0]?.name ?? null);
    // this.formData.append('nominee2_state', selectedState2[0]?.name);
    this.formData.append('factory_state', 'Rajasthan');
    if (this.ecCheck1 == false) {
      this.formData.append('ec_document', this.hwmDocuments?.ec_document ?? null);
    }
    if (this.ecCheck2 == false) {
      this.formData.append('cte_document', this.hwmDocuments?.cte_document ?? null);
    }
    if (this.ecCheck3 == false) {
      this.formData.append('cto_document', this.hwmDocuments?.cto_document ?? null);
    }
    if (this.ecCheck4 == false) {
      this.formData.append('hwm_document', this.hwmDocuments?.hwm_document ?? null);
    }
    if (this.underTakingChanged) {
      this.formData.append('undertaking', this.hwmDocuments?.undertaking ?? null);
    }
    if (this.applicationChanged) {
      this.formData.append('application', this.hwmDocuments?.application ?? null);
    }
    if (this.oldLogoState == false) {
      this.formData.append('logo', this.hwmDocuments?.logo ?? null);
    }
    if (this.oldNominee1State == false) {
      this.formData.append('profile1', this.hwmDocuments?.profile1 ?? null);
    }
    if (this.oldNominee2State == false) {
      this.formData.append('profile2', this.hwmDocuments?.profile2 ?? null );
    }

    if (this.oldUndertakingState == false) {
      this.formData.append(
        'gst_certificate',
        this.hwmDocuments?.gst_certificate ?? null
      );
    }

    if (this.oldAppState == false) {
      this.formData.append('ca_certificate', this.hwmDocuments?.ca_certificate ?? null);
    }
    // if (!this.logoPreviewState) {
    //   this.formData.append('logo', this.hwmDocuments?.logo);
    // }

    // if (!this.nominee1PreviewState) {
    //   this.formData.append('profile1', this.hwmDocuments?.profile1);
    // }
    // if (!this.nominee2PreviewState) {
    //   this.formData.append('profile2', this.hwmDocuments?.profile2);
    // }
    // if (!this.gstDocState) {
    //   this.formData.append(
    //     'gst_certificate',
    //     this.hwmDocuments?.gst_certificate
    //   );
    // }
    // if (!this.caDocState) {
    //   this.formData.append('ca_certificate', this.hwmDocuments?.ca_certificate);
    // }
    // this.formData.append('preferred_communication1', this.preferred1Checkbox);
    for (var i = 0; i < this.preferred1Checkbox.length; i++) {
      // // console.log('hkdqVDWAE', this.preferred1Checkbox);
      this.formData.append(
        'preferred_communication1[]',
        this.preferred1Checkbox[i] ?? null
      );
    }
    // let aoi1: any = [];
    // for (var i = 0; i < this.hwmMemberShipFormData?.area_of_interest1?.length; i++) {
    //   aoi1.push(this.hwmMemberShipFormData?.area_of_interest1[i].id);
    // }
    console.log(this.hwmMemberShipFormData.area_of_interest1);
    this.formData.append('nominee1_aoi', this.hwmMemberShipFormData.area_of_interest1 ??  ' ');

    // let aoi2: any = [];
    // for (
    //   var i = 0;
    //   i < this.hwmMemberShipFormData?.area_of_interest2?.length;
    //   i++
    // ) {
    //   aoi2.push(this.hwmMemberShipFormData?.area_of_interest2[i].id);
    // }
    this.formData.append('nominee2_aoi', this.hwmMemberShipFormData?.area_of_interest2[i] ?? null);
    this.hwmMemberShipFormData.supprting_doc.map((res: any, index: any) => {
      this.formData.append(
        `supporting_doc[document][${index}]`,
        res.document ?? null
      );
      this.formData.append(
        `supporting_doc[document_for][${index}]`,
        res.document_for ?? null
      );
      console.log(res);
    });
    this.newData = {
      company: {
        company_name: this.hwmMemberShipFormData?.company_name,
      },
      address: {
        factory_address: this.hwmMemberShipFormData?.factory_address,
        factory_city: this.hwmMemberShipFormData?.factory_city,
        factory_pin_code: this.hwmMemberShipFormData?.factory_pin_code,
        // factory_state: selectedState3[0].name,
        unit: this.hwmMemberShipFormData?.unit,
      },
      nominee1: {
        name1: this.hwmMemberShipFormData?.name1,
        designation1: this.hwmMemberShipFormData?.designation1,
        pan_number1: this.hwmMemberShipFormData?.pan_number1,
        email1: this.hwmMemberShipFormData?.email1,
        office_phone1: this.hwmMemberShipFormData?.office_phone1,
        mobile_no1: this.hwmMemberShipFormData?.mobile_no1,
        department1: this.hwmMemberShipFormData?.department1,
        profile1: this.hwmMemberShipFormData?.profile1,
        nominee1_address: this.hwmMemberShipFormData?.nominee1_address,
        nominee1_city: this.hwmMemberShipFormData?.nominee1_city,
        nominee1_pin_code: this.hwmMemberShipFormData?.nominee1_pin_code,
        biodata1: this.hwmMemberShipFormData?.biodata1,
        area_of_interest1: this.hwmMemberShipFormData?.area_of_interest1,
        preferred1: this.preferred1Checkbox,
      },
      // nominee1: {
      //   name1: this.hwmMemberShipFormData?.name1,
      //   designation1: this.hwmMemberShipFormData?.designation1,
      //   pan_number1: this.hwmMemberShipFormData?.pan_number1,
      //   email1: this.hwmMemberShipFormData?.email1,
      //   office_phone1: this.hwmMemberShipFormData?.office_phone1,
      //   mobile_no1: this.hwmMemberShipFormData?.mobile_no1,
      //   department1: this.hwmMemberShipFormData?.department1,
      //   profile1: this.hwmMemberShipFormData?.profile1,
      //   nominee1_address: this.hwmMemberShipFormData?.nominee1_address,
      //   nominee1_city: this.hwmMemberShipFormData?.nominee1_city,
      //   nominee1_pin_code: this.hwmMemberShipFormData?.nominee1_pin_code,
      //   biodata1: this.hwmMemberShipFormData?.biodata1,
      //   area_of_interest1: this.hwmMemberShipFormData?.area_of_interest1,
      //   preferred1: this.preferred1Checkbox,
      // },
      waste_details: this.hwmMemberShipFormData?.waste_details,
    };

    console.log(this.oldData.nominee1, this.newData.nominee1);

    let oldCompanyData = JSON.stringify(this.oldData.company);
    let newCompanyData = JSON.stringify(this.newData.company);
    let oldnominee1Data = JSON.stringify(this.oldData.nominee1);
    let newnominee1Data = JSON.stringify(this.newData.nominee1);
    let oldAddressData = JSON.stringify(this.oldData.address);
    let newAddressData = JSON.stringify(this.newData.address);
    let oldWasteDetailData = JSON.stringify(this.oldData.waste_details);
    let newWasteDetailData = JSON.stringify(this.newData.waste_details);
    let changedItemes: any = [];

    console.log(this.oldData, this.newData);
    if (oldCompanyData != newCompanyData) {
      console.log('company array has same elements');

      changedItemes.push('company');
    }
    if (oldnominee1Data != newnominee1Data) {
      console.log('nominee1 array has same elements');
      changedItemes.push('nominee1');
    }
    if (oldAddressData != newAddressData) {
      console.log('Address array has same elements');
      changedItemes.push('address');
    }
    if (oldWasteDetailData != newWasteDetailData) {
      console.log('Waste Details array has same elements');
      changedItemes.push('waste_detail');
    }

    // this.formData.append('otp', this.otp);
    // this.formData.append('changed_in', changedItemes);
    this.formData.append('membership_type', 'HWM');

    for (var i = 0; i < this.preferred2Checkbox.length; i++) {
      this.formData.append(
        'preferred_communication2[]',
        this.preferred2Checkbox[i] ?? null
      );
    }
    this.formData.append('member_id', this.memberData.id ?? null);
   
    let memberSince = moment(this.hwmMemberShipFormData.member_since).format('YYYY-MM-DD');
this.formData.append('member_since', memberSince ?? null);
    this.apiservice
    .updateHwmMemberInfoByAdmin(this.formData, this.memberData.id )
    .subscribe((res: any) => {
      // console.log(res);
      this.messageService.add({
        severity: 'success',
        detail: res.message,
      });
      this.successMessage = {
        form: 'hwm-edit',
        message: 'Form Submitted Successfully',
      };
      this.successState = true;
      // setTimeout(() => {
      //   this.router.navigateByUrl('/');
      // }, 1500);
    });
    // var orderIdObj: any = {
    //   changed_in: changedItemes,
    //   membership_type: 'HWM',
    //   member_id: this.memberData.id
    // };
    // this.apiservice.getOrderId(orderIdObj).subscribe((res: any) => {
    //   // console.log(res);
    //   this.approveModalState = false;
    //   this.formData.append('payment_id', res.payment_id);
    //   this.spinner.hide();
    //   if (res.message != 'No order created') {
    //     setTimeout(() => {
    //       this.orderId = res.data.order_id;
    //       this.amount = res.data.amount;
    //       this.formData.append('order_id', this.orderId);
    //       console.log(this.amount, this.orderId);
    //       if (this.role != 'Admin' && this.role != 'UCCI Staff') {
    //         this.payment();
    //       } else if (this.role == 'Admin' || this.role == 'UCCI Staff') {
    //         this.formData.append('order_id', this.orderId);
    //         this.formData.append('user_id', this.userId);
    //         this.formData.append('member_id', this.memberData.id);
    //         this.apiservice
    //           .updateMemberInfo(this.formData)
    //           .subscribe((res: any) => {
    //             // console.log(res);
    //             this.messageService.add({
    //               severity: 'success',
    //               detail: res.message,
    //             });
    //             this.successMessage = {
    //               form: 'hwm-edit',
    //               message: 'Form Submitted Successfully',
    //             };
    //             this.successState = true;
    //             // setTimeout(() => {
    //             //   this.router.navigateByUrl('/');
    //             // }, 1500);
    //           });
    //       }
    //     }, 1500);
    //   } else if (res.message == 'No order created') {
    //     this.formData.append('order_id', '');
    //     if (this.role == 'Admin' || this.role == 'UCCI Staff') {
    //       this.formData.append('user_id', this.userId);
    //       this.formData.append('member_id', this.memberData.id);
    //     }
    //     this.apiservice
    //       .updateMemberInfo(this.formData)
    //       .subscribe((res: any) => {
    //         // console.log(res);
    //         this.messageService.add({
    //           severity: 'success',
    //           detail: res.message,
    //         });
    //         this.successMessage = {
    //           form: 'hwm-edit',
    //           message: 'Form Submitted Successfully',
    //         };
    //         this.successState = true;
    //         // setTimeout(() => {
    //         //   this.router.navigateByUrl('/');
    //         // }, 1500);
    //       });
    //   }
    // });
  }
}

