<!-- <p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast> -->
<!-- <p-dialog header="Member List" [draggable]="false" [modal]="true" [(visible)]="displayBasic" [style]="{width: '50vw'}"
    (onHide)="onHide()">
    <div style="padding: 0 24px;margin-bottom: 30px;">
        <input type="text" class="form-control" placeholder="Search Member Here" [(ngModel)]="searchMember">
    </div>
    <div class="form-check mb-2">
        <div class="form-check ">
            <input class="form-check-input px-2" type="checkbox" id="checkAll" value="all" [(ngModel)]="checkAll"
                (change)="onAllSelect()">
            <label class="form-check-label fw-bold" for="all">
                Select All
            </label>
        </div>
    </div>
    <ng-container *ngFor="let item of dialogData.memberList | filter: searchMember;index as index">
        <div class="form-check mb-2">
            <div class="form-check ">
                <input class="form-check-input px-2 vanillaHandsets" type="checkbox" [id]="item.id" value="{{item.id}}"
                    [checked]="item.checked" (change)="onChange($event, index)">
                <label class="form-check-label" for="{{item.id}}">
                    {{item.company_name}}
                </label>
            </div>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <p-button (click)="sendMemberInvite()" label="Submit"></p-button>
    </ng-template>
</p-dialog> -->
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog> -->

<p-dialog header="Attendee Details" [(visible)]="displayBasic" [modal]="true" [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false" (onHide)="onHide()">
    <ng-container *ngIf="dialogData?.length > 0">
        <!-- <lib-renderer [sections]="dialogData"></lib-renderer> -->
        <div class="preview">
            <ng-container *ngFor="let section of dialogData">
                <ng-container *ngFor="let control of section.controls; let i = index">
                    <!-- <div *ngIf="i === 0" class="section-heading">
                        <span>{{control.label}}</span>
                    </div> -->
                    <div class="group">
                        <label for="" *ngIf="control.type != 'submit'" class="label">{{control.label}}</label>
                        <ng-container *ngIf="control.name !='table' && control.type != 'submit'">
                            <div class="value" *ngIf="(control.name !== 'label' && control.name !== 'file')">
                                <!-- <i class="bx bx-circle" style="font-size: 8px;
                                    color: black;
                                    background: black;
                                    border-radius: 25px;"></i> -->
                                <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                <span>{{control.value != '' ? (control.type == 'date' ? (control.value | date:'dd-MM-yyyy') : control.value) : '-'}}</span>
                            </div>
                            <div class="value color" *ngIf="control.name === 'file'">
                                <ng-container *ngIf="control.value != '' ">
                                    <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                    <!-- <span><a href="{{control.value}}" target="_blank">{{control.value}}</a></span> -->
                                    <span>Uploaded</span>
                                </ng-container>
                                <ng-container *ngIf="control.value == '' ">
                                    <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                    <span>-</span>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <ng-contaier *ngIf="control.name =='table'">
                        <table>
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let colHead of control.columns">
                                        <th class="table-label">
                                            {{colHead.label}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let tableRow of control.rows">
                                    <tr>
                                        <ng-container *ngFor="let tableCol of tableRow">
                                            <!-- {{tableCol | json}} -->
                                            <td *ngIf="(tableCol.name !== 'label' && tableCol.name !== 'file')">
                                                <span class="value" *ngIf="tableCol.value != ''">{{
                                                    tableCol.value }}</span>
                                                <span class="value" *ngIf="tableCol.value == ''"
                                                    style="display: flex; justify-content: center; align-items: center;">-</span>

                                            </td>

                                            <td *ngIf="(tableCol.name == 'label')" style="background-color: #f9f9f9;">
                                                <label for="" class="table-label">{{tableCol.label}}</label>
                                            </td>
                                            <td *ngIf="tableCol.name == 'file'" style="background-color: #f9f9f9;">
                                                <ng-container *ngIf="tableCol.value !== ''">
                                                    <label for="" class="value"
                                                        style="justify-content: center;">Uploaded</label>
                                                    <!-- <label for="" class="value color"><a href="{{tableCol.value}}"
                                                                target="_blank">{{tableCol.value}}</a></label> -->
                                                </ng-container>
                                                <ng-container *ngIf="tableCol.value === ''">
                                                    <label for="" class="value"
                                                        style="justify-content: center;">-</label>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-contaier>
                    <!-- <br> -->
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</p-dialog>