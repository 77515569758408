import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root',
})
export class CommonClass implements OnInit {
  ngOnInit() {}
  constructor(private router: Router, private apiservice: ApiService) {}
  getLocalStorage() {
    let local_id = localStorage.getItem('access_token');
    if (local_id) {
      // console.log(local_id)
      return JSON.parse(local_id);
    }
  }
  getCompanyName() {
    let local_id = localStorage.getItem('companyName');
    if (local_id) {
      return local_id;
    }
  }
  inputMobile(event: any) {
    if (event.keyCode != 8) {
      if (!/^[0-9]$/.test(event.key)) {
        event.preventDefault();
      }
    }
  }
  isJSON(str) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  getStringValue(value: any) {
    return this.isJSON(value) ? JSON.parse(value) : value;
  }

  romoveToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('data');
    localStorage.removeItem('Member_Role');
    localStorage.removeItem('companyName');
    localStorage.removeItem('variable');
    this.router.navigateByUrl('/');
    this.apiservice.authModalState.next(false);
  }
}
