
<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>

<section class="list">
            <div class="theader">
                <div class="flex">
                    <div class="d-flex">
                        <span class="p-input-icon-left ml-auto mr-2">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search Keyword" [(ngModel)]="filterVal" />
                        </span>
                    </div>
                </div>
            </div>

        <p-table #dt2 [value]="listofData " dataKey="id" [rows]="10" [showCurrentPageReport]="true"
            styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
            responsiveLayout="scroll" scrollable="true" scrollHeight="600px"  currentPageReportTemplate="{totalRecords} of {{listofData.length}}
            records"
            [globalFilterFields]="['company_name', 'email', 'membership_name']">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 62px">S. No.</th>
                    <th style="min-width: 100px;">
                        Action
                    </th>
                    <th style="min-width: 200px">Membership Name</th>
                    <th style="min-width: 200px">Company Name</th>
                    <th style="min-width: 250px">Email</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-listofData let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td>
                        <button *ngIf="enableEditButton" pButton pRipple class="p-button-rounded
                        p-button-text p-button-info" pTooltip="Edit" tooltipPosition="bottom" icon="bx bx-pencil"
                            (click)="goToUpdates(listofData)"></button>

                    </td>
                    <td class="capitalize">{{listofData.membership_name?? '-'}}</td>
                    <td class="capitalize">{{listofData.company_name?? '-'}}</td>

                    <td>{{listofData?.email ?? '-'}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="9">No Data Found.</td>
                </tr>
            </ng-template>
        </p-table>
    
  
<!-- update nominee <details></details> -->
    <p-dialog header="Update Nominee Details" [(visible)]="displayMaximizable" [modal]="true"
        [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
        
        <ng-container *ngFor="let data of selectedMemberData">
            <div class="row">
                <div class="col-lg-12 mt-2">
                    <div class="my-profile-box">
                        <h6 class="nomineeHead">Primary Nominee Details</h6>

                        <form  [formGroup]="nomine1Form" (ngSubmit)="UpdateNomineeDetails()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Name:</label>
                                        <input type="text" class="form-control" formControlName="nominee1Name" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text" formControlName="nominee1Email" class="form-control" />
                                    </div>
                                </div>
                            
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Mobile :</label>
                                        <input type="number" pattern="^((\\+91-?)|0)?[0-9]{10}$" class="form-control" formControlName="nominee1Mobile" />
                                    </div>
                                </div>
                            
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="text" class="form-control" formControlName="nominee1Password" />
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>

                <div class="col-lg-12 mt-2">
                    <div class="my-profile-box">
                        <div style="display: flex;">
                            <h6 class="nomineeHead" style="padding-right: 46px;">Seconded Nominee Details</h6>
                                <div class="field-checkbox margin">
                                    <p-checkbox name="sameAsPrimaryNominee" value="true" [binary]="true" (onChange)="onchange($event, 'regular')"
                                        inputId="ny" class="mr-3">
                                    </p-checkbox>
                                    <label class="check-box" for="ny">Same as Primary Nominee</label>
                                </div>
                        </div>
                       

                        <form  [formGroup]="nomine2Form" (ngSubmit)="UpdateNomineeDetails()">
                            <div class="row">
                              
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Name:</label>
                                        <input type="text"  class="form-control" formControlName="nominee2Name"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text"  formControlName="nominee2Email"  class="form-control"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Mobile :</label>
                                        <input type="number" pattern="^((\\+91-?)|0)?[0-9]{10}$" class="form-control"  formControlName="nominee2Mobile" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="text"  class="form-control"  formControlName="nominee2Password"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-lg-12 mt-2">
                    <div class="my-profile-box">
                        <h6 class="nomineeHead">Third Nominee Details</h6>

                        <form  [formGroup]="nomine3Form" (ngSubmit)="UpdateNomineeDetails()">
                            <div class="row">
                                
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Name:</label>
                                        <input type="text"  class="form-control" formControlName="nominee3Name"   />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text"formControlName="nominee3Email"  class="form-control" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Mobile :</label>
                                        <input type="number" pattern="[0-9]{10}$" class="form-control" formControlName="nominee3Mobile" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="text"  class="form-control"formControlName="nominee3Password" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" (click)="closeDialogBox()" label="Close" styleClass="p-button-text"></p-button>
            <p-button icon="pi pi-check"  type="submit" (click)="UpdateNomineeDetails()" label="Update" styleClass="p-button-text"></p-button>
        </ng-template>        
    </p-dialog>
    <p-dialog header="Update Member Details" [(visible)]="hwmDisplayMaximizable" [modal]="true"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    
    <ng-container *ngFor="let data of selectedMemberData">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <div class="my-profile-box">
                    <h6 class="nomineeHead">Occupier/Owner Details</h6>

                    <form  [formGroup]="nomine1Form" (ngSubmit)="UpdateNomineeDetails()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Name:</label>
                                    <input type="text"  class="form-control" formControlName="nominee1Name" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Email :</label>
                                    <input type="text" formControlName="nominee1Email"   class="form-control" />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Mobile :</label>
                                    <input type="number" pattern="^((\\+91-?)|0)?[0-9]{10}$"  class="form-control" formControlName="nominee1Mobile"  />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="text"  class="form-control" formControlName="nominee1Password"  />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-12 mt-2">
                <div class="my-profile-box">
                    <div style="display: flex;">
                        <h6 class="nomineeHead" style="padding-right: 46px;">Contact Person Details</h6>
                            <div class="field-checkbox margin">
                                <p-checkbox name="sameAsContactPerson" value="true" [binary]="true" (onChange)="onchange($event, 'hwm')"
                                    inputId="ny" class="mr-3">
                                </p-checkbox>
                                <label class="check-box" for="ny">Same as Contact Person</label>
                            </div>
                    </div>
                   
                    <form  [formGroup]="nomine2Form" (ngSubmit)="UpdateNomineeDetails()">
                        <div class="row">
                          
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Name:</label>
                                    <input type="text"  class="form-control" formControlName="nominee2Name"/>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Email :</label>
                                    <input type="text"  formControlName="nominee2Email"  class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Mobile :</label>
                                    <input type="number" pattern="^((\\+91-?)|0)?[0-9]{10}$" class="form-control"  formControlName="nominee2Mobile" />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="text"  class="form-control"  formControlName="nominee2Password"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="closeDialogBox()" label="Close" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-check"  type="submit" (click)="UpdateNomineeDetails()" label="Update" styleClass="p-button-text"></p-button>
    </ng-template>        
</p-dialog>
</section>
<ng-container *ngIf="successState">
    <app-success [statusMsg]="message"></app-success>
</ng-container>
