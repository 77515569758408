<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>

<!-- <p-toast life="50000"></p-toast> -->
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<!-- <app-navbar-style-one></app-navbar-style-one>
<app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- <div class="main-content d-flex flex-column"> -->
<ng-container *ngIf="!successState">
  <div class="breadcrumb-area">
    <h1>Registered Non Membership Edit Form</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">Registered Non Membership Edit Form</li>
    </ol>
  </div>
  <section class="non-member-form-section" id="backToTop">
    <div class="container" *ngIf="isShow">
      <div class="heading_section">
        <div class="row m-0">
          <!-- <h3 class="m-0 px-0 pb-3">Registered Non Membership Edit Form</h3> -->
        </div>
      </div>

      <form (ngSubmit)="Register(register)" #register="ngForm">
        <!-- basic information -->
        <div class="add-listings-box">
          <h3>Basic Informations <span
            style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 50px;">
            Any Information Filled in the Form is your Responsibility</span></h3>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Exporter Name<span class="required">*</span>:</label>
                <input type="text" scriptInjection class="form-control capital" maxlength="70" [class.is-invalid]="(register.submitted &&
                                            company_name?.invalid) || company_name?.invalid
                                            && company_name.touched" name="company_name" #company_name="ngModel"
                  [(ngModel)]="nonMemberFormData.company_name" placeholder="Exporter Name" required>
                <div *ngIf="register.submitted ||
                                            company_name.touched" class="alert
                                            alert-danger" (keypress)="restrictSpecialChar($event)">
                  <div *ngIf="company_name.errors?.['required']">
                    Exporter Name is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>GST Number<span class="required">*</span>:</label>
                <input type="text" scriptInjection class="form-control invalid" readonly
                  oninput="this.value= this.value.toUpperCase()" name="gst_number"
                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  [(ngModel)]="nonMemberFormData.gst_number" [class.is-invalid]="(register.submitted &&
                                            gst_number?.invalid) || gst_number?.invalid
                                            && gst_number.touched" maxlength="15" placeholder="GST Number"
                  #gst_number="ngModel" required>
                <div *ngIf="register.submitted ||
                                            gst_number.touched" class="alert alert-danger">
                  <div *ngIf="gst_number.errors?.['required']">
                    GST Number is Required.
                  </div>
                  <div *ngIf="gst_number.errors?.pattern">
                    Please Enter valid GST number.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Exporter Type<span class="required">*</span>:</label>
                <p-dropdown [options]="exportTypes.data"
                  [class.is-invalid]="(register.submitted && export_type?.invalid) || export_type?.invalid && export_type.touched"
                  [(ngModel)]="nonMemberFormData.export_type" name="export_type" #export_type="ngModel" [styleClass]="(register.submitted &&
                                        export_type?.invalid) || export_type?.invalid
                                        && export_type.touched ? 'error' : ''" placeholder="Select Exporter Type"
                  optionLabel="value" optionValue="value" [showClear]="false" [resetFilterOnHide]="true"
                  required></p-dropdown>
                <div class="invalid-feedback">Exporter Type is Required.</div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Address<span class="required">*</span>:</label>
                <input type="text" scriptInjection class="form-control" name="address" maxlength="100"
                  [(ngModel)]="nonMemberFormData.address" placeholder="Address" [class.is-invalid]="(register.submitted &&
                                            address?.invalid) || address?.invalid
                                            && address.touched" #address="ngModel" required>
                <div *ngIf="register.submitted || address.touched" class="alert alert-danger">
                  <div *ngIf="address.errors?.['required']">
                    Address is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Office Telephone<span class="required">*</span>:</label>
                <input type="text" scriptInjection class="form-control" name="office_telephone" maxlength="10"
                  [(ngModel)]="nonMemberFormData.office_telephone" pattern="^((\\+91-?)|0)?[0-9]{10}$"
                  [class.is-invalid]="(register.submitted &&
                                            office_telephone?.invalid) ||
                                            office_telephone?.invalid
                                            && office_telephone.touched" placeholder="Office Telephone"
                  #office_telephone="ngModel" required>
                <div *ngIf="register.submitted ||
                                            office_telephone.touched" class="alert
                                            alert-danger">
                  <div *ngIf="office_telephone.errors?.['required']">
                    Office Telephone is Required.
                  </div>
                  <div *ngIf="office_telephone.errors?.['pattern']">
                    Please Enter Valid Office Telephone.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Party Type<span class="required">*</span>:</label>
                <p-dropdown [options]="partyTypes?.data"
                  [class.is-invalid]="(register.submitted && party_type?.invalid) || party_type?.invalid && party_type.touched"
                  [(ngModel)]="nonMemberFormData.party_type" name="party_type" #party_type="ngModel"
                  [styleClass]="(register.submitted && party_type?.invalid) || party_type?.invalid && party_type.touched ? 'error' : ''"
                  placeholder="Select Exporter Type" optionLabel="value" optionValue="value" [showClear]="false"
                  [resetFilterOnHide]="true" required></p-dropdown>
                <div *ngIf="register.submitted ||
                                            party_type.touched" class="alert alert-danger">
                  <div *ngIf="party_type.errors?.['required']">
                    Party Type is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Nationality<span class="required">*</span>:</label>
                <p-dropdown [options]="nationalities?.data"
                  [class.is-invalid]="(register.submitted && nationality?.invalid) || nationality?.invalid && nationality.touched"
                  [(ngModel)]="nonMemberFormData.nationality" name="nationality" #nationality="ngModel"
                  [styleClass]="(register.submitted && nationality?.invalid) || nationality?.invalid && nationality.touched ? 'error' : ''"
                  placeholder="Select Exporter Type" optionLabel="value" optionValue="value" [showClear]="false"
                  [resetFilterOnHide]="true" required></p-dropdown>
                <div *ngIf="register.submitted ||
                                            nationality.touched" class="alert alert-danger">
                  <div *ngIf="nationality.errors?.['required']">
                    Nationality is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Contact Person<span class="required">*</span>:</label>
                <input type="text" scriptInjection class="form-control" placeholder="Member Name" maxlength="50"
                  [(ngModel)]="nonMemberFormData.name1" [class.is-invalid]="(register.submitted &&
                                            name1?.invalid) || name1?.invalid
                                            && name1.touched" name="name1" #name1="ngModel" required>
                <div *ngIf="register.submitted || name1.touched" class="alert alert-danger">
                  <div *ngIf="name1.errors?.['required']">
                    Name is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail<span class="required">*</span>:</label>
                <input type="email" class="form-control" placeholder="Company E-Mail" maxlength="100"
                  pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                  [(ngModel)]="nonMemberFormData.email1" email name="email1" [class.is-invalid]="(register.submitted &&
                                            email1?.invalid) || email1?.invalid
                                            && email1.touched" #email1="ngModel" required>
                <div *ngIf="register.submitted || email1.touched" class="alert alert-danger">
                  <div *ngIf="email1.errors?.['required']">
                    E-Mail is Required.
                  </div>
                  <div *ngIf="email1.errors?.email">
                    Please Enter a Valid E-Mail.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number<span class="required">*</span>:</label>
                <input type="text" scriptInjection placeholder="Mobile Number" class="form-control"
                  [(ngModel)]="nonMemberFormData.mobile_no1" maxlength="10" pattern="^((\\+91-?)|0)?[0-9]{10}$"
                  name="mobile_no1" [class.is-invalid]="(register.submitted &&
                                            mobile_no1?.invalid) || mobile_no1?.invalid
                                            && mobile_no1.touched" #mobile_no1="ngModel" required>
                <div *ngIf="register.submitted ||
                                            mobile_no1.touched" class="alert alert-danger">
                  <div *ngIf="mobile_no1.errors?.['required']">
                    Mobile Number is Required.
                  </div>
                </div>
                <div class="alert alert-danger" *ngIf="mobile_no1.errors?.['pattern']">
                  Please Enter Valid Mobile Number.
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication<span class="required">*</span>:</label>
                <div class="form-check" *ngFor="let item of
                                            communication; let i= index">
                  <input type="checkbox" [name]="item" [checked]="pre1?.includes(item)"
                    (change)="onPreChange($event.target.checked,item,'pre1')" class="form-check-input" [id]="item+i"
                    required>
                  <label class="form-check-label" [for]="item+i">{{item}}</label>
                </div>
                <div *ngIf="register.submitted &&
                                            preferred1Checkbox?.length == 0" class="alert
                                            alert-danger">
                  Preferred Communication is Required.
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label>Supporting Documents: </label>
                <div class="row" *ngFor="let item of
                              nonMemberFormData.supprting_doc;index as index">
                  <div class="col-lg-5">
                    <div class="form-group">
                      <input type="text" scriptInjection class="form-control" [(ngModel)]="item.document_for"
                        [class.is-invalid]="(register.submitted
                                                &&
                                                document_for?.invalid) ||
                                                document_for?.invalid
                                                &&
                                                document_for.touched" placeholder="Document Name"
                        name="document_for{{index}}" #document_for="ngModel">
                      <div class="invalid-feedback">Document Name is Required</div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="form-group">
                      <input type="file" class="inputfile form-control px-2 py-1" placeholder="Document" required
                        [class.is-invalid]="(register.submitted
                                                &&
                                                document?.invalid) ||
                                                document?.invalid
                                                &&
                                                document.touched" accept="application/pdf"
                        (change)="onDocUppload($event, index)">
                      <div class="invalid-feedback">Document is Required</div>
                    </div>
                  </div>
                  <div class="col-lg-2 align-items-center" style="margin: auto;">
                    <div class="action_btn d-flex ">
                      <div>
                        <p-button type="button" class="mr-2" icon="bx bx-plus" (click)="addDocument()"
                          iconPos="right"></p-button>
                      </div>
                      <div *ngIf="index>0" class="remove">
                        <p-button type="button" class="ml-2" icon="bx bx-minus" (click)="removeDoc(index)"
                          iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-12">
              <div class="add-listings-btn mt-5">
                <button type="submit" class="button" (click)="goToTop()">Submit</button>
              </div>
            </div> -->

          </div>
        </div>
          <!-- reason of amendment in membership -->
          <div class="add-listings-box">
            <div class="row">
  
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <div class="d-flex">
                    <label for="">Reason for amendment</label>
                  </div>
                  <textarea type="text" scriptInjection class="form-control" placeholder="Reason for amendment"
                    [(ngModel)]="nonMemberFormData.amendment_reason" [class.is-invalid]="(register.submitted &&
                  amendment_reason?.invalid) || amendment_reason?.invalid
                  && amendment_reason.touched" type="text" scriptInjection class="form-control" name="amendment_reason"
                    #amendment_reason="ngModel" [(ngModel)]="nonMemberFormData.amendment_reason" required></textarea>
                  <div *ngIf="register.submitted ||
  amendment_reason.touched" class="alert
  alert-danger">
                    <div *ngIf="amendment_reason.errors?.['required']">
                      Reason for amendment is Required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
  
              <div class="col-lg-12">
                <div class="add-listings-btn">
                  <button type="submit" class="button" (click)="goToTop()">Submit</button>
                </div>
              </div>
            </div>
          </div>
        <!-- register form -->
      </form>
    </div>
    <!-- *********************************************************************************************************************** -->
    <!-- payment section if form submitted successfully -->
    <!-- *********************************************************************************************************************** -->
    <div class="payment-section container" *ngIf="!isShow">
      <div class="payment-totals">
        <h3>Payment Detail</h3>
        <ul>
          <ng-container *ngFor="let data of responseSubmission">
            <li>{{data.fee_type}}<span>₹{{data.amount}}</span></li>
            <li>Tax<span>{{data.tax_percentage}}%</span></li>
          </ng-container>
          <li>Total <span>₹{{nonMemberRes.total_fee}}</span></li>
        </ul>
        <div class="add-listings-btn mt-5">
          <button type="submit" class="button" (click)="payment()">Proceed
            to Pay</button>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<!-- <app-copyrights></app-copyrights>
</div> -->
<!-- <app-footer-style-one></app-footer-style-one> -->



<!-- <p-dialog header="Verification" [(visible)]="approveModalState" [modal]="true" [style]="{width: '45vw'}"
  [draggable]="false" [resizable]="false" [closable]="true" [closeOnEscape]="true" (onHide)="onClose()">
  <div>
    <!-- class="add-listings-box" -->

<!-- <form [formGroup]="otpForm" (ngSubmit)="onSendOtp()" #otpVerificationForm="ngForm">
            <div class="row">
                <div class="col-lg-12 col-md-12" *ngIf="!otpState">
                    <div class="form-group">
                        <label>Email<span class="required">*</span>:</label>
                        <input [class.is-invalid]="(otpVerificationForm.submitted &&
                        otpForm.get('email')?.invalid) ||
                        otpForm.get('email')?.invalid &&
                        otpForm.get('email').touched" type="email" class="form-control" placeholder="Enter Email"
                            maxlength="100" formControlName="email">
                        <div *ngIf="otpVerificationForm.submitted &&
                              o.email.errors?.required ||
                              o.email.touched" class="alert
                              alert-danger">
                            <div *ngIf="o.email.errors?.required">
                                Email is Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="otpState">
                    <div class="form-group">
                        <label>OTP<span class="required">*</span>:</label>
                        <input [class.is-invalid]="(otpVerificationForm.submitted &&
                        otpForm.get('otp')?.invalid) ||
                        otpForm.get('otp')?.invalid &&
                        otpForm.get('otp').touched" type="text" scriptInjection class="form-control" placeholder="Enter OTP"
                            maxlength="6" formControlName="otp">
                        <div *ngIf="otpVerificationForm.submitted &&
                              o.otp.errors?.required ||
                              o.otp.touched" class="alert
                              alert-danger">
                            <div *ngIf="o.otp.errors?.required">
                                OTP is Required
                            </div>

                        </div>
                        <p (click)="onOtpResend()" class="resend">Resend OTP</p> <br>
                        <p (click)="otpState = false" class="change-email">Entered wrong Email? <span> Change
                                Email</span></p>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="add-listings-btn" style="text-align: right;">
                        <button type="submit">{{otpState ? 'Verify' : 'Sent OTP'}}</button>
                    </div>
                </div>
            </div>
        </form> -->

<!-- <form [formGroup]="appliedByForm" (ngSubmit)="onSendOtp()" #otpVerificationForm="ngForm" #form>
          <div class="row justify-content-end" style="min-height: 240px;">
            <div class="col-lg-12 col-md-12 mb-2">
              <div class="form-group">
                <label>Applied By<span class="required">*</span>:</label>
                <p-dropdown [options]="nominee" placeholder="Please Applied By" [class.is-invalid]="(form.submitted &&
                appliedByForm.get('userId')?.invalid) ||
                appliedByForm.get('userId')?.invalid &&
                appliedByForm.get('userId').touched" formControlName="userId" optionLabel="nomineeName"
                  optionValue="userId" [showClear]="false" [resetFilterOnHide]="true">
                </p-dropdown>
                <!-- <span class="form-control d-flex align-items-center mt-2">{{loggedInUserEmail}}</span> -->
<!-- </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-end align-items-end ">
              <div class="add-listings-btn" style="text-align: right;">
                <button type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
  </div>
</p-dialog> -->



<ng-container *ngIf="successState">
  <app-success [statusMsg]="successMessage"></app-success>
</ng-container>