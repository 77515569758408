import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-committe-ui',
  templateUrl: './committe-ui.component.html',
  styleUrls: ['./committe-ui.component.scss']
})
export class CommitteUIComponent implements OnInit {

  constructor(
    private route : Router
  ) { }

  data:any = {page_json : []}

  @Input() pageData : any;

  ngOnInit() {

    this.data = this.pageData
    console.log(this.data);
    if(this.data?.page_json) {
      this.data.page_json = JSON.parse(this.data?.page_json);
    }
    console.log(this.data);

  }

  navigate(slug : any) {
    // alert(slug)
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([slug]);
  }

}
