<!-- Start Sidemenu Area -->
<!-- *ngIf="localStorage.role == 'Admin' || localStorage.role == 'President' || localStorage.role == 'Member Secretary'" -->
<!-- =====================  for admin role dashboard  ======================== -->
<div   [ngClass]="{'sideOverlay': isTrue , 'fadeIn': isTrue}" (click)="hideSideMenu()"></div>
    <ng-container
    *ngIf="localStorage.role == 'Admin' || localStorage.role == 'CEO' || localStorage.role == 'UCCI Staff' || localStorage.role == 'President' || localStorage.role == 'Member Secretary'">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">
      
            <div    class="sidemenu-header d-none d-xl-block" >
            <a routerLink="/user-panel/dashboard" class="navbar-brand d-flex align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
           </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>
        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item-title">
                    Main
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a class="nav-link" routerLink="/user-panel/dashboard">
                        <!-- <span class="icon"><i class='bx bx-home-circle'></i></span> -->
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false"  *ngIf="getUserPermission(59)">
                    <a routerLink="./call-member" class="nav-link">
                        <span class="menu-title">Contact List</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false"   *ngIf="getUserPermission(65)">
                    <a routerLink="/user-panel/membership-renewal" class="nav-link">
                        <span class="menu-title">Membership Renewal </span>
                    </a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/reports" class="nav-link">
                        <span class="men
                        u-title">Reports </span>
                    </a>
                </li> -->
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(158)">
                    <a routerLink="/user-panel/audit-logs" class="nav-link">
                        <span class="menu-title">Audit Logs</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(60)">
                    <a routerLink="/user-panel/issues-lists" class="nav-link">
                        <span class="menu-title">Issues</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                    <a routerLink="/user-panel/products" class="nav-link">
                        <span class="menu-title">Marketplace Listing </span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false"
                    *ngIf="localStorage.role !== 'Admin' && localStorage.role !== 'CEO' && localStorage.role !== 'CEO' && localStorage.role !== 'UCCI Staff' && localStorage.role !== 'President' && localStorage.role !== 'Member Secretary'">
                    <a routerLink="/user-panel/add-product" class="nav-link">
                        <span class="menu-title">My Marketplace Listing </span>
                    </a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/event-dashboard" class="nav-link">
                        <span class="menu-title">Event Registration</span>
                    </a>
                </li> -->
                <!-- <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/marketplace-products" class="nav-link">
                        <span class="menu-title">Marketplace</span>
                    </a>
                </li> -->
                <!-- <li class="nav-item-title">
                    Approval Masters
                </li> -->
                <li class="nav-item" routerLinkActive="active mm-active"
                    *ngIf="getUserPermission(164) || getUserPermission(60) || getUserPermission(163) || getUserPermission(162) || getUserPermission(161) || getUserPermission(159)">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">
                            Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0" *ngIf="getUserPermission(164)">
                        <li class="nav-item" routerLinkActive="activeLink">
                            <a href="/user-panel/reports/Hwm" class="nav-link">
                                <span class="menu-title">HWM Renewal Due</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" *ngIf="getUserPermission(165)">
                            <a href="/user-panel/reports/Regular" class="nav-link">
                                <span class="menu-title">Regular Renewal Due</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="activeLink">
                            <a href="/user-panel/reports/hwm-work-que" class="nav-link">
                                <span class="menu-title">HWM Work Queue</span>
                            </a>
                        </li> -->
                        <li class="nav-item" routerLinkActive="activeLink"  *ngIf="getUserPermission(163)">
                            <a href="/user-panel/reports/membership-approved" class="nav-link">
                                <span class="menu-title">New Membership Approved</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" *ngIf="getUserPermission(161)">
                            <a href="/user-panel/reports/voter-list" class="nav-link">
                                <span class="menu-title">Eligible Voters List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" *ngIf="getUserPermission(162)">
                            <a href="/user-panel/reports/membership-status" class="nav-link">
                                <span class="menu-title">Membership Status</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" *ngIf="getUserPermission(159)">
                            <a href="/user-panel/reports/member-data" class="nav-link">
                                <span class="menu-title">Member Data</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="activeLink">
                            <a href="/user-panel/reports/hwm-renewal-fee" class="nav-link">
                                <span class="menu-title">HWM RENEWAL FEE</span>
                            </a>
                        </li> -->
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active"
                    *ngIf="getUserPermission(1) || getUserPermission(7) || getUserPermission(12) || getUserPermission(17) || getUserPermission(22) || getUserPermission(27) || getUserPermission(32)">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">Approval
                            Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(1)">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(7)">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(12)">
                            <a routerLink="/user-panel/non-member-list" class="nav-link">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(17)">
                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link">
                                <span class="menu-title">Certificate of Origin</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(22)">
                            <a routerLink="/user-panel/document-attestation-list" class="nav-link">
                                <span class="menu-title">Document Attestation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(27)">
                            <a routerLink="/user-panel/visa-approval" class="nav-link">
                                <span class="menu-title">Visa Recommendation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(32)">
                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                <span class="menu-title">Facility Booking</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false"
                            *ngIf="localStorage.role == 'Admin' || localStorage.role == 'CEO' || localStorage.role == 'UCCI Staff' || localStorage.role == 'President'">
                            <a routerLink="/user-panel/member-data-update" class="nav-link">
                                <span class="menu-title">Membership Revision Approval</span>
                            </a>
                        </li>

                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="active mm-active"
                            *ngIf="getUserPermission(67) || getUserPermission(72)">
                            <a href="javascript:void(0)" class="collapsed-nav-link
                                nav-link" aria-expanded="false">
                                <span class="menu-title">Marketplace</span>
                            </a>
                            <ul class="sidemenu-nav-second-level p-0">
                                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(67)">
                                    <a routerLink="/user-panel/category" class="nav-link pl-2">
                                        <span class="menu-title">Category</span>
                                    </a>
                                </li>
                                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(72)">
                                    <a routerLink="/user-panel/sub-category" class="nav-link pl-2">
                                        <span class="menu-title">Sub Category</span>
                                    </a>
                                </li>


                                <!-- <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                                    <a routerLink="/user-panel/brand" class="nav-link pl-2">
                                        <span class="menu-title">Brand</span>
                                    </a>
                                </li> -->
                            </ul>
                        </li>

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/userCredentials" class="nav-link">
                                <span class="menu-title"> User Credential</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(82)">
                            <a routerLink="/user-panel/member-categories" class="nav-link">
                                <span class="menu-title">Member Categories</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(86)">
                            <a routerLink="/user-panel/member-classification" class="nav-link">
                                <span class="menu-title">Member Classification</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(90)">
                            <a routerLink="/user-panel/service-type-master" class="nav-link">
                                <span class="menu-title">Service Type-Fees</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(94)">
                            <a routerLink="/user-panel/turnover-master" class="nav-link">
                                <span class="menu-title">Turnover</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(98)">
                            <a routerLink="/user-panel/membership-type-master" class="nav-link">
                                <span class="menu-title">Membership Type</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(102)">
                            <a routerLink="/user-panel/custom-form" class="nav-link">
                                <span class="menu-title">Custom Forms</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(38)">
                            <a routerLink="/user-panel/events-list" class="nav-link">
                                <span class="menu-title">Events List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(106)">
                            <a routerLink="/user-panel/escalation-matrix" class="nav-link">
                                <span class="menu-title">Escalation Matrix</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(110)">
                            <a routerLink="/user-panel/create-issue-type" class="nav-link">
                                <span class="menu-title">Issue Type</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(114)">
                            <a routerLink="/user-panel/area-of-interest" class="nav-link">
                                <span class="menu-title">Area of Interest</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(118)">
                            <a routerLink="/user-panel/tax-master" class="nav-link">
                                <span class="menu-title">Tax</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/member-permissions" class="nav-link">
                                <span class="menu-title">User Permissions</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/users" class="nav-link">
                                <span class="menu-title">Roles & Permissions</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff'">
                            <a routerLink="/user-panel/news-and-events" class="nav-link">
                                <!-- <span class="icon"><i class='bx bx-home-circle'></i></span> -->
                                <span class="menu-title">News & Announcements</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/settings" class="nav-link">
                                <!-- <span class="icon"><i class='bx bx-home-circle'></i></span> -->
                                <span class="menu-title">Settings</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-item-title">
                    Masters
                    
                </li> -->
                <!-- <li class="nav-item-title">
                    Website
                </li> -->
                <li class="nav-item" routerLinkActive="active mm-active"
                    *ngIf="getUserPermission(45) || getUserPermission(138) || getUserPermission(139) || getUserPermission(51) || getUserPermission(55) || localStorage.role == 'Admin'">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">Website</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(45)">
                            <a routerLink="/user-panel/site-map" class="nav-link">
                                <span class="menu-title">Site Map</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(45)">
                            <a routerLink="/user-panel/menu-list" class="nav-link">
                                <span class="menu-title">Menus</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(138)">
                            <a routerLink="/user-panel/footer-list" class="nav-link">
                                <span class="menu-title">Footer</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(139)">
                            <a routerLink="/user-panel/home-template" class="nav-link">
                                <span class="menu-title">Home Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(51)">
                            <a routerLink="/user-panel/template-1" class="nav-link">
                                <span class="menu-title">Grid Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="getUserPermission(55)">
                            <a routerLink="/user-panel/template-2" class="nav-link">
                                <span class="menu-title">List Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/committee-template" class="nav-link">
                                <span class="menu-title">Committee Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage.role == 'Admin'">
                            <a routerLink="/user-panel/blog-listing" class="nav-link">
                                <span class="menu-title">Blog Template</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="localStorage.role == 'Nominee 3'">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">
        <div class="sidemenu-header d-none d-xl-block">
            <a routerLink="/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
        </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>

        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item-title">
                    Main
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link">
                        <span class="menu-title">Certificate of Origin</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/document-attestation-list" class="nav-link">

                        <span class="menu-title">Document Attestation</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/visa-approval" class="nav-link">
                        <span class="menu-title">Visa Recommendation</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<!-- <ng-container *ngIf="localStorage.role == 'CEO'">
    <div class="sidemenu-area">
        <div class="sidemenu-header" style="visibility:hidden">
            <a routerLink="/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/regular-member-list" class="nav-link">
                        <span class="menu-title">Regular Member</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container> -->
<!-- =====================  for ucci staff role dashboard  ======================== -->
<!-- <ng-container *ngIf="localStorage.role == 'UCCI Staff'">
    <div class="sidemenu-area">
        <div class="sidemenu-header" style="visibility:hidden">
            <a routerLink="/user-panel/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item-title">
                    Main
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/call-member" class="nav-link">
                        <span class="menu-title">Contact List</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/membership-renewal" class="nav-link">
                        <span class="menu-title">Membership Renewal </span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/issues-lists" class="nav-link">
                        <span class="menu-title">Issues</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/enrolled-events" class="nav-link">
                        <span class="menu-title">Enrolled Events</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/custom-form" class="nav-link">
                        <span class="menu-title">Custom Forms</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/events-list" class="nav-link">
                        <span class="menu-title">Events List</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">
                            Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">Approval
                            Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/non-member-list" class="nav-link">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link">
                                <span class="menu-title">Certificate of Origin</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/document-attestation-list" class="nav-link">
                                <span class="menu-title">Document Attestation</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/visa-approval" class="nav-link">
                                <span class="menu-title">Visa Recommendation</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                <span class="menu-title">Facility Booking</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/member-data-update" class="nav-link">
                                <span class="menu-title">Membership Revision Approval</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title" style="font-weight: 600; text-transform: uppercase;">Approval
                            Website</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/menu-list" class="nav-link">
                                <span class="menu-title">Menus</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/footer-list" class="nav-link">
                                <span class="menu-title">Footer</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/home-template" class="nav-link">
                                <span class="menu-title">Home Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/template-1" class="nav-link">
                                <span class="menu-title">Grid Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/template-2" class="nav-link">
                                <span class="menu-title">List Template</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/committee-template" class="nav-link">
                                <span class="menu-title">Committee Template</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container> -->
<!-- =====================  for HWM Owner and HWM Contact Person role dashboard  ======================== -->
<ng-container *ngIf="localStorage.role == roleObject.hwmContact || localStorage.role == roleObject.hwmOwner">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">

        <div class="sidemenu-header d-none d-xl-block">
            <a routerLink="/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>
        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                        <span class="menu-title">My Membership</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/member-data-update" class="nav-link">
                        <span class="menu-title">Membership Revision</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/avail-services" class="nav-link">
                        <span class="menu-title">Services</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Apply for Membership</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link pl-2">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link pl-2">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/non-member-list" class="nav-link pl-2">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/event-dashboard" class="nav-link">
                        <span class="menu-title">Event Registration</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/enrolled-events" class="nav-link">
                        <span class="menu-title">Enrolled Events</span>
                    </a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/my-issues" class="nav-link">
                        <span class="menu-title">My Issues</span>
                    </a>
                </li> -->
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/add-product" class="nav-link">
                        <span class="menu-title">My Marketplace Listing </span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/facilities-approval" class="nav-link">
                        <span class="menu-title">Facility Booking</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage && memberRole?.length > 1">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Switch Account</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngFor="let item of memberRole">
                            <a class="nav-link pl-2" style="cursor:pointer;"><i class='bx bx-user'></i>
                                <span (click)="getRoleWiseData(item)" pTooltip="{{item.name}}"
                                    tooltipPosition="top">{{item.name}}</span>
                                <br>
                                <p>{{item.membership_no}}</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<!-- =====================  for non member role dashboard  ======================== -->
<ng-container *ngIf="localStorage.role == roleObject.nonMember">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">
        <div class="sidemenu-header d-none d-xl-block">
            <a routerLink="/user-panel/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>

        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/non-member-list" class="nav-link">
                        <span class="menu-title">My Membership</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/member-data-update" class="nav-link">
                        <span class="menu-title">Membership Revision</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Services</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link pl-2">
                                <span class="menu-title">Certificate of Origin</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/document-attestation-list" class="nav-link pl-2">
                                <span class="menu-title">Document Attestation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/visa-approval" class="nav-link pl-2">
                                <span class="menu-title">Visa Recommendation</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Apply for Membership</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link pl-2">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link pl-2">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/non-member-list" class="nav-link pl-2">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/add-product" class="nav-link">
                        <span class="menu-title">My Marketplace Listing </span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/event-dashboard" class="nav-link">
                        <span class="menu-title">Event Registration</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/enrolled-events" class="nav-link">
                        <span class="menu-title">Enrolled Events</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/facilities-approval" class="nav-link">
                        <span class="menu-title">Facility Booking</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage && memberRole?.length > 1">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Switch Account</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngFor="let item of memberRole">
                            <a class="nav-link pl-2" style="cursor:pointer;"><i class='bx bx-user'></i>
                                <span (click)="getRoleWiseData(item)" pTooltip="{{item.name}}"
                                    tooltipPosition="top">{{item.name}}</span></a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<!-- =====================  for nominee role dashboard  ======================== -->
<ng-container *ngIf="localStorage.role == roleObject.nominee1|| localStorage.role == roleObject.nominee2">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">
        <div class="sidemenu-header d-none d-xl-block" >
            <a routerLink="/user-panel/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>

        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/regular-member-list" class="nav-link">
                        <span class="menu-title">My Membership</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/member-data-update" class="nav-link">
                        <span class="menu-title">Membership Revision</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Services</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link pl-2">
                                <span class="menu-title">Certificate of Origin</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/document-attestation-list" class="nav-link pl-2">
                                <span class="menu-title">Document Attestation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/visa-approval" class="nav-link pl-2">
                                <span class="menu-title">Visa Recommendation</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Apply for Membership</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link pl-2">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link pl-2">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/non-member-list" class="nav-link pl-2">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/event-dashboard" class="nav-link">
                        <span class="menu-title">Event Registration</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/enrolled-events" class="nav-link">
                        <span class="menu-title">Enrolled Events</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/representatives-approval" class="nav-link">
                        <span class="menu-title">Representatives Approval</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/my-issues" class="nav-link">
                        <span class="menu-title">My Issues</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/add-product" class="nav-link">
                        <span class="menu-title">My Marketplace Listing </span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/facilities-approval" class="nav-link">
                        <span class="menu-title">Facility Booking</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngIf="localStorage && memberRole?.length > 1">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Switch Account</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">

                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false" *ngFor="let item of memberRole">
                            <a class="nav-link pl-2" style="cursor:pointer;"><i class='bx bx-user'></i>
                                <span (click)="getRoleWiseData(item)" pTooltip="{{item.name}}"
                                    tooltipPosition="top">{{item.name}}</span></a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<!-- =====================  for guest role dashboard  ======================== -->
<ng-container *ngIf="localStorage.role == 'Guest' || localStorage.role == 'AM'">
    <div [ngClass]="{'showSideBar': isTrue, 'sidemenu-area': true}">
        <div class="sidemenu-header d-none d-xl-block" >
            <a routerLink="/user-panel/dashboard" class="navbar-brand d-flex
                align-items-center">
                <img src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo">
            </a>
            <div class="responsive-burger-menu d-block d-lg-none">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <button class="closeSidebar" (click)="hideSideMenu()"><svg class="ct-icon" width="12" height="12" viewBox="0 0 15 15"><path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path></svg></button>

        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <li class="nav-item-title">
                    Main
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/dashboard" class="nav-link">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link
                        nav-link" aria-expanded="false">
                        <span class="menu-title">Apply for Membership</span>
                    </a>
                    <ul class="sidemenu-nav-second-level p-0">
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/regular-member-list" class="nav-link pl-2">
                                <span class="menu-title">Regular Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/hwm-member-list" class="nav-link pl-2">
                                <span class="menu-title">HWM Member</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                            <a routerLink="/user-panel/non-member-list" class="nav-link pl-2">
                                <span class="menu-title">Non Member Exporter</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/avail-services" class="nav-link">
                        <span class="menu-title">Services</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/event-dashboard" class="nav-link">
                        <span class="menu-title">Event Registration</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/enrolled-events" class="nav-link">
                        <span class="menu-title">Enrolled Events</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="activeLink" (click)="isTrue = false">
                    <a routerLink="/user-panel/facilities-approval" class="nav-link">
                        <span class="menu-title">Facility Booking</span>
                    </a>
                </li>
                <li class="nav-item-title">
                    Account
                </li>
                <li class="nav-item">
                    <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                        <span class="icon"><i class='bx bx-log-out'></i></span>
                        <span class="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>

<button class="sidemenuButton" (click)="addClassTOSidemenu()">
    Dashboard
</button>

<!-- End Sidemenu Area -->