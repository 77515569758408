import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HtmlInjectionDirective } from 'src/app/directives/html-injection.directive';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  slug: any;

  template_type: any;

  pageData: any;
  blogUrl: any;

  constructor(
    private websiteService: WebsiteService,
    private activateRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit() {
    if (localStorage.getItem('access_token')) {
      this.websiteService.navigateOption.next('website');
    }
    else {
      this.websiteService.navigateOption.next('logout')
    }
    this.slug = this.activateRoute.snapshot.params;
    this.blogUrl = this.activateRoute.snapshot
    console.log(this.blogUrl)
    console.log(this.blogUrl._routerState.url)
    console.log(this.slug);
    this.spinner.show();
    if (this.slug?.slug) {

      this.spinner.hide();

      if (this.slug.slug.includes('blog')) {

        await this.websiteService.getBlogPage().then((res: any) => {
          this.pageData = res.data
          this.template_type = 'blog'
          console.log(res);
        });
      }
      else if (this.blogUrl._routerState.url.includes('blog-page/' + this.slug?.slug) || this.blogUrl._routerState.url.includes('aao-factory-dekhein/' + this.slug?.slug)) {
        await this.websiteService.getBlogBySlug(this.slug?.slug).then((res: any) => {
          this.pageData = res.data
          this.template_type = 'blog-detail'
          console.log(res);
        });
      }
      else {

        let innerDataCheck: boolean = false;
        let innerPageData: any;
        this.websiteService.allPages.subscribe(async (res: any) => {
          console.log(res);
          if (res?.length > 0) {
            let data = res.filter((response: any) => response.slug == this.slug?.slug);
            this.template_type = data[0]?.template_type;
            this.pageData = data[0];
           
          }
          else {
            await this.websiteService.getAllPages().then((res: any) => {
              innerDataCheck = true;
              innerPageData = res?.data;
              console.log(res);
              this.websiteService.allPages.next(innerPageData);
            })
          }
        });
      }
      // setTimeout(() => {
      //   if(innerDataCheck) {
      //     this.websiteService.allPages.next(innerPageData);
      //   }
      // }, 2000);
      // await this.websiteService.getPageBySlug(this.slug?.slug).then((res:any) => {
      //   console.log(res);
      //   if(res?.code == 200 ) {
      //     this.template_type = res?.data?.template_type;
      //     this.pageData = res?.data;
      //     this.spinner.hide();
      //   }
      // })
      // }
    }
    else {
      let dataCheck: boolean = false;
      this.websiteService.homePageData.subscribe(async (res: any) => {
        console.log(res);
        if ((Object.keys(res)?.length > 0) && res != undefined) {
          this.pageData = res;
          this.template_type = 'home'
        }
        else {
          await this.websiteService.getAllPages().then((res: any) => {
            console.log(res);
            let array = res?.data?.filter((res: any) => res.template_type == 'home');
            console.log(array);
            dataCheck = true;
            this.spinner.hide();
            this.pageData = array[0];
            this.template_type = 'home';
            let data = res?.data?.filter((obj) => obj.template_type === 'Aao Factory Dekhein');
            this.websiteService.afdData = (data);
            console.log(data);
          })
        }
      });
      setTimeout(() => {
        if (dataCheck) {
          this.websiteService.homePageData.next(this.pageData);
        }
      }, 2000);
    }
  }

  // ngOnDestroy() {
  // this.websiteService.allPages.unsubscribe();
  // this.websiteService.homePageData.unsubscribe();
  // }

}
