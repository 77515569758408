import { Injectable } from '@angular/core';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryAreaMasterService {

  constructor(public http: HTTPApi) { }
  getIndustryAreaList() {
    return this.http.get('getIA');
  }
}
