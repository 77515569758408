import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-grid',
  templateUrl: './blog-grid.component.html',
  styleUrls: ['./blog-grid.component.scss'],
})
export class BlogGridComponent implements OnInit {
  eventList: any = [];
  localStorage: any;
  baseUrl = environment.profile_baseUrl;
  storageData: any = [];
  newRoles: any[];
  changedRole: any;
  data: any;
  showModal: boolean = false;
  response: any;
  variable: boolean = false;
  showPastEvents:boolean =false;
  pastEventList:any=[];
  currentEventsList:any=[]
  Event:string=" "
  constructor(
    private __eventservice: EventService,
    public route: Router,
    private commonFunction: CommonClass,
    private apiservice: ApiService,
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    console.log(this.route.url);
    this.localStorage = this.commonFunction.getLocalStorage();

    if (this.localStorage) {
      this.websiteService.navigateOption.next('website');
    } else {
      this.websiteService.navigateOption.next('dashboard');
    }

    if (
      this.localStorage?.role == 'Admin' &&
      this.localStorage?.role == 'UCCI Staff'
    ) {
      this.showModal = false;
    } else if (this.storageData.length < 1) {
      this.showModal = false;
    }
    // else if (
    //   this.localStorage?.role != 'Admin' &&
    //   this.localStorage?.role != 'UCCI Staff' &&
    //   this.localStorage?.role != 'Guest'
    // ) {
    //   this.showModal = true;
    // }
    this.__eventservice.getEventsList().subscribe((res: any) => {
      console.log('res : ', res);
      this.eventList = res?.data;

   this.showCurrentEvents(this.Event)

      const currentDate = new Date();

this.pastEventList = res?.data.filter((event: any) => {
  const eventDate = new Date(event.date);
  return eventDate < currentDate;
});

    });
    console.log(this.localStorage.variable, localStorage.variable);

    this.getRoleWiseData();
    if (this.storageData.length > 1) {
      this.showModal = true;
    } else if (this.storageData.length == 1) {
      this.showModal = false;
    }
    console.log(this.showModal);
    if (localStorage.variable) {
      this.showModal = true;
    }
    if (!localStorage.access_token) {
      localStorage.removeItem('variable');
    }

    
  }

  pageTitleContent = [
    {
      title: 'Events',
      backgroundImage: 'assets/img/page-title/page-title2.jpg',
    },
  ];
  singleBlogPost = [
    {
      mainImg: 'assets/img/blog/blog4.jpg',
      authorImg: 'assets/img/user1.jpg',
      authorName: 'Taylor',
      date: 'Aug 7, 2020',
      title: '10 Types of Social Proof and What Makes Them Effective',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog5.jpg',
      authorImg: 'assets/img/user2.jpg',
      authorName: 'Sarah',
      date: 'Aug 6, 2020',
      title: 'Tech Products That Make It Easier to Stay Home',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog6.jpg',
      authorImg: 'assets/img/user3.jpg',
      authorName: 'Andy',
      date: 'Aug 5, 2020',
      title: '13 Feel-Good Restaurant Stories from the Pandemic',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog7.jpg',
      authorImg: 'assets/img/user1.jpg',
      authorName: 'Taylor',
      date: 'Aug 4, 2020',
      title: '5 Ways to Convert Customers Into Advocates Brand',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog8.jpg',
      authorImg: 'assets/img/user2.jpg',
      authorName: 'Sarah',
      date: 'Aug 3, 2020',
      title: 'Vesax Tips To-Go: Getting Started With Analytics',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog9.jpg',
      authorImg: 'assets/img/user3.jpg',
      authorName: 'Andy',
      date: 'Aug 6, 2020',
      title: 'How to Beat the High Cost of Customer Questions',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog10.jpg',
      authorImg: 'assets/img/user1.jpg',
      authorName: 'Taylor',
      date: 'Aug 5, 2020',
      title: 'Tech Products That Make It Easier to Stay Home',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog11.jpg',
      authorImg: 'assets/img/user2.jpg',
      authorName: 'Sarah',
      date: 'Aug 4, 2020',
      title: 'Necessity May Give Us a Virtual Court System',
      link: 'blog-details',
    },
    {
      mainImg: 'assets/img/blog/blog12.jpg',
      authorImg: 'assets/img/user3.jpg',
      authorName: 'Andy',
      date: 'Aug 3, 2020',
      title: '3 Improvements the COVID-19 Pandemic May Force',
      link: 'blog-details',
    },
  ];

  blogGrid: number = 1;

  //route to detail page
  navigateToDetailPage(id?: any, string?: any) {
    if (string == 'home') {
      this.route.navigateByUrl('/event-details/' + id);
    }
    if (string == 'dashboard') {
      this.route.navigateByUrl('/user-panel/event-dashboard-details/' + id);
    }
    console.log(id);
  }
  // ======================================================ROLE SELECTION===============================================




  
  getRoleWiseData() {
    this.storageData = this.commonFunction.getLocalStorage().memberRole;
    console.log(this.storageData);
  }
  onSelect() {
    console.log(this.changedRole);
    let membervalue = {
      member_role: this.changedRole.value,
    };
    this.apiservice.sendDataOnLogin(membervalue).subscribe((res: any) => {
      console.log(this.variable);
      console.log(res, membervalue, this.localStorage);
      var newLocalStorageData = {
        access_token: this.localStorage?.access_token,
        username: this.localStorage?.username,
        useremail: this.localStorage?.useremail,
        role: res.role.name,
        memberRole: this.localStorage?.memberRole,
      };
      this.variable = true;
      localStorage.setItem('access_token', JSON.stringify(newLocalStorageData));
      localStorage.setItem('variable', JSON.stringify(this.variable));
      this.response = localStorage.getItem('variable');
      // setTimeout(() => {
      this.showModal = false;
      this.spinner.hide();
      // }, 1000);
    });
  }




  // showPastEvent(){
  //     this.showPastEvents=true;

  // }
  showCurrentEvents(event:any){
   
    if(event == " " || event == "View Current Events"){
      this.Event="View Past Events"
      this.showPastEvents=false;
      const currentDate = new Date();
  
   this.currentEventsList =this.eventList.filter((event: any) => {
    const eventDate = new Date(event.date);
    return eventDate >= currentDate;
  });
  return
    }


    if(event == "View Past Events"){
      this.Event="View Current Events"

      this.showPastEvents=true;

      const currentDate = new Date();

      this.pastEventList = this.pastEventList.filter((event: any) => {
        const eventDate = new Date(event.date);
        return eventDate < currentDate;
      });
      return

    }
   

console.log( this.currentEventsList)
  }
}
