import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonClass } from '../common';
import { ApiService } from '../services/api.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(
    private apiservice: ApiService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService
  ) {}
  newToken: any;
  tokenData: any;
  failedRequest: any = [];
  activeRequest: number = 0;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // debugger;
    if (request.url.includes('login') || request.url.includes('register')) {
      if (this.activeRequest == 0) {
        this.spinner.show();
      }
      // return next.handle(request);
    }

    var token: any = JSON.parse(localStorage.getItem('access_token'));
    const isLoggedIn = token ? true : false;
    const isApiUrl =
      request.url.startsWith(environment.api_baseurl) ||
      request.url.startsWith('assets');
    if (isLoggedIn && isApiUrl) {
      // debugger;
      if (
        this.activeRequest == 0 &&
        this.activatedRoute.snapshot['_routerState'].url !=
          '/user-panel/dashboard' &&
        this.activatedRoute.snapshot['_routerState'].url.includes('/user-panel') &&
        (!this.activatedRoute.snapshot['_routerState'].url.includes('/member-data-update'))
      ) {
        this.spinner.show();
      }
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token.access_token}` },
      });
    }
    this.activeRequest++;

    return next.handle(request).pipe(
      finalize(() => {
        this.stopLoader(request);
      }),
      catchError((err) => {
        // debugger;
        if (err.status === 401) {
          this.tokenData = this.commonFunction?.getLocalStorage();
          // debugger;
          if (
            !request.url.includes('login') &&
            !request.url.includes('register') &&
            err.error.message == 'Unauthenticated.'
          ) {
            // Unauthenticated.
            // if (
            //   // this.activatedRoute.snapshot['_routerState'].url !=
            //   //   '/user-panel/dashboard' &&
            //   err.error.message == 'Unauthenticated.'
            // ) {
            //   return this.handle401Error(request, next);
            // } else

            if (
              // this.activatedRoute.snapshot['_routerState'].url ==
              //   '/user-panel/dashboard' &&
              err.error.message == 'Unauthenticated.'
            ) {
              this.apiservice.authModalState.next(true);
              environment.authModalState = true;
            }
            // if()
          } else if (
            !request.url.includes('login') &&
            !request.url.includes('register') &&
            err.error.message != 'Unauthenticated.'
          ) {
            let message: string;
            if (err.error.error) {
              message = err.error.error;
            }
            this.messageService.add({
              severity: 'error',
              detail: message,
            });
          } else if (
            request.url.includes('login') ||
            request.url.includes('register')
          ) {
            let message: string;
            this.spinner.hide();
            console.log(err, err.error.error);
            if (err.error.error) {
              message = err.error.error;
            } else if (err.error.message) {
              message = err.error.message;
            }

            this.messageService.add({
              severity: 'error',
              detail: message,
            });
          }
          // this.spinner.hide();
          return next.handle(request);
        }
        if (err.status === 400) {
          console.log(err);

          let message: any;
          if (err.error.error) {
            message = err.error.error;
          } else if (err.error.message) {
            if (err.error.message.invoice) {
              message = err.error.message.invoice[0];
            } else if (err.error.message.other_document) {
              message = err.error.message.other_document;
            } else if (err.error.message.interest_area) {
              message = err.error.message.interest_area[0];
            } else if (err.error.message.sequence) {
              message = err.error.message.sequence[0];
            } else if (err.error.message.slug) {
              message = err.error.message.slug[0];
            } else {
              message = err.error.message;
              console.log(message);
            }
          } else if (err.error.password) {
            message = err.error.password;
          } else if (err.error.email) {
            message = err.error.email;
          } else if (err.error.name) {
            message = err.error.name;
          } else if (err.error.profile2) {
            message = err.error.profile2;
          } else if (err.error.profile1) {
            message = err.error.profile1;
          } else if (err.error.logo) {
            message = err.error.logo;
          } else if (err?.error?.name1[0]) {
            message = err?.error?.name1[0];
          } else if (err?.error?.filter_name[0]) {
            message = err?.error?.filter_name[0];
          } else if (err.error.form_name[0]) {
            message = err.error.form_name[0];
          }
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            detail: message,
          });
        }
        if (err.status === 500) {
          if (err.error) {
            // err.error.message != 'Token could not be parsed from the request.'
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 403) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 404) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 422) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        const error = err?.error?.message || err?.statusText;
        this.spinner.hide();
        return throwError(error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.apiservice.refreshToken().pipe(
        switchMap((res) => {
          this.isRefreshing = false;
          this.newToken = res?.token;
          this.tokenData = this.commonFunction.getLocalStorage();
          this.tokenData.access_token = this.newToken;
          localStorage.setItem('access_token', JSON.stringify(this.tokenData));
          this.isRefreshing = false;
          this.spinner.hide();
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.tokenData.access_token}`,
            },
          });
          for (var i = 0; i < this.failedRequest.length; i++) {
            var newrequest = this.failedRequest[i].clone({
              setHeaders: {
                Authorization: `Bearer ${this.tokenData.access_token}`,
              },
            });
            next.handle(newrequest);
          }
          return next.handle(request);
        }),
        catchError((error) => {
          this.isRefreshing = false;

          return throwError(() => error);
        })
      );
    } else {
      this.failedRequest.push(request);
      //return next.handle(request);
    }
  }

  stopLoader(request) {
    this.activeRequest--;

    if (
      this.activeRequest === 0 &&
      !request.url.includes('set-member-and-role') &&
      (!this.activatedRoute.snapshot['_routerState'].url.includes('/member-data-update'))
    ) {
      // debugger;
      this.spinner.hide();
    }
  }
}

// import {
//   HttpErrorResponse,
//   HttpEvent,
//   HttpHandler,
//   HttpInterceptor,
//   HttpRequest,
// } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { ConfirmationService, MessageService } from 'primeng/api';
// import { Observable, throwError } from 'rxjs';
// import { catchError, map, switchMap } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
// import { CommonClass } from '../common';
// import { ApiService } from '../services/api.service';

// @Injectable()
// export class ErrorInterceptor implements HttpInterceptor {
//   private isRefreshing = false;
//   constructor(
//     private apiservice: ApiService,
//     private messageService: MessageService,
//     private spinner: NgxSpinnerService,
//     private router: Router,
//     private commonFunction: CommonClass
//   ) {}
//   newToken: any;
//   tokenData: any;
//   failedRequest: any = [];

//   intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     if (request.url.includes('login') || request.url.includes('register'))
//       // return next.handle(request);
//       this.spinner.hide();

//     var token: any = JSON.parse(localStorage.getItem('access_token'));
//     const isLoggedIn = token ? true : false;
//     const isApiUrl = request.url.startsWith(environment.api_baseurl);
//     if (isLoggedIn && isApiUrl) {
//       request = request.clone({
//         setHeaders: { Authorization: `Bearer ${token.access_token}` },
//       });
//     }

//     return next.handle(request).pipe(
//       catchError((err) => {
//         // console.log(request.url);
//         // console.log(JSON.stringify(err));

//         if (err.status === 401) {
//           this.tokenData = this.commonFunction?.getLocalStorage();

//           if (
//             !request.url.includes('login') &&
//             !request.url.includes('register')
//           ) {
//             debugger;
//             return this.handle401Error(request, next);
//           } else {
//             console.log(err, err.error.error);
//           }
//           let message: string;
//           this.messageService.add({
//             severity: 'error',
//             detail: message,
//           });
//           return next.handle(request);
//           // else if (
//           //   request.url.includes('login') &&
//           //   request.url.includes('register')
//           // ) {
//           //   let message: string;
//           //   this.spinner.hide();
//           //   console.log(err, err.error.error);
//           //   if (err.error.error) {
//           //     message = err.error.error;
//           //   } else if (err.error.message) {
//           //     message = err.error.message;
//           //   }

//           //   this.messageService.add({
//           //     severity: 'error',
//           //     detail: message,
//           //   });
//           //   return next.handle(request);
//           // }
//         }
//         if (err.status === 400) {
//           let message: any;
//           if (err.error.error) {
//             message = err.error.error;
//           } else if (err.error.message) {
//             if (err.error.message.invoice) {
//               message = err.error.message.invoice[0];
//             } else if (err.error.message.other_document) {
//               message = err.error.message.other_document;
//             } else if (err.error.message.interest_area) {
//               message = err.error.message.interest_area[0];
//             } else {
//               message = err.error.message;
//               console.log(message);
//             }
//           } else if (err.error.password) {
//             message = err.error.password;
//           } else if (err.error.email) {
//             message = err.error.email;
//           } else if (err.error.name) {
//             message = err.error.name;
//           } else if (err.error.profile2) {
//             message = err.error.profile2;
//           } else if (err.error.profile1) {
//             message = err.error.profile1;
//           } else if (err.error.logo) {
//             message = err.error.logo;
//           }
//           this.messageService.add({
//             severity: 'error',
//             detail: message,
//           });
//         }
//         if (err.status === 500) {
//           if (err.error) {
//             // err.error.message != 'Token could not be parsed from the request.'
//             this.messageService.add({
//               severity: 'error',
//               detail: err.error.message || err.statusText,
//             });
//           }
//         }
//         if (err.status === 403) {
//           if (err.error) {
//             this.messageService.add({
//               severity: 'error',
//               detail: err.error.message || err.statusText,
//             });
//           }
//         }
//         if (err.status === 404) {
//           if (err.error) {
//             this.messageService.add({
//               severity: 'error',
//               detail: err.error.message || err.statusText,
//             });
//           }
//         }
//         if (err.status === 422) {
//           if (err.error) {
//             this.messageService.add({
//               severity: 'error',
//               detail: err.error.message || err.statusText,
//             });
//           }
//         }
//         const error = err.error.message || err.statusText;
//         this.spinner.hide();
//         return throwError(error);
//       })
//     );
//   }

//   // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//   //   debugger;
//   //   if (!this.isRefreshing) {
//   //     this.isRefreshing = true;

//   //     // if (this.storageService.isLoggedIn()) {
//   //     // this.apiservice.refreshToken().subscribe((res: any) => {
//   //     //   debugger;

//   //     // });
//   //     return this.apiservice.refreshToken().pipe(
//   //       switchMap((res) => {
//   //         this.newToken = res?.token;
//   //       this.tokenData = this.commonFunction.getLocalStorage();
//   //       this.tokenData.access_token = this.newToken;
//   //       localStorage.setItem('access_token', JSON.stringify(this.tokenData));
//   //       this.isRefreshing = false;
//   //       this.spinner.hide();
//   //       request = request.clone({
//   //         setHeaders: {
//   //           Authorization: `Bearer ${this.tokenData.access_token}`,
//   //         },
//   //       });
//   //       next.handle(request);
//   //       }),
//   //       catchError((error) => {
//   //         this.isRefreshing = false;

//   //         this.spinner.hide();
//   //         return throwError(() => error);
//   //       })
//   //     );
//   //     }
//   //   }
//   //   return next.handle(request);
//   // }
//   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//     if (!this.isRefreshing) {
//       this.isRefreshing = true;

//       return this.apiservice.refreshToken().pipe(
//         switchMap((res) => {
//           this.isRefreshing = false;
//           this.newToken = res?.token;
//           this.tokenData = this.commonFunction.getLocalStorage();
//           this.tokenData.access_token = this.newToken;
//           localStorage.setItem('access_token', JSON.stringify(this.tokenData));
//           this.isRefreshing = false;
//           this.spinner.hide();
//           request = request.clone({
//             setHeaders: {
//               Authorization: `Bearer ${this.tokenData.access_token}`,
//             },
//           });
//           for (var i = 0; i < this.failedRequest.length; i++) {
//             var newrequest = this.failedRequest[i].clone({
//               setHeaders: {
//                 Authorization: `Bearer ${this.tokenData.access_token}`,
//               },
//             });
//             next.handle(newrequest);
//           }
//           return next.handle(request);
//         }),
//         catchError((error) => {
//           this.isRefreshing = false;

//           return throwError(() => error);
//         })
//       );
//     } else {
//       this.failedRequest.push(request);
//       //return next.handle(request);
//     }
//   }
// }
