import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-blog-template',
  templateUrl: './blog-template.component.html',
  styleUrls: ['./blog-template.component.scss']
})
export class BlogTemplateComponent implements OnInit {

  bannerData : any = [{bannerImage: '../../../../../assets/img/main-banner-bg1.jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'}];
  alterSection : any = [{sectionImage: './../../../../../assets/img/about-img (1).jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'},{sectionImage: './../../../../../assets/img/about-img (1).jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'}];
  
  constructor(  private route: Router,private slugroute: ActivatedRoute) { }
  blogPage:boolean=false
  factory:boolean=true
  bannerimg:any
   uniqueYears:any=[]
   sortedUniqueYears:any=[];
  jsonData:any = {page_json : []}
  data:any=[]
  @Input() pageData : any;
  baseUrl = environment.blogImgUrl
  bannerImg = environment.bannerImgUrl
  ngOnInit() {
    console.log(this.bannerImg)
    
    this.jsonData = this.pageData
    console.log(this.jsonData);
      
    this.data =this.jsonData
    console.log(this.jsonData);
    console.log(this.slugroute.snapshot.params.slug);
  }
  getImageUrl(url) {
    // const imageUrl = 'data:image/png;base64,' + url;
    // return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  callToActionButton(ctaURl, id){
      this.route.navigateByUrl('blog-page/'+ id);
     
  }
  

  
}
