import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-complete-detail',
  templateUrl: './blog-complete-detail.component.html',
  styleUrls: ['./blog-complete-detail.component.scss'],
})
export class BlogCompleteDetailComponent implements OnInit {
  constructor(private websiteService: WebsiteService,  private activatedRoute: ActivatedRoute,) {}
  @Input() pageData : any;
  baseUrl = environment.blogImgUrl
  data: any;
  slug:any;

  ngOnInit() {
    this.slug = this.activatedRoute?.snapshot?.params;
    console.log(this.slug)
    this.data = this.pageData
    console.log(this.data);
  }
}
