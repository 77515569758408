import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonMemberComma',
})
export class NonMemberCommaPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let convertedData: any;

    if (value.includes('[')) {
      convertedData = JSON.parse(value);
    } else if (value.includes(', ')) {
      convertedData = value.split(', ');
    } else {
      convertedData = value?.split(',');
    }

    return convertedData;
  }
}
