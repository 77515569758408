import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    allInquiries: any = [];
    productLength: any = [];
    slug: any;
    searchString : string;
    public marketplace_media_url = environment.marketplace_media_url;
    blankArray: any = [];

    constructor(private marketPlaceApi: MarketplaceService,
        private activatedRoute: ActivatedRoute,
        private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        this.slug = this.activatedRoute.snapshot.params;
        console.log(this.slug);
        if (this.slug?.slug == 'myinquiry') {
            this.showGeneratedInquires();
        }
        else if (this.slug?.slug == 'allInquiry') {
            this.getInquires();
        }
    }

    pageTitleContent = [
        {
            title: 'Cart',
            backgroundImage: 'assets/img/page-title/page-title2.jpg'
        }
    ]
    // get all marketplace enquiries 
    getInquires() {
        this.spinner.show();
        this.marketPlaceApi.getEnquiries().subscribe((res: any) => {
            this.spinner.hide();
            console.log(res);
            this.allInquiries = res.data;
            this.allInquiries.map((resp: any) => {
                if (resp.product_id == this.slug.id) {
                    this.productLength.push(resp);
                } 
            })
        })
    }
    // get my generated marketplace enquiry 
    showGeneratedInquires() {
        this.spinner.show();
        this.marketPlaceApi.getGeneratedEnquiry().subscribe((res: any) => {
            this.spinner.hide();
            console.log(res);
            this.allInquiries = res.data;
        })
    }
    getImageUrl(url: any) {
        return this.marketplace_media_url + '/media/' + url;
    }
    reset() {
        this.searchString = '';
        
    }
}