import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnChanges,
  OnInit,
  SecurityContext,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class NavbarStyleOneComponent implements OnInit, OnChanges {
  @ViewChild('formlogin') formlogin: NgForm;
  @ViewChild('formRegister') formRegister: NgForm;
  @ViewChild('forgotSubmit') forgotSubmit: NgForm;
  private destroy = new Subject<void>();
  signUpSubmitted = false;
  passwordSubmitted = false;
  submitted = false;
  submitButton: string = 'Change Password';
  resSignupMsg: string = '';
  resSignupMsgCheck: string = ' ';
  resSigninMsgCheck: string = ' ';
  resSigninMsg: string = ' ';
  loginobj: any;
  loggedIn: boolean = false;
  userName: string = '';
  userEmail: string = '';
  resData: any = {};
  localStorage: any;
  classCheck: string = 'login';
  showData: boolean = false;
  spinnerMsg: string;
  role: any;
  Modal: boolean = false;
  selectRole: string;
  roleObject: any = environment.roles;
  blockSpecial: RegExp = /^[^<>*!/]+$/;
  disablebtn: boolean = false;
  registerForm = new FormGroup({
    signupname: new FormControl(null, [Validators.required]),
    signupemail: new FormControl(null, [Validators.required]),
    signuppassword: new FormControl(null, [Validators.required]),
    confirmpassword: new FormControl(null, [Validators.required]),
  });
  UserEmail: any;
  otp: string;
  memberRole: any;
  roles: any = [];
  MemberValue: any;
  companyName: any;

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private messageService: MessageService,
    private commonFunction: CommonClass,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public sanit: DomSanitizer,
    private utility: utility
  ) {}

  ngOnChanges() {}
  ngOnInit(): void {
    // save user name or email in localstorage
    var access_token: any = localStorage.getItem('access_token');
    if (access_token && access_token != 'undefined') {
      this.userName = JSON.parse(localStorage.getItem('access_token')).username;
      this.userEmail = JSON.parse(
        localStorage.getItem('access_token')
      ).useremail;
    }
    this.localStorage = this.commonFunction.getLocalStorage();

    // if (this.localStorage) {
    //   if (!this.localStorage?.role) {
    //     setTimeout(() => {
    //       this.messageService.add({
    //         sticky: true,
    //         severity: 'warn',
    //         detail:
    //           'You have to Select Role First to Access Data! Please Login Again!',
    //       });
    //     }, 1000);
    //     setTimeout(() => {
    //       this.logout();
    //     }, 4000);
    //   }
    // }
    this.getMemberRole();
    this.getUserEmail();
    this.checkFeedbackValidation();
  }

  // sign up form controls

  // user sign up function
  signUp() {
    this.signUpSubmitted = true;
    let formData = new FormData();

    if (this.registerForm.valid) {
      this.spinnerMsg = 'Signing up';
      this.spinner.show();
      let data = this.registerForm.value;
      formData.append('name', this.utility.htmlInjection(data.signupname));
      formData.append('email', data.signupemail);
      formData.append('password', data.signuppassword);
      formData.append('password_confirmation', data.confirmpassword);
      this.apiservice.register(formData).then(
        (res) => {
          this.resSignupMsgCheck = 'success';
          this.resSignupMsg = res.message;
          this.saveAccessTOkenforSignup(res);
          let roleObject = {
            member_role: res.member_role[0].value,
          };
          this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
            localStorage.setitem('data', JSON.stringify(res));
          });

          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            detail: 'User Registered Successfully!',
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  // change password form controls
  PasswordForm = new FormGroup({
    newpassword: new FormControl('', [Validators.required]),
    confirmpassword: new FormControl('', [Validators.required]),
    passemail: new FormControl(''),
    otp: new FormControl(null, Validators.pattern(/\d/)),
  });
  // change password function
  changePassword() {
    Object.keys(this.PasswordForm.controls).forEach((res: any) => {
      // console.log(res, this.PasswordForm.controls[res].status);
    });

    // console.log(
    //   this.submitButton,
    //   this.PasswordForm.valid,
    //   this.PasswordForm.value
    // );
    if (this.submitButton == 'Change Password') {
      if (this.PasswordForm.valid) {
        // alert();
        let emailObject = {
          email: this.userEmail,
        };
        this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          if (res.code == 200) {
            this.showData = true;
            this.submitButton = 'Verify';
            this.PasswordForm.controls['otp'].setValidators([
              Validators.required,
            ]);
            this.PasswordForm.controls['otp'].updateValueAndValidity();
          }
        });
      }
    } else if (this.submitButton != 'Change Password') {
      if (this.PasswordForm.valid) {
        let otpObject = {
          otp: this.PasswordForm.value.otp,
          email: this.userEmail,
        };
        this.spinner.show();
        this.apiservice.OTPVerification(otpObject).subscribe((res: any) => {
          this.spinner.hide();
          if (res.code == 200) {
            let data = new FormData();
            let Data = this.PasswordForm.value;
            data.append('email', this.UserEmail);
            data.append('password', Data.newpassword);
            this.apiservice.changePasswordApi(data).subscribe((res: any) => {
              this.messageService.add({
                severity: 'success',
                detail: res.mesage,
              });
              setTimeout(() => {
                this.router.navigateByUrl('/');
              }, 1500);
            });
          }
        });
      }
    }
    this.passwordSubmitted = true;
  }
  // send role controls
  roleForm = new FormGroup({
    memberrole: new FormControl(null, [Validators.required]),
  });
  sendRole(role?: Form) {
    if (this.roles.length > 1) {
      if (this.roleForm.valid) {
        let roleObject = {
          member_role: this.roleForm.value.memberrole,
        };
        this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
          this.companyName = res?.member?.company_name;
          this.saveAccessToken(this.resData, res.role.name);
          localStorage.setItem('data', JSON.stringify(res));
        });
        this.Modal = false;
        this.router.navigateByUrl('/user-panel/dashboard');
      }
    }

    if (this.roles.length == 1) {
      let roleObject = {
        member_role: this.resData.member_role[0].value,
      };
      this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
        this.companyName = res?.member?.company_name;
        this.saveAccessToken(this.resData, res.role.name);
        localStorage.setItem('data', JSON.stringify(res));
      });
      this.router.navigateByUrl('/user-panel/dashboard');
    }
  }
  // sign in form controls
  loginForm = new FormGroup({
    loginemail: new FormControl('', [Validators.required]),
    loginpassword: new FormControl('', [Validators.required]),
  });

  // user login form function
  loginIn() {
    this.submitted = true;

    if (this.loginForm.valid) {
      this.spinnerMsg = 'Logging In';
      this.spinner.show();
      let localStorageData: any = {};
      let data = Object.assign({}, this.loginForm.value);
      this.loginobj = {
        email: data.loginemail,
        password: data.loginpassword,
      };

      this.apiservice.login(this.loginobj).subscribe(
        (res: any) => {
          this.resData = res;
          this.roles = res.member_role;

          if (res.code == 200) {
            this.saveAccessToken(this.resData, null);
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: 'You are Successfully logged in!',
            });
            setTimeout(() => {
              if (this.roles.length > 1) {
                this.Modal = true;
              }
            }, 1000);
            this.sendRole();

            document.getElementById('close_btn').click();
            this.localStorage = this.commonFunction.getLocalStorage();
            if (localStorage.getItem('redirect_url')) {
              this.router.navigateByUrl(localStorage.getItem('redirect_url'));
            } else {
              if (this.localStorage.role == 'Admin') {
                setTimeout(() => {
                  this.router.navigateByUrl('/user-panel/dashboard');
                }, 1500);
              }
            }
          }
        },
        (error: HttpErrorResponse) => {
          // console.log(error);
          this.disablebtn = true;

          setTimeout(() => {
            this.disablebtn = false;
          }, 3000);
        }
      );
    }
  }

  errorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      detail: message,
    });
  }

  // convenience getter for easy access to form fields for register form
  get f() {
    return this.registerForm.controls;
  }
  // convenience getter for easy access to form fields for login form
  get g() {
    return this.loginForm.controls;
  }
  // convenience getter for easy access to form fields for change password form
  get p() {
    return this.PasswordForm.controls;
  }
  // convenience getter for easy access to form fields for change password form
  get o() {
    return this.roleForm.controls;
  }
  // logout function
  logout() {
    this.spinner.show();
    this.spinnerMsg = 'Loging Out';
    this.apiservice.logoutUser().subscribe(
      (res: any) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('data');
        localStorage.removeItem('Member_Role');
        localStorage.removeItem('companyName');
        localStorage.removeItem('variable');
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });
        this.userName = '';
        this.localStorage = this.commonFunction.getLocalStorage();
        this.spinner.hide();
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 1500);
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  }
  saveAccessTOkenforSignup(res: any) {
    var localStorageData = {
      access_token: res?.access_token,
      username: res?.user?.name,
      useremail: res?.user?.email,
      role: res?.role[0],
    };
    localStorage.setItem('access_token', JSON.stringify(localStorageData));
  }
  saveAccessToken(res: any, name?: string) {
    if (name == null) {
      var localStorageData = {
        access_token: res?.access_token,
        username: res?.user?.name,
        useremail: res?.user?.email,
      };
      localStorage.setItem('access_token', JSON.stringify(localStorageData));
    } else if (name != null) {
      var newLocalStorageData = {
        access_token: res?.access_token,
        username: res?.user?.name,
        useremail: res?.user?.email,
        role: name,
        memberRole: res?.member_role,
      };
      localStorage.setItem('access_token', JSON.stringify(newLocalStorageData));
      localStorage.setItem('companyName', this.companyName);
      window.location.reload();
    }
    this.userName = res.user.name;
  }
  onChange(value) {
    // this.bindEvents();
    let changed_value = value;
    // .replace(/(<([^>]+)>)/gi, '')
    // .replace(/\r\n|\r|\n/g);
    // console.log(
    //   `form value : ${this.registerForm.value.signupname}, input value : ${value}, Changed Value : ${changed_value}`
    // );
  }

  private bindEvents(): void {
    this.sanitizeInput();
  }

  private sanitizeInput(): void {
    // debugger;
    this.registerForm.valueChanges
      .pipe(
        map((value) => (value == null ? '' : String(value))),
        map((value) => this.sanit.sanitize(SecurityContext.HTML, value)),
        // map(value => value.replace(/[^01]+/gi, '')),
        // map(value => value.replace(/^0+/, '')),
        filter((sanitizedValue) => sanitizedValue !== this.registerForm.value),
        takeUntil(this.destroy)
      )
      .subscribe((sanitizedValue: any) =>
        this.registerForm.setValue(sanitizedValue)
      );
  }
  // get user email from localstorage
  getUserEmail() {
    let Storage = localStorage.getItem('access_token');
    if (Storage) {
      this.UserEmail = JSON.parse(Storage).useremail;

      this.PasswordForm.patchValue({
        passemail: this.UserEmail,
      });
    }
  }
  // get member role from localstoarge
  getMemberRole() {
    this.memberRole = JSON.parse(
      localStorage?.getItem('access_token')
    )?.memberRole;
  }
  // change role from dropdown
  getRoleWiseData(data: any) {
    this.MemberValue = data.value;
    let membervalue = {
      member_role: this.MemberValue,
    };
    this.spinner.show();
    this.apiservice.sendDataOnLogin(membervalue).subscribe(
      (res: any) => {
        localStorage.setItem('data', JSON.stringify(res));
        var newLocalStorageData = {
          access_token: this.localStorage?.access_token,
          username: this.localStorage?.username,
          useremail: this.localStorage?.useremail,
          role: res.role.name,
          memberRole: this.localStorage?.memberRole,
        };
        localStorage.setItem('companyName', res.member.company_name);
        localStorage.setItem(
          'access_token',
          JSON.stringify(newLocalStorageData)
        );
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/user-panel/dashboard']);
        this.spinner.hide();
      },

      (error: HttpErrorResponse) => {
        if (error.status == 401) {
          // console.log('skjdzgfcyktws');
        }
      }
    );
  }

  //check feedback form authentication
  checkFeedbackValidation() {}

  forgotForm = this.fb.group({
    emailAddress: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.pattern(/\d/)]),
    password: new FormControl(''),
    confirmpassword: new FormControl(''),
  });

  emailAddress: string = '';

  showOtp: boolean = false;
  forgotButton: string = 'Send OTP';
  changepassword: boolean = false;
  forgotGetOtp(forgotSubmit: FormGroupDirective) {
    if (this.forgotForm.valid) {
      let data = {
        email: this.forgotForm.value.emailAddress,
      };
      if (this.forgotButton == 'Send OTP') {
        this.apiservice.forgotPassword(data).then((res: any) => {
          this.spinner.show();
          if (res.code == 200) {
            this.forgotForm.setControl(
              'otp',
              this.fb.control('', [Validators.required])
            );
            this.showOtp = true;
            this.emailAddress = this.forgotForm.get('emailAddress').value;
            this.forgotButton = 'Submit';
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          }
        });
      } else if (this.forgotButton == 'Submit') {
        this.spinner.show();
        let object = {
          email: this.emailAddress,
          otp: this.forgotForm.value.otp,
        };
        this.apiservice.OTPVerification(object).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.forgotButton = 'Change Password';
            this.spinner.hide();
            this.forgotForm.setControl(
              'password',
              this.fb.control('', [Validators.required])
            );
            this.forgotForm.setControl(
              'confirmpassword',
              this.fb.control('', [Validators.required])
            );

            this.changepassword = true;
          }
        });
      } else if (this.forgotButton == 'Change Password') {
        this.spinner.show();
        let object = {
          email: this.emailAddress,
          password: this.forgotForm.value.password,
          confirmpassword: this.forgotForm.value.confirmpassword,
        };
        this.apiservice.changePasswordApi(object).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          }
          this.spinner.hide();
          this.forgotForm.reset();
          Object.keys(this.forgotForm.controls).forEach((key: any) => {
            this.forgotForm.controls[key].setErrors(null);
          });
          forgotSubmit.resetForm();
          this.resetForm();
        });
      }
    } else {
      this.spinner.hide();
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill the Form',
      });
    }
  }

  resetForm() {
    this.classCheck = 'login';
    this.showOtp = false;
    this.changepassword = false;
    this.forgotButton = 'Send OTP';
    this.formlogin.resetForm();
    this.formRegister.resetForm();
    this.forgotSubmit.resetForm();
    this.forgotForm.setControl('otp', this.fb.control(''));
    this.forgotForm.reset();
  }
  modelForChangepassword(string: any) {
    this.classCheck = string;
    this.submitButton = 'Change Password';
    this.showData = false;
    this.forgotSubmit.resetForm();
    this.PasswordForm.controls['otp'].removeValidators(Validators.required);
    this.PasswordForm.controls['otp'].updateValueAndValidity();
    this.forgotForm.reset();
  }
}
