import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {

  @Input() imageData : any;
  modalStyle: string = 'flex';
  constructor(private sanitizer: DomSanitizer) { 
    console.log(this.imageData);
  }

  ngOnInit() {
    console.log(this.imageData);
  }
  ngOnChanges() {
    console.log(this.imageData);
    // this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData.url);
  }

  onImageClose() {
    this.modalStyle = 'none';
    this.imageData.boolean = false;
  }
}
