import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-user-credential',
  templateUrl: './user-credential.component.html',
  styleUrls: ['./user-credential.component.scss'],
  providers: [MessageService],
})
export class UserCredentialComponent implements OnInit {
  @ViewChild('dt2') dt2: Table;
  breadcrumb: any[] = [
    {
      title: 'User Credentials',
      subTitle: 'Masters',
    },
  ];
  tooltipText: string = environment.resetTooltip;
  displayMaximizable: boolean = false;
  hwmDisplayMaximizable: boolean = false;
  getAPIData: any = [];
  Modal: boolean = false;

  nomine1Id: any;
  nomine2Id: any;
  nomine3Id: any;
  showRenewalListing: boolean = true;
  filterVal: string;
  colFilter = [];
  selectedMemberData: any[] = [];
  listofData: any[] = [];
  formGroup: FormGroup;
  successState: boolean;
  message: any;
  permission: any = [];
  enableEditButton: boolean = true;
  response: any = [];
  nominee1Id: string = '';
  nomine1Form: FormGroup;
  nomine2Form: FormGroup;
  nomine3Form: FormGroup;
  nominee2Details: any;

  constructor(
    private apiservice: ApiService,
    private fb: FormBuilder,
    public apiService: ApiService,
    private messageService: MessageService
  ) {}

  getUserdata() {
    this.apiService.getmemberlist().subscribe((res: any) => {
      console.log(res);
      this.listofData = res;
    });
  }
  goToUpdates(data: any) {
    this.selectedMemberData = [];
    if (data.membership_name == 'HWM Member') {
      this.hwmDisplayMaximizable = true;
    } else {
      this.displayMaximizable = true;
    }

    const memberId = data.id;
    this.apiService.nomineeDetails(memberId).subscribe((res: any) => {
      console.log(res);
      this.nominee2Details = res?.nominee2;
      this.selectedMemberData.push(res);
      this.nomine1Id = res.nominee1?.id || ' ';
      this.nomine2Id = res.nominee2?.id || ' ';
      this.nomine3Id = res.nominee3?.id || ' ';
      this.nomine1Form = this.fb.group({
        nominee1Name: [res.nominee1?.name || ''],
        nominee1Email: [res.nominee1?.email || ''],
        nominee1Mobile: [res.nominee1?.mobile || ''],
        nominee1Password: [''],
      });

      this.nomine2Form = this.fb.group({
        nominee2Name: [res.nominee2?.name || ''],
        nominee2Email: [res.nominee2?.email || ''],
        nominee2Mobile: [res.nominee2?.mobile || ''],
        nominee2Password: [''],
      });
      this.nomine3Form = this.fb.group({
        nominee3Name: [res.nominee3?.name || ' '],
        nominee3Email: [res.nominee3?.email || ''],
        nominee3Mobile: [res.nominee3?.mobile || ''],
        nominee3Password: [''],
      });
      console.log(this.selectedMemberData);
    });
  }

  UpdateNomineeDetails() {
    console.log(this.nomine1Form.value);
    console.log(this.nomine2Form.value);
    console.log(this.nomine3Form.value);
    const nomine1 = this.nomine1Form.value;
    const nomine2 = this.nomine2Form.value;
    const nomine3 = this.nomine3Form.value;
    const updateJsonOfNomineeDetails = {
      nominee1_id: this.nomine1Id,
      nominee1_name: nomine1.nominee1Name,
      nominee1_email: nomine1.nominee1Email,
      nominee1_mobile: nomine1.nominee1Mobile,
      nominee1_password: nomine1.nominee1Password,
      nominee2_id: this.nomine2Id,
      nominee2_name: nomine2.nominee2Name,
      nominee2_email: nomine2.nominee2Email,
      nominee2_mobile: nomine2.nominee2Mobile,
      nominee2_password: nomine2.nominee2Password,
      nominee3_id: this.nomine3Id,
      nominee3_name: nomine3.nominee3Name,
      nominee3_email: nomine3.nominee3Email,
      nominee3_mobile: nomine3.nominee3Mobile,
      nominee3_password: nomine3.nominee3Password,
    };

    this.apiservice.updateNomineeDetails(updateJsonOfNomineeDetails).subscribe(
      (response) => {
        console.log('Nominee details updated successfully:', response);
        this.displayMaximizable = false;
        this.hwmDisplayMaximizable = false;
        this.messageService.add({
          severity: 'success',
          detail: 'Credentials are updated successfully',
        });
      },
      (error) => {
        this.displayMaximizable = false;
        this.hwmDisplayMaximizable = false;
        console.error('Error updating nominee details:', error);
        this.messageService.add({
          severity: 'error',
          detail: error.message,
        });
      }
    );
  }

  closeDialogBox() {
    this.displayMaximizable = false;
    this.hwmDisplayMaximizable = false;
  }
  async ngOnInit(): Promise<void> {
    this.getUserdata();
  }
  onchange(event, type: any) {
    console.log('onchange', event);
    if (event.checked == true) {
      const nominee1EmailValue =
        this.nomine1Form.controls['nominee1Email'].value;
      const [emailPrefix, emailDomain] = nominee1EmailValue.split('@');
      const modifiedEmailPrefix = emailPrefix + '+1';

      const modifiedEmail = `${modifiedEmailPrefix}@${emailDomain}`;

      this.nomine2Form.controls['nominee2Name'].setValue(
        this.nomine1Form.controls['nominee1Name'].value
      );
      this.nomine2Form.controls['nominee2Email'].setValue(modifiedEmail);
      this.nomine2Form.controls['nominee2Mobile'].setValue(
        this.nomine1Form.controls['nominee1Mobile'].value
      );
      this.nomine2Form.controls['nominee2Password'].setValue(
        this.nomine1Form.controls['nominee1Password'].value
      );
    } else {
      this.nomine2Form.controls['nominee2Name'].setValue(
        this.nominee2Details?.name
      );
      this.nomine2Form.controls['nominee2Email'].setValue(
        this.nominee2Details?.email
      );
      this.nomine2Form.controls['nominee2Mobile'].setValue(
        this.nominee2Details?.mobile
      );
      this.nomine2Form.controls['nominee2Password'].setValue(null);
    }
  }
}
