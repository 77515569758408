import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';
import {
  FormGroup, FormControl, Validators
} from '@angular/forms';
@Component({
  selector: 'app-dashboard-sidemenu',
  templateUrl: './dashboard-sidemenu.component.html',
  styleUrls: ['./dashboard-sidemenu.component.scss'],
  providers: [MessageService],
  
})
export class DashboardSidemenuComponent implements OnInit {
  localStorage: any;
  isTrue:boolean=false
  MemberValue: any;
  memberRole: any;
  companyName: any;
  submitButton: string = 'Change Password';
  showData: boolean = false;
  UserEmail: any;
  userEmail: string = '';
  passwordSubmitted = false;
  classCheck: string = 'login';
  permissionData: any = []
  constructor(
    private apiservice: ApiService,
    private messageService: MessageService,
    private router: Router,
    private commonFunction: CommonClass,
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.companyName = this.commonFunction.getCompanyName();
    if (this.localStorage) {
      this.websiteService.navigateOption.next('dashboard');
    } else {
      this.websiteService.navigateOption.next('logout');
    }
    //  console.log(this.localStorage)
    this.getMemberRole();
    var parseUserId = JSON.parse(localStorage.getItem('access_token'));
        var userId = parseUserId.userId;
        var roleId = parseUserId.roleId;
        
       this.apiservice.getUserPermission(userId, roleId).then((res: any) => {
            this.permissionData = res.data
        });
  }

  getMemberRole() {
    this.memberRole = JSON.parse(
      localStorage?.getItem('access_token')
    )?.memberRole;
  }
  // route to report page function
  routeToReportPage(value: string) {
    this.router.navigateByUrl(`/user-panel/reports?value=${value}`)
  }
  routeToPage() {
    // console.log('hello');
    this.router.navigateByUrl('user-panel/dashboard');
  }

  roleObject: any = environment.roles;

  logout() {
    this.apiservice.logoutUser().subscribe((res: any) => {
      // console.log(res, 'resresres');
      localStorage.removeItem('access_token');
      localStorage.removeItem('variable');
      localStorage.removeItem('data');
      this.messageService.add({
        severity: 'success',
        // summary: 'success',
        detail: res.message,
      });
      this.router.navigateByUrl('');
      this.websiteService.navigateOption.next('logout');
    });
  }

  getRoleWiseData(data: any) {
    this.MemberValue = data.value;
    let membervalue = {
      member_role: this.MemberValue,
    };
    this.spinner.show();
    this.apiservice.sendDataOnLogin(membervalue).subscribe(
      (res: any) => {
        this.spinner.hide();
        localStorage.setItem('data', JSON.stringify(res));
        console.log(res)
        var newLocalStorageData = {
          access_token: this.localStorage?.access_token,
          username: this.localStorage?.username,
          useremail: this.localStorage?.useremail,
          role: res.role.name,
          memberRole: this.localStorage?.memberRole,
          userId: res?.user?.id,
          roleId: res?.user?.role_id,
        };
        localStorage.setItem('companyName', res.member.company_name);
        localStorage.setItem('member_id', res.member.id);
        localStorage.setItem('memberShipId', res?.member?.membership_no)
        localStorage.setItem(
          'access_token',
          JSON.stringify(newLocalStorageData)
        );
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/user-panel/dashboard']);
      },

      (error: HttpErrorResponse) => {
        if (error.status == 401) {
          // console.log('skjdzgfcyktws');
        }
      }
    );
  }
  // change password form controls
  PasswordForm = new FormGroup({
    newpassword: new FormControl('', [Validators.required]),
    confirmpassword: new FormControl('', [Validators.required]),
    passemail: new FormControl(''),
    otp: new FormControl(null, Validators.pattern(/\d/)),
  });
  // change password function
  changePassword() {
    Object.keys(this.PasswordForm.controls).forEach((res: any) => {
      // console.log(res, this.PasswordForm.controls[res].status);
    });

    // console.log(
    //   this.submitButton,
    //   this.PasswordForm.valid,
    //   this.PasswordForm.value
    // );
    if (this.submitButton == 'Change Password') {
      if (this.PasswordForm.valid) {
        // alert();
        let emailObject = {
          email: this.userEmail,
        };
        this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          if (res.code == 200) {
            this.showData = true;
            this.submitButton = 'Verify';
            this.PasswordForm.controls['otp'].setValidators([
              Validators.required,
            ]);
            this.PasswordForm.controls['otp'].updateValueAndValidity();
          }
        });
      }
    } else if (this.submitButton != 'Change Password') {
      if (this.PasswordForm.valid) {
        let otpObject = {
          otp: this.PasswordForm.value.otp,
          email: this.userEmail,
        };
        this.spinner.show();
        this.apiservice.OTPVerification(otpObject).subscribe((res: any) => {
          this.spinner.hide();
          if (res.code == 200) {
            let data = new FormData();
            let Data = this.PasswordForm.value;
            data.append('email', this.UserEmail);
            data.append('password', Data.newpassword);
            this.apiservice.changePasswordApi(data).subscribe((res: any) => {
              this.messageService.add({
                severity: 'success',
                detail: res.mesage,
              });
              setTimeout(() => {
                this.router.navigateByUrl('/');
              }, 1500);
            });
          }
        });
      }
    }
    this.passwordSubmitted = true;
  }

//   getUserPermission(id: any) {
//     debugger;
//     var parseUserId = JSON.parse(localStorage.getItem('access_token'));
//     var userId = parseUserId.userId;
//     var roleId = parseUserId.roleId;
    
//     return this.apiservice.getUserPermission(userId, roleId).then((res: any) => {
//         // Checking if res.data exists and it is an array
//         if (Array.isArray(res.data)) {
//             // Using Array.prototype.some() for iteration
//             return res.data.some(item => item.permission_id === id);
//         } else {
//             return false; // If res.data is not an array or does not exist
//         }
//     });
// }
getUserPermission(id: any) {
  // Accessing local data instead of calling API
  if (Array.isArray(this.permissionData)) {
      // Using Array.prototype.some() for iteration
       if(this.permissionData.some(item => item.permission_id === id)){
        return true
      }
      else{
        return false
      }
  } else {
      throw new Error('Permission data is not available.');
  }
}


addClassTOSidemenu(){
  this.isTrue=true
   const a =document.getElementById('sideOverlays').innerHTML;
  

}

hideSideMenu(){
  this.isTrue=false
}
}
