<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast life="50000"></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" header="Confirmation"></p-confirmDialog>
<!-- <app-navbar-style-one></app-navbar-style-one>
<app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- <div class="main-content d-flex flex-column"> -->
<ng-container *ngIf="!successState">
  <div class="breadcrumb-area">
    <h1>HWM Membership Revision Form</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">HWM Membership Revision Form</li>
    </ol>
  </div>
  <section class="hwm-member-form-section">
    <div class="container" *ngIf="isShow" id="backToTop">
      <div class="heading_section">
        <div class="row m-0">
          <!-- <h3 class="m-0 px-0 mb-3">HWM Membership Edit Form</h3> -->
        </div>
      </div>
      <form (ngSubmit)="Register(register)" #register="ngForm">
        <!-- basic information -->
        <div class="add-listings-box">
          <h3>Basic Information <span
            style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 50px;">
            Any Information Filled in the Form is your Responsibility</span></h3>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label>Company Name:</label>
                  <!-- <p *ngIf="this.memberShipStatus !== 'In progress' && this.memberShipStatus !== 'Pending'"
                    style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 5px">
                    Chargeable</p> -->
                </div>
                <input style="text-transform: capitalize" [class.is-invalid]="(register.submitted &&
                                  company_name?.invalid) || company_name?.invalid
                                  && company_name.touched" type="text" scriptInjection maxlength="70"
                  class="form-control" name="company_name" #company_name="ngModel"
                  [(ngModel)]="hwmMemberShipFormData.company_name" placeholder="Company Name">
                <!-- <div *ngIf="register.submitted ||
                                  company_name.touched" class="alert
                                  alert-danger">
                  <div *ngIf="company_name.errors?.['required']">
                    Company Name is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>GST Number:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  gst_number?.invalid) || gst_number?.invalid &&
                                  gst_number.touched" type="text" scriptInjection class="form-control"
                  name="gst_number" oninput="this.value= this.value.toUpperCase()"
                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  [(ngModel)]="hwmMemberShipFormData.gst_number" maxlength="15" placeholder="GST Number"
                  #gst_number="ngModel">
                <div *ngIf="register.submitted ||
                                  gst_number.touched" class="alert alert-danger">
                  <!-- <div *ngIf="gst_number.errors?.['required']">
                    GST Number is Required.
                  </div> -->
                  <div *ngIf="gst_number.errors?.['pattern']">
                    Please enter valid GST number.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>CIN:</label>
                <input placeholder="CIN Number" type="text" scriptInjection maxlength="21"
                  pattern="^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
                  oninput="this.value= this.value.toUpperCase()" [(ngModel)]="hwmMemberShipFormData.cin_number"
                  class="form-control" name="cin_number" #cin_number="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  cin_number.touched" class="alert alert-danger">
                <div *ngIf="cin_number.errors?.['required']">
                  CIN is Required.
                </div>
              </div> -->
                <div class="alert alert-danger" *ngIf="cin_number.errors?.pattern">
                  Please Enter Valid CIN
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Unit ID:</label>
                <input type="text" scriptInjection class="form-control" [(ngModel)]="hwmMemberShipFormData.unit"
                  name="unit" placeholder="Unit" #unit="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  unit.touched" class="alert
                                  alert-danger">
                <div *ngIf="unit.errors?.['required']">
                  Unit is Required.
                </div>
              </div> -->
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>Average Fulltime
                                  Employee:</label>
                              <input [class.is-invalid]="(register.submitted &&
                                  avgemp?.invalid) ||
                                  avgemp?.invalid &&
                                  avgemp.touched" type="text" scriptInjection
                                  maxlength="6"
                                  pKeyFilter="num"
                                  name="avgemp" class="form-control"
                                  [(ngModel)]="hwmMemberShipFormData.avgemp"
                                  placeholder="Average Fulltime Employee"
                                  #avgemp="ngModel" required>
                              <div *ngIf="register.submitted ||
                                  avgemp.touched" class="alert
                                  alert-danger">
                                  <div
                                      *ngIf="avgemp.errors?.['required']">
                                      Average Fulltime
                                      Employee is Required.
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>Office Telephone:</label>
                              <input [class.is-invalid]="(register.submitted &&
                                  office_telephone?.invalid) ||
                                  office_telephone?.invalid &&
                                  office_telephone.touched" type="text" scriptInjection
                                  class="form-control" name="office_telephone"
                                  maxlength="10"
                                  pKeyFilter="num"
                                  [(ngModel)]="hwmMemberShipFormData.office_telephone"
                                  pattern="^((\\+91-?)|0)?[0-9]{10}$"
                                  placeholder="Office Telephone"
                                  #office_telephone="ngModel" required>
                              <div *ngIf="register.submitted ||
                                  office_telephone.touched" class="alert
                                  alert-danger">
                                  <div
                                      *ngIf="office_telephone.errors?.['required']">
                                      Office Telephone is Required.
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>Residental
                                  Telephone:</label>
                              <input [class.is-invalid]="(register.submitted &&
                                  residential_telephone?.invalid) ||
                                  residential_telephone?.invalid &&
                                  residential_telephone.touched" type="text" scriptInjection
                                  class="form-control"
                                  [(ngModel)]="hwmMemberShipFormData.residential_telephone"
                                  maxlength="10"
                                  pKeyFilter="num"
                                  pattern="^((\\+91-?)|0)?[0-9]{10}$"
                                  name="residential_telephone"
                                  placeholder="Residental Telephone"
                                  #residential_telephone="ngModel" required>
                              <div *ngIf="register.submitted ||
                                  residential_telephone.touched" class="alert
                                  alert-danger">
                                  <div
                                      *ngIf="residential_telephone.errors?.['required']">
                                      Residental Telephone is Required.
                                  </div>
                              </div>
                          </div>
                      </div> -->
          </div>
        </div>
        <!-- address section -->
        <div class="add-listings-box">
          <div class="row">
            <div class="d-flex align-items-center">
              <h3>Factory Address</h3>
      
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <div class="d-flex"><label>Factory Address:</label></div>
                
                <input [class.is-invalid]="(register.submitted
                                  && factory_address?.invalid) ||
                                  factory_address?.invalid &&
                                  factory_address.touched" type="text" scriptInjection class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.factory_address" name="factory_address"
                  placeholder="Factory Address" #factory_address="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  factory_address.touched" class="alert
                                  alert-danger">
                  <div *ngIf="factory_address.errors?.['required']">
                    Factory Address is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Factory District:</label>
                <p-dropdown autocomplete="off" [options]="roDetails" [class.is-invalid]="(register.submitted &&
                factory_district?.invalid) ||
                factory_district?.invalid &&
                factory_district.touched" name="factory_district" #factory_district="ngModel"
                  [(ngModel)]="selectedDistrict2" [styleClass]="(register.submitted &&
                factory_district?.invalid) ||
                factory_district?.invalid &&
                factory_district.touched ? 'error' : ''" placeholder="Select District"
                  optionLabel="regional_office_name" (onChange)="change('factory_district')" [showClear]="false"
                  [resetFilterOnHide]="true">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="selectedDistrict2">
                      <div>{{selectedDistrict2.regional_office_name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.regional_office_name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted ||
                factory_district.touched" class="alert alert-danger">
                  <div *ngIf="factory_district.errors?.['required']">
                    Factory District is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Factory State:</label>
                <input type="text" readonly value="Rajasthan" class="form-control">
                <!-- <p-dropdown [options]="state" optionLabel="name" name="factory_state" [styleClass]="(register.submitted
                              && factory_state?.invalid) ||
                              factory_state?.invalid &&
                              factory_state.touched ? 'error' : ''" [(ngModel)]="selectedState3" [filter]="true"
                  (onChange)="change('factory_state')" filterBy="name" [showClear]="false" [resetFilterOnHide]="true"
                  placeholder="Please Select Factory State" #factory_state="ngModel" required>
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item
                                          state-item-value" *ngIf="selectedState3">
                      <div>{{selectedState3.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown> -->
                <!-- <div *ngIf="register?.submitted ||
                                  factory_state?.touched" class="alert
                                  alert-danger">
                  <div *ngIf="factory_state?.errors?.['required']">
                    Factory State is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Factory City:</label>
                <input [class.is-invalid]="(register.submitted
                                  && factory_city?.invalid) ||
                                  factory_city?.invalid &&
                                  factory_city.touched" type="text" scriptInjection class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.factory_city" name="factory_city" placeholder="Factory City"
                  #factory_city="ngModel" >
                <!-- <div *ngIf="register.submitted ||
                                  factory_city.touched" class="alert
                                  alert-danger">
                  <div *ngIf="factory_city.errors?.['required']">
                    Factory City is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Factory Pin Code:</label>
                <input [class.is-invalid]="(register.submitted
                                  && factory_pin_code?.invalid) ||
                                  factory_pin_code?.invalid &&
                                  factory_pin_code.touched" placeholder="Factory Pin Code" type="text" scriptInjection
                  maxlength="6" pKeyFilter="num" [(ngModel)]="hwmMemberShipFormData.factory_pin_code"
                  class="form-control" name="factory_pin_code" #factory_pin_code="ngModel" >
                <!-- <div *ngIf="register.submitted ||
                                  factory_pin_code.touched" class="alert
                                  alert-danger">
                  <div *ngIf="factory_pin_code.errors?.['required']">
                    Factory Pin Code is Required.
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        
        <!-- registered address section -->
        <div class="add-listings-box">
          <div class="row">
            <div class="d-flex align-items-center">
              <h3>Correspondence
                Address</h3>
              <!-- <p
                style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 5px; height: 25px" *ngIf="this.memberShipStatus !== 'In progress' && this.memberShipStatus !== 'Pending'">
                 Chargeable</p> -->
                 <span><div class="d-flex align-items-center justify-content-between w-100"
                  style="margin-bottom: 10px; border-bottom: 1px dashed #eeeeee;">
                  <div>
                      <input type="checkbox" class="form-check-input
                  mr-2" id="mainUnit" name="check1" style="margin:5px 5px 5px 3px"
                          (change)="getData($event.target.checked)" value="">
                      <label for="mainUnit">Same as factory address</label>
                  </div>
              </div></span>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Correspondence Address: </label>
                <input scriptInjection autocomplete="off" [class.is-invalid]="(register.submitted
                    && registered_address?.invalid) ||
                    registered_address?.invalid &&
                    registered_address.touched" type="text" class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.registered_address" name="registered_address"
                  placeholder="Correspondence Address" #registered_address="ngModel">
                <!-- <div *ngIf="register.submitted ||
                    registered_address.touched" class="alert
                    alert-danger">
                  <div *ngIf="registered_address.errors?.['required']">
                    Correspondence Address is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>District:</label>
                <p-dropdown autocomplete="off" [options]="roDetails" [class.is-invalid]="(register.submitted &&
                registered_district?.invalid) ||
                registered_district?.invalid &&
                registered_district.touched" name="registered_district" #registered_district="ngModel"
                  [(ngModel)]="selectedDistrict1" [styleClass]="(register.submitted &&
                registered_district?.invalid) ||
                registered_district?.invalid &&
                registered_district.touched ? 'error' : ''" placeholder="Select District"
                  optionLabel="regional_office_name" (onChange)="change('registered_district')" [showClear]="false"
                  [resetFilterOnHide]="true" >
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item
                            state-item-value" *ngIf="selectedDistrict1">
                      <div>{{selectedDistrict1.regional_office_name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.regional_office_name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted ||
                    registered_district.touched" class="alert alert-danger">
                  <div *ngIf="registered_district.errors?.['required']">
                    District is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>State:</label>
                <p-dropdown autocomplete="off" [options]="state" optionLabel="name" name="registered_state"
                  [styleClass]="(register.submitted
                && registered_state?.invalid) ||
                registered_state?.invalid &&
                registered_state.touched ? 'error' : ''" [(ngModel)]="selectedState4" [filter]="true"
                  (onChange)="change('registered_state')" filterBy="name" [showClear]="false" [resetFilterOnHide]="true"
                  placeholder="Please Select Factory State" required #registered_state="ngModel">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item
                            state-item-value" *ngIf="selectedState4">
                      <div>{{selectedState4.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register?.submitted ||
                    registered_state?.touched" class="alert
                    alert-danger">
                  <div *ngIf="registered_state?.errors?.['required']">
                    State is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>City:</label>
                <input scriptInjection autocomplete="off" [class.is-invalid]="(register.submitted
                    && registered_city?.invalid) ||
                    registered_city?.invalid &&
                    registered_city.touched" type="text" class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.registered_city" name="registered_city" placeholder="City"
                  #registered_city="ngModel">
                <!-- <div *ngIf="register.submitted ||
                    registered_city.touched" class="alert
                    alert-danger">
                  <div *ngIf="registered_city.errors?.['required']">
                    City is Required.
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Pin Code:</label>
                <input scriptInjection autocomplete="off"
                  [class.is-invalid]="(register.submitted && registered_pin_code?.invalid) || registered_pin_code?.invalid && registered_pin_code.touched"
                  placeholder="Pin Code" type="text" maxlength="6" minlength="6" pKeyFilter="num" pattern="^[0-9]{6}$"
                  [(ngModel)]="hwmMemberShipFormData.registered_pin_code" class="form-control"
                  name="registered_pin_code" #registered_pin_code="ngModel">
                <div *ngIf="register.submitted ||
                    registered_pin_code.touched" class="alert
                    alert-danger">
                  <div *ngIf="registered_pin_code.errors?.['pattern'] || registered_pin_code.errors?.invalid">
                    Pin Code is Invalid.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company details -->
        <div class="add-listings-box">
          <h3>Company Details</h3>

          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Membership
                  Number:</label>
                <input scriptInjection name="registration_number" class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.registration_number" readonly placeholder="Membership Number"
                  #registration_number="ngModel">
              </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Capital
                  Investment:</label>
                <p-inputNumber inputId="locale-indian" mode="decimal" locale="en-IN" prefix="₹" maxlength="12"
                  placeholder="Capital Investment" required [class.is-invalid]="(register.submitted
                                  &&
                                  capital_investment?.invalid) ||
                                  capital_investment?.invalid &&
                                  capital_investment.touched" [styleClass]="(register.submitted
                                  && capital_investment?.invalid) ||
                                  capital_investment?.invalid &&
                                  capital_investment.touched ? 'error' : ''"
                  [(ngModel)]="hwmMemberShipFormData.capital_investment" name="capital_investment"
                  #capital_investment="ngModel">
                </p-inputNumber>
                <div *ngIf="register.submitted ||
                                  capital_investment.touched" class="alert
                                  alert-danger">
                  <div *ngIf="capital_investment.errors?.['required']">
                    Capital Investment is Required.
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Website:</label>
                <input type="text" scriptInjection maxlength="100"
                  pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                  class="form-control" placeholder="Website" [(ngModel)]="hwmMemberShipFormData.website" name="website"
                  #website="ngModel">
                <div class="alert
              alert-danger" *ngIf="website.errors?.['pattern']">
                  Please enter valid Website
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>E-Mail:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  email?.invalid) || email?.invalid &&
                                  email.touched" type="email" class="form-control" placeholder="E-mail" email
                  maxlength="50"
                  pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                  [(ngModel)]="hwmMemberShipFormData.email" name="email" #email="ngModel">
                <div *ngIf="register.submitted || email.touched" class="alert alert-danger">
                  <div *ngIf="email.errors?.email">
                    E-Mail is not valid.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Nature of Business:</label>
                <!-- <input [class.is-invalid]="(register.submitted &&
                                  nature_of_business?.invalid) ||
                                  nature_of_business?.invalid &&
                                  nature_of_business.touched" type="text" scriptInjection
                                  placeholder="Nature of Business"
                                  [(ngModel)]="hwmMemberShipFormData.nature_of_business"
                                  name="nature_of_business"
                                  class="form-control"
                                  #nature_of_business="ngModel" required> -->
                <p-dropdown [options]="natureofBusinessList"
                  [class.is-invalid]="(register.submitted && nature_of_business?.invalid) || nature_of_business?.invalid && nature_of_business.touched"
                  [(ngModel)]="hwmMemberShipFormData.nature_of_business" name="nature_of_business"
                  #nature_of_business="ngModel"
                  [styleClass]="(register.submitted && nature_of_business?.invalid) || nature_of_business?.invalid && nature_of_business.touched ? 'error' : ''"
                  placeholder="Select
                              Nature of Business" optionLabel="name" optionValue="id" [showClear]="false"
                  [resetFilterOnHide]="true"></p-dropdown>

                <!-- <select [class.is-invalid]="(register.submitted &&
                                  nature_of_business?.invalid) ||
                                  nature_of_business?.invalid &&
                                  nature_of_business.touched" [(ngModel)]="hwmMemberShipFormData.nature_of_business"
                                  name="nature_of_business" #nature_of_business="ngModel" id="type"
                                  data-live-search="true" required>
                                  <option value="undefined">Please Select Nature
                                      of Business</option>
                                  <ng-container *ngFor="let item of
                                      natureofBusinessList">
                                      <option [ngValue]="item.id">{{item.name}}</option>
                                  </ng-container>
                              </select> -->
                <!-- <div *ngIf="register.submitted ||
                                  nature_of_business.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nature_of_business.errors?.['required']">
                    Nature of Business is Required.
                  </div>
                </div> -->
              </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>About:</label>
                              <input [class.is-invalid]="(register.submitted &&
                                  about?.invalid) || about?.invalid &&
                                  about.touched" type="text" scriptInjection class="form-control"
                                  placeholder="About" maxlength="200"
                                  [(ngModel)]="hwmMemberShipFormData.about"
                                  name="about" #about="ngModel" required>
                              <div *ngIf="register.submitted || about.touched"
                                  class="alert alert-danger">
                                  <div *ngIf="about.errors?.['required']">
                                      Field is Required.
                                  </div>
  
                              </div>
                          </div>
                      </div> -->
            <!-- <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <ng-container *ngIf="logoPreviewState">
                  <label for="file">Logo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Logo Preview"
                      (click)="onImageClick(logo, 'image', logoName, 'company')" tooltipPosition="bottom"
                      *ngIf="oldLogoState">
                      {{logo | slice:0:10}}
                      {{hwmMemberShipFormData.logo}}
                    </span>
                    <span pTooltip="Click to Show Logo Preview"
                      (click)="onImageClick(logo, 'image', logoName, 'company')" tooltipPosition="bottom"
                      *ngIf="!oldLogoState">
                      {{logoName | slice:0:25}}
                      {{hwmMemberShipFormData.logo}}
                    </span>
                    <span (click)="logoPreview('company')" style="color: red" pTooltip="Remove Preview and Change Logo"
                      tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.logoBoolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!logoPreviewState ">
                  <label for="file">Upload Logo:</label>
                  <input type="file" name="logo" (change)="fileUpload($event,'logo')"
                    [(ngModel)]="hwmMemberShipFormData.logo" id="logofile" class="inputfile form-control px-2 py-1 "
                    #logo="ngModel" />
                  <div class="alert alert-danger" *ngIf="!logoState">
                    Logo Size Should Not Larger Then 2MB
                  </div>
                </ng-container>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>Sp. Classfication:</label>
                              <select name="member_classification_id" [class.is-invalid]="(register.submitted &&
                                  member_classification_id?.invalid) ||
                                  member_classification_id?.invalid &&
                                  member_classification_id.touched"
                                  [(ngModel)]="hwmMemberShipFormData.member_classification_id" id="type"
                                  data-live-search="true" #member_classification_id="ngModel" required>
                                  <option value="undefined" disabled>Please Select
                                      Sp. Classfication</option>
                                  <option *ngFor="let item of
                                      member_classification.data" [ngValue]="item.id">
                                      {{item.name}}</option>
                              </select>
                              <div *ngIf="register.submitted ||
                                  member_classification_id.touched" class="alert
                                  alert-danger">
                                  <div *ngIf="member_classification_id.errors?.['required']">
                                      Sp. Classfication is Required.
                                  </div>
                              </div>
                          </div>
                      </div>
  
                      <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label>Sp. Category:</label>
                              <select name="member_category_id" [(ngModel)]="hwmMemberShipFormData.member_category_id"
                                  [class.is-invalid]="(register.submitted &&
                                  member_category_id?.invalid) ||
                                  member_category_id?.invalid &&
                                  member_category_id.touched" id="type" data-live-search="true"
                                  #member_category_id="ngModel" required>
                                  <option value="undefined" disabled>Please Select
                                      Sp.
                                      Category</option>
                                  <option *ngFor="let item of categoryData.data" [ngValue]="item.id">{{item.name}}
                                  </option>
                              </select>
                              <div *ngIf="register.submitted ||
                                  member_category_id.touched" class="alert
                                  alert-danger">
                                  <div *ngIf="member_category_id.errors?.['required']">Expected Date for Disposal of HW
                                      Sp. Category is Required.Expected Date for Disposal of HW
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                          <label>Member Since:</label>
                          <p-calendar name="member_since" showClear="true"
                          [(ngModel)]="hwmMemberShipFormData.member_since" placeholder="DD/MM/YYYY"
                          dateFormat="dd/mm/yy" [showIcon]="true" #member_since="ngModel"></p-calendar>
                        </div>
                      </div>
          </div>
        </div>
        <!-- nominee one details -->
        <div class="add-listings-box">
          <h3>Details Of Occupier/Owner</h3>
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Occupier/Owner Name:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  name1?.invalid) || name1?.invalid &&
                                  name1.touched" type="text" scriptInjection class="form-control"
                  placeholder="Occupier/Owner Name" [(ngModel)]="hwmMemberShipFormData.name1" name="name1"
                  #name1="ngModel">
                <!-- <div *ngIf="register.submitted || name1.touched" class="alert alert-danger">
                  <div *ngIf="name1.errors?.['required']">
                    Occupier/Owner Name is Required.
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Designation:</label>
                <input type="text" scriptInjection class="form-control" placeholder="Designation"
                  [(ngModel)]="hwmMemberShipFormData.designation1" name="designation1" maxlength="100"
                  #designation1="ngModel">
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">PAN:</label>
                <input [(ngModel)]="hwmMemberShipFormData.pan_number1" oninput="this.value= this.value.toUpperCase()"
                  pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" maxlength="10" type="text" scriptInjection class="form-control"
                  placeholder="PAN" name="pan_number1" #pan_number1="ngModel">
                <div class="alert
              alert-danger" *ngIf="pan_number1.errors?.['pattern']">
                  Please enter valid PAN
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                              <label for="">Date of Birth:</label>
                              <p-calendar name="date_of_birth1" showClear="true"
                                  [(ngModel)]="hwmMemberShipFormData.date_of_birth1"
                                  [readonlyInput]="true"
                                  [class.is-invalid]="(register.submitted &&
                                  date_of_birth1?.invalid) ||
                                  date_of_birth1?.invalid &&
                                  date_of_birth1.touched"
                                  placeholder="DD/MM/YYYY"
                                  [maxDate]="today" [disabledDates]="invalidDates"
                                  [showIcon]="true" #date_of_birth1="ngModel"
                                  required></p-calendar>
                              <div class="invalid-feedback">
                                  Date of Birth is Required.
                              </div>
                          </div>
                      </div> -->
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  email1?.invalid) || email1?.invalid &&
                                  email1.touched" type="email" maxlength="100"
                  pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                  class="form-control" placeholder="E-Mail" [(ngModel)]="hwmMemberShipFormData.email1" email
                  name="email1" #email1="ngModel" >
                <div *ngIf="register.submitted || email1.touched" class="alert alert-danger">
                  <div *ngIf="email1.errors?.email">
                    E-Mail is not valid.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Office Phone:</label>
                <input type="text" scriptInjection name="office_phone1" class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.office_phone1" pattern="^((\\+91-?)|0)?[0-9]{10}$" maxlength="10"
                  pKeyFilter="num" placeholder="Office Phone" #office_phone1="ngModel">
                <div *ngIf="office_phone1.errors?.['pattern']" class="alert alert-danger">
                  Please enter valid Office Phone
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  mobile_no1?.invalid) || mobile_no1?.invalid &&
                                  mobile_no1.touched" type="text" scriptInjection placeholder="Mobile Number"
                  class="form-control" [(ngModel)]="hwmMemberShipFormData.mobile_no1"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$" maxlength="10" name="mobile_no1" #mobile_no1="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  mobile_no1.touched" class="alert alert-danger">
                  <div *ngIf="mobile_no1.errors?.['required']">
                    Mobile Number is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Address:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  nominee1_address?.invalid) ||
                                  nominee1_address?.invalid &&
                                  nominee1_address.touched" type="text" scriptInjection class="form-control"
                  name="nominee1_address" [(ngModel)]="hwmMemberShipFormData.nominee1_address" placeholder="Address"
                  #nominee1_address="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  nominee1_address.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nominee1_address.errors?.['required']">
                    Address is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>City:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  nominee1_city?.invalid) ||
                                  nominee1_city?.invalid && nominee1_city.touched" type="text" scriptInjection
                  name="nominee1_city" placeholder="City" [(ngModel)]="hwmMemberShipFormData.nominee1_city"
                  class="form-control" #nominee1_city="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  nominee1_city.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nominee1_city.errors?.['required']">
                    City is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>State:</label>
                <!-- <input [class.is-invalid]="(register.submitted &&
                                  nominee1_state?.invalid) ||
                                  nominee1_state?.invalid &&
                                  nominee1_state.touched" placeholder="State"
                                  type="text" scriptInjection class="form-control"
                                  [(ngModel)]="hwmMemberShipFormData.nominee1_state"
                                  name="nominee1_state"
                                  #nominee1_state="ngModel" required> -->
                <p-dropdown [options]="state" optionLabel="name" name="nominee1_state" [styleClass]="(register.submitted
                              && nominee1_state?.invalid) ||
                              nominee1_state?.invalid &&
                              nominee1_state.touched ? 'error' : ''" [(ngModel)]="selectedState1" [filter]="true"
                  (onChange)="change('nominee1_state')" filterBy="name" [showClear]="false" [resetFilterOnHide]="true"
                  placeholder="Please Select State">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="selectedState1">
                      <div>{{selectedState1.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register?.submitted ||
                                  nominee1_state?.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nominee1_state?.errors?.['required']">
                    State is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Pin Code:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  nominee1_pin_code?.invalid) ||
                                  nominee1_pin_code?.invalid &&
                                  nominee1_pin_code.touched" placeholder="Pin
                                  Code" type="text" scriptInjection maxlength="6" pKeyFilter="num"
                  [(ngModel)]="hwmMemberShipFormData.nominee1_pin_code" class="form-control" name="nominee1_pin_code"
                  #nominee1_pin_code="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  nominee1_pin_code.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nominee1_pin_code.errors?.['required']">
                    Pin Code is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Department:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  department1?.invalid) || department1?.invalid &&
                                  department1.touched" type="text" scriptInjection class="form-control"
                  placeholder="Department" [(ngModel)]="hwmMemberShipFormData.department1" name="department1"
                  #department1="ngModel">
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <ng-container *ngIf="photo1">
                  <label for="file">Profile Photo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Photo Preview"
                      (click)="onImageClick(photoURL1, 'image', logoName, 'nominee1')" tooltipPosition="bottom"
                      *ngIf="oldNominee1State">{{hwmMemberShipFormData.profile1 | slice:0:25}}</span>
                    <span pTooltip="Click to Show Photo Preview"
                      (click)="onImageClick(photoURL1, 'image', logoName, 'nominee1')" tooltipPosition="bottom"
                      *ngIf="!oldNominee1State">{{profile1Name | slice:0:25}}</span>
                    <span (click)="logoPreview('photo1')" style="color: red" pTooltip="Remove Preview and Change Logo"
                      tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.nominee1Boolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!photo1">
                  <label for="file">Profile Photo:</label>
                  <input [class.is-invalid]="(register.submitted &&
                                      profile1?.invalid) || profile1?.invalid &&
                                      profile1.touched" type="file" accept="image/x-png,image/gif,image/jpeg"
                    name="profile1" (change)="fileUpload($event,'profile1')" id="nomineephotofile"
                    [(ngModel)]="hwmMemberShipFormData.profile1" class="inputfile form-control px-2 py-1"
                    #profile1="ngModel" />
                  <!-- <div *ngIf="register.submitted || profile1.touched" class="alert alert-danger">
                  <div *ngIf="profile1.errors?.['required']">
                    Photo is Required.
                  </div>
                </div> -->
                  <div class="alert alert-danger" *ngIf="!profile1State">
                    Image Size Should Not Larger Then 2MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication:</label>
                <div class="form-check" *ngFor="let item of
                                  communication; let i= index">
                  <input type="checkbox" [name]="item" (change)="onChange($event,item,'pre1')" class="form-check-input"
                    [id]="item+i"  [checked]="pre1?.includes(item)">
                  <label class="form-check-label" [for]="item+i">{{item}}</label>
                </div>
                <!-- <input
                                  [class.is-invalid]="(register.submitted &&
                                  preferred_communication1?.invalid) ||
                                  preferred_communication1?.invalid &&
                                  preferred_communication1.touched"
                                  type="text" scriptInjection class="form-control"
                                  placeholder="Preferred Communication"
                                  [(ngModel)]="hwmMemberShipFormData.preferred_communication1"
                                  name="preferred_communication1"
                                  #preferred_communication1="ngModel" required> -->
                <div *ngIf="register.submitted &&
                                  preferred1Checkbox?.length == 0" class="alert
                                  alert-danger">
                  Preferred Communication is Required.
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Area of Interest:</label>
                <div class="areacheckbox">
                  <p-multiSelect [options]="interest" defaultLabel="Select a Area of Interest"
                    [(ngModel)]="hwmMemberShipFormData.area_of_interest1" [ngModelOptions]="{standalone: true}"
                    [styleClass]="(register.submitted &&
                    area_of_interest1?.invalid) || area_of_interest1?.invalid &&
                    area_of_interest1.touched ? 'error' : ''" [resetFilterOnHide]="true" optionLabel="interest_area"
                    display="chip" name="area_of_interest1" #area_of_interest1="ngModel"></p-multiSelect>
                  <ul style="max-height: 50px;
                    overflow-y: auto;" class="m-0" *ngIf="hwmMemberShipFormData.area_of_interest1?.length > 0">
                    <li *ngFor="let item of hwmMemberShipFormData.area_of_interest1">{{item?.interest_area}}</li>
                  </ul>
                </div>
                <!-- <div *ngIf="register.submitted || area_of_interest1.touched" class="alert alert-danger">
                  <div *ngIf="area_of_interest1.errors?.['required']">
                    Area of Interest is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Biodata:</label>
                <textarea type="text" scriptInjection placeholder="Biodata" name="biodata1"
                  [(ngModel)]="hwmMemberShipFormData.biodata1" class="form-control" #biodata1="ngModel">
              </textarea>
              </div>
            </div> -->
          </div>
        </div>
        <!-- second nominee details -->
        <div class="add-listings-box">
          <h3>Details Of Contact Person </h3>
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Contact Person Name:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  name2?.invalid) || name2?.invalid &&
                                  name2.touched" type="text" scriptInjection class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.name2" placeholder="Contact Person Name" name="name2"
                  #name2="ngModel" >
                <!-- <div *ngIf="register.submitted || name2.touched" class="alert alert-danger">
                  <div *ngIf="name2.errors?.['required']">
                    Contact Person Name is Required.
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Designation:</label>
                <input type="text" scriptInjection class="form-control" [(ngModel)]="hwmMemberShipFormData.designation2"
                  placeholder="Designation" name="designation2" #designation2="ngModel">
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">PAN:</label>
                <input oninput="this.value=
                                  this.value.toUpperCase()" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" maxlength="10"
                  type="text" scriptInjection class="form-control" placeholder="PAN"
                  [(ngModel)]="hwmMemberShipFormData.pan_number2" name="pan_number2" #pan_number2="ngModel">
                <!-- <div *ngIf="register.submitted ||
                                  pan_number2.touched" class="alert
                                  alert-danger">
                <div *ngIf="pan_number2.errors?.['required']">
                  PAN is Required.
                </div>
              </div> -->
                <div *ngIf="pan_number2.errors?.['pattern']" class="alert
                alert-danger">
                  Please enter valid PAN
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  email2?.invalid) || email2?.invalid &&
                                  email2.touched" type="email" class="form-control"
                  [(ngModel)]="hwmMemberShipFormData.email2" email placeholder="E-Mail" name="email2" #email2="ngModel"
                  >
                <div *ngIf="register.submitted || email2.touched" class="alert alert-danger">
                  <!-- <div *ngIf="email2.errors?.['required']">
                    E-Mail is Required.
                  </div> -->
                  <div *ngIf="email2.errors?.email">
                    E-Mail is not valid.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  mobile_no2?.invalid) || mobile_no2?.invalid &&
                                  mobile_no2.touched" type="text" scriptInjection placeholder="Mobile Number"
                  class="form-control" [(ngModel)]="hwmMemberShipFormData.mobile_no2" maxlength="10" pKeyFilter="num"
                  name="mobile_no2" #mobile_no2="ngModel">
                <div *ngIf="register.submitted ||
                                  mobile_no2.touched" class="alert alert-danger">
                  <!-- <div *ngIf="mobile_no2.errors?.['required']">
                    Mobile Number is Required.
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Address:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  nominee2_address?.invalid) ||
                                  nominee2_address?.invalid &&
                                  nominee2_address.touched" type="text" scriptInjection class="form-control"
                  name="nominee2_address" [(ngModel)]="hwmMemberShipFormData.nominee2_address" placeholder="Address"
                  maxlength="100" #nominee2_address="ngModel" >
                <!-- <div *ngIf="register.submitted ||
                                  nominee2_address.touched" class="alert
                                  alert-danger">
                  <div *ngIf="nominee2_address.errors?.['required']">
                    Address is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Department:</label>
                <input type="text" scriptInjection class="form-control" [(ngModel)]="hwmMemberShipFormData.department2"
                  placeholder="Department" name="department2" #department2="ngModel">
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">


                <ng-container *ngIf="photo2">
                  <label for="file">Profile Photo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Preview"
                      (click)="onImageClick(photoURL2, 'image', logoName, 'nominee2')" tooltipPosition="bottom"
                      *ngIf="oldNominee2State">{{hwmMemberShipFormData.profile2 | slice:0:25}}</span>
                    <span pTooltip="Click to Show Photo Preview"
                      (click)="onImageClick(photoURL2, 'image', logoName, 'nominee2')" tooltipPosition="bottom"
                      *ngIf="!oldNominee2State">{{profile2Name | slice:0:25}}</span>
                    <span (click)="logoPreview('photo2')" style="color: red" pTooltip="Remove Preview and Change Photo"
                      tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.nominee2Boolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!photo2">
                  <label for="file">Profile Photo:</label>
                  <input name="profile2" type="file" [(ngModel)]="hwmMemberShipFormData.profile2"
                    (change)="fileUpload($event,'profile2')" accept="image/x-png,image/gif,image/jpeg" id="photofile"
                    class="inputfile form-control px-2 py-1" #profile2="ngModel" />
                  <!-- <div *ngIf="register.submitted || profile2.touched" class="alert alert-danger">
                  <div *ngIf="profile2.errors?.['required']">
                    Photo is Required.
                  </div>
                </div> -->
                  <div class="alert alert-danger" *ngIf="!profile2State">
                    Image Size Should Not Larger Then 2MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication:</label>
                <div class="form-check" *ngFor="let data of
                                  communication; let i= index">
                  <input type="checkbox" (change)="onChange($event,data,'pre2')" class="form-check-input" [id]="data+i"
                    [name]="data" [checked]="pre2?.includes(data)">
                  <label class="form-check-label" [for]="data+i" for="create-an-account">{{data}}</label>
                </div>
                <!-- <div *ngIf="register.submitted &&
                                  preferred2Checkbox?.length == 0" class="alert
                                  alert-danger">
                  Preferred Communication is Required.
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Area of Interest:</label>
                <div class="interestcheck">
                  <p-multiSelect [options]="interest" defaultLabel="Select a Area of Interest"
                    [(ngModel)]="hwmMemberShipFormData.area_of_interest2" [ngModelOptions]="{standalone: true}"
                    [styleClass]="(register.submitted &&
                    area_of_interest2?.invalid) || area_of_interest2?.invalid &&
                    area_of_interest2.touched ? 'error' : ''" optionLabel="interest_area" display="chip"
                    [resetFilterOnHide]="true" name="area_of_interest2" #area_of_interest2="ngModel" >
                  </p-multiSelect>
                  <ul style="max-height: 50px;
                      overflow-y: auto;" class="m-0" *ngIf="hwmMemberShipFormData.area_of_interest2?.length > 0">
                    <li *ngFor="let item of hwmMemberShipFormData.area_of_interest2">{{item?.interest_area}}</li>
                  </ul>
                </div>
                <!-- <div *ngIf="register.submitted || area_of_interest2.touched" class="alert alert-danger">
                  <div *ngIf="area_of_interest2.errors?.['required']">
                    Area of Interest is Required.
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="add-listings-box">
          <div class="head_section">
            <h3>Details of Manufactured Products (Annually)</h3>
          </div>
          <ng-container>
            <div class="row">
              <ng-container *ngFor="let item of
                              hwmMemberShipFormData.product_details ; index as
                              index">
                <div class="col-lg-5">
                  <div class="form-group">
                    <label>Product Description:</label>
                    <input type="text" scriptInjection class="form-control" [(ngModel)]="item.product_description"
                      placeholder="Product Description" #product_description="ngModel" [class.is-invalid]="(register.submitted
                                          && product_description?.invalid) ||
                                          product_description?.invalid &&
                                          product_description.touched" name="product_description{{index}}" >
                    <!-- <div *ngIf="register.submitted ||
                                          product_description.touched" class="alert alert-danger">
                      <div *ngIf="product_description.errors?.['required']">
                        Product Description is Required.
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group">
                    <label>Product Quantity (Per Annum):</label>
                    <!-- <input type="text" scriptInjection class="form-control"
                                          [(ngModel)]="item.product_quantity"
                                          placeholder="Product Quantity (Per
                                          Annum)"
                                          required
                                          name="product_quantity{{index}}"
                                          [class.is-invalid]="(register.submitted
                                          && product_quantity?.invalid) ||
                                          product_quantity?.invalid
                                          &&
                                          product_quantity.touched"
                                          #product_quantity="ngModel"> -->
                    <input class="form-control" inputId="integeronly" type="text" scriptInjection maxlength="20"
                      [(ngModel)]="item.product_quantity" placeholder="Product Quantity (Per Annum)"
                      name="product_quantity{{index}}" [class.is-invalid]="(register.submitted
                                          && product_quantity?.invalid) ||
                                          product_quantity?.invalid
                                          &&
                                          product_quantity.touched" #product_quantity="ngModel">
                    <!-- <div *ngIf="register.submitted ||
                                          product_quantity.touched" class="alert
                                          alert-danger">
                      <div *ngIf="product_quantity.errors?.['required']">
                        Product Quantity is Required.
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-2 align-items-center" style="margin: auto;">
                  <div class="action_btn d-flex ">
                    <div>
                      <p-button type="button" class="mr-2" icon="bx bx-plus" (click)="addProductDetailRow('product')"
                        iconPos="right"></p-button>
                    </div>
                    <div *ngIf="index> 0" class="remove">
                      <p-button type="button" class="ml-2" icon="bx
                                              bx-minus" (click)="removeProductDetailRow(index,'product')"
                        iconPos="right"></p-button>
                      <!-- <p class="btn"
                                              (click)="removeProductDetailRow(index,'product')">Remove<i
                                                  class="fa fa-minus mx-1"></i></p> -->
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </ng-container>
        </div>
        <div class="add-listings-box">
          <div class="head_section d-flex align-items-center">
            <h3>Types of Hazardous Waste (Estimated Annual Quantity)</h3>
            <!-- <p
              style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 5px; height: 25px" *ngIf="this.memberShipStatus !== 'In progress' && this.memberShipStatus !== 'Pending'">
              Chargeable</p> -->
          </div>
          <div class="row" *ngFor="let item of
                      hwmMemberShipFormData.waste_details;index as index">
            <div class="col-lg-3">
              <div class="form-group">
                <label>Schedule No:</label>
                <p-dropdown autocomplete="off" [options]="scheduleNoArray" optionLabel="schedule_no"
                  optionValue="schedule_no" [(ngModel)]="item.schedule_no" placeholder="Schedule No"
                  [styleClass]="(register.submitted && schedule_no?.invalid) || schedule_no?.invalid && schedule_no.touched ? 'error' : ''"
                  (onChange)="getScheduleList($event, 'schNo', index)" name="schedule_no{{index}}"
                  #schedule_no="ngModel">
                </p-dropdown>
                <!-- <div *ngIf="register.submitted || schedule_no.touched" class="alert alert-danger">
                  <div *ngIf="schedule_no.errors?.['required']">
                    Schedule No is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>Process No:</label>
                <ng-container *ngIf="scheduleListProcess[index]?.showProcessInput">
                  <input scriptInjection autocomplete="off" type="text" class="form-control"
                    [(ngModel)]="item.waste_process_no" placeholder="Process No" name="waste_process_no{{index}}"
                    #waste_process_no="ngModel" >
                  <!-- <div *ngIf="register.submitted || waste_process_no.touched" class="alert alert-danger">
                    <div *ngIf="waste_process_no.errors?.['required']">
                      Process No is Required.
                    </div>
                  </div> -->
                </ng-container>
                <ng-container *ngIf="!scheduleListProcess[index]?.showProcessInput">
                  <p-dropdown autocomplete="off" [options]="scheduleArray" optionLabel="process_no"
                    optionValue="process_no" [(ngModel)]="item.waste_process_no" placeholder="Process No"
                    [styleClass]="(register.submitted && waste_process_no?.invalid) || waste_process_no?.invalid && waste_process_no.touched ? 'error' : ''"
                    (onChange)="getScheduleList($event, 'process-no', index)" name="waste_process_no{{index}}"
                    #waste_process_no="ngModel" >
                  </p-dropdown>
                  <!-- <div *ngIf="register.submitted || waste_process_no.touched" class="alert alert-danger">
                    <div *ngIf="waste_process_no.errors?.['required']">
                      Process No is Required.
                    </div>
                  </div> -->
                </ng-container>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>Code:</label>
                <ng-container *ngIf="scheduleListProcess[index]?.showCodeInput">
                  <input scriptInjection autocomplete="off" type="text" class="form-control"
                    [(ngModel)]="item.waste_sub_process_no" placeholder="Code" name="waste_sub_process_no{{index}}"
                    #waste_sub_process_no="ngModel" >
                  <!-- <div *ngIf="register.submitted || waste_sub_process_no.touched" class="alert alert-danger">
                    <div *ngIf="waste_sub_process_no.errors?.['required']">
                      Code is Required.
                    </div>
                  </div> -->
                </ng-container>
                <ng-container *ngIf="!scheduleListProcess[index]?.showCodeInput">
                  <p-dropdown autocomplete="off" [options]="newScheduleList[index]?.value" optionLabel="sub_process_no"
                    optionValue="sub_process_no" [(ngModel)]="item.waste_sub_process_no"
                    (onChange)="getScheduleList($event, 'code', index)" placeholder="Code"
                    [styleClass]="(register.submitted && waste_sub_process_no?.invalid) || waste_sub_process_no?.invalid && waste_sub_process_no.touched ? 'error' : ''"
                    name="waste_sub_process_no{{index}}" #waste_sub_process_no="ngModel" >
                  </p-dropdown>
                  <!-- <div *ngIf="register.submitted || waste_sub_process_no.touched" class="alert alert-danger">
                    <div *ngIf="waste_sub_process_no.errors?.['required']">
                      Code is Required.
                    </div>
                  </div> -->
                </ng-container>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>Waste Type:</label>
                <!-- {{item.waste_type}} {{processName}} -->
                <input scriptInjection autocomplete="off" type="text" class="form-control" [(ngModel)]="item.waste_type"
                  placeholder="Waste Type" name="waste_type{{index}}" #waste_type="ngModel"
                  [readonly]="item.schedule_no == '1'" >
                <!-- <div *ngIf="item.schedule_no == '1'">
                                <div *ngIf="register.submitted ||
                                    waste_type.touched" class="alert alert-danger">
                                    <div *ngIf="waste_type.errors?.['required']">
                                        Waste Type is Required.
                                    </div>
                                </div>
                            </div> -->
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>Waste Quantity (Per Annum):</label>
                <input class="form-control" [(ngModel)]="item.waste_quantity" type="text"
                  placeholder="Waste Quantity (Per Annum)" maxlength="20" [class.is-invalid]="(register.submitted
                        &&
                        waste_quantity?.invalid) ||
                        waste_quantity?.invalid
                        &&
                        waste_quantity.touched" name="waste_quantity{{index}}" #waste_quantity="ngModel" >
                <!-- <div *ngIf="register.submitted ||
                        waste_quantity.touched" class="alert
                        alert-danger">
                  <div *ngIf="waste_quantity.errors?.['required']">
                    Waste Quantity is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-2 align-items-center" style="margin: auto;">
              <div class="action_btn d-flex ">
                <div>
                  <p-button type="button" class="mr-2" icon="bx
                                      bx-plus" (click)="addProductDetailRow('waste')" iconPos="right"></p-button>
                  <!-- <p class="btn" (click)="addProductDetailRow('waste')">Add<i
                                          class="fa fa-plus mx-1"></i></p> -->
                </div>
                <div *ngIf="index>0" class="remove">
                  <p-button type="button" class="ml-2" icon="bx
                                      bx-minus" (click)="removeProductDetailRow(index,'waste')" iconPos="right">
                  </p-button>
                  <!-- <p class="btn"
                                      (click)="removeProductDetailRow(index,'waste')">Remove<i
                                          class="fa fa-minus mx-1"></i></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-listings-box">
          <h3>Extra Information</h3>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Estimated or Actual Investment:</label>
                <p-inputNumber scriptInjection [(ngModel)]="hwmMemberShipFormData.investment"
                  placeholder="Estimated or Actual Investment" name="investment" #investment="ngModel"
                  [class.is-invalid]="(register.submitted
                                &&
                                investment?.invalid) ||
                                investment?.invalid
                                &&
                                investment.touched" > </p-inputNumber>
                <!-- <input type="text" scriptInjection class="form-control" [(ngModel)]="hwmMemberShipFormData.investment"
                placeholder="Estimated or Actual Investment" name="investment" #investment="ngModel"
                [class.is-invalid]="(register.submitted
                                  &&
                                  investment?.invalid) ||
                                  investment?.invalid
                                  &&
                                  investment.touched" required> -->
                <!-- <div *ngIf="register.submitted ||
                                  investment.touched" class="alert alert-danger">
                  <div *ngIf="investment.errors?.['required']">
                    Estimated or Actual Investment is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Expected Date for Disposal of HW:</label>
                <p-calendar name="hw_disposal_expected_date" showClear="true"
                  [(ngModel)]="hwmMemberShipFormData.hw_disposal_expected_date" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" [showIcon]="true" #hw_disposal_expected_date="ngModel" [class.is-invalid]="(register.submitted &&
                                  hw_disposal_expected_date?.invalid) ||
                                  hw_disposal_expected_date?.invalid &&
                                  hw_disposal_expected_date.touched" ></p-calendar>
                <!-- <div class="invalid-feedback">
                  Expected Date for Disposal of HW is Required.
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>The Production Has Started Yet:</label>
                <div class="form-check">
                  <input autocomplete="off" class="form-check-input"
                    [(ngModel)]="hwmMemberShipFormData.productionStarted" value="1" type="radio"
                    name="productionStarted" id="productionStartedYes" (change)="getValue($event, 'production')"
                    #productionStarted="ngModel" >
                  <label class="form-check-label" for="productionStartedYes">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input autocomplete="off" class="form-check-input"
                    [(ngModel)]="hwmMemberShipFormData.productionStarted" type="radio" value="0"
                    name="productionStarted" id="productionStartedNo" #productionStarted="ngModel"
                    (change)="getValue($event, 'production')" >
                  <label class="form-check-label" for="productionStartedNo">
                    No
                  </label>
                </div>
                <!-- <div *ngIf="register.submitted ||
                productionStarted.touched" class="alert
                alert-danger">
                  <div *ngIf="productionStarted.errors?.['required']">
                    The Production Has Not Started Yet is
                    required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6" *ngIf="showCommencementDate">
              <div class="form-group">
                <label>Production Commencement Date:</label>
                <p-calendar name="production_commencement_date" showClear="true"
                  [(ngModel)]="hwmMemberShipFormData.production_commencement_date" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" [showIcon]="true" 
                  [class.is-invalid]="(register.submitted && production_commencement_date?.invalid) || production_commencement_date?.invalid && production_commencement_date.touched"
                  [styleClass]="(register.submitted && production_commencement_date?.invalid) || production_commencement_date?.invalid && production_commencement_date.touched ? 'inputError' : ''"
                  #production_commencement_date="ngModel" >
                </p-calendar>
                <!-- <div class="invalid-feedback">
                  Production Commencement Date is Required.
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Incineration Facility Required:</label>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.incineration_facility_required"
                    value="yes" type="radio" name="incineration_facility_required" id="flexRadioDefault1"
                    [class.is-invalid]="(register.submitted
                                      &&
                                      incineration_facility_required?.invalid) ||
                                      incineration_facility_required?.invalid
                                      &&
                                      incineration_facility_required.touched" #incineration_facility_required="ngModel"
                    >
                  <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.incineration_facility_required"
                    type="radio" value="no" name="incineration_facility_required" id="flexRadioDefault2"
                    #incineration_facility_required="ngModel" [class.is-invalid]="(register.submitted
                                      &&
                                      incineration_facility_required?.invalid) ||
                                      incineration_facility_required?.invalid
                                      &&
                                      incineration_facility_required.touched"  checked>
                  <label class="form-check-label" for="flexRadioDefault2">
                    No
                  </label>
                </div>
                <!-- <div *ngIf="register.submitted ||
                                  incineration_facility_required.touched" class="alert alert-danger">
                  <div *ngIf="incineration_facility_required.errors?.['required']">
                    Incineration Facility Required is
                    required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">


                <ng-container *ngIf="gstCertificateCheck">
                  <label for="file">GST Certificate:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Certificate Preview"
                      (click)="downLoadPdf(gstCertificateBaseURL + hwmMemberShipFormData.gst_certificate)"
                      tooltipPosition="bottom" *ngIf="oldGstState">{{hwmMemberShipFormData.gst_certificate |
                      slice:0:25}}</span>
                    <span pTooltip="Click to Show Certificate Preview"
                      (click)="onImageClick(gstCertificateURL, 'pdf', gstDocName)" tooltipPosition="bottom"
                      *ngIf="!oldGstState">{{gstDocName | slice:0:25}}</span>
                    <span (click)="logoPreview('gstCertificate')" style="color: red"
                      pTooltip="Remove Preview and Change Certificate" tooltipPosition="bottom">x</span>
                  </span>
                </ng-container>

                <ng-container *ngIf="!gstCertificateCheck">
                  <label>Upload GST Certificate:</label>
                  <input type="file" class="form-control px-2 py-1" [class.is-invalid]="(register.submitted
                                      &&
                                      gst_certificate?.invalid) ||
                                      gst_certificate?.invalid &&
                                      gst_certificate.touched" [(ngModel)]="hwmMemberShipFormData.gst_certificate"
                    (change)="fileUpload($event,'gst_certificate')" placeholder="Choose Document" name="gst_certificate"
                     #gst_certificate="ngModel">
                  <!-- <div class="invalid-feedback">
                    GST Certificate
                    is
                    required.
                  </div> -->
                  <div class="alert alert-danger" *ngIf="!gstState">
                    Invoice Document Size Should Not Larger Then
                    10MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Turnover:</label>
                <p-dropdown [options]="turnoverData.data" [class.is-invalid]="(register.submitted &&
                              turnover?.invalid) ||
                              turnover?.invalid &&
                              turnover.touched" name="turnover" #turnover="ngModel"
                  [(ngModel)]="hwmMemberShipFormData.turnover_id" [styleClass]="(register.submitted &&
                              turnover?.invalid) ||
                              turnover?.invalid &&
                              turnover.touched ? 'error' : ''" placeholder="Select Turnover" optionLabel="component"
                  optionValue="id" [showClear]="false" [resetFilterOnHide]="true"
                  (onChange)="getValue($event, 'certificate')"></p-dropdown>

                <!-- <select name="turnover" [(ngModel)]="hwmMemberShipFormData.turnover" id="type"
                                  (change)="getValue($event)" data-live-search="true" #turnover="ngModel"
                                  [class.is-invalid]="(register.submitted
                                  &&
                                  turnover?.invalid) ||
                                  turnover?.invalid
                                  &&
                                  turnover.touched" [required]="turnoverRequired">
                                  <option value="undefined" disabled>Please
                                      Select
                                      Turnover</option>
                                  <option *ngFor="let item of
                                      turnoverData.data" [value]="item.id">{{item.component}}
                                  </option>
                              </select> -->
                <!-- <div *ngIf="register.submitted ||
                                  turnover.touched" class="alert alert-danger">
                  <div *ngIf="turnover.errors?.['required']">
                    Turnover is Required.
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-6 col-md-6" *ngIf="certificateRequired">
              <div class="form-group">

                <ng-container tainer *ngIf="caCertificateCheck">
                  <label for="file">CA Certificate:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Certificate Preview"
                      (click)="downLoadPdf(environment.caCertificateBaseURL + hwmMemberShipFormData.ca_certificate)"
                      tooltipPosition="bottom" *ngIf="oldCaState">{{hwmMemberShipFormData.ca_certificate |
                      slice:0:25}}</span>
                    <span pTooltip="Click to Show Certificate Preview"
                      (click)="onImageClick(caCertificateURL, 'pdf', caDocName)" tooltipPosition="bottom"
                      *ngIf="!oldCaState">{{caDocName | slice:0:25}}</span>
                    <span (click)="logoPreview('caCertificateCheck')" style="color: red"
                      pTooltip="Remove Preview and Change Certificate" tooltipPosition="bottom">x</span>
                  </span>
                </ng-container>

                <ng-container *ngIf="!caCertificateCheck">
                  <label>Please Upload CA Certificate If Turnover
                    is
                    Less Than 5 Crores:</label>
                  <input [class.is-invalid]="(register.submitted
                                      &&
                                      ca_certificate?.invalid) ||
                                      ca_certificate?.invalid
                                      &&
                                      ca_certificate.touched" type="file" class="form-control px-2 py-1"
                    [(ngModel)]="hwmMemberShipFormData.ca_certificate" (change)="fileUpload($event,'ca_certificate')"
                    placeholder="Choose Document" name="ca_certificate" #ca_certificate="ngModel">
                  <!-- <div class="invalid-feedback">
                    CA Certificate Document
                    is
                    Required.
                  </div> -->
                  <div class="alert alert-danger" *ngIf="!caState">
                    Certificate Size Should Not Larger Then 10MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Applicable Regional Office:</label>
                <p-dropdown autocomplete="off" [options]="roDetails" [class.is-invalid]="(register.submitted &&
                  regional_office?.invalid) ||
                  regional_office?.invalid &&
                  regional_office.touched" name="regional_office" #regional_office="ngModel"
                  [(ngModel)]="selectedOffice" [styleClass]="(register.submitted &&
                  regional_office?.invalid) ||
                  regional_office?.invalid &&
                  regional_office.touched ? 'error' : ''" placeholder="Select Applicable Regional Office"
                  optionLabel="regional_office_name" [showClear]="false" [resetFilterOnHide]="true"
                  (onChange)="change('regional_office')">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="selectedOffice">
                      <div>{{selectedOffice.regional_office_name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.regional_office_name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted ||
                  regional_office.touched" class="alert alert-danger">
                  <div *ngIf="regional_office.errors?.['required']">
                    Applicable Regional Office is Required.
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="add-listings-box" style="overflow-x: auto;">
          <!-- <h3>Status of Consents / Authorization</h3> -->
          <table class="table table-responsive text-center">
            <thead>
              <tr>
                <th colspan="5" style="text-align: left;">
                  <h3>Status of Consents / Authorization</h3>
                </th>
              </tr>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Environmental Clearance (EC)</th>
                <th scope="col">Consent To Establish (CTE)</th>
                <th scope="col">Consent To Operate (CTO)</th>
                <th scope="col">HWM Authorization</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Applied</th>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_ec_applied" value="yes"
                        type="radio" name="is_ec_applied" id="ecappliedyes" #is_ec_applied="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_ec_applied" type="radio"
                        value="no" name="is_ec_applied" id="ecappliedno" #is_ec_applied="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_ec_applied.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_ec_applied.errors?.['required']">
                                          Environmental Clearance (EC) Applied
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cte_applied" value="yes"
                        type="radio" name="is_cte_applied" id="is_cte_applied1" #is_ec_applied="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cte_applied" type="radio"
                        value="no" name="is_cte_applied" id="is_cte_applied2" #is_cte_applied="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_cte_applied.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_cte_applied.errors?.['required']">
                                          Consent To Establish (CTE) Applied
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cto_applied" value="yes"
                        type="radio" name="is_cto_applied" id="is_cto_applied1" #is_cto_applied="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cto_applied" type="radio"
                        value="no" name="is_cto_applied" id="is_cto_applied2" #is_cto_applied="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_cto_applied.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_cto_applied.errors?.['required']">
                                          Consent To Operate (CTO) Applied
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_hwm_auth_applied"
                        value="yes" type="radio" name="is_hwm_auth_applied" id="is_hwm_auth_applied1"
                        #is_hwm_auth_applied="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_hwm_auth_applied"
                        type="radio" value="no" name="is_hwm_auth_applied" id="is_hwm_auth_applied2"
                        #is_hwm_auth_applied="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_hwm_auth_applied.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_hwm_auth_applied.errors?.['required']">
                                          HWM Authorization Applied
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
              </tr>
              <tr>
                <th scope="row">Obtained</th>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_ec_obtained" value="yes"
                        type="radio" name="is_ec_obtained" id="is_ec_obtained1" #is_ec_obtained="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_ec_obtained" type="radio"
                        value="no" name="is_ec_obtained" id="is_ec_obtained2" #is_ec_obtained="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_ec_obtained.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_ec_obtained.errors?.['required']">
                                          Environmental Clearance (EC)
                                          Obtained
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cte_obtained" value="yes"
                        type="radio" name="is_cte_obtained" id="is_cte_obtained1" #is_cte_obtained="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cte_obtained" type="radio"
                        value="no" name="is_cte_obtained" id="is_cte_obtained2" #is_cte_obtained="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_cte_obtained.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_cte_obtained.errors?.['required']">
                                          Consent To Establish (CTE) Obtained
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cto_obtained" value="yes"
                        type="radio" name="is_cto_obtained" id="is_cto_obtained1" #is_cto_obtained="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_cto_obtained" type="radio"
                        value="no" name="is_cto_obtained" id="is_cto_obtained2" #is_cto_obtained="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_cto_obtained.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_cto_obtained.errors?.['required']">
                                          Consent To Operate (CTO) Obtained
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
                <td>
                  <div class="d-flex align-items-center
                                      justify-content-evenly">
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_hwm_auth_obtained"
                        value="yes" type="radio" name="is_hwm_auth_obtained" id="is_hwm_auth_obtained1"
                        #is_hwm_auth_obtained="ngModel" >
                      <label class="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" [(ngModel)]="hwmMemberShipFormData.is_hwm_auth_obtained"
                        type="radio" value="no" name="is_hwm_auth_obtained" id="is_hwm_auth_obtained2"
                        #is_hwm_auth_obtained="ngModel" >
                      <label class="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <!-- <div *ngIf="register.submitted ||
                                      is_hwm_auth_obtained.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="is_hwm_auth_obtained.errors?.['required']">
                                          HWM Authorization Obtained
                                          is
                                          required.
                                      </div>
                                  </div> -->
                </td>
              </tr>
              <tr>
                <th scope="row">On Hold <br>(Give reason)</th>
                <td>
                  <input type="text" scriptInjection class="form-control"
                    [(ngModel)]="hwmMemberShipFormData.ec_hold_reason" placeholder="EC Hold Reason"
                    name="ec_hold_reason" #ec_hold_reason="ngModel" >
                  <!-- <div *ngIf="register.submitted ||
                                      ec_hold_reason.touched" class="alert
                                      alert-danger">
                    <div *ngIf="ec_hold_reason.errors?.['required']">
                      Environmental Clearance (EC) Hold
                      Reason
                      is
                      required.
                    </div>
                  </div> -->
                </td>
                <td>
                  <input type="text" scriptInjection class="form-control"
                    [(ngModel)]="hwmMemberShipFormData.cte_hold_reason" placeholder="CTE Hold Reason"
                    name="cte_hold_reason" #cte_hold_reason="ngModel" >
                  <!-- <div *ngIf="register.submitted ||
                                      cte_hold_reason.touched" class="alert
                                      alert-danger">
                    <div *ngIf="cte_hold_reason.errors?.['required']">
                      Consent To Establish (CTE) Hold
                      Reason
                      is
                      required.
                    </div>
                  </div> -->
                </td>
                <td>
                  <input type="text" scriptInjection class="form-control"
                    [(ngModel)]="hwmMemberShipFormData.cto_hold_reason" placeholder="CTO Hold Reason"
                    name="cto_hold_reason" #cto_hold_reason="ngModel" >
                  <!-- <div *ngIf="register.submitted ||
                                      cto_hold_reason.touched" class="alert
                                      alert-danger">
                    <div *ngIf="cto_hold_reason.errors?.['required']">
                      Consent To Operate (CTO) Hold Reason
                      is
                      required.
                    </div>
                  </div> -->
                </td>
                <td>
                  <input type="text" scriptInjection class="form-control"
                    [(ngModel)]="hwmMemberShipFormData.hwm_hold_reason" placeholder="HWM Hold Reason"
                    name="hwm_hold_reason" #hwm_hold_reason="ngModel" >
                  <!-- <div *ngIf="register.submitted ||
                                      hwm_hold_reason.touched" class="alert
                                      alert-danger">
                    <div *ngIf="hwm_hold_reason.errors?.['required']">
                      HWM Authorization Hold Reason
                      is
                      required.
                    </div>
                  </div> -->
                </td>
              </tr>
              <tr>
                <th scope="row">Upload <br>(Any One)</th>
                <td>
                  <ng-container *ngIf="ecCheck1">
                    <!-- {{ecCheck1}}{{oldecstate}} -->
                    <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                      <span pTooltip="Click to Show Preview"
                        (click)="downLoadPdf(ecCertificateBaseURL + hwmMemberShipFormData.ec_document)"
                        tooltipPosition="bottom" *ngIf="oldecState">{{hwmMemberShipFormData.ec_document | slice:0:25
                        }}</span>
                      <span pTooltip="Click to Show Preview" (click)="onImageClick(ecCertificateURL, 'pdf', ecDocName)"
                        tooltipPosition="bottom" *ngIf="!oldecState">{{ecDocName | slice:0:25 }}</span>
                      <span (click)="logoPreview('ec1')" style="color: red"
                        pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                    </span>
                    <!-- <ng-container *ngIf="imageData.boolean">
                                          <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                                      </ng-container> -->
                  </ng-container>
                  <ng-container *ngIf="!ecCheck1">
                    <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.ec_document"
                      (change)="fileUpload($event,'ec_document')" placeholder="Choose Document" name="ec_document"
                      #ec_document="ngModel">
                    <!-- <div *ngIf="register.submitted ||
                                      ec_document.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="ec_document.errors?.['required']">
                                          Environmental Clearance (EC)
                                          Document
                                          is
                                          required.
                                      </div>
                                  </div> -->
                    <div class="alert alert-danger" *ngIf="!ecState">
                      Document Size Should Not Larger Then 10MB
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ecCheck2">
                    <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                      <span pTooltip="Click to Show document Preview"
                        (click)="downLoadPdf(cteDocBaseURL + hwmMemberShipFormData.cte_document)"
                        tooltipPosition="bottom"
                        *ngIf="oldec2State && hwmMemberShipFormData.cte_document != ''">{{hwmMemberShipFormData.cte_document
                        | slice :0:25}}</span>
                      <span pTooltip="Click to Show Preview"
                        (click)="onImageClick(cteCertificateURL, 'pdf', cteDocName)" tooltipPosition="bottom"
                        *ngIf="!oldec2State">{{cteDocName | slice :0:25}}</span>
                      <span (click)="logoPreview('ec2')" style="color: red"
                        pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                    </span>
                    <!-- <ng-container *ngIf="imageData.boolean">
                                          <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                                      </ng-container> -->
                  </ng-container>
                  <ng-container *ngIf="!ecCheck2">
                    <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.cte_document"
                      (change)="fileUpload($event,'cte_document')" placeholder="Choose Document" name="cte_document"
                      #cte_document="ngModel">
                    <!-- <div *ngIf="register.submitted ||
                                          cte_document.touched" class="alert
                                          alert-danger">
                                          <div
                                              *ngIf="cte_document.errors?.['required']">
                                              Consent To Establish (CTE) Document
                                              is
                                              required.
                                          </div>
                                      </div> -->
                    <div class="alert alert-danger" *ngIf="!cteState">
                      Document Size Should Not Larger Then 10MB
                    </div>
                  </ng-container>
                </td>
                <td>

                  <ng-container *ngIf="ecCheck3">
                    <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                      <span pTooltip="Click to Show Preview"
                        (click)="downLoadPdf(ctoDocBaseURL+hwmMemberShipFormData.cto_document)" tooltipPosition="bottom"
                        *ngIf="oldec3State">{{hwmMemberShipFormData.cto_document == '' ? 'Not
                        Uploaded' :
                        hwmMemberShipFormData.cto_document | slice: 0:25}}</span>
                      <span pTooltip="Click to Show Logo Preview"
                        (click)="onImageClick(ctoCertificateURL, 'pdf', ctoDocName)" tooltipPosition="bottom"
                        *ngIf="!oldec3State">{{ctoDocName | slice: 0:25}}</span>
                      <span (click)="logoPreview('ec3')" style="color: red"
                        pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                    </span>
                    <!-- <ng-container *ngIf="imageData.boolean">
                      <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                    </ng-container> -->
                  </ng-container>
                  <ng-container *ngIf="!ecCheck3">
                    <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.cto_document"
                      (change)="fileUpload($event,'cto_document')" placeholder="Choose Document" name="cto_document"
                      #cto_document="ngModel">
                    <!-- <div *ngIf="register.submitted ||
                                      cto_document.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="cto_document.errors?.['required']">
                                          Consent To Operate (CTO) Document
                                          is
                                          required.
                                      </div>
                                  </div> -->
                    <div class="alert alert-danger" *ngIf="!ctoState">
                      Certificate Size Should Not Larger Then 10MB
                    </div>
                  </ng-container>
                </td>
                <td>

                  <ng-container *ngIf="ecCheck4">
                    <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                      <span pTooltip="Click to Show Preview"
                        (click)="downLoadPdf(hwmDocBaseURL+hwmMemberShipFormData.hwm_document)"
                        tooltipPosition=" bottom" *ngIf="oldec3State">{{hwmMemberShipFormData.hwm_document == '' ?
                        'Not
                        Uploaded' :
                        hwmMemberShipFormData.hwm_document | slice: 0:25}}</span>
                      <span pTooltip="Click to Show Logo Preview"
                        (click)="onImageClick(hwmCertificateURL, 'pdf', hwmDocName)" tooltipPosition="bottom"
                        *ngIf="!oldec3State">{{hwmDocName | slice: 0:25}}</span>
                      <span (click)="logoPreview('ec4')" style="color: red"
                        pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                    </span>
                    <ng-container *ngIf="imageData.boolean">
                      <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!ecCheck4">
                    <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.hwm_document"
                      (change)="fileUpload($event,'hwm_document')" placeholder="Choose Document" name="hwm_document"
                      #hwm_document="ngModel" >
                    <!-- <div *ngIf="register.submitted ||
                                      hwm_document.touched" class="alert
                                      alert-danger">
                                      <div
                                          *ngIf="hwm_document.errors?.['required']">
                                          HWM Authorization Document
                                          is
                                          required.
                                      </div>
                                  </div> -->
                    <div class="alert alert-danger" *ngIf="!hwmState">
                      Certificate Size Should Not Larger Then 10MB
                    </div>
                  </ng-container>
                </td>
              </tr>
              <!-- <tr *ngIf="register?.submitted ||
                        ec_document?.touched">
                <td colspan="5">
                  <div class="alert
                                alert-danger" *ngIf="requiredAnyOneDoc">
                    Please Upload at Least One Document.
                  </div>
                </td>
              </tr> -->
              <tr>
                <th scope="row">Any other <br>Remarks</th>
                <td colspan="4">
                  <textarea type="text" scriptInjection placeholder="Remarks" name="remarks"
                    [(ngModel)]="hwmMemberShipFormData.remarks" class="form-control" #remarks="ngModel">
                                  </textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="add-listings-box">
          <h3>Upload Documents</h3>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>Undertaking for New Membership:</label>


                <ng-container *ngIf="underTakingCheck">
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Preview"
                      (click)="downLoadPdf(utDocBaseURL+ hwmMemberShipFormData.undertaking )" tooltipPosition="bottom"
                      *ngIf="oldUndertakingState">{{hwmMemberShipFormData.undertaking != ''?
                      hwmMemberShipFormData.undertaking :'Not Uploaded' | slice: 0:25}}</span>
                    <span pTooltip="Click to Show Preview" (click)="onImageClick(utCertificateURL, 'pdf', utDocName)"
                      tooltipPosition="bottom" *ngIf="!oldUndertakingState">{{utDocName | slice: 0:25}}</span>
                    <span (click)="logoPreview('undertaking')" style="color: red"
                      pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                  </span>
                  <!-- <ng-container *ngIf="imageData.boolean">
                                      <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                                  </ng-container> -->
                </ng-container>
                <ng-container *ngIf="!underTakingCheck">

                  <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.undertaking"
                    (change)="fileUpload($event,'undertaking')" placeholder="Choose Document" name="undertaking"
                    #undertaking="ngModel" >
                  <!-- <div *ngIf="register.submitted ||
                                  undertaking.touched" class="alert
                                  alert-danger">
                                  <div *ngIf="undertaking.errors?.['required']">
                                      Undertaking for New Membership is Required.
                                  </div>
                              </div> -->
                  <div class="alert alert-danger" *ngIf="!underState">
                    Certificate Size Should Not Larger Then 10MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>Application on Company Letterhead:</label>

                <ng-container *ngIf="appDocCheck">
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Preview"
                      (click)="downLoadPdf(appDocBaseURL+hwmMemberShipFormData.application)" tooltipPosition="bottom"
                      *ngIf="oldAppState">{{hwmMemberShipFormData.application | slice: 0:20}}</span>
                    <span pTooltip="Click to Show Preview" (click)="onImageClick(appCertificateURL, 'pdf', appDocName)"
                      tooltipPosition="bottom" *ngIf="!oldAppState">{{appDocName | slice: 0:20}}</span>
                    <span (click)="logoPreview('application')" style="color: red"
                      pTooltip="Remove Preview and Change Document" tooltipPosition="bottom">x</span>
                  </span>
                  <!-- <ng-container *ngIf="imageData.boolean">
                    <app-pdf-preview [imageData]="imageData"></app-pdf-preview>
                  </ng-container> -->
                </ng-container>
                <ng-container *ngIf="!appDocCheck">
                  <input type="file" class="form-control px-2 py-1" [(ngModel)]="hwmMemberShipFormData.application"
                    (change)="fileUpload($event,'application')" placeholder="Choose Document" name="application"
                    #application="ngModel" >
                  <div class="alert alert-danger" *ngIf="!appState">
                    Certificate Size Should Not Larger Then 10MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>Supporting Documents:</label>
                <div class="row" *ngFor="let item of
                          hwmMemberShipFormData.supprting_doc;index as index">
                  <div class="col-lg-5">
                    <div class="form-group">
                      <input type="text" scriptInjection class="form-control" [(ngModel)]="item.document_for"
                        placeholder="Document Name" name="document_for{{index}}" [class.is-invalid]="(register.submitted
                      &&
                      document_for?.invalid) ||
                      document_for?.invalid
                      &&
                      document_for.touched" #document_for="ngModel">
                      <div class="invalid-feedback">Document Name is Required</div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="form-group">
                      <input type="file" class="inputfile form-control px-2 py-1" placeholder="Document"
                        accept="application/pdf"  [class.is-invalid]="(register.submitted
                      &&
                      document?.invalid) ||
                      document?.invalid
                      &&
                      document.touched" (change)="onDocUppload($event, index)">
                      <div class="invalid-feedback">Document is Required</div>
                    </div>
                  </div>
                  <div class="col-lg-2 align-items-center" style="margin: auto;">
                    <div class="action_btn d-flex">
                      <div>
                        <p-button type="button" class="mr-2" icon="bx bx-plus" (click)="addDocument()"
                          iconPos="right"></p-button>
                      </div>
                      <div *ngIf="index>0" class="remove">
                        <p-button type="button" class="ml-2" icon="bx bx-minus" (click)="removeDoc(index)"
                          iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-12">
              <div class="add-listings-btn">
                <button type="submit" class="button" (click)="goToTop()">Submit</button>
              </div>
            </div> -->
          <!-- </div>
        </div> -->
        <!-- reason of amendment in membership -->
        <div class="add-listings-box">
          <div class="row" *ngIf="role !== 'Admin' && role !== 'UCCI Staff' && memberShipStatus !== 'In progress' && memberShipStatus !== 'Pending'">

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <div class="d-flex">
                  <label for="">Reason for amendment</label>
                </div>
                <textarea type="text" scriptInjection class="form-control" placeholder="Reason for amendment"
                  [(ngModel)]="hwmMemberShipFormData.amendment_reason" [class.is-invalid]="(register.submitted &&
                amendment_reason?.invalid) || amendment_reason?.invalid
                && amendment_reason.touched" type="text" scriptInjection class="form-control" name="amendment_reason"
                  #amendment_reason="ngModel" [(ngModel)]="hwmMemberShipFormData.amendment_reason" ></textarea>
                <!-- <div *ngIf="register.submitted ||
amendment_reason.touched" class="alert
alert-danger">
                  <div *ngIf="amendment_reason.errors?.['required']">
                    Reason for amendment is Required.
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-lg-12">
              <div class="add-listings-btn">
                <button type="submit" class="button" (click)="goToTop()">Submit</button>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        
        <!-- register form -->
      </form>
    </div>
    <!-- *********************************************************************************************************************** -->
    <!-- payment section if form submitted successfully -->
    <!-- *********************************************************************************************************************** -->
    <div class="payment-section container" *ngIf="!isShow">
      <div class="payment-totals row">
        <div class="col-12 adminssion-fee">
          <h3>Admission Fee</h3>
          <ul>
            <ng-container *ngFor="let data of
                            formResponse.admission_fee">
              <li>{{data.fee_type}}<span>₹{{data.amount}}</span></li>
              <li>Tax<span>{{data.value}}%</span></li>
            </ng-container>
            <li>Total <span>₹{{formResponse.total_admission_fee}}</span></li>
          </ul>
          <div class="add-listings-btn my-3">
            <button type="submit" class="button mb-3" (click)="payment('admission', formResponse)">Proceed
              to Pay</button>
          </div>
        </div>
        <div class="col-12 mt-5 trust-fee">
          <h3>Trust Fee</h3>
          <ul>
            <ng-container *ngFor="let data of formResponse.trust_fee">
              <li>{{data.fee_type}}<span>₹{{data.amount}}</span></li>
              <li>Total<span>₹{{data.amount}}</span></li>
            </ng-container>
          </ul>
          <div class="add-listings-btn mt-5">
            <button type="submit" class="button" (click)="payment('trust')">Proceed
              to Pay</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>




<p-dialog header="Verification" [breakpoints]="{'850px' : '90vw'}" [(visible)]="approveModalState" [modal]="true"
  [style]="{width: '30vw'}" [draggable]="false" [resizable]="false" [closable]="true" [closeOnEscape]="true"
  (onHide)="onClose()">
  <div>

    <form [formGroup]="appliedByForm" (ngSubmit)="onSendOtp()" #otpVerificationForm="ngForm" #form>
      <div class="row justify-content-end" style="min-height: 240px;">
        <div class="col-lg-12 col-md-12 mb-2">
          <div class="form-group">
            <label>Applied By:</label>
            <p-dropdown [options]="nominee" placeholder="Please Applied By" [class.is-invalid]="(role.submitted &&
            appliedByForm.get('userId')?.invalid) ||
            appliedByForm.get('userId')?.invalid &&
            appliedByForm.get('userId').touched" formControlName="userId" optionLabel="nomineeName"
              optionValue="userId" [showClear]="false" [resetFilterOnHide]="true">
            </p-dropdown>
            <!-- <span class="form-control d-flex align-items-center mt-2">{{loggedInUserEmail}}</span> -->
          </div>
        </div>
        <div class="col-lg-12 d-flex justify-content-end align-items-end ">
          <div class="add-listings-btn" style="text-align: right;">
            <button type="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<ng-container *ngIf="successState">
  <app-success [statusMsg]="successMessage"></app-success>
</ng-container>