<p-toast></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<!-- <app-spinner [msg]="spinnerMsg"></app-spinner> -->
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- ********************** Main Content ******************** -->

<!-- <div class="main-content d-flex flex-column"> -->

<!-- <app-dashboard-navbar></app-dashboard-navbar> -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
        <li class="item">{{Content.title}}</li>
    </ol>
</div>

<!-- ********************** End Breadcrumb Area  ******************** -->
<!-- create tax form -->
<!-- <div class="add-listings-box">
    <form (ngSubmit)="createSetting(settingForm)" #settingForm="ngForm">
        <div class="row" style="justify-content: space-between;">
            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="form-group">
                    <label>Name<span class="required">*</span>:</label>
                    <input type="text" scriptInjection class="form-control" name="setting_name"
                        #setting_name="ngModel" [(ngModel)]="settingData.setting_name"
                        placeholder="Enter Name" required>
                    <div *ngIf="settingForm.submitted || setting_name.touched" class="alert alert-danger">
                        <div *ngIf="setting_name.errors?.['required']">
                            Name is Required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-2 col-sm-12">
                <div class="form-group">
                    <label>Value<span class="required">*</span>:</label>
                    <input type="text" scriptInjection class="form-control" name="setting_value"
                        #setting_value="ngModel" pKeyFilter="num" [(ngModel)]="settingData.setting_value"
                        placeholder="Enter value">
                </div>
            </div>
            <div class="col-lg-4 col-md-2 col-sm-12 add-btn">
                <p-button type="submit" class="ml-2" icon="bx bx-plus" label="Add" iconPos="right"></p-button>
            </div>
        </div>
    </form>
</div> -->
<!-- ***************************************************************Tax Master List*************************************************************** -->
<div class="Master-view-box">
    <div class="theader">
        <div class="flex">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" scriptInjection (input)="dt1.filterGlobal($event.target.value,
                                'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                    </span>
                    <p-button pTooltip="Reset Filter" tooltipPosition="bottom" type="button" class="mx-2"
                        (click)="reset(dt1)" icon="bx bx-reset"></p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="onXlExport()" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf"
                        (click)="exportPdf()" class="p-button-warning mr-2"
                        pTooltip="PDF" tooltipPosition="bottom"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <!-- p-datatable-striped -->
            <p-table #dt1 [value]="settingTableData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]"
                responsiveLayout="scroll" [paginator]="true"
                currentPageReportTemplate="{totalRecords} of {{settingTableData.length == 0? 0 : settingTableData.length}} records"
                [globalFilterFields]="['name', 'value']" editMode="row"
                [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="max-width: 80px; text-align: center;">S.No.</th>
                        <th style="min-width: 200px;">Name
                        </th>
                        <th style="min-width: 200px;text-align: center;">Value</th>
                        <!-- <th style="min-width: 200px;text-align: center;">Action</th> -->
                        <th style="min-width: 150px;text-align: center;">Enable/Disable</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-setting let-i="rowIndex" let-editing="editing">
                    <tr [pEditableRow]="setting">
                        <td style="text-align: center;">
                            {{i+1}}
                        </td>
                        <td>
                            <p-cellEditor editingRowKeys="setting.id">
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" scriptInjection [(ngModel)]="setting.name" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{setting.name}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td style="text-align: center;">
                            <p-cellEditor editingRowKeys="setting.id">
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" scriptInjection [(ngModel)]="setting.value"
                                        pKeyFilter="num">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{setting.value}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <!-- <td class="d-flex align-items-center justify-content-center" style="text-align: center;">
                            <button *ngIf="!editing" pButton pRipple icon="bx bx-pencil" pTooltip="Edit"
                                tooltipPosition="bottom" class="p-button-rounded p-button-text
                                    p-button-success mr-2" (click)="editSettingConfirm(setting, i)" pInitEditableRow></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                (click)="editSetting(setting, i)" class="p-button-rounded p-button-success
                                    p-button-text mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                (click)="onRowEditCancel(setting, i)" class="p-button-rounded p-button-danger
                                    p-button-text"></button>
                        </td> -->
                        <td style="text-align: center;">
                            <div class="sidebar-widgets">
                                <div class="box">
                                    <label class="switch">
                                    <input type="checkbox" [disabled]="!(setting?.value == '' || setting?.value == null)" [checked]="(setting.flag == 0)" (change)="enableAction($event, i)">
                                    <span></span>
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<!-- <app-copyrights></app-copyrights>
</div> -->