import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonClass } from 'src/app/common';
import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-two',
  templateUrl: './template-two.component.html',
  styleUrls: ['./template-two.component.scss'],
})
export class TemplateTwoComponent implements OnInit {
  bannerData: any = [
    {
      bannerImage: '../../../../../assets/img/main-banner-bg1.jpg',
      heading: 'About UCCI',
      content:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia. Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia. Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.',
    },
  ];
  alterSection: any = [
    {
      sectionImage: './../../../../../assets/img/team1.jpg',
      heading: 'Mr. Sanjaya Singhal',
      content: 'UCCI president',
    },
    {
      sectionImage: './../../../../../assets/img/team1.jpg',
      heading: 'Mr. Sanjaya Singhal',
      content: 'UCCI president',
    },
    {
      sectionImage: './../../../../../assets/img/team1.jpg',
      heading: 'Mr. Sanjaya Singhal',
      content: 'UCCI president',
    },
    {
      sectionImage: './../../../../../assets/img/team1.jpg',
      heading: 'Mr. Sanjaya Singhal',
      content: 'UCCI president',
    },
  ];
  base_URL = environment.ucci_base_url;
  data: any = { page_json: [] };
  news: any = [];

  constructor(
    private route: Router,
    private notificationService: NewsAndEventsServiceService
  ) {}

  @Input() pageData: any;

  ngOnInit() {
    this.data = this.pageData;
    console.log(this.pageData);
    if (this.pageData?.slug == 'resources') {
      this.notificationService.getNotifications().subscribe((res: any) => {
        console.log(res);
        this.news = res?.data?.filter(
          (res) => res?.news_type != 'Archive' && res?.news_type != 'Library'
        );
        console.log(this.data, this.news);
      });
    }
    if (this.data?.page_json) {
     
      // Get the year from the grid_heading string
      if(this.pageData?.slug == 'past-awardees'){
        this.data.page_json = JSON.parse(this.data?.page_json);
        console.log(this.data.page_json, this.data.page_json[0].grid);
        const extractYear = (str) => {
          const regex = /(\d{4})/;
          const match = regex.exec(str);
          return match ? parseInt(match[0]) : null;
        };
  
        // Custom comparison function
        const compareGridHeading = (a, b) => {
          const yearA = extractYear(a.grid_heading);
          const yearB = extractYear(b.grid_heading);
  
          // Return the comparison result
          return yearB - yearA; // Sort in descending order
        };
  
        // Sort the array in descending order based on grid_heading
        this.data.page_json.sort(compareGridHeading);
        console.log( this.data.page_json[0].grid.sort(compareGridHeading))
      } else if(this.pageData?.slug == 'past-president'){
        this.route.navigateByUrl('about-ucci/presidents')
      }
      else{

        this.data.page_json = JSON.parse(this.data?.page_json);
        console.log(this.data.page_json, this.data.page_json[0].grid);
      }
    }

  }

  navigate(slug: any) {
    // alert(slug)
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([slug]);
  }
}
