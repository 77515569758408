import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-committee-pages-list',
  templateUrl: './committee-pages-list.component.html',
  styleUrls: ['./committee-pages-list.component.scss']
})
export class CommitteePagesListComponent implements OnInit {

  filterVal: any;
  localStorage: any;
  templates: any = [];

  constructor(
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: Router
  ) { }

  ngOnInit() {
    this.localStorage = JSON.parse(localStorage.getItem('access_token'));
    this.getData();
  }

  async getData() {
    this.spinner.show();
    this.templates = []
    await this.websiteService.getAllPages().then((res: any) => {
      console.log(res);
      res?.data?.forEach((page) => {
        if (page.template_type == 'committe') {
          this.templates.push(page);
        }
      });
      // this.templates = res?.data;
      this.spinner.hide();
    });
  }

  breadcrumb: any[] = [
    {
      title: 'Committee Template',
      subTitle: 'Masters',
    },
  ];

  reset(dt1) {
    dt1.reset();
    this.filterVal = null;
  }

  //edit page
  editPage(page: any) {
    this.route.navigateByUrl('/user-panel/create-committee/' + page.id);
  }

  //delete page
  deletePage(id: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this page?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        this.spinner.show();
        await this.websiteService.deletePage(id).then((res: any) => {
          console.log(res);
          this.templates = [];
          res?.data?.forEach((page) => {
            if (page.template_type == 'committe') {
              this.templates.push(page);
            }
          });
          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            detail: 'Page Deleted Successfully!',
          });
        });
      },
    });
  }
  baseURL = environment.frontURL;
  pagePreview(slug :any) {
    let a= document.createElement('a');
    a.target= '_blank';
    a.href= this.baseURL+'/preview/'+slug;
    a.click();
  }

  approvePage(template : any) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure that you want to approve?',
      accept: () => {
        this.spinner.show();
        let object = {
          is_active : 0
        }
        this.websiteService
          .pageApproval(template?.id, object)
          .then((res: any) => {
            this.getData();
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          });
      },
    });
  }

}
