<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<app-spinner [msg]="spinnerMsg"></app-spinner>

<!-- ********************** Main Content ******************** -->


<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>


<!-- ***************************************************************notification Master List*************************************************************** -->
<div class="Master-view-box">
    <div class="theader">
        <div class="flex">
            <div class="d-flex align-items-center">
                <div>
                    <!-- <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" scriptInjection (input)="dt1.filterGlobal($event.target.value,
                                'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                    </span> -->
                    <!-- <p-button pTooltip="Reset Filter" tooltipPosition="bottom" type="button" class="mx-2"
                        (click)="reset(dt1)" icon="bx bx-reset"></p-button> -->
                        
                        <button type="button" pButton pRipple icon="bx bx-reset" (click)="reset(dt1)"
                        class="p-button mx-2" pTooltip="Reset Table" tooltipPosition="bottom"></button>

                    <button type="button" pButton pRipple icon="pi pi-file-excel" *ngIf="roleName == 'Admin'" (click)="onXlExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" *ngIf="roleName == 'Admin'"
                        (click)="exportPdf()" class="p-button-warning mr-2"
                        pTooltip="PDF" tooltipPosition="bottom"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <p-table #dt1 dataKey="id" [rows]="10" [lazy]="true" (onLazyLoad)="loadData($event)" [showCurrentPageReport]="true" styleClass="p-datatable-sm
            p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [totalRecords]="datalength" [paginator]="true"
                currentPageReportTemplate="{totalRecords} of {{datalength}}
            records" [globalFilterFields]="['event_action',
            'user.name', 'user.email', 'user.contact_no', 'role.name']" [value]="list">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 62px">S. No.</th>
                        <!-- <th style="min-width: 100px;">
                            Action
                        </th> -->
                        <th style="min-width: 175px;">Activity</th>
                        <th style="min-width: 120px;">Created On</th>
                        <th style="min-width: 100px;">User Name</th>
                        <th>User Email</th>
                        <th style="min-width: 130px;">Contact No.</th>
                        <th style="min-width: 130px;">Role</th>
                        <th>Description</th>
                    </tr>


                </ng-template>
                <ng-template pTemplate="body" let-list let-i="rowIndex">
                    <tr>
                        <td>
                            {{ i + 1 }}
                        </td>
                        <!-- <td style="min-width: 100px;" class="view-buttons">
                            <button pButton pRipple class="p-button-rounded
                            p-button-text
                            p-button-info" (click)="showMaximizableDialog(list)" pTooltip="View"
                                tooltipPosition="bottom">
                                <i class="far fa-eye"></i>
                            </button>
                        </td> -->
                        <td>{{ list?.event_action ? list?.event_action : '-' }}</td>
                        <td>{{ list?.created_at | date:'dd/MM/YYYY' }}</td>
                        <td>{{ list?.user?.name ? list?.user?.name : '-' }}</td>
                        <td>{{ list?.user?.email ? list?.user?.email : '-' }}</td>
                        <td>{{ list?.user?.contact_no ? list?.user?.contact_no : '-' }}</td>
                        <td>{{ list?.role?.name ? list?.role?.name : '-' }}</td>
                        <td style="white-space: nowrap;">{{ list?.event_description ? list?.event_description : '-' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>


<!-- ***************************************************************************************************************** -->
<!----------------------------------------------------------- view dialog --------------------------------------------------------->
<!-- ***************************************************************************************************************** -->
<p-dialog header="User Audit Log Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
    [draggable]="false" [resizable]="false">
    <!-- Start -->
    <ng-container *ngFor="let data of userLogs">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <div class="my-profile-box">
                    <h3>Activity</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Action Name :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.event_action | titlecase}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Created :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.created_at | date:'dd/MM/YYYY'}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Description :</label>
                                    <textarea type="text" readonly class="form-control" value="{{
                                                    data?.event_description }}">
                                    </textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Role :</label>
                                    <input type="text" readonly class="form-control" value="{{data?.role?.name}}" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="my-profile-box">
                    <h3>User Details</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>User Name :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.user?.name | titlecase}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Email :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.user?.email }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Contact Number :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.user?.contact_no ? data?.user?.contact_no : '-'}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>PAN :</label>
                                    <input type="text" readonly class="form-control" value="{{data?.user?.pan ? data?.user?.pan : '-'}}" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="my-profile-box">
                    <h3>Member Details</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Company Name :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.company_name | titlecase}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Email :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.email ? data?.member?.email : '-' }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>GST Number :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.gst_number ? data?.member?.gst_number : '-' }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Office Telephone :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.gst_number ? data?.member?.office_telephone : '-' }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Registration Number :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.gst_number ? data?.member?.registration_number : '-' }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Residental Telephone :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                                    data?.member?.gst_number ? data?.member?.residential_telephone : '-' }}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Membership Name :</label>
                                    <input type="text" readonly class="form-control" value="{{data?.member?.membership_name ? data?.member?.membership_name : '-'}}" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
</p-dialog>