<div class="container px-1 px-md-4 py-5 mx-auto">



    <div class="notification danger">
        <i class="fa fa-exclamation-circle"></i>
        <span>Oh no! Somenthing went wrong with your payment</span>
        <i class="fa fa-times-circle"></i>
    </div>



    <div class="card">
        <div class="row d-flex justify-content-between px-3 top">
            <div class="d-flex">
                <h5 style="font-size: 26px;">You are almost there! </h5>
            </div>
            <div class="d-flex flex-column text-sm-right">
                <p class="mb-0">Sorry we had an issue confirming your payment. Please try again.</p>
            </div>
        </div>
        <div class="track">
            <div class="step active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">Form
                    Submitted</span> </div>
            <div class="step active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">
                    Payment Pending
                </span>
                <p style="color: grey; font-size: 10px;">(Yor are just one step away)</p>
            </div>
            <div class="step"> <span class="icon"> <i class="fa fa-spinner"></i> </span> <span class="text"> Processing
                    Report
                </span> </div>
        </div>
        <br>
        <br>
        <div>
            <p>
                If your payment got detucted for above transaction, the same shall be credited back to your account in 5
                working
                days
            </p>
        </div>
        <div style="text-align: center; margin-top: 20px;">
            <button _ngcontent-rga-c266="" class="homeBUtton" routerLink="/" routerLinkActive="router-link-active">Back
                Home</button>
        </div>
    </div>
</div>