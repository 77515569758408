import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { FormBuilderService } from 'src/app/services/form-builder.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  providers: [MessageService],
})
export class FormBuilderComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private activeRoute: ActivatedRoute,
    private messageService: MessageService,
    private __formBuilder: FormBuilderService,
    private route: Router
  ) {}
  // buttonFields:any = {controls: [
  //   {
  //     id: 'button',
  //     name: 'button',
  //     label: 'Submit',
  //     type: 'submit',
  //     validators: {},
  //     layout: 'col-lg-12',
  //   }
  // ],paymentDetailObject: [
  //   {
  //     selectedCategory: '',
  //     type: '',
  //     amount: '',
  //   },
  // ],payment_required:false};
  sections: any = [];
  formModel: any = { payment_required: false };
  breadcrumb: any[] = [
    {
      title: 'Form Builder',
      subTitle: 'Masters',
    },
  ];
  slug: any;
  formId: any;
  async ngOnInit() {
    this.slug = this.activeRoute.snapshot;
    if (this.slug.params.slug) {
      this.spinner.show();
      await this.__formBuilder.getCustomFormBySlug(this.slug.params.slug).then(
        (res: any) => {
          this.formId = res.data.id;
          let parsedData: any;
          if (res?.data?.form_fields) {
            parsedData = JSON.parse(res?.data?.form_fields);
          }
          this.sections = JSON.parse(parsedData);
          this.formModel.form_name = res?.data?.name;
          this.formModel.payment_required =
            res?.data?.payment_required == 1 ? true : false;
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          this.route.navigateByUrl('/user-panel/custom-form');
        }
      );
    } else {
      this.sections = [
        {
          title: `Form Preview`,
          description: '',
          state: 'normal',
          canEnter: true,
          canExit: false,
          controls: [],
        },
      ];
    }
  }
  onReject() {
    this.messageService.clear('c');
  }
  async customFormData(event: any) {
    console.log(event, event?.controls?.length);

    if (this.formModel.form_name) {
      if (event[0]?.controls?.length > 0) {
        this.formModel.data = event;
        let formObject = {
          data: JSON.stringify(this.formModel.data),
          form_name: this.formModel.form_name,
          payment_required: this.formModel.payment_required,
        };
        if (this.slug.params.slug) {
          await this.__formBuilder.editCustomForm(this.formId, formObject).then(
            async (res: any) => {
              await this.messageService.add({
                severity: 'success',
                detail: 'Form Updated Successfully!',
              });
              setTimeout(() => {
                this.route.navigateByUrl('/user-panel/custom-form');
              }, 1500);
            },
            (error: HttpErrorResponse) => {}
          );
        } else {
          await this.__formBuilder.createCustomForm(formObject).then(
            async (res: any) => {
              await this.messageService.add({
                severity: 'success',
                detail: 'Form Created Successfully!',
              });
              setTimeout(() => {
                this.route.navigateByUrl('/user-panel/custom-form');
              }, 1500);
            },
            (error: HttpErrorResponse) => {}
          );
        }
      } else if (event[0]?.controls?.length == 0) {
        this.messageService.add({
          severity: 'error',
          detail: 'Please Create Form',
        });
        window.scroll(0, 0);
      }
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Enter Form Title to Proceed!',
      });
      window.scroll(0, 0);
    }
  }

  CheckInput(event: any) {
    length = this.formModel?.form_name?.replace(/ /g, '').length;
    if (length >= 20) {
      event.preventDefault();
    }
  }
}
