
<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <span class="p-float-label">
            <textarea id="float-input"  [(ngModel)]="siteMapData" style="border-color: #AAA ;" rows="20" class="w-100" pInputTextarea></textarea>
        </span>
        <div class="text-end">
            <button pbutton label="Save" class="p-element p-button p-component" (click)="saveData()"><span class="p-button-label">Save</span></button>
        </div>
    </div>
    
