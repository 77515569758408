import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
  providers: [MessageService, ConfirmationService],

})
export class SiteMapComponent implements OnInit {
  siteMapData:any=''
  constructor(private api:ApiService,private messageService: MessageService,
    ) { }
  breadcrumb: any[] = [
    {
      title: 'Site Map',
      subTitle: 'Masters',
    },
  ];
  ngOnInit(): void {
   
     this.getdata()
  }
  getdata(){
    this.api.getSiteMapData().then((res: any) => {
      this.siteMapData = res.data.sitemap_text
  }, (err: any) => {
    console.log(err);
  });
  }
  saveData(){
    console.log(this.siteMapData)
    let body = {
      sitemap_text:this.siteMapData
    }
    this.api.putSiteMapData(body)
        .subscribe((res: any) => {
          console.log(res);
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
        }, (err: any) => {
          console.log(err);
        });
  }

}
