<p-dialog [(visible)]="displayBasic" [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" [modal]="true"
  [draggable]="false" [closable]="false" [resizable]="false" showHeader="false" closable="false">
  <!-- ! CCAVENUE TEST PAYMENT FORM -->
  <!-- <form action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction" method="POST"
    #paymentForm (ngSubmit)="testMethod()"> -->
  <!-- ! CCAVENUE LIVE PAYMENT FORM -->
  <form action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" method="POST"
    #paymentForm (ngSubmit)="testMethod()">
    <div style="padding: 30px 0;">
      <h4 style="font-size: 15px; text-align:center">Please Do Not Press Back Button or Refresh Page</h4>
      <div class="info-container d-flex" style="align-items: center;">
        <h4 align="center"> <span style="font-size: 15px;">
            Redirecting To Payment Please Wait
          </span>
          <div class="dot dot1"></div>
          <div class="dot dot2"></div>
          <div class="dot dot3"></div>
        </h4>
      </div>
    </div>
    <!-- ! CCAVENUE TEST PAYMENT GATEWAY INPUT'S -->
    <!-- <input type="hidden" name="encRequest" [value]="merchantRequest">
    <input *ngIf="RecivedData?.string != 'HWM Trust'" type="hidden" name="access_code" value="AVQK74KF85AO23KQOA">
    <input *ngIf="RecivedData?.string == 'HWM Trust'" type="hidden" name="access_code" value="AVNK74KF84AO20KNOA"> -->
    <!-- ! CCAVENUE LIVE PAYMENT GATEWAY INPUT'S -->
    <input type="hidden" name="encRequest" [value]="merchantRequest">
    <input *ngIf="RecivedData?.string != 'HWM Trust'" type="hidden" name="access_code" value="AVJL98KH63AW28LJWA">
    <input *ngIf="RecivedData?.string == 'HWM Trust'" type="hidden" name="access_code" value="AVSV99KH06AY96VSYA">

    <!-- ! WORLDLINE PAYMENT GATEWAY -->
    <!-- <input type="hidden" size="200" name="merchantRequest" id="merchantRequest" [value]="merchantRequest" />
    <div *ngIf="this.RecivedData?.string != 'HWM Trust'">
      <input type="hidden" name="MID" id="MID" [value]="MID" />
    </div>
    <div *ngIf="this.RecivedData?.string == 'HWM Trust'">
      <input type="hidden" name="trustMID" id="trustMID" [value]="trustMID" />
    </div> -->
  </form>
</p-dialog>