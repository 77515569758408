import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-committee',
  templateUrl: './create-committee.component.html',
  styleUrls: ['./create-committee.component.scss'],
})
export class CreateCommitteeComponent implements OnInit {
  memberData: any = [];
  noFollowValue: any;
  // getNoFollowValue: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private apiService: ApiService
  ) {}

  breadcrumb: any[] = [
    {
      title: 'Commitee Template Form',
      subTitle: 'Masters',
    },
  ];

  slug: any;

  allPages: any = [];

  imageData: any = {};

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  nomineeData: any = [[[]]];

  templateData: any = {
    page_name: '',
    slug: '',
    banner_image: '',
    meta_title: '',
    meta_description: '',
    focus_keywords: '',
    canonical_tags: '',
    schema_markup: '',
    meta_robots: '',
    og_title: '',
    og_description: '',
    og_image: '',
    og_alt: '',
    geo_location_meta_tags: '',
    no_follow: null,
    sections: [
      {
        isTable: false,
        title: 'Section 1',
        heading: '',
        content: '',
        cta: '',
        grid: [
          {
            title: 'Grid 1',
            grid_heading: '',
            grid_content: '',
            grid_image: '',
            company_name: '',
            nominee_name: '',
            grid_contact: '',
            designation: '',
            slug: null,
          },
        ],
      },
    ],
  };

  async ngOnInit() {
    this.slug = this.activatedRoute?.snapshot?.params;

    console.log(this.slug);

    await this.websiteService.getAllPages().then((res: any) => {
      console.log(res);
      this.allPages = res?.data?.filter(
        (res: any) => res.template_type != 'footer'
      );
      this.allPages.push({ name: 'No Redirection', slug: '' });
    });

    this.apiService.getMember().subscribe((res: any) => {
      console.log(res);
      this.memberData = res?.data;
    });

    if (this.slug?.slug) {
      this.spinner.show();
      await this.websiteService.getPageById(this.slug.slug).then((res: any) => {
        // if(res?.page?.no_follow === 0){
        //   this.noFollowValue = 'false'
        // }
        // else{
        //   this.noFollowValue = 'true'
        // }
        console.log(res);
        this.templateData = {
          page_name: res?.page?.name,
          slug: res?.page?.slug,
          banner_image: res?.page?.banner,
          meta_title:res?.page?.meta_title,
          meta_description : res?.page?.meta_description,
          focus_keywords:res?.page?.focus_keywords,
          canonical_tags:res?.page?.canonical_tags,
          schema_markup:res?.page?.schema_markup,
          meta_robots:res?.page?.meta_robots,
          og_title:res?.page?.og_title,
          og_description:res?.page?.og_description,
          og_image:res?.page?.og_image,
          og_alt:res?.page?.og_alt,
          geo_location_meta_tags:res?.page?.geo_location_meta_tags,
          no_follow:res?.page?.no_follow,
          sections: JSON.parse(res?.page?.page_json),
        };
        let parsedJson = JSON.parse(res?.page?.page_json);
        this.nomineeData = parsedJson[0]?.nomineeData;
        this.spinner.hide();
       
      });
      
    }
  }

  ngOnChanges() {
    this.editor = new Editor();
  }

  //get page data
  async saveTemplate(template: NgForm) {
    if(this.templateData.no_follow == true){
      this.templateData.no_follow = 1
    }
    else{
      this.templateData.no_follow = 0

    }
    console.log(this.templateData, template.value, template.valid);
    // if(!this.getNoFollowValue){
    //   this.templateData.no_follow  =  this.templateData.no_follow;
    // }
    if (template.valid) {
      this.templateData.sections[0].nomineeData = this.nomineeData;
      let object = {
        name: this.templateData?.page_name,
        slug: this.templateData?.slug,
        banner: this.templateData?.banner_image,
        template_type: 'committe',
        meta_title: this.templateData?.meta_title,
        meta_description: this.templateData?.meta_description,
        focus_keywords: this.templateData?.focus_keywords,
        canonical_tags: this.templateData?.canonical_tags,
        schema_markup: this.templateData?.schema_markup,
        meta_robots: this.templateData?.meta_robots,
        og_title: this.templateData?.og_title,
        og_description: this.templateData?.og_description,
        og_image: this.templateData?.og_image,
        og_alt: this.templateData?.og_alt,
        geo_location_meta_tags: this.templateData?.geo_location_meta_tags,
        no_follow: this.templateData?.no_follow,
        page_json: JSON.stringify(this.templateData.sections),
      };
      this.spinner.show();
      if (this.slug?.slug) {
        await this.websiteService
          .updatePage(this.slug?.slug, object)
          .then((res: any) => {
            console.log(res);
            this.spinner.hide();
            this.router.navigateByUrl('/user-panel/committee-template');
          });
      } else {
        await this.websiteService.createPage(object).then((res: any) => {
          console.log(res);
          this.spinner.hide();
          this.router.navigateByUrl('/user-panel/committee-template');
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
  }

  //get slug when creating page
  getSlug(page: any) {
    if (page) {
      this.templateData.slug = page
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
    }
  }

  //add template section
  addSection(sectionIndex: any, string: any) {
    console.log(this.nomineeData);
    if (string == 'section') {
      this.templateData.sections.push({
        title: `Section ${this.templateData.sections.length + 1}`,
        heading: '',
        content: '',
        grid: [
          {
            title: 'Grid 1',
            grid_heading: '',
            grid_content: '',
            grid_image: '',
            company_name: '',
            nominee_name: '',
            grid_contact: '',
            designation: '',
            slug: null,
          },
        ],
      });
      this.nomineeData.push([[]]);
      console.log(this.nomineeData);
    } else {
      this.templateData.sections[sectionIndex].grid.push({
        title: `Grid ${
          this.templateData.sections[sectionIndex].grid.length + 1
        }`,
        grid_heading: '',
        grid_content: '',
        grid_image: '',
        company_name: '',
        nominee_name: '',
        grid_contact: '',
        designation: '',
        slug: null,
      });
    }
    this.editor = new Editor();
    console.log(this.nomineeData);
  }

  //remove template section
  removeSection(sectionIndex: any, string: any) {
    if (string == 'section') {
      this.templateData?.sections.splice(
        this.templateData?.sections?.length - 1,
        1
      );
      this.nomineeData.splice(this.nomineeData?.length - 1, 1);
    } else {
      this.templateData?.sections[sectionIndex].grid.splice(
        this.templateData?.sections[sectionIndex]?.grid.length - 1,
        1
      );
    }
  }

  // ngOnDestroy(): void {
  //   this.editor.destroy();
  // }

  //get image URL on upload
  getImageURL(event?: any, string?: any, sectionIndex?: any, gridIndex?: any) {
    console.log(event, string);
    let formData = new FormData();
    formData.append('image', event?.target?.files[0]);
    this.spinner.show();
    this.websiteService.getImageURL(formData).then((res: any) => {
      console.log(res);
      if (string == 'banner') {
        this.templateData.banner_image = res?.path;
      } else if (string == 'grid') {
        this.templateData.sections[sectionIndex].grid[gridIndex].grid_image =
          res?.path;
      } else if (string == 'og_image') {
        this.templateData.og_image = res?.path;
      }
      this.spinner.hide();
    });
  }
  getImage(image){
    this.templateData.og_image = image.target.files[0].name
  }

  //image preview
  onImageClick(url: any) {
    this.imageData.url = url;
    this.imageData.boolean = true;
  }

  //remove uploaded image
  logoPreview(string?: any, sectionIndex?: any, gridIndex?: any) {
    this.confirmationService.confirm({
      message: 'Are you sure to change image?',
      header: 'Confirmation',
      accept: () => {
        if (string == 'banner') {
          this.templateData.banner_image = '';
        }
        else if (string == 'og_image') {
          this.templateData.og_image = '';
        } else if (string == 'gridImage') {
          this.templateData.sections[sectionIndex].grid[gridIndex].grid_image =
            '';
        }
      },
    });
  }

  onSelectionOfCompany(event: any, sectionIndex: any, gridIndex: any) {
    console.log(event, event?.value);
    let array = this.memberData.filter(
      (res) => res?.company_name == event?.value
    );
    console.log(array);
    this.nomineeData[sectionIndex][gridIndex] = [];
    this.nomineeData[sectionIndex][gridIndex].push(array[0]?.nominee1);
    this.nomineeData[sectionIndex][gridIndex].push(array[0]?.nominee2);
  }

  onSelectionOfNominee(event: any, sectionIndex: any, gridIndex: any) {
    console.log(event?.value, gridIndex, sectionIndex);
    let image1BaseURL = environment.nominee1Image;
    let image2BaseURL = environment.nominee2Image;
    this.templateData.sections[sectionIndex].grid[gridIndex].grid_heading =
      event?.value;
    this.templateData.sections[sectionIndex].grid[gridIndex].grid_sub_heading =
      this.nomineeData[sectionIndex][gridIndex][0]?.name == event?.value
        ? this.nomineeData[sectionIndex][gridIndex][0]?.email
        : this.nomineeData[sectionIndex][gridIndex][1]?.email;
    this.templateData.sections[sectionIndex].grid[gridIndex].grid_contact =
      this.nomineeData[sectionIndex][gridIndex][0]?.name == event?.value
        ? this.nomineeData[sectionIndex][gridIndex][0]?.mobile
        : this.nomineeData[sectionIndex][gridIndex][1]?.mobile;
    this.templateData.sections[sectionIndex].grid[gridIndex].designation =
      this.nomineeData[sectionIndex][gridIndex][0]?.name == event?.value
        ? this.nomineeData[sectionIndex][gridIndex][0]?.designation
        : this.nomineeData[sectionIndex][gridIndex][1]?.designation;
    this.templateData.sections[sectionIndex].grid[gridIndex].grid_image =
      this.nomineeData[sectionIndex][gridIndex][0]?.name == event?.value
        ? image1BaseURL + this.nomineeData[sectionIndex][gridIndex][0]?.media
        : image2BaseURL + this.nomineeData[sectionIndex][gridIndex][1]?.media;
    console.log(this.templateData, this.nomineeData);
  }
  getNoFollow(event: any) {
    // this.getNoFollowValue = event.target.value;
    if (event.target.value === 'true') {
      this.templateData.no_follow = 1;
    } else if (event.target.value === 'false') {
      this.templateData.no_follow = 0;
    }
  }
}
