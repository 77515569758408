import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root',
})
export class InterestMasterService {
  constructor(private http: HTTPApi) {}
  // create are of interest api
  createInterest(data: any) {
    return this.http.post('interest-area', data);
  }
  // get interest master list
  getInterestArea() {
    return this.http.get('interest-area');
  }
  // edit interest area
  editInteresArea(id: any, data: any) {
    return this.http.put(`interest-area/${id}`, data);
  }
  // delete area of interest
  deleteInterest(id: any) {
    return this.http.delete(`interest-area/${id}`);
  }

  getInterestAreaForMembership() {
    return this.http.get('interest-area').toPromise();
  }
}
