import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { FeesMasterService } from 'src/app/services/fees-master.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { utility } from 'src/app/services/utility';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
  providers: [MessageService],
})
export class BlogDetailsComponent implements OnInit {
  event_id: any;
  form_id: any;
  payment_required: any;
  userId: any = -1;
  emailCheck: boolean = false;
  bodyObject: any = {};
  todayDate: Date;
  pastEvents: any = [];
  data: any = [];
  details: any = [];
  futureEvents: any = [];
  eventId: any;
  pastEventLength: any = { length: 3, button: 'Show All' };
  enrollEventLength: any = { length: 3, button: 'Show All' };
  record_id: any;
  paymentStatus: boolean = false;
  paymentResponse: { formState: boolean; merchantRequest: any; };
  formResponse: any;
  facilityDropdownValue: any;
  isPaid:boolean = false;
  constructor(
    private _formBuilderService: FormBuilderService,
    public route: Router,
    private __eventservice: EventService,
    private activatedRoute: ActivatedRoute,
    private commonFunction: CommonClass,
    private _apiService: ApiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private websiteService: WebsiteService,
    private feesService : FeesMasterService,
    private utility: utility
  ) {}

  slug: any;
  eventData: any = {};
  customFromData: any = [];
  baseUrl = environment.profile_baseUrl;
  coverageURL = environment.marketplace_coverage_url;
  frontURL = environment.frontURL;
  localStorage: any;
  eventDateCheck: boolean = true;
  pastEventDetails: boolean = false;
  bookedEventString: any = '';
  EventDetails: boolean = true;
  ngOnInit(): void {
    console.log(this.route.url);

    this.localStorage = this.commonFunction.getLocalStorage();
    this.slug = this.activatedRoute.snapshot;

    if (this.localStorage) {
      this.websiteService.navigateOption.next('website');
    } else {
      this.websiteService.navigateOption.next('dashboard');
    }

    if(this.slug?.params?.id && this.slug?.params?.order_id && this.slug?.params?.status) {
      let statusDetail = {
        status :   this.slug?.params?.status == 'Success' ? 'Payment Success' : 'Payment Failed',
        payment_for_id : this.slug?.params?.id,
        payment_for: 'Event',
        }
      this._formBuilderService
      .updatePaymentStatus(this.slug?.params?.order_id, statusDetail)
      .subscribe((res: any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: 'Event Registration Successful!',
        });
        setTimeout(() => {
          this.route.navigateByUrl('/user-panel/enrolled-events');
        }, 1500);
      });
    }
    else {
      this.__eventservice
        .getEventById(this.slug?.params?.slug)
        .then((res: any) => {
          console.log(res);
          if(res?.data?.member_fee !==0 ){
            this.isPaid = true;
          }else if(res?.data?.member_fee == 0){
            this.isPaid = false;
          }
          this.eventData = res?.data;
          console.log(this.eventData);
          let formdata: any;
          if (res?.form?.form_fields) {
            formdata = JSON.parse(res?.form?.form_fields);
          }
          let date = new Date();
          console.log(
            moment(res?.data?.date, moment.defaultFormat).toDate() < date
          );
          if (moment(res?.data?.date, moment.defaultFormat).toDate() < date) {
            this.eventDateCheck = false;
          }
  
          this.customFromData = JSON.parse(formdata);
  
          this.form_id = res?.form.id;
          this.event_id = res?.data.id;
          this.payment_required = res?.form?.payment_required;
          this.userId = this.localStorage?.user?.id;
          // debugger;
          console.log('this.customFromData : ', this.customFromData);
        });
      if (this.localStorage) {
        this.__eventservice.getEventsList().subscribe((res: any) => {
          let status = res?.data.filter(
            (res) => res.id == this.eventData.id && res.status == 'booked'
          );
          console.log(status);
          if (status?.length > 0) {
            this.bookedEventString = 'You Have Already Booked This Event!';
          }
        });
      }
  
      this.getEvents();
      this.todayDate = new Date();
      console.log(this.todayDate);
      this.feesService.getFeesMaster().subscribe((res: any) => {
        console.log(res, 'fees');
        this.facilityDropdownValue = res?.data?.filter(
          (x) => x?.fee_category == 'Facility'
        );
        let facility = this.facilityDropdownValue.filter(res => res?.id == this.eventData?.facility_id);
        console.log(facility);
        this.eventData.fee_name = facility[0]?.fee_name;
      });
    }

  }

  openModel() {
    this.websiteService.modalCheck.next(true);
  }

  pageTitleContent = [
    {
      title: 'Event Details',
      backgroundImage: 'assets/img/page-title/page-title2.jpg',
    },
  ];

  async getFormResponse(event: any) {
    var newEmailArray = event.Obj[0].controls
    console.log(newEmailArray);
    // this.catchEvent(event);
    if (event.string == 'submit') {
      console.log(event, this.localStorage);
      let role = ['Admin', 'UCCI Staff', 'CEO', 'President'];
      let allUsers: any = [];
      await this.__eventservice.getAllUsers().then((res: any) => {
        allUsers = res;
      });
      // console.log(this.localStorage.role);
      // debugger;
      // if ((this.localStorage == undefined) ? true : role.includes(this.localStorage?.role)) {
      // if (role.includes(this.localStorage?.role)) {
        console.log(event.Obj);
        let emailArray: any = [];

        // event.Obj[0].controls.forEach((control) => {
        //   if (control.type == 'email') {
        //     emailArray.push(control.value);
        //   }
        // });

        // for (let i = 0; i < emailArray.length; i++) {
        //   for (let j = 0; j < allUsers.length; j++) {
        //     if (emailArray[i] == allUsers[j].email) {
        //       this.userId = allUsers[j].id;
        //       this.emailCheck = true;
        //       console.log(this.userId);
        //       break;
        //     }
        //   }
        //   if (this.emailCheck) {
        //     break;
        //   }
        // }

        // if (!this.emailCheck) {
        //   let formData = new FormData();
        //   // formData.append(
        //   //   'name',
        //   //   emailArray[0]?.substring(0, emailArray[0]?.indexOf('@'))
        //   // );
        //   formData.append(
        //     'name',
        //     newEmailArray[0]?.value)
        //   var randomChars =
        //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //   var result = '';
        //   for (var i = 0; i < 10; i++) {
        //     result += randomChars.charAt(
        //       Math.floor(Math.random() * randomChars.length)
        //     );
        //   }
        //   console.log(newEmailArray)
        //   formData.append('email', newEmailArray[1].value);
        //   formData.append('password', result);
        //   formData.append('password_confirmation', result);
        //   formData.append('is_event', 'E');
        //   formData.append('event_name', this.eventData.event_name);
        //   if(!this.localStorage?.role){
        //     await this._apiService.register(formData).then((res: any) => {
        //       this.userId = res.user.id;
        //       console.log(this.userId);
        //       localStorage.setItem('access_token', JSON.stringify(res));
        //     });
        //   }
         
        // }
      // }
      await this.catchEvent(event);
      let storage = localStorage.getItem('data');
      let member_id: any = null;
      if (storage) {
        let data = JSON.parse(storage);
        member_id = data?.member?.id;
      }
      // debugger;
      let filled_by: any = localStorage.getItem('access_token');
      // alert(filled_by)
      console.log(filled_by);
      this.bodyObject = {
        form_id: this.form_id,
        event_id: this.event_id,
        member_id: member_id,
        // filled_by: (JSON.parse(filled_by)?.role) ?? 'User',
        data: {
          form_response: JSON.stringify(event.Obj),
          payment_required: this.payment_required,
        },
      };
      console.log(this.userId);
      if (this.userId != -1) {
        // alert(this.userId)
        this.bodyObject.user_id = this.userId;
      }
      // if(this.emailCheck) {
      //   this.websiteService.modalCheck.next(true);
      // }
      // else {
      // }

      this._formBuilderService
        .saveFormResponse(this.bodyObject)
        .subscribe((res: any) => {
          console.log(res);
          this.formResponse = res;
          if (this.userId != -1) {
            if (this.payment_required != '0') {
              if (res.Order_id != undefined && res.Order_id != '') {
                this.record_id = res.record_id;
                // alert();
                this.payment(res.Order_id, res.Amount);
              }
            } else {
              this.messageService.add({
                severity: 'success',
                detail: 'Event Registration Successful!',
              });
              setTimeout(() => {
                this.route.navigateByUrl('/user-panel/enrolled-events');
              }, 1500);
            }
          } else {
            this.messageService.add({
              severity: 'success',
              detail: 'Event Registration Successful!',
            });
            setTimeout(() => {
              this.route.navigateByUrl('/');
            }, 1500);
          }
        });
    } else if (event.string == 'not valid') {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
  }

  payment(orderId: string, amount: number): void {
    this.paymentStatus = true;
    console.log('====================================');
    console.log('ukwgsbdjku');
    console.log('====================================');
    this.paymentResponse = {
      formState: true,
      merchantRequest: this.formResponse.gatewayResponse,
    };
    // let options = {
    //   key: environment.rezorpay_key,
    //   amount: amount,
    //   name: 'UCCI',
    //   image: environment.rezorpay_logo,
    //   order_id: orderId,
    //   handler: function (response: any) {
    //     var event = new CustomEvent('payment.success', {
    //       detail: response,
    //       bubbles: true,
    //       cancelable: true,
    //     });
    //     window.dispatchEvent(event);
    //   },
    //   prefill: {
    //     name: '',
    //     email: '',
    //     contact: '',
    //   },
    //   notes: {
    //     address: '',
    //   },
    //   theme: {
    //     color: '#3399cc',
    //   },
    // };

    // var rzp1 = new Razorpay(options);
    // rzp1.open();

    // rzp1.on('payment.failed', (response: any) => {
    //   console.log(response);
    //   console.log(response.error.code);
    //   console.log(response.error.description);
    //   console.log(response.error.source);
    //   console.log(response.error.step);
    //   console.log(response.error.reason);
    //   console.log(response.error.metadata.order_id);
    //   console.log(response.error.metadata.payment_id);
    //   let statusDetail = {
    //     status: 'Payment Success',
    //     payment_id: response.error.metadata.payment_id,
    //     razorpay_signature: '',
    //     description: response.error.description,
    //     payment_for: 'Event',
    //     payment_for_id : this.record_id
    //   };
    //   this._formBuilderService
    //     .updatePaymentStatus(response.error.metadata.order_id, statusDetail)
    //     .subscribe((res: any) => {
    //       console.log(res);
    //       this.messageService.add({
    //         severity: 'danger',
    //         detail: 'Payment Failed!',
    //       });
    //       setTimeout(() => {
    //         this.route.navigateByUrl('/');
    //       }, 1500);
    //     });
    // });
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    console.log(event);
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      payment_for: 'Event',
      payment_for_id : this.record_id
    };
    this._formBuilderService
      .updatePaymentStatus(event.detail.razorpay_order_id, statusDetail)
      .subscribe((res: any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: 'Event Registration Successful!',
        });
        setTimeout(() => {
          this.route.navigateByUrl('/user-panel/enrolled-events');
        }, 1500);
      });
  }
  // get past events list
  getEvents() {
    this.futureEvents = [];
    this.pastEvents = [];

    this.__eventservice.getEventsList().subscribe((res: any) => {
      console.log('res : ', res);
       this.data=res?.data
      const eventList = res?.data;
      const currentDate = new Date();

this.pastEvents = res?.data.filter((event: any) => {
  const eventDate = new Date(event.date);
  return eventDate < currentDate;
});
console.log(this.pastEvents)

    });
  }
  showDetails(id: any) {
  // this.route.navigateByUrl('/event-details/' + id);
  this.__eventservice.getEventById(id)
  .then((res: any) => {
    console.log(res);
    this.eventData = res?.data;
    this.eventDateCheck = false;
  })



    // console.log(id);
    // this.pastEventDetails = true;
    // this.EventDetails = false;
    // console.log(this.data);
    //  debugger    
    // this.data.map((resp: any) => {
    //   console.log(resp, resp.id);
    //   if (resp.id == id) {
    //     console.log(true);
    //     this.details.push(resp);
    //     console.log(this.details)
    //   }
    // });
  }
  // show less show more button functionality
  showAllData(value: string) {
    if (value == 'enroll') {
      if (this.enrollEventLength['button'] == 'Show All') {
        this.enrollEventLength = {
          length: this.futureEvents.length,
          button: 'Show Less',
        };
        console.log(this.enrollEventLength);
      } else {
        this.enrollEventLength = { length: 3, button: 'Show All' };
      }
    }
    if (value == 'past') {
      if (this.pastEventLength['button'] == 'Show All') {
        this.pastEventLength = {
          length: this.pastEvents.length,
          button: 'Show Less',
        };
      } else {
        this.pastEventLength = { length: 3, button: 'Show All' };
      }
    }
  }
   async catchEvent(event:any){
      const data: any[] = [];
      data.push(event?.Obj[0]['controls']);
      const obj:Object = {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
      }
      data[0].forEach((element: any) => {
      if(element?.label == 'Name'){
        obj['name'] = element?.value
      }else if(element?.label == 'Email'){
        obj['email'] = element?.value
      }else if(element?.label == 'Mobile No.'){
        obj['password'] = element?.value
        obj['password_confirmation'] = element?.value
      }
    })
    await this._apiService.registerEvent(obj).then(async (res:any) =>{
      console.log("LOGIN RESPONSE",res);
      if(res.code == 201 || res.code == 200){
        var localStorageData:any = {
          access_token: res?.access_token,
          username: res?.user?.name,
          useremail: res?.user?.email,
          role: res?.role[0],
          userId: res?.user?.id,
          roleId: res?.user?.role_id,
        };
        this.utility.loggedUsername = res?.user?.name
     
      await localStorage.setItem('access_token', JSON.stringify(localStorageData));
      }
    })
  }
  formSubmit(event:any){

  }
}
