import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  providers: [MessageService]
})
export class PermissionComponent implements OnInit {
  slug: any;

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, private activated: ActivatedRoute, private MessageService : MessageService, private route: Router) { }

  @ViewChild('dt1') dt1: Table

  breadcrumb: any[] = [
    {
      title: 'Permissions',
      subTitle: 'Masters',
    },
  ];

  checkAll: any = false;

  allPermissions: any = [];

  groupByAllPermissions: any = [];

  selectedValue: any = []

  filterVal: any;

  async ngOnInit() {
    let slug2 = this.activated

    this.slug = this.activated.snapshot;
    if (this.slug) {
      this.spinner.show();
      await this.apiService.getAllPermissions().then((res: any) => {
        this.allPermissions = res?.data;
        let resultObject = this.groupBy(res?.data, 'title');
        for (const property in resultObject) {
          this.groupByAllPermissions.push(resultObject[property])
        }
      })
      await this.apiService.getUserPermission(this.slug?.params?.slug, this.slug?.params?.slug1).then((res: any) => {
        this.groupByAllPermissions.forEach((response: any, index: any) => {
          this.selectedValue.push([])
          response.forEach(element => {
            let permission = res?.data?.filter(resp => resp.permission_id == element.id);
            if (permission?.length > 0) {
              this.selectedValue[index].push(true);
            }
            else {
              this.selectedValue[index].push(false);
            }
          });
        });
        if (res?.data?.length == this.allPermissions?.length) {
          this.checkAll = true;
        }
      }).catch((error: any) => {
        for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
          this.selectedValue.push([])
          for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
            this.selectedValue[i].push(false);
          }
        }
      })
      this.spinner.hide();
    }
  }

  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  reset(dt1) {
    dt1.reset();
    this.filterVal = '';
  }

  onCheckboxAction(event: any, i: number, index: number) {
    if (event.checked) {
      this.selectedValue[i][index] = true;
    }
    else if (event.checked == false) {
      this.selectedValue[i][index] = false
    }
  }

  checkAllPermission() {
    if (this.checkAll == true) {
      for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
        for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
          this.selectedValue[i][j] = true;
        }
      }
    }
    if (this.checkAll == false) {
      for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
        for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
          this.selectedValue[i][j] = false;
        }
      }
    }
  }

  setPermission() {
    let sendArray = [];
    for (let i = 0; i < this.selectedValue?.length; i++) {
      for (let j = 0; j < this.selectedValue[i]?.length; j++) {
        if (this.selectedValue[i][j] == true) {
          sendArray.push(this.groupByAllPermissions[i][j].id);
        }
      }
    }
    let object = {
      "user_id": Number(this.slug.params.slug),
      "role_id": Number(this.slug.params.slug1),
      "permission_ids": sendArray
    }
    this.apiService.setPermission(object).then((res: any) => {
      this.MessageService.add({
        severity: 'success',
        detail: res.message,
    });
      this.route.navigateByUrl('/user-panel/users');
    })
  }
}
