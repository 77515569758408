<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>

<section class="footerSection" *ngIf="footerData">
    <div class="footer">
        <div class="row m-0">
            <div class="col-lg-3 col-md-6 col-12 socialLinksSection">
                <div class="footerLogo" routerLink="/">
                    <img src="{{footerData?.logo}}" alt="">
                </div>
                <p class="footerContent">{{footerData?.content}}</p>
                <div class="mediaIcons d-flex align-items-center">
                    <ng-container *ngFor="let item of footerData?.icons">
                        <ng-container *ngIf="item?.redirectioURL">
                            <a href="{{item?.redirectioURL}}" target="_blank">
                                <img src="{{item?.icon}}" alt="" style="width : 19px;height : 19px;">
                            </a>
                        </ng-container>
                        <ng-container *ngIf="!item?.redirectioURL">
                            <img src="{{item?.icon}}" alt="" style="width : 19px;height : 19px;">
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12 serviceNavLinks">
                <div>
                    <p class="serviceHeading">
                        {{footerData?.serviceHeading}}
                    </p>
                    <div class="services">
                        <ng-container *ngFor="let item of footerData?.services">
                            <p class="serviceLinks" (click)="navigate(item?.slug)">{{item?.service}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12 contactNavLinks">
                <div class="contactInformation">
                    <p class="contactHeading">
                        {{footerData?.contactHeading}}
                    </p>
                    <div class="contactInfo">
                        <p class="contactLinks">{{footerData?.contactAddress }}</p>
                        <ng-container *ngFor="let item of footerData?.contactInfo">
                            <p class="contactLinks">{{item?.info}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12 newsLetterSection">
                <div class="newsLetterSectionContent">
                    <p class="newsLetterHeading">
                        {{footerData?.newsletterHeading}}
                    </p>
                    <p class="newsLetterContent">{{footerData?.newsletterContent}}</p>
                    <div class="newsLetterForm">
                        <form #newsletter="ngForm" (ngSubmit)="submitNewsLetter(newsletter)">
                            <div class="formControlDiv">
                                <input type="email" class="formControl" name="emailaddress" #emailaddress="ngModel"
                                    [(ngModel)]="newsLetterEmail" pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                    placeholder="Enter Email Address" required>
                                <div *ngIf="newsletter.submitted || emailaddress.touched" class="alert alert-danger">
                                    <div *ngIf="emailaddress.errors?.['required']">
                                        Email is Required.
                                    </div>
                                    <div *ngIf="emailaddress.errors?.['pattern']">
                                        Email is Invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="submitButton">
                                <button type="submit" class="homeBUtton" style="min-width: 100%;">Submit Now</button>
                                <a *ngIf="footerData?.newsletterredirection"
                                    href="{{footerData?.newsletterredirection}}" target="_blank"
                                    rel="noopener noreferrer">
                                    <button type="button" class="homeBUtton mt-2" style="min-width: 100%;">
                                        Newsletter
                                    </button>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footerStrip">
        <div class="copyRightSection">
            <div class="copyRight">
                <p class="copyRightContent">
                    {{footerData?.copyrightContent}}
                </p>
                <p class="copyRightContent">All Rights Reserved.</p>
                <div style="padding-top: 6px;">
                    <span style=" color: white;">Powered by</span> <a href="https://webanixsolutions.com/" target="_blank" class="webanix" style="color: white; margin-left: 6px;">WEBaniX</a>
                </div>
            </div>
            <div class="navLinks">
                <ul>
                    <ng-container *ngFor="let item of footerData?.navlinks">
                        <li><span (click)="navigate(item.slug)"
                                style="color: white; cursor: pointer;">{{item.link}}</span></li>
                    </ng-container>
                    <li><span (click)="navigate('blog-page/')" style="color: white; cursor: pointer;">Blogs</span></li>
                    <!-- <li><span (click)="navigate('Aao-factory-dekhein/')" style="color: white; cursor: pointer;">Aao Factory Dekhein</span></li> -->
                </ul>
            </div>
        </div>
    </div>
</section>