<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>

<!-- ********************** Main Content ******************** -->


<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>


<!-- ***************************************************************Tax Master List*************************************************************** -->
<div class="Master-view-box">
    <div class="theader">
        <div class="flex">
            <div class="d-flex align-items-center justify-content-between w-100">
                <div>
                    <!-- <p-button type="button" class="mx-2" (click)="setPermission()" label="Set Permission"></p-button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <p-table #dt1 [value]="usersList" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                [rows]="10" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [paginator]="true"
                currentPageReportTemplate="{totalRecords} of {{totalRecords}} records" [globalFilterFields]="['user.name' , 'user.email' , 'role.name']">
                <ng-template pTemplate="header" style="min-height:
                        500px;">
                    <tr>
                        <th style="min-width:50px;">S. No.</th>
                        <th style="min-width:50px;">Action</th>
                        <th style="min-width:100px">User Name</th>
                        <th style="min-width:100px">Email Address</th>
                        <th style="min-width:100px">Role</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="caption">
                    <span class="p-input-icon-left d-flex align-items-center member-filters">
                        <!-- <i class="pi pi-search"></i> -->
                        <input pInputText type="text" class="mr-2"
                            (input)="dt1.filterGlobal($event.target.value,
                            'contains')" style="height: 40px; width: 300px;"
                            placeholder="Global Search" [(ngModel)]="filterval" />
                        <p-button type="button" (click)="reset(dt1)"
                            icon="bx bx-reset"></p-button>
                    </span>
                </ng-template>
                <ng-template pTemplate="body" let-users let-i="rowIndex">
                    <tr>
                        <td>{{i+1}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-unlock" class="m-auto p-button-rounded p-button-text
                            p-button-info"
                                routerLink="/user-panel/member-permissions-action/{{users?.user?.id}}/{{users?.role.id}}"></button>
                        </td>
                        <td>{{users?.user?.name ?? ''}}</td>
                        <td>{{users?.user?.email ?? '-'}}</td>
                        <td>{{users?.role.name ?? '-'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>