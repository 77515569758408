import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let convertedData: any;
    if (value?.includes(', ')) {
      convertedData = value?.split(', ');
    } else {
      convertedData = value?.split(',');
    }
    // console.log(convertedData);

    return convertedData;
  }
}
