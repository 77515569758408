<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<app-spinner></app-spinner>
<!-- Header Start -->
<section class="top_header mainheader">
    <div class="top_strip">
        <div class="contact_info">
            <p class="contact_number">+91-6377205389 / info@ucciudaipur.com</p>
            <!-- <p class="contact_email"></p> -->
        </div>
        <div class="btn_div d-flex align-items-center">
            <!-- {{localStorage}} -->
            <!-- <p class="m-0 user_name" *ngIf="headerText">Welcome {{userName | titlecase}},</p> -->
            <p class="m-0 login_btn" *ngIf="headerText" (click)="goToDashboard()">{{headerText}},</p>
            <!-- <p class="login_btn" *ngIf="localStorage" (click)="logout()"><i class='bx bx-log-out px-2' style="text-decoration: underline;"></i>Logout</p> -->
            <ng-container *ngIf="headerText == ''">
                <p class="m-0 login_btn" (click)="onLoginClick('login')" #login data-bs-toggle="modal"
                    data-bs-target="#loginRegisterModal"><i class="flaticon-user mx-2"></i>Login / </p>
                    <p class="m-0 login_btn" (click)="onLoginClick('register')" #login data-bs-toggle="modal"
                    data-bs-target="#RegisterModal">Register </p>
            </ng-container>
            <div class="others-option">
                <!-- <div class="option-item" *ngIf="!localStorage">
                    <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i
                            class="flaticon-user"></i> Login / Register</span>
                </div> -->
                <!-- *ngIf="localStorage" -->
                <div class="option-item" *ngIf="headerText">
                    <div class="dropdown profile-nav-item">
                        <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <div class="menu-profile">
                                <span class="name">Welcome {{utility.loggedUsername | titlecase}}</span>
                            </div>
                        </a>
                        <div class="dropdown-menu">
                            <div class="dropdown-header d-flex
                                flex-column align-items-center">
                                <div class="info text-center">
                                    <span class="name" style="color:
                                        #000; white-space: normal">{{userName | titlecase}}</span>
                                    <p class="mb-3 email">{{UserEmail}}</p>
                                </div>
                            </div>
                            <div class="dropdown-body">
                                <ul class="profile-nav p-0 pt-3">
                                    <!-- <li class="nav-item">
                                        <a (click)="goToDashboard()" class="nav-link cursor-pointer">
                                            <i class='bx bx-user'></i>
                                            <span>{{headerText}}</span>
                                        </a>
                                    </li> -->
                                    <li class="nav-item"><a class="nav-link"><i class='bx bx-edit-alt'></i>
                                            <span data-bs-toggle="modal" data-bs-target="#ChangePasswordModal"
                                                (click)="modelForChangepassword('changepassword')">Change
                                                Password</span></a></li>
                                    <!-- <li class="nav-item"><a class="nav-link"><i class='bx
                                        bx-edit-alt'></i>
                                            <span class="cursor-pointer" (click)="onSwitchAccount()">Switch
                                                Account</span></a></li> -->
                                </ul>
                            </div>
                            <div class="dropdown-footer">
                                <ul class="profile-nav">
                                    <li class="nav-item">
                                        <div>
                                            <a class="nav-link" (click)="logout()"><i class='bx
                                                    bx-log-out'></i>
                                                <span>Logout</span></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="option-item">
                    <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i>
                        Add Listing</a>
                </div> -->
            </div>
        </div>
    </div>
</section>
<section class="header_section" id="header">

    <ng-container *ngIf="!(navLinks?.length > 0)">
        <ngx-shimmer-loading></ngx-shimmer-loading>
    </ng-container>

    <ng-container *ngIf="navLinks?.length > 0">
        <!-- Header Logo Section Start -->
        <div class="web_logo" routerLink="/">
            <img src="assets/img/ucci-logo.PNG" alt="" width="100%" height="100%">
        </div>


        <!-- Header Logo Section End -->
        <!-- Header Nav Links Section for desktop screen Start -->
        <div class="head_nav_links">
            <div class="nav_links_div">
                <ul class="unOrderNavLinks">

                    <ng-container *ngIf="navLinks">
                        <ng-container *ngFor="let menu of navLinks; index as mainIndex">
                            <li class="nav_link para_family navigation_menus" *ngIf="menu.parent_id == null">
                                <span
                                    (click)="navigate(menu.title == 'Marketplace' ? 'Marketplace' : (menu.title == 'Home' ? 'Home' : menu.slug ?? 'string'))"
                                    style="cursor:pointer">{{menu.title}}</span>
                                <!-- style="cursor:pointer">{{menu.title | titlecase}}</span> -->
                                <span *ngIf="menu.sub_menu?.length > 0" (click)="openmenu(mainIndex)"
                                    style="margin-left:5px;cursor: pointer;">
                                    <i *ngIf="!(openMenu && (mainIndex == menuIndex))" class="pi pi-angle-down"></i>
                                    <i *ngIf="(openMenu && (mainIndex == menuIndex))" class="pi pi-angle-up"></i>
                                </span>
                                <div class="resp_head_nav_links">
                                    <div class="open-menu" *ngIf="openMenu && (mainIndex == menuIndex)">
                                        <ul class="responsive-menu">
                                            <ng-container *ngFor="let item of childMenus;index as index">
                                                <li class="nav_link_child">
                                                    <span style="cursor: pointer;"
                                                        (click)="navigate(menu?.slug, item.slug ?? 'string')">{{item.title
                                                        | titlecase}}</span>
                                                    <span *ngIf="item.sub_menu?.length > 0"
                                                        (click)="openChildMenus('child1', index)"
                                                        style="margin-left:5px;cursor: pointer;">

                                                        <i *ngIf="!(child1Check && (index== child1Index))"
                                                            class="pi pi-angle-down"></i>
                                                        <i *ngIf="child1Check && (index== child1Index)"
                                                            class="pi pi-angle-up"></i>
                                                        <!-- {{child1Check && (index== child1Index) ? '-' : '+'}} -->

                                                    </span>

                                                    <ng-container *ngIf="item.sub_menu?.length > 0">
                                                        <ul *ngIf="child1Check && index == child1Index"
                                                            class="desktopchild1class">

                                                            <ng-container

                                                                *ngFor="let child1 of item.sub_menu; index as secondIndex">
 
                                                                <li class="child1_nav_link">
                                                                    <span style="cursor: pointer;" *ngIf="child1.title == 'Apply for award'" routerLink="coming-soon">{{child1.title | titlecase}}</span>
                                                                    <a href="https://awards.ucciudaipur.com" style="cursor: pointer;" *ngIf="child1.title == 'Apply for award'">{{child1.title | titlecase}}</a>
                                                                    <span *ngIf="child1.title != 'Apply for award'"
                                                                        (click)="navigate(menu?.slug, item.slug, child1.slug ? child1.slug : (child1.title == 'Sub Committees' ? 'Sub-Committees' : 'string'))"
                                                                        style="cursor: pointer;">{{child1.title |
                                                                        titlecase}}</span>
                                                                    <span *ngIf="child1.sub_menu?.length > 0"
                                                                        (click)="openChildMenus('child2', secondIndex)"
                                                                        style="margin-left:5px;cursor: pointer;">

                                                                        <i *ngIf="!(child2Check && (secondIndex == child2Index))"
                                                                            class="pi pi-angle-down"></i>
                                                                        <i *ngIf="child2Check && (secondIndex == child2Index)"
                                                                            class="pi pi-angle-up"></i>

                                                                        <!-- {{child2Check && (secondIndex == child2Index) ? '-' : '+'}} -->
                                                                    </span>

                                                                    <ng-container *ngIf="child1.sub_menu?.length > 0">

                                                                        <ul *ngIf="child2Check && child2Index == secondIndex"
                                                                            class="desktopchild2class">
                                                                            <ng-container
                                                                            
                                                                                *ngFor="let child2 of child1.sub_menu; index as thridIndex">
                                                                                <li class="child2_nav_link">
                                                                                    <span
                                                                                        (click)="navigate(menu?.slug, item.slug, child1.slug, child2.slug ?? 'string')"
                                                                                        style="cursor: pointer;">{{child2.title
                                                                                        | titlecase}}</span>
                                                                                    <span
                                                                                        *ngIf="child2.sub_menu?.length > 0"
                                                                                        (click)="openChildMenus('child3', thridIndex)"
                                                                                        style="margin-left:5px;cursor: pointer;">

                                                                                        <i *ngIf="!(child3Check && (child3Index == thridIndex))"
                                                                                            class="pi pi-angle-down"></i>
                                                                                        <i *ngIf="child3Check && (child3Index == thridIndex)"
                                                                                            class="pi pi-angle-up"></i>

                                                                                        <!-- {{child3Check && (child3Index == thridIndex) ? '-' : '+'}} -->
                                                                                    </span>

                                                                                    <ng-container
                                                                                        *ngIf="child2.sub_menu?.length > 0">

                                                                                        <ul *ngIf="child3Check && child3Index == thridIndex"
                                                                                            class="desktopchild3class">
                                                                                            <ng-container
                                                                                                *ngFor="let child3 of child2.sub_menu; index as fourthIndex">
                                                                                                <li
                                                                                                    class="child3_nav_link">
                                                                                                    <span
                                                                                                        (click)="navigate(menu?.slug, item.slug, child1.slug, child2.slug, child3.slug ?? 'string')"
                                                                                                        style="cursor: pointer;">{{child3.title
                                                                                                        |
                                                                                                        titlecase}}</span>
                                                                                                    <span
                                                                                                        *ngIf="child3.sub_menu?.length > 0"
                                                                                                        (click)="openChildMenus('child4', fourthIndex)"
                                                                                                        style="margin-left:5px;cursor: pointer;">

                                                                                                        <i *ngIf="!(child3Check && (child3Index == thridIndex))"
                                                                                                            class="pi pi-angle-down"></i>
                                                                                                        <i *ngIf="child4Check && (child4Index == fourthIndex)"
                                                                                                            class="pi pi-angle-up"></i>

                                                                                                        {{child4Check &&
                                                                                                        (child4Index ==
                                                                                                        fourthIndex) ?
                                                                                                        '-' : '+'}}
                                                                                                    </span>

                                                                                                    <ng-container
                                                                                                        *ngIf="child3.sub_menu?.length > 0">

                                                                                                        <ul *ngIf="child4Check && child4Index == fourthIndex"
                                                                                                            class="desktopchild4class">
                                                                                                            <ng-container
                                                                                                                *ngFor="let child4 of child3.sub_menu; index as fifthIndex">
                                                                                                                <li
                                                                                                                    class="child4_nav_link">
                                                                                                                    <span
                                                                                                                        (click)="navigate(menu?.slug, item.slug, child1.slug, child2.slug, child3.slug, child4.slug ?? 'string')"
                                                                                                                        style="cursor: pointer;">{{child4.title
                                                                                                                        |
                                                                                                                        titlecase}}</span>
                                                                                                                    <span
                                                                                                                        *ngIf="child4.sub_menu?.length > 0"
                                                                                                                        (click)="openChildMenus('child5', fifthIndex)"
                                                                                                                        style="margin-left:5px;cursor: pointer;">{{child5Check
                                                                                                                        &&
                                                                                                                        (child5Index
                                                                                                                        ==
                                                                                                                        fifthIndex)
                                                                                                                        ?
                                                                                                                        '-'
                                                                                                                        :
                                                                                                                        '+'}}</span>

                                                                                                                    <ng-container
                                                                                                                        *ngIf="child4.sub_menu?.length > 0">

                                                                                                                        <ul *ngIf="child5Check && child5Index == fifthIndex"
                                                                                                                            class="desktopchild5class">
                                                                                                                            <ng-container
                                                                                                                                *ngFor="let child5 of child4.sub_menu; index as fifthIndex">
                                                                                                                                <li
                                                                                                                                    class="child5_nav_link">
                                                                                                                                    <span
                                                                                                                                        (click)="navigate(menu?.slug, item.slug, child1.slug, child2.slug, child3.slug, child4.slug, child5.slug ?? 'string')"
                                                                                                                                        style="cursor: pointer;">{{child5.title
                                                                                                                                        |
                                                                                                                                        titlecase}}</span>
                                                                                                                                    <span
                                                                                                                                        *ngIf="child5.sub_menu?.length > 0"
                                                                                                                                        (click)="openChildMenus('child6', fifthIndex)"
                                                                                                                                        style="margin-left:5px;cursor: pointer;">+</span>
                                                                                                                                </li>
                                                                                                                            </ng-container>
                                                                                                                        </ul>

                                                                                                                    </ng-container>

                                                                                                                </li>
                                                                                                            </ng-container>
                                                                                                        </ul>

                                                                                                    </ng-container>

                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>

                                                                                    </ng-container>

                                                                                </li>
                                                                            </ng-container>


                                                                           
                                                                        </ul>

                                                                    </ng-container>

                                                                </li>

                                                            </ng-container>

                                                        </ul>
                                                       

                                                    </ng-container>


                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            
                        </ng-container>
                        <!-- <li class="nav_link para_family navigation_menus" *ngIf="showAaoFactoryPage">
                            <span
                                (click)="navigate('afd/')"
                                style="cursor:pointer">Aao Factory Dekhein</span></li>
                                 -->
                        <button *ngIf="headerText == ''" class="btn btn-danger" (click)="onLoginClick('login')" #login data-bs-toggle="modal"
                        data-bs-target="#loginRegisterModal">Login</button>
                        <button *ngIf="headerText == ''" class="btn btn-outline-danger ms-2" (click)="onLoginClick('register')" #login data-bs-toggle="modal"
                        data-bs-target="#RegisterModal">Register</button>
                    </ng-container>
                </ul>
            </div>
        </div>
        <!-- Header Nav Links Section for desktop screen End -->
        <!-- Header Nav Links Section for mobile screen Start -->
        <div class="resp_head_nav_links mobile_screen">
            <div class="nav_icon d-flex align-items-center">
                <button class="navbar-toggler pr-0" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span style="display: flex;align-items:center;" (click)="openmenu()" *ngIf="!openMobileMenu"><i
                            class="fa fa-bars light-color"></i></span>
                    <span style="display: flex;align-items:center;" (click)="openmenu()" *ngIf="openMobileMenu"><i
                            class="fa light-color">&#xf00d;</i></span>
                </button>
            </div>
            <div class="open-menu" *ngIf="openMobileMenu">
                <ul class="responsive-menu">
                    <ng-container *ngFor="let item of navLinks;index as index">
                        <li class="nav_link" *ngIf="item.parent_id == null">
                            <span style="cursor: pointer;"
                                (click)="navigate(item.title == 'Marketplace' ? 'Marketplace' : item.slug ?? 'string')">{{item.title}}</span>
                            <span *ngIf="item.sub_menu?.length > 0" (click)="openChildMenus('child1', index)"
                                style="margin-left:5px;cursor: pointer;">
                                <i *ngIf="!(child1Check && index == child1Index)" class="pi pi-angle-down"></i>
                                <i *ngIf="child1Check && index == child1Index" class="pi pi-angle-up"></i>
                                <!-- {{child1Check && index == child1Index ? '-' :'+'}} -->
                            </span>


                            <ng-container *ngIf="item.sub_menu?.length > 0">

                                <ul *ngIf="child1Check && index == child1Index" class="child1class">

                                    <ng-container *ngFor="let child1 of item.sub_menu; index as secondIndex">

                                        <li class="child1_nav_link">

                                            <span (click)="navigate(item.slug, child1.slug ?? 'string')"
                                                style="cursor: pointer;">{{child1.title}}</span>
                                            <span *ngIf="child1.sub_menu?.length > 0"
                                                (click)="openChildMenus('child2', secondIndex)"
                                                style="margin-left:5px;cursor: pointer;">

                                                <i *ngIf="!(child2Check && secondIndex == child2Index)"
                                                    class="pi pi-angle-down"></i>
                                                <i *ngIf="child2Check && secondIndex == child2Index"
                                                    class="pi pi-angle-up"></i>

                                                <!-- {{child2Check && secondIndex == child2Index ? '-' : '+'}} -->

                                            </span>


                                            <ng-container *ngIf="child1.sub_menu?.length > 0">

                                                <ul *ngIf="child2Check && child2Index == secondIndex"
                                                    class="child2class">
                                                    <ng-container
                                                        *ngFor="let child2 of child1.sub_menu; index as thridIndex">
                                                        <li class="child2_nav_link">
                                                            <span
                                                                (click)="navigate(item.slug, child1.slug, child2.slug ?? (child2.title == 'Sub Committees' ? 'Sub-Committees' : 'string'))">{{child2.title}}</span>
                                                            <span *ngIf="child2.sub_menu?.length > 0"
                                                                (click)="openChildMenus('child3', thridIndex)"
                                                                style="margin-left:5px;cursor: pointer;">

                                                                <i *ngIf="!(child3Check && thridIndex == child3Index)"
                                                                    class="pi pi-angle-down"></i>
                                                                <i *ngIf="child3Check && thridIndex == child3Index"
                                                                    class="pi pi-angle-up"></i>

                                                                <!-- {{child3Check && thridIndex == child3Index ? '-' : '+'}} -->
                                                            </span>

                                                            <ng-container *ngIf="child2.sub_menu?.length > 0">

                                                                <ul *ngIf="child3Check && child3Index == thridIndex"
                                                                    class="child3class">
                                                                    <ng-container
                                                                        *ngFor="let child3 of child2.sub_menu; index as fourthIndex">
                                                                        <li class="child3_nav_link">
                                                                            <span
                                                                                (click)="navigate(item.slug, child1.slug, child2.slug, child3.slug ?? 'string')">{{child3.title}}</span>
                                                                            <span *ngIf="child3.sub_menu?.length > 0"
                                                                                (click)="openChildMenus('child4', fourthIndex)"
                                                                                style="margin-left:5px;cursor: pointer;">

                                                                                <i *ngIf="!(child4Check && fourthIndex == child4Index)"
                                                                                    class="pi pi-angle-down"></i>
                                                                                <i *ngIf="child4Check && fourthIndex == child4Index"
                                                                                    class="pi pi-angle-up"></i>

                                                                                <!-- {{child4Check && fourthIndex == child4Index ? '-' : '+'}} -->

                                                                            </span>

                                                                            <ng-container
                                                                                *ngIf="child3.sub_menu?.length > 0">

                                                                                <ul *ngIf="child4Check && child4Index == fourthIndex"
                                                                                    class="child4class">
                                                                                    <ng-container
                                                                                        *ngFor="let child4 of child3.sub_menu; index as fifthIndex">
                                                                                        <li class="child4_nav_link">
                                                                                            <span
                                                                                                (click)="navigate(item.slug, child1.slug, child2.slug, child3.slug, child4.slug ?? 'string')">{{child4.title}}</span>
                                                                                            <span
                                                                                                *ngIf="child4.sub_menu?.length > 0"
                                                                                                (click)="openChildMenus('child5', fifthIndex)"
                                                                                                style="margin-left:5px;cursor: pointer;">{{child5Check
                                                                                                && fifthIndex ==
                                                                                                child5Index
                                                                                                ? '-' : '+'}}</span>

                                                                                            <ng-container
                                                                                                *ngIf="child4.sub_menu?.length > 0">

                                                                                                <ul *ngIf="child5Check && child5Index == fifthIndex"
                                                                                                    class="child5class">
                                                                                                    <ng-container
                                                                                                        *ngFor="let child5 of child4.sub_menu; index as fifthIndex">
                                                                                                        <li
                                                                                                            class="child5_nav_link">
                                                                                                            <span
                                                                                                                (click)="navigate(item.slug, child1.slug, child2.slug, child3.slug, child4.slug, child5.slug ?? 'string')">{{child5.title}}</span>
                                                                                                            <span
                                                                                                                *ngIf="child5.sub_menu?.length > 0"
                                                                                                                (click)="openChildMenus('child6', fifthIndex)"
                                                                                                                style="margin-left:5px;cursor: pointer;">+</span>
                                                                                                        </li>
                                                                                                    </ng-container>
                                                                                                </ul>

                                                                                            </ng-container>

                                                                                        </li>
                                                                                    </ng-container>
                                                                                </ul>

                                                                            </ng-container>

                                                                        </li>
                                                                    </ng-container>
                                                                </ul>

                                                            </ng-container>

                                                        </li>
                                                    </ng-container>
                                                </ul>

                                            </ng-container>

                                        </li>

                                    </ng-container>

                                </ul>

                            </ng-container>


                        </li>

                    </ng-container>
                    <!-- <li class="nav_link para_family navigation_menus">
                        <span
                            (click)="navigate('aao-factory-dekhein/')"
                            style="cursor:pointer">Aao Factory Dekhein</span></li> -->

                    <!-- <ng-container *ngIf="authMenus" >
                        <li class="nav_link">

                            <span style="cursor: pointer;" (click)="navigate('string')">User Panel</span>
                            <span (click)="openChildMenus('user_panel')" style="margin-left:5px;cursor: pointer;">
                                <i *ngIf="!userPanelAuth" class="pi pi-angle-down"></i>
                                <i *ngIf="userPanelAuth" class="pi pi-angle-up"></i>
                            </span>
                            <ul class="child1class">
                                <ng-container *ngIf="userPanelAuth">
                                    <ng-container *ngIf="adminMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a class="nav-link" routerLink="/user-panel/dashboard">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/call-member" class="nav-link">
                                                <span class="menu-title">Contact List</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/audit-logs" class="nav-link">
                                                <span class="menu-title">Audit Logs</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/issues-lists" class="nav-link">
                                                <span class="menu-title">Issues</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/membership-renewal" class="nav-link">
                                                <span class="menu-title">Membership Renewal </span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/products" class="nav-link">
                                                <span class="menu-title">Products </span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>
                                        
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                                <span class="menu-title">Regular Member</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                                                <span class="menu-title">HWM Member</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/non-member-list" class="nav-link">
                                                <span class="menu-title">Non Member Exporter</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link">
                                                <span class="menu-title">Certificate of Origin</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/document-attestation-list" class="nav-link">
                                                <span class="menu-title">Document Attestation</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/visa-approval" class="nav-link">
                                                <span class="menu-title">Visa Recommendation</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/member-data-update" class="nav-link">
                                                <span class="menu-title">Membership Revision Approval</span>
                                            </a>
                                        </li>
                                        
                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Marketplace</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                            
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/category" class="nav-link pl-2">
                                                        <span class="menu-title">Category</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/sub-category" class="nav-link pl-2">
                                                        <span class="menu-title">Sub Category</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/brand" class="nav-link pl-2">
                                                        <span class="menu-title">Brand</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/member-categories" class="nav-link">
                                                <span class="menu-title">Member Categories</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/member-classification" class="nav-link">
                                                <span class="menu-title">Member Classification</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/service-type-master" class="nav-link">
                                                <span class="menu-title">Service Type-Fees</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/turnover-master" class="nav-link">
                                                <span class="menu-title">Turnover</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/membership-type-master" class="nav-link">
                                                <span class="menu-title">Membership Type</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/custom-form" class="nav-link">
                                                <span class="menu-title">Custom Forms</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/events-list" class="nav-link">
                                                <span class="menu-title">Events List</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/escalation-matrix" class="nav-link">
                                                <span class="menu-title">Escalation Matrix</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/create-issue-type" class="nav-link">
                                                <span class="menu-title">Issue Type</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/area-of-interest" class="nav-link">
                                                <span class="menu-title">Area of Interest</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/tax-master" class="nav-link">
                                                <span class="menu-title">Tax</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/users" class="nav-link">
                                                <span class="menu-title">Roles & Permissions</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/news-and-events" class="nav-link">
                                                <span class="menu-title">News & Announcements</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/settings" class="nav-link">
                                                <span class="menu-title">Settings</span>
                                            </a>
                                        </li>
                                        
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/menu-list" class="nav-link">
                                                <span class="menu-title">Menus</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/footer-list" class="nav-link">
                                                <span class="menu-title">Footer</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/home-template" class="nav-link">
                                                <span class="menu-title">Home Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/template-1" class="nav-link">
                                                <span class="menu-title">Grid Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/template-2" class="nav-link">
                                                <span class="menu-title">List Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/committee-template" class="nav-link">
                                                <span class="menu-title">Committee Template</span>
                                            </a>
                                        </li>
                                       
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="ceoMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                                <span class="menu-title">Regular Member</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="staffMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/dashboard" class="nav-link">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/call-member" class="nav-link">
                                                <span class="menu-title">Contact List</span>
                                            </a>
                                        </li>
                                       
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/issues-lists" class="nav-link">
                                                <span class="menu-title">Issues</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/enrolled-events" class="nav-link">
                                                <span class="menu-title">Enrolled Events</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/custom-form" class="nav-link">
                                                <span class="menu-title">Custom Forms</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/events-list" class="nav-link">
                                                <span class="menu-title">Events List</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                                <span class="menu-title">Regular Member</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                                                <span class="menu-title">HWM Member</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/non-member-list" class="nav-link">
                                                <span class="menu-title">Non Member Exporter</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/certificate-of-origin-list" class="nav-link">
                                                <span class="menu-title">Certificate of Origin</span>
                                            </a>
                                        </li>

                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/document-attestation-list" class="nav-link">
                                                <span class="menu-title">Document Attestation</span>
                                            </a>
                                        </li>

                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/visa-approval" class="nav-link">
                                                <span class="menu-title">Visa Recommendation</span>
                                            </a>
                                        </li>

                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/member-data-update" class="nav-link">
                                                <span class="menu-title">Member Data Updates</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/menu-list" class="nav-link">
                                                <span class="menu-title">Menus</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/footer-list" class="nav-link">
                                                <span class="menu-title">Footer</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/home-template" class="nav-link">
                                                <span class="menu-title">Home Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/template-1" class="nav-link">
                                                <span class="menu-title">Grid Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/template-2" class="nav-link">
                                                <span class="menu-title">List Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/committee-template" class="nav-link">
                                                <span class="menu-title">Committee Template</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/dashboard" class="nav-link">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/hwm-member-list" class="nav-link">
                                                <span class="menu-title">My MemberShip</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/avail-services" class="nav-link">
                                                <span class="menu-title">Services</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Apply for Membership</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                           
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/regular-member-form"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Regular Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/hwm-registration" class="nav-link pl-2">
                                                        <span class="menu-title">HWM Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/non-member-registration"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Non Member Exporter</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/enrolled-events" class="nav-link">
                                                <span class="menu-title">Enrolled Events</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/my-issues" class="nav-link">
                                                <span class="menu-title">My Issues</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/add-product" class="nav-link">
                                                <span class="menu-title">Add Product </span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>

                                       
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="regularMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/dashboard" class="nav-link">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/regular-member-list" class="nav-link">
                                                <span class="menu-title">My Membership</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Services</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                            
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/certificate-of-origin-list"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Certificate of Origin</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/document-attestation-list"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Document Attestation</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/visa-approval" class="nav-link pl-2">
                                                        <span class="menu-title">Visa Recommendation</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Apply for Membership</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                            
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/regular-member-form"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Regular Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/hwm-registration" class="nav-link pl-2">
                                                        <span class="menu-title">HWM Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/non-member-registration"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Non Member Exporter</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/enrolled-events" class="nav-link">
                                                <span class="menu-title">Enrolled Events</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/representatives-approval" class="nav-link">
                                                <span class="menu-title">Representatives Approval</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/my-issues" class="nav-link">
                                                <span class="menu-title">My Issues</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/add-product" class="nav-link">
                                                <span class="menu-title">Add Product </span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>

                                        
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="nonMemberMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/dashboard" class="nav-link">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/non-member-list" class="nav-link">
                                                <span class="menu-title">My MemberShip</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Services</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                          
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/certificate-of-origin-list"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Certificate of Origin</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/document-attestation-list"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Document Attestation</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/visa-approval" class="nav-link pl-2">
                                                        <span class="menu-title">Visa Recommendation</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="child1_nav_link child1_nav_link-title">
                                          
                                            <span class="menu-title">Apply for Membership</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                           
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/regular-member-form"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Regular Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/hwm-registration" class="nav-link pl-2">
                                                        <span class="menu-title">HWM Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/non-member-registration"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Non Member Exporter</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/add-product" class="nav-link">
                                                <span class="menu-title">Add Product </span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/enrolled-events" class="nav-link">
                                                <span class="menu-title">Enrolled Events</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/my-issues" class="nav-link">
                                                <span class="menu-title">My Issues</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>
                                        
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="guestMenus">
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/dashboard" class="nav-link">
                                                <span class="menu-title">Dashboard</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link child1_nav_link-title">
                                            
                                            <span class="menu-title">Apply for Membership</span>
                                            <span style="margin-left:5px;cursor: pointer;">
                                                <i class="pi pi-angle-up"></i>
                                            </span>
                                           
                                            <ul class="child1class">
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/regular-member-form"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Regular Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/hwm-registration" class="nav-link pl-2">
                                                        <span class="menu-title">HWM Member</span>
                                                    </a>
                                                </li>
                                                <li class="child1_nav_link" routerLinkActive="activeLink">
                                                    <a routerLink="/user-panel/non-member-registration"
                                                        class="nav-link pl-2">
                                                        <span class="menu-title">Non Member Exporter</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/avail-services" class="nav-link">
                                                <span class="menu-title">Services</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/event-dashboard" class="nav-link">
                                                <span class="menu-title">Event Registration</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/enrolled-events" class="nav-link">
                                                <span class="menu-title">Enrolled Events</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/marketplace-products" class="nav-link">
                                                <span class="menu-title">Marketplace</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link" routerLinkActive="activeLink">
                                            <a routerLink="/user-panel/facilities-approval" class="nav-link">
                                                <span class="menu-title">Facility Booking</span>
                                            </a>
                                        </li>
                                        <li class="child1_nav_link">
                                            <a (click)="logout()" style="cursor: pointer;" class="nav-link">
                                                <span class="menu-title">Logout</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </li>
                    </ng-container> -->
                </ul>
            </div>
            
        </div>
        
    </ng-container>
    
</section>
<!-- Header End -->



<!-- modal for login -->

<div class="modal fade loginRegisterModal" id="loginRegisterModal" #myModal tabindex="-1" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" (click)="resetForm()" class="close" data-bs-dismiss="modal" id="close_btn"
                aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                   <span style="font-size: 21px; font-weight: 500;" *ngIf="isLogin">Login for Member</span>
                   <span style="font-size: 21px; font-weight: 500;"*ngIf="isRegister">Register as New</span>
                </li>
                <!-- <li class="nav-item">
                    <button class="nav-link {{classCheck == 'register' ? 'active' :
                                ''}}" (click)="classCheck= 'register'" id="register-tab" data-bs-toggle="tab"
                        data-bs-target="#register" type="button" role="tab" aria-controls="register"
                        aria-selected="true">Register</button>
                </li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
                <!-- Login Form -->
                <div class="tab-pane fade {{classCheck == 'login' ? 'show active' : ''}}" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <!-- Login form -->
                        <form [formGroup]="loginForm" (ngSubmit)="loginIn()" #formlogin="ngForm">
                            <div class="form-group">
                                <input type="email" scriptInjection placeholder="Username or Email"
                                    formControlName="loginemail" maxlength="50" class="form-control"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="formlogin.submitted && g.loginemail.errors?.required || g.loginemail.touched"
                                    class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="g.loginemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <div class="d-flex"> -->
                                <input [type]="show ? 'text' : 'password'" formControlName="loginpassword"
                                    placeholder="Password" minlength="8" required class="form-control">
                                <i *ngIf="!show" style="position: absolute;
                                    right: 50px;
                                    margin-top: -22px; cursor: pointer;" (click)="showPassword('login')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                    right: 50px;
                                    margin-top: -22px; cursor: pointer;" (click)="showPassword('login')"
                                    aria-hidden="true" *ngIf="show"></i>
                                <!-- </div> -->
                                <div *ngIf="formlogin.submitted &&
                                            g.loginpassword.errors?.required
                                            || g.loginpassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginpassword.errors?.required">Password is Required
                                    </div>
                                    <div *ngIf="g.loginpassword.errors?.minlength">Password Must be at Least 8
                                        Characters Long
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="disablebtn">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an
                            account?
                                <a (click)="isLoginFor('register')">Register Now</a>
                          
                        </span>
                        <span class="dont-account"> <a (click)="classCheck= 'forgot'">Forgot Password</a></span>
                    </div>
                </div>
                <!-- Register Form -->
                <div class="tab-pane fade {{classCheck == 'register' ? 'show active' :
                            ''}}" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="registerForm" (ngSubmit)="signUp()" #formRegister="ngForm">
                            <!-- pop ups -->
                            <div class="form-group">
                                <!-- appHtmlInjection -->
                                <input type="text" scriptInjection formControlName="signupname" placeholder="Name"
                                    class="form-control" (change)="onChange($event.target.value)"
                                    style="text-transform: capitalize;" pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupname.errors?.required
                                            ||
                                            f.signupname.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupname.errors?.required">Name
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupname.errors?.pattern">Please
                                        Enter a Valid User Name
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" scriptInjection formControlName="signupemail" placeholder="Email"
                                    class="form-control" pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupemail.errors?.required ||
                                            f.signupemail.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input [type]="showpass ? 'text' : 'password'" formControlName="signuppassword"
                                    placeholder="Password" minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('signuppassword')?.invalid" required class="form-control">
                                <i *ngIf="!showpass" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('registerpass')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('registerpass')"
                                    aria-hidden="true" *ngIf="showpass"></i>
                                <div *ngIf="signUpSubmitted &&
                                            f.signuppassword.errors?.required
                                            || f.signuppassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signuppassword.errors?.required">Password
                                        is Required
                                    </div>
                                    <div *ngIf="f.signuppassword.errors?.minlength">Password
                                        Must be at Least 8 Characters
                                        Long
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input [type]="showconfirmpass ? 'text' : 'password'" formControlName="confirmpassword" placeholder="Confirm Password"
                                    minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('confirmpassword')?.invalid" required
                                    class="form-control">
                                <i *ngIf="!showconfirmpass" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('confirmpass')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('confirmpass')"
                                    aria-hidden="true" *ngIf="showconfirmpass"></i>
                                <div *ngIf="signUpSubmitted &&
                                            f.confirmpassword.errors?.required
                                            || f.confirmpassword.touched" class="alert
                                            alert-danger">
                                    <div class="invalid-feedback">
                                        Confirm Password is Required
                                    </div>
                                    <div *ngIf="f.confirmpassword.errors?.required">Confirm
                                        Password is Required
                                    </div>
                                </div>
                                <div *ngIf="registerForm.get('confirmpassword').value" class="alert
                                            alert-danger">
                                    {{
                                    registerForm.get('signuppassword').value
                                    === "" &&
                                    registerForm.get('confirmpassword').value
                                    != "" ?
                                    "Enter Password First" : "" }} {{
                                    registerForm.get('signuppassword').value
                                    != "" &&
                                    registerForm.get('signuppassword').value
                                    !=
                                    registerForm.get('confirmpassword').value
                                    ? "Password Not Match" : "" }}
                                </div>
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="isLoginFor('login')">Login Now</a></span>
                    </div>
                </div>
                <!-- Forgot Password Form -->
                <div class="tab-pane fade {{classCheck == 'forgot' ? 'show active' :
                            ''}}" id="forgot" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="forgotForm" (ngSubmit)="forgotGetOtp(forgotSubmit)" #forgotSubmit="ngForm">
                            <!-- pop ups -->
                            <ng-container *ngIf="!changepassword">
                                <div class="form-group">
                                    <ng-container *ngIf="!showOtp">
                                        <input type="email" scriptInjection formControlName="emailAddress"
                                            placeholder="Email Address" class="form-control"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.required && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.required && forgotForm.get('emailAddress')?.touched">
                                            Email Address is Required</div>
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.pattern && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.pattern && forgotForm.get('emailAddress')?.touched">
                                            Enter Valid Email Address</div>
                                    </ng-container>
                                    <ng-container *ngIf="showOtp">
                                        <span
                                            class="form-control d-flex align-items-center">{{forgotForm.value.emailAddress}}</span>
                                    </ng-container>
                                </div>
                                <div class="form-group" *ngIf="showOtp">
                                    <input type="text" scriptInjection formControlName="otp" maxlength="6"
                                        placeholder="Enter OTP" class="form-control"
                                        style="text-transform: capitalize;">
                                    <div *ngIf="forgotSubmit.submitted || forgotForm.get('otp').touched" class="alert
                                                alert-danger">
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.required">
                                            Enter OTP</div>
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.pattern">
                                            Enter Valid OTP</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="changepassword">
                                <div class="form-group">
                                    <input type="email" formControlName="password" placeholder="Enter Password"
                                        class="form-control">
                                    <div class="alert alert-danger"
                                        *ngIf="forgotForm.get('password').errors?.required && forgotSubmit.submitted || forgotForm.get('password').errors?.required && forgotForm.get('password')?.touched">
                                        Password is Required</div>
                                </div>
                                <div class="form-group">
                                    <input type="text" scriptInjection formControlName="confirmpassword"
                                        placeholder="Enter Confirm Password" class="form-control">
                                    <div *ngIf="forgotSubmit && forgotForm.get('confirmpassword').errors?.required
                                                ||
                                                forgotForm.get('confirmpassword').touched && forgotForm.get('confirmpassword').errors?.required"
                                        class="alert
                                                alert-danger">
                                        <div class="alert alert-danger">Confirm Password is Required</div>
                                    </div>
                                </div>
                            </ng-container>
                            <button type="submit">{{forgotButton}}</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="classCheck= 'login'">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal for login end -->
<!-- modal for Register -->
<div class="modal fade RegisterModal" id="RegisterModal" #myModal tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" (click)="resetForm()" class="close" data-bs-dismiss="modal" id="close_btn2"
                aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <span style="font-size: 21px; font-weight: 500;" *ngIf="isLogin">Login for Member</span>
                    <span style="font-size: 21px; font-weight: 500;"*ngIf="isRegister">Register as New</span>
                 </li>
                <!-- <li class="nav-item">
                    <button class="nav-link {{classCheck == 'register' ? 'active' :
                                ''}}" (click)="classCheck= 'register'" id="register-tab" data-bs-toggle="tab"
                        data-bs-target="#register" type="button" role="tab" aria-controls="register"
                        aria-selected="true">Register</button>
                </li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
                <!-- Login Form -->
                <div class="tab-pane fade {{classCheck == 'login' ? 'show active' : ''}}" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <!-- Login form -->
                        <form [formGroup]="loginForm" (ngSubmit)="loginIn()" #formlogin="ngForm">
                            <div class="form-group">
                                <input type="email" scriptInjection placeholder="Username or Email"
                                    formControlName="loginemail" maxlength="50" class="form-control"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="formlogin.submitted && g.loginemail.errors?.required || g.loginemail.touched"
                                    class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="g.loginemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <div class="d-flex"> -->
                                <input [type]="show ? 'text' : 'password'" formControlName="loginpassword"
                                    placeholder="Password" minlength="8" required class="form-control">
                                <i *ngIf="!show" style="position: absolute;
                                    right: 50px;
                                    margin-top: -22px; cursor: pointer;" (click)="showPassword('login')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                    right: 50px;
                                    margin-top: -22px; cursor: pointer;" (click)="showPassword('login')"
                                    aria-hidden="true" *ngIf="show"></i>
                                <!-- </div> -->
                                <div *ngIf="formlogin.submitted &&
                                            g.loginpassword.errors?.required
                                            || g.loginpassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginpassword.errors?.required">Password is Required
                                    </div>
                                    <div *ngIf="g.loginpassword.errors?.minlength">Password Must be at Least 8
                                        Characters Long
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="disablebtn">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an
                            account?
                                <a (click)="isLoginFor2('register')">Register Now</a>
                          
                        </span>
                        <span class="dont-account"> <a (click)="classCheck= 'forgot'">Forgot Password</a></span>
                    </div>
                </div>
                <!-- Register Form -->
                <div class="tab-pane fade {{classCheck == 'register' ? 'show active' : ''}}" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="registerForm" (ngSubmit)="signUp()" #formRegister="ngForm">
                            <!-- pop ups -->
                            <div class="form-group">
                                <!-- appHtmlInjection -->
                                <input type="text" scriptInjection formControlName="signupname" placeholder="Name"
                                    class="form-control" (change)="onChange($event.target.value)"
                                    style="text-transform: capitalize;" pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupname.errors?.required
                                            ||
                                            f.signupname.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupname.errors?.required">Name
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupname.errors?.pattern">Please
                                        Enter a Valid User Name
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" scriptInjection formControlName="signupemail" placeholder="Email"
                                    class="form-control" pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupemail.errors?.required ||
                                            f.signupemail.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input [type]="showpass ? 'text' : 'password'" formControlName="signuppassword"
                                    placeholder="Password" minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('signuppassword')?.invalid" required class="form-control">
                                <i *ngIf="!showpass" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('registerpass')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('registerpass')"
                                    aria-hidden="true" *ngIf="showpass"></i>
                                <div *ngIf="signUpSubmitted &&
                                            f.signuppassword.errors?.required
                                            || f.signuppassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signuppassword.errors?.required">Password
                                        is Required
                                    </div>
                                    <div *ngIf="f.signuppassword.errors?.minlength">Password
                                        Must be at Least 8 Characters
                                        Long
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input [type]="showconfirmpass ? 'text' : 'password'" formControlName="confirmpassword" placeholder="Confirm Password"
                                    minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('confirmpassword')?.invalid" required
                                    class="form-control">
                                <i *ngIf="!showconfirmpass" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('confirmpass')"
                                    class="far fa-eye"></i>
                                <i class="fa fa-eye-slash" style="position: absolute;
                                            right: 50px;
                                            margin-top: -22px; cursor: pointer;" (click)="showPassword('confirmpass')"
                                    aria-hidden="true" *ngIf="showconfirmpass"></i>
                                <div *ngIf="signUpSubmitted &&
                                            f.confirmpassword.errors?.required
                                            || f.confirmpassword.touched" class="alert
                                            alert-danger">
                                    <div class="invalid-feedback">
                                        Confirm Password is Required
                                    </div>
                                    <div *ngIf="f.confirmpassword.errors?.required">Confirm
                                        Password is Required
                                    </div>
                                </div>
                                <div *ngIf="registerForm.get('confirmpassword').value" class="alert
                                            alert-danger">
                                    {{
                                    registerForm.get('signuppassword').value
                                    === "" &&
                                    registerForm.get('confirmpassword').value
                                    != "" ?
                                    "Enter Password First" : "" }} {{
                                    registerForm.get('signuppassword').value
                                    != "" &&
                                    registerForm.get('signuppassword').value
                                    !=
                                    registerForm.get('confirmpassword').value
                                    ? "Password Not Match" : "" }}
                                </div>
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="isLoginFor2('login')">Login Now</a></span>
                    </div>
                </div>
                <!-- Forgot Password Form -->
                <div class="tab-pane fade {{classCheck == 'forgot' ? 'show active' :
                            ''}}" id="forgot" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="forgotForm" (ngSubmit)="forgotGetOtp(forgotSubmit)" #forgotSubmit="ngForm">
                            <!-- pop ups -->
                            <ng-container *ngIf="!changepassword">
                                <div class="form-group">
                                    <ng-container *ngIf="!showOtp">
                                        <input type="email" scriptInjection formControlName="emailAddress"
                                            placeholder="Email Address" class="form-control"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.required && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.required && forgotForm.get('emailAddress')?.touched">
                                            Email Address is Required</div>
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.pattern && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.pattern && forgotForm.get('emailAddress')?.touched">
                                            Enter Valid Email Address</div>
                                    </ng-container>
                                    <ng-container *ngIf="showOtp">
                                        <span
                                            class="form-control d-flex align-items-center">{{forgotForm.value.emailAddress}}</span>
                                    </ng-container>
                                </div>
                                <div class="form-group" *ngIf="showOtp">
                                    <input type="text" scriptInjection formControlName="otp" maxlength="6"
                                        placeholder="Enter OTP" class="form-control"
                                        style="text-transform: capitalize;">
                                    <div *ngIf="forgotSubmit.submitted || forgotForm.get('otp').touched" class="alert
                                                alert-danger">
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.required">
                                            Enter OTP</div>
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.pattern">
                                            Enter Valid OTP</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="changepassword">
                                <div class="form-group">
                                    <input type="email" formControlName="password" placeholder="Enter Password"
                                        class="form-control">
                                    <div class="alert alert-danger"
                                        *ngIf="forgotForm.get('password').errors?.required && forgotSubmit.submitted || forgotForm.get('password').errors?.required && forgotForm.get('password')?.touched">
                                        Password is Required</div>
                                </div>
                                <div class="form-group">
                                    <input type="text" scriptInjection formControlName="confirmpassword"
                                        placeholder="Enter Confirm Password" class="form-control">
                                    <div *ngIf="forgotSubmit && forgotForm.get('confirmpassword').errors?.required
                                                ||
                                                forgotForm.get('confirmpassword').touched && forgotForm.get('confirmpassword').errors?.required"
                                        class="alert
                                                alert-danger">
                                        <div class="alert alert-danger">Confirm Password is Required</div>
                                    </div>
                                </div>
                            </ng-container>
                            <button type="submit">{{forgotButton}}</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="classCheck= 'login'">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal for register end -->
<div class="role-modal">
    <p-dialog p-dialog header="Select Role" #dialog [modal]="true" [closeOnEscape]="false" [closable]="false"
        [style]="{width: '42vw', height: '308px'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false"
        [resizable]="false" [(visible)]="Modal">
        <form action="" [formGroup]="roleForm" (ngSubmit)="sendRole(role)" #role="ngForm" style="height: 100%;">
            <div class="row py-3 justify-content-center align-items-center">
                <div class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center mb-3"
                    *ngFor="let item of roles">
                    <div [pTooltip]=" item?.name | nameFilter : 'company'" class="role-cards"
                        (click)="onRoleSelect(item)">
                        <p>Login as</p>
                        <p class="title">{{item?.name | nameFilter : 'role' | slice:0:30}}</p>
                        <p>for</p>
                        <p class="title">{{item?.name | nameFilter : 'company' | slice:0:25}}</p>
                        <p class="title">{{item?.membership_no}}</p>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group">

                <label class="mb-3">Please Select Role<span class="required">*</span>:</label>
                <p-dropdown [options]="roles" placeholder="Please Select Role" [class.is-invalid]="(role.submitted &&
            roleForm.get('memberrole')?.invalid) ||
            roleForm.get('memberrole')?.invalid &&
            roleForm.get('memberrole').touched" formControlName="memberrole" optionLabel="name" optionValue="value"
                    [showClear]="false" [resetFilterOnHide]="true">
                </p-dropdown>
                <div *ngIf="role.submitted &&
            o.memberrole.errors?.required ||
            o.memberrole.touched" class="alert
            alert-danger">
                    <div *ngIf="o.memberrole.errors?.required">
                        Role is Required
                    </div>
                </div>
            </div>
            <button style="position: relative;
            top: 110px;" pButton label="Proceed" class="p-button-outlined
                my-3" style="float: right;"></button> -->
        </form>
    </p-dialog>
</div>

<!-- *****************************************************Change Password Modal**************************************************************** -->
<div class="modal fade loginRegisterModal" id="ChangePasswordModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" id="close_button" aria-label="Close"><i
                    class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link {{classCheck == 'changepassword' ? 'show active' :
                                ''}}" (click)="classCheck = 'changepassword'" id="login-tab" data-bs-toggle="tab"
                        data-bs-target="#login" type="button" role="tab" aria-controls="login"
                        aria-selected="true">Change Password</button>
                </li>
            </ul>
            <!-- Change Password Form -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade {{classCheck == 'changepassword' ? 'show active' : ''}}" id="changepassword"
                    role="tabpanel">
                    <div class="vesax-login">
                        <form [formGroup]="PasswordForm" (ngSubmit)="changePassword()" #changePassForm="ngForm">
                            <div class="my-profile-box">
                                <div class="" *ngIf="!showData">
                                    <div class="row">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input value="{{UserEmail}}" readonly class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <label>New Password</label>
                                            <input type="password" formControlName="newpassword"
                                                placeholder="New Password" minlength="8" [class.is-invalid]="(changePassForm.submitted
                                                        &&
                                                        PasswordForm.get('newpassword')?.invalid)
                                                        ||
                                                        PasswordForm.get('newpassword')?.invalid
                                                        &&
                                                        PasswordForm.get('newpassword').touched" class="form-control">
                                            <div *ngIf="changePassForm.submitted
                                                        &&
                                                        p.newpassword.errors?.required
                                                        ||
                                                        p.newpassword.touched" class="alert
                                                        alert-danger">
                                                <div *ngIf="p.newpassword.errors?.required">Password
                                                    is Required
                                                </div>
                                                <div *ngIf="p.newpassword.errors?.minlength">Password Must be at Least 8
                                                    Characters Long
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" formControlName="confirmpassword"
                                                placeholder="Confirm Password" minlength="8" [class.is-invalid]="(changePassForm.submitted
                                                        &&
                                                        PasswordForm.get('confirmpassword')?.invalid)
                                                        ||
                                                        PasswordForm.get('confirmpassword')?.invalid
                                                        &&
                                                        PasswordForm.get('confirmpassword').touched"
                                                class="form-control">
                                            <div *ngIf="changePassForm.submitted
                                                        &&
                                                        p.confirmpassword.errors?.required
                                                        ||
                                                        p.confirmpassword.touched" class="alert
                                                        alert-danger">
                                                <div class="invalid-feedback">
                                                    Confirm Password is
                                                    Required
                                                </div>
                                                <div *ngIf="p.confirmpassword.errors?.required">Confirm
                                                    Password is Required
                                                </div>
                                            </div>
                                            <div *ngIf="PasswordForm.get('confirmpassword').value" class="alert
                                                        alert-danger">
                                                {{
                                                PasswordForm.get('newpassword').value
                                                === "" &&
                                                PasswordForm.get('confirmpassword').value
                                                != "" ?
                                                "Enter Password First" :
                                                "" }} {{
                                                PasswordForm.get('newpassword').value
                                                != "" &&
                                                PasswordForm.get('newpassword').value
                                                !=
                                                PasswordForm.get('confirmpassword').value
                                                ? "Password Not Match" :
                                                "" }}
                                            </div>
                                            <!-- <div *ngIf="PasswordForm.get('newpassword').value == PasswordForm.get('confirmpassword').value ? validConfirmPassword = true : validConfirmPassword = false">
                                                
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showData">
                                    <div class="form-group">
                                        <label>OTP<span class="required">*</span>:</label>
                                        <input [class.is-invalid]="(changePassForm.submitted
                                                    &&
                                                    PasswordForm.get('otp')?.invalid)
                                                    ||
                                                    PasswordForm.get('otp')?.invalid
                                                    &&
                                                    PasswordForm.get('otp').touched" type="text" scriptInjection
                                            class="form-control" placeholder="Enter OTP" maxlength="6"
                                            formControlName="otp">

                                        <div class="invalid-feedback">
                                            <div *ngIf="p.otp.errors?.required">
                                                OTP is Required
                                            </div>
                                            <div *ngIf="p.otp.errors?.pattern">
                                                OTP is Required
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <button type="submit">{{submitButton}}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>