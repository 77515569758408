import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit, OnChanges {
  @Input() imageData: any;
  modalStyle: string = 'flex';
  constructor(private sanitizer: DomSanitizer) {
    // console.log(this.imageData);
  }

  ngOnInit() {
    console.log(this.imageData);
  }
  ngOnChanges() {
    // console.log(this.imageData);
    // this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData.url);
  }

  onImageClose() {
    this.modalStyle = 'none';
    if (this.imageData?.imageType == 'company') {
      this.imageData.logoBoolean = false;
    } else if (this.imageData?.imageType == 'nominee1') {
      this.imageData.nominee1Boolean = false;
    } else if (this.imageData?.imageType == 'nominee2') {
      this.imageData.nominee2Boolean = false;
    } else this.imageData.boolean = false;
  }
}
