<!-- 
<label for="file">Logo<span class="required">*</span>:</label>
<span id="myImg" class="form-control d-flex align-items-center justify-content-between">
    <span pTooltip="Click to Show Logo Preview" (click)="onImageClick()"
        tooltipPosition="bottom">{{imageData?.data?.media}}</span>
    <span (click)="logoPreview('company')" style="color: red" pTooltip="Remove Preview and Change Logo"
        tooltipPosition="bottom">x</span>
</span> -->
<div id="myModal" class="modal" [ngStyle]="{'display': modalStyle}">
    <span class="close" (click)="onImageClose()">X</span>
    <img [src]="imageData?.url" class="modal-content" id="img01">
    <!-- <iframe class="embed-responsive-item" [src]="imageData?.url" width="100%" height="300"></iframe> -->
    <div id="caption"></div>
</div>