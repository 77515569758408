<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>

<app-navbar-style-one></app-navbar-style-one>

<section class="feedbackform" id="backToTop">
    <div class="container">
        <div class="heading_section">
            <div class="row m-0">
                <h3 class="m-0 px-0 pb-3">Event Feedback Form</h3>
            </div>
        </div>
        <div class="add-listings-box">
            <ng-container *ngIf="customFromData.length > 0">
                <lib-renderer [sections]="customFromData" (sectionsData)="getFormResponse($event)">
                </lib-renderer>
            </ng-container>
        </div>
    </div>
</section>