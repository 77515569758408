import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-representatives-approval',
  templateUrl: './representatives-approval.component.html',
  styleUrls: ['./representatives-approval.component.scss']
})
export class RepresentativesApprovalComponent implements OnInit {

  @ViewChild('nameFilter') nameFilter : Table

  breadcrumb: any[] = [
    {
      title: 'Representatives Approval',
      subTitle: 'Dashboard',
    },
  ];
  filterVal: string;
  tooltipText: string = environment.resetTooltip;
  loading: boolean = false;
  dtDataLength: any;
  mergedArray: any = [];
  excelData: any[];
  filteredXLSData: any[];
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any;
  cols: { field: string; header: string; }[];

  constructor(private ApiServices: ApiService, private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getNomineeData();
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'company_name', header: 'Company Name' },
      { field: 'Email', header: 'Email' },
      { field: 'office_telephone', header: 'Office Telephone' },
      { field: 'Website', header: 'Website' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }
  getNomineeData() {
    this.loading = true;
    this.ApiServices.getProposedNomineeData().subscribe((res: any) => {
      console.log(res);
      this.mergedArray = [...res.data?.seconded_by_data, ...res.data?.proposed_by_data];
      console.log(this.mergedArray);
      this.dtDataLength = res.data?.length;
      this.loading = false;
    })
  }
  reset(nameFilter) {
    this.filterVal = '';
  }
  confirm(id: any, data) {
    console.log(id, data);
    let Data: any = {
      status: data,
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to accept this nominee proposal on your behalf ? Please confirm before proceeding?',
      accept: () => {
        this.ApiServices.approvalProposalData(id, Data).subscribe((res: any) => {
          console.log(res);
          this.messageService.add({
            severity: 'success',
            detail: res?.message,
          });
          this.getNomineeData();
        })
      }
    });
  }

   // XLS Export Function
   onXLSExport() {
    this.excelData = [];
    this.filteredXLSData = [];
    if (this.nameFilter.filteredValue != null) {
      this.filteredXLSData = this.nameFilter.filteredValue;
    } else if (this.nameFilter.filteredValue == null) {
      this.filteredXLSData = this.mergedArray;
    }

    for (let i = 0; i < this.filteredXLSData.length; i++) {
      this.excelData.push({
        'Sr No': i+1,
        'Status' : this.filteredXLSData[i]?.seconded_by_status ?? this.filteredXLSData[i]?.proposed_by_status,
        'Name' : this.filteredXLSData[i]?.seconded_by_nominee ?? this.filteredXLSData[i]?.proposed_by_nominee,
        'Company' : this.filteredXLSData[i]?.seconded_by_organisation ?? this.filteredXLSData[i]?.proposed_by_organisation,
        'Designation' : this.filteredXLSData[i]?.seconded_by_designation ?? this.filteredXLSData[i]?.proposed_by_designation,
        'Company (Proposed By)' : this.filteredXLSData[i]?.member_details?.company_name,
        'Email (Proposed By)' : this.filteredXLSData[i]?.member_details?.email,
        'Office Telephone (Proposed By)' : this.filteredXLSData[i]?.member_details?.office_telephone,
        'Website (Proposed By)' : this.filteredXLSData[i]?.member_details?.website,
      });
    }
    this.ApiServices.exportExcel(this.excelData, 'Representative-Approval-List');
  }

  // PDF Export Function
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    if (this.nameFilter.filteredValue != null) {
      this.filteredPDFData = this.nameFilter.filteredValue;
    } else if (this.nameFilter.filteredValue == null) {
      this.filteredPDFData = this.mergedArray;
    }
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        'company_name' : this.filteredPDFData[i]?.member_details?.company_name,
        'Email' : this.filteredPDFData[i]?.member_details?.email,
        'office_telephone' : this.filteredPDFData[i]?.member_details?.office_telephone,
        'Website' : this.filteredPDFData[i]?.member_details?.website,
      });
    }
    this.ApiServices.exportPdf(
      this.pdfData,
      this.exportColumns,
      'Representative-Approval-List'
    );
  }

}
