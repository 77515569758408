import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class DialogBoxComponent implements OnInit {
  // @Input() dialogData:any = {selectedMember : []};
  @Input() dialogData:any;
  @Input() displayBasic : boolean ;
  @Output() getDialogData = new EventEmitter<any>();
  @Output() toastCheck = new EventEmitter<any>();

  selectedMembers : any = []
  checkAll:boolean = true;
  searchMember:any;
  searchWordUpdate = new Subject<string>();
  // displayBasic : boolean = true;
  constructor(private __eventservice : EventService,private messageService: MessageService, private spinner : NgxSpinnerService) { 
  }

  async ngOnInit() {
      // await this.__eventservice.getAttachedMembers(this.dialogData.id)
      // .then(async (res: any) => {
      //   this.selectedMembers = [];
      //   await res?.data?.forEach((member: any) => {
      //     this.selectedMembers.push(member.member_id);
      //   });
      // });

      // await this.dialogData.memberList.forEach((res:any)=>{
      //   let value : boolean = false;
      //   value = this.selectedMembers.includes(res.id);
      //   if(value){
      //     res.checked = true;
      //   }
      //   else{
      //     res.checked = false;
      //   }
      // })

      // for(let i = 0; i < this.dialogData?.memberList?.length; i++) {
      //   if(this.dialogData.memberList[i].checked == false) {
      //     this.checkAll = false;
      //   }
      // }

  }

  // async sendMemberInvite() {
  //   this.selectedMembers = []
  //   this.spinner.show();
  //   let array = this.dialogData.memberList.filter(res => res.checked == true);
  //   array.forEach(element => {
  //     this.selectedMembers.push(element.id)
  //   });
  //   let object = {
  //     event_id: this.dialogData.id,
  //     members: this.selectedMembers,
  //   };
  //   await this.__eventservice.storeSelectedMember(object).then((res: any) => {
  //     this.spinner.hide();
  //     this.displayBasic = false;
  //     this.toastCheck.emit();
  //   });
  // }
  onHide() {
    this.displayBasic = false;
    this.getDialogData.emit(false);
  }
  // onChange(event: any, index:any) {
  //   if (event.target.checked) {
  //     this.dialogData.memberList[index].checked = true;
  //     let value = this.dialogData.memberList.filter(res => res.checked == false);
  //     if(value?.length == 0) {
  //       this.checkAll = true
  //     }
  //   } 
    
  //   else {
  //     this.checkAll = false;
  //     this.dialogData.memberList[index].checked = false;
  //   }
  // }

  // onAllSelect() {
  //   // alert(this.checkAll);
  //   if(this.checkAll) {
  //     this.dialogData.memberList.forEach(member => {
  //       member.checked = true;
  //     });
  //   }
  //   else {
  //     this.dialogData.memberList.forEach(member => {
  //       member.checked = false;
  //     });
  //   }
  // }

  // onClose() {
  //   this.displayBasic = false;
  // }



}
