<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<p-toast></p-toast>
<div>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- certificate of origin list table -->
    <section class="approval-list">
        <div class="">
            <div class="theader">
                <div class="d-flex">
                    <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input class="form-control" pInputText type="text" (input)="nameFilter.filterGlobal($event.target.value,
                                'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                    </span>
                    <p-button type="button" class="mx-2" (click)="reset(nameFilter)" icon="bx bx-reset"
                        [pTooltip]="tooltipText" tooltipPosition="bottom">
                    </p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
                        class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                </div>
            </div>
            <p-table scrollable="true" scrollHeight="600px" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm p-datatable-customers"
                [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true" currentPageReportTemplate="{totalRecords} of {{dtDataLength}}
                    records" #nameFilter
                [globalFilterFields]="['member_details.company_name', 'member_details.email', 'member_details.office_telephone', 'member_details.website']"
                responsiveLayout="scroll" [value]="mergedArray" [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 62px;">S. No.</th>
                        <th style="min-width: 140px;text-align: center;">Action</th>
                        <th style="min-width: 160px;">Status</th>
                        <th style="min-width: 160px;">Company Name</th>
                        <th style="min-width: 160px;">Email</th>
                        <th style="min-width: 160px;">Office Telephone</th>
                        <th style="min-width: 160px;">Website</th>
                    </tr>

                </ng-template>
                <ng-template pTemplate="body" let-data let-i="rowIndex">
                    <tr>
                        <td>{{ i + 1 }}</td>
                        <td class="view-buttons" style="justify-content: center;">
                            <!-- <button pButton pRipple class="p-button-rounded
                                    p-button-text
                                    p-button-info" pTooltip="View" (click)="showMaximizableDialog(data?.member_details.id)"
                                tooltipPosition="bottom"><i class="far fa-eye"></i></button> -->

                            <button pButton pRipple type="button"
                                [disabled]="(data?.seconded_by_status == 'Approved' || data?.proposed_by_status == 'Approved') || (data?.seconded_by_status == 'Rejected' || data?.proposed_by_status == 'Rejected')"
                                pTooltip="Yes" (click)="confirm(data?.member_details.id, 'Approved')"
                                tooltipPosition="bottom" icon="pi pi-check"
                                class="p-button-rounded p-button-text"></button>

                            <button pButton pRipple type="button" icon="pi pi-times"
                                (click)="confirm(data?.member_details.id, 'Rejected')"
                                [disabled]="(data?.seconded_by_status == 'Approved' || data?.proposed_by_status == 'Approved') || (data?.seconded_by_status == 'Rejected' || data?.proposed_by_status == 'Rejected')"
                                pTooltip="No" tooltipPosition="bottom"
                                class="p-button-rounded p-button-danger p-button-text"></button>
                        </td>
                        <td class="status">
                            <ng-container
                                *ngIf="data?.seconded_by_status == 'Pending' || data?.proposed_by_status == 'Pending'">
                                <span class="pending">
                                    {{ data?.seconded_by_status ? data?.seconded_by_status : data?.proposed_by_status}}
                                </span>
                            </ng-container>
                            <ng-container
                                *ngIf="data?.seconded_by_status == 'Approved' || data?.proposed_by_status == 'Approved'">
                                <span class="approved">
                                    {{ data?.seconded_by_status ? data?.seconded_by_status : data?.proposed_by_status}}
                                </span>
                            </ng-container>
                            <ng-container
                                *ngIf="data?.seconded_by_status == 'Rejected' || data?.proposed_by_status == 'Rejected'">
                                <span class="rejected">
                                    {{ data?.seconded_by_status ? data?.seconded_by_status : data?.proposed_by_status}}
                                </span>
                            </ng-container>
                        </td>
                        <td>{{data?.member_details?.company_name}}</td>
                        <td>{{data?.member_details?.email}}</td>
                        <td>{{data?.member_details?.office_telephone}}</td>
                        <td>{{data?.member_details?.website}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </section>
</div>