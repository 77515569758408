<!-- <app-navbar-style-two></app-navbar-style-two> -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<div *ngIf="route.url == '/user-panel/event-dashboard'">
    <!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
    <!-- <div class="main-content d-flex flex-column"> -->
    <div>
        <div class="container card" *ngIf="showModal">
            <div>
                <label class="mb-3" style="font-weight: 500;">Please Select Role to Proceed :
                </label>
                <p-dropdown [options]="storageData" placeholder="Please Select Role to Proceed" optionLabel="name"
                    [showClear]="false" [resetFilterOnHide]="true" [(ngModel)]="changedRole"
                    (onChange)="onSelect()"></p-dropdown>
            </div>
        </div>
    </div>
    <!-- Start Page Title Area -->
    <div>
        <div *ngIf="!showModal">
            <div class="page-title-area page-title-style-two" *ngFor="let Content of pageTitleContent;"
                style="background-image: url({{Content.backgroundImage}});position: relative;">
                <!-- <div style="height: 100%;background:black;opacity: 0.7;position:absolute;"></div> -->
                <div class="container">
                    <div class="page-title-content">
                        <h2>{{Content.title}}</h2>
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li>{{Content.title}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- End Page Title Area -->

            <!-- Start Blog Area -->
            <section class="blog-area bg-f9f9f9 ptb-100">
                <div class="container">
                    <div class="row" *ngIf="eventList?.length>0">
                        <div class="col-lg-4 col-md-6"
                            *ngFor="let Content of eventList | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a (click)="navigateToDetailPage(Content.id, 'dashboard')" class="d-block"
                                        style="cursor: pointer;">
                                        <img [src]="Content.coverage_image" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <ul class="post-meta d-flex align-items-center">
                                        <li><i class="flaticon-calendar"></i> {{Content.date | date : 'dd/MM/yyyy'}}
                                        </li>
                                        <li>
                                            <i class="flaticon-clock"></i> {{Content.date | date : 'hh:mm:ss a'}}
                                        </li>
                                    </ul>
                                    <h3 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                                            (click)="navigateToDetailPage(Content.id, 'dashboard')"
                                            style="cursor: pointer;">{{Content.event_name}}</a></h3>
                                    <a (click)="navigateToDetailPage(Content.id, 'dashboard')" class="link-btn"
                                        style="cursor: pointer;"><i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area text-center">
                                <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0" *ngIf="(eventList?.length==0)">
                        <div class="col-12">
                            No event found
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<!-- </div> -->

<!-- ********************** Main Content ******************** -->
<!-- <ng-container *ngIf="localStorage"> -->
<!-- Start Page Title Area -->
<div *ngIf="route.url == '/event-grid'">
    <!-- <app-header></app-header> -->
    <div class="page-title-area page-title-style-two" *ngFor="let Content of pageTitleContent;"
        style="background-image: url({{Content.backgroundImage}});position: relative;">
        <!-- <div style="height: 100%;background:black;opacity: 0.7;position:absolute;"></div> -->
        <div class="container">
            <div class="page-title-content">
                <h2>{{Content.title}}</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{Content.title}}</li>
                </ul>
            </div>
        </div>
    </div>
    <!-- End Page Title Area -->

    <!-- Start Blog Area -->

    <!-- past events -->
    <section class="blog-area bg-f9f9f9 ptb-100" *ngIf= "showPastEvents">
        <div class="container">
            <div class="row" *ngIf="pastEventList?.length>0">
                <div class="col-lg-4 col-md-6"
                    *ngFor="let Content of pastEventList | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a (click)="navigateToDetailPage(Content.id, 'home')" class="d-block"
                                style="cursor: pointer;">
                                <img [src]="Content.coverage_image" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <ul class="post-meta d-flex align-items-center">
                                <li><i class="flaticon-calendar"></i> {{Content.date | date : 'dd/MM/yyyy'}}</li>
                                <li>
                                    <i class="flaticon-clock"></i> {{Content.date | date : 'hh:mm:ss a'}}
                                </li>
                            </ul>
                            <h3 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                                    (click)="navigateToDetailPage(Content.id, 'home')"
                                    style="cursor: pointer;">{{Content.event_name}}</a></h3>
                            <a (click)="navigateToDetailPage(Content.id, 'home')" class="link-btn"
                                style="cursor: pointer;"><i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                    </div>
                </div>


               
            </div>
            <div class="row m-0" *ngIf="(eventList?.length==0)">
                <div class="col-12">
                    No event found
                </div>
            </div>
        </div>
    </section>




<!-- current events -->
    <section class="blog-area bg-f9f9f9 ptb-100" *ngIf="!showPastEvents">
        <div class="container">
            <div class="row" *ngIf="currentEventsList?.length>0">
                <div class="col-lg-4 col-md-6"
                    *ngFor="let Content of currentEventsList | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a (click)="navigateToDetailPage(Content.id, 'home')" class="d-block"
                                style="cursor: pointer;">
                                <img [src]="Content.coverage_image" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <ul class="post-meta d-flex align-items-center">
                                <li><i class="flaticon-calendar"></i> {{Content.date | date : 'dd/MM/yyyy'}}</li>
                                <li>
                                    <i class="flaticon-clock"></i> {{Content.date | date : 'hh:mm:ss a'}}
                                </li>
                            </ul>
                            <h3 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                                    (click)="navigateToDetailPage(Content.id, 'home')"
                                    style="cursor: pointer;">{{Content.event_name}}</a></h3>
                            <a (click)="navigateToDetailPage(Content.id, 'home')" class="link-btn"
                                style="cursor: pointer;"><i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                    </div>
                </div>



            </div>
            <div class="row m-0" *ngIf="(currentEventsList?.length==0)">
                <div class="col-12">
                    No event found
                </div>
            </div>
        </div>
    </section>
    <div class="col-lg-12 col-md-12">
        <div class="pagination-area text-center">
            <button class="button" (click)="showCurrentEvents(Event)">{{Event}}</button>
        </div>
    </div>
   



    <!-- End Blog Area -->
    <!-- <app-copyrights></app-copyrights> -->

    <app-footer></app-footer>
</div>
<!-- </ng-container> -->

<!-- <ng-container *ngIf="!localStorage">
    <app-non-authorized-modal></app-non-authorized-modal>
</ng-container> -->