<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<!-- <app-spinner [msg]="spinnerMsg"></app-spinner> -->

<!-- ********************** Main Content ******************** -->


<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>


<!-- ***************************************************************Tax Master List*************************************************************** -->
<div class="Master-view-box">
    <div class="theader">
        <div class="flex">
            <div class="d-flex align-items-center justify-content-end w-100">
                <div>
                    <!-- <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" scriptInjection (input)="dt1.filterGlobal($event.target.value,
                                'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                    </span> -->
                    <p-button type="button" class="mx-2" (click)="setPermission()" label="Set Permission"></p-button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" *ngIf="groupByAllPermissions?.length > 0">
            <p-table #dt1 [value]="groupByAllPermissions" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,25,50]" [rows]="10" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [paginator]="true"
                currentPageReportTemplate="{totalRecords} of {{groupByAllPermissions.length}} records"
                [globalFilterFields]="['title']">
                <ng-template pTemplate="header" style="min-height:
                        500px;">
                    <tr>
                        <th style="width : 350px;">Permission</th>
                        <th>
                            <p-checkbox name="checkAll" [binary]="true" (onChange)="checkAllPermission()"
                                [(ngModel)]="checkAll"></p-checkbox>
                            Check All
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-permission let-i="rowIndex">
                    <tr>
                        <td style="width : 350px;">{{permission[0]?.title}}</td>
                        <td style="display: flex; justify-content: space-between;"
                            *ngIf="permission?.length > 0 && selectedValue?.length > 0">
                            <div *ngFor="let item of permission; index as index">
                                <p-checkbox [name]="'groupname'+index+i" [binary]="true"
                                    (onChange)="onCheckboxAction($event, i, index, item)"
                                    [(ngModel)]="selectedValue[i][index]" #groupName="ngModel"></p-checkbox>
                                {{item.name}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Permissions Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>