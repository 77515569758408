<div id="myModal" class="modal" [ngStyle]="{'display': modalStyle}">
    <span class="close" (click)="onImageClose()">X</span>
    <!-- <img [src]="imageData?.url" class="modal-content" id="img01"> -->
    <!-- <iframe class="embed-responsive-item" src="" width="100%" height="300"></iframe> -->
    <ng-container *ngIf="imageData?.url">
        <object data="{{imageData?.url}}" width="800" height="500"></object>
    </ng-container>
    <!-- <embed src="" width="100%" height="100%"> -->
    <div id="caption"></div>
</div>

<!-- <a [href]="imageData?.url" target="_blank" rel="noopener noreferrer"></a> -->