import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent implements OnInit {
  @ViewChild('news') news: NgForm
  breadcrumb: any[] = [
    {
      title: 'Notifications',
      subTitle: 'Masters',
    },
  ];
  newsType: any = [
    { label: 'UCCI News', value: 'UCCI News' },
    { label: 'Government News', value: 'Government News' },
    { label: 'Library', value: 'Library' }
  ]
  mediaType: any = [
    { label: 'File', value: 'File' },
    { label: 'Link', value: 'Link' }
  ]
  selectedFile: File;
  spinnerMsg: string;
  ImageURL: string = '';
  UploadFeatured: boolean;
  notificationFormFiles: any = {};
  imageData: any = {};
  baseUrl = environment;
  docErrorState: boolean = false;
  localUrl: any;
  documents: any = [];
  mediatype: any;
  submitButton: string = 'Submit'
  datalength: any;
  notificationList: any = [];
  slug: any;
  getDataFromId: any;
  selectedValues: string;
  isNew: boolean = false;
  constructor(private newsService: NewsAndEventsServiceService,
    private messageService: MessageService,
    private _utility: utility,
    private router: Router,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerMsg = 'loading...';
    this.slug = this.activatedRoute.snapshot.params;
    console.log(this.slug);
    if (!(this.slug?.slug)) {
      this.onAddRow('add');
    }
    this.getDataByID();
    this.onChange('File', '');

    console.log(this.notificationForm.controls.mediaArray.value, this.notificationForm.controls.mediaArray.valid);
    Object.keys(this.notificationForm.controls).forEach((res: any) => {
      console.log(res, this.notificationForm.controls[res].status);
    })

  }
  // create tax form control
  notificationForm = this.fb.group({
    news_type: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    is_new: new FormControl(null),
    mediaArray: this.fb.array([]),
  });
  // create tax api function
  createNotification(news) {
    console.log(this.notificationForm.valid);
    Object.keys(this.notificationForm.controls).forEach((res: any) => {
      console.log(res, this.notificationForm.controls[res].status);
    })
    let formData = new FormData();
    let data = this.notificationForm.value;
    formData.append('news_type', data.news_type);
    formData.append('title', data.title);
    formData.append('description', data.description);
    if (this.slug.slug) {
      formData.append('is_new', this.isNew == true ? '1' : '0');
    } else {
      formData.append('is_new', data.is_new ? data.is_new : '0');
    }
    this.notificationForm.controls['mediaArray'].value.map(
      (res: any, index: any) => {
        console.log(res);
        formData.append(`media_type[${index}]`, res?.media_type);
        formData.append(`media_title[${index}]`, res?.media_title);
        if (res?.media_type == 'File') {
          formData.append(`media[${index}]`, res?.media);
          formData.append(`media_alt[${index}]`, res?.media_alt);
          console.log(res);

        } else if (res?.media_type == 'Link') {
          formData.append(`media[${index}]`, String(res?.media))
          // console.log(res);
        }
      }
    );
    if (this.notificationForm.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
    else if (this.notificationForm.valid) {
      if (this.slug.slug) {
        this.newsService.updateNotification(this.slug.slug, formData).subscribe((res: any) => {
          console.log(res);
          this.notificationForm.reset();
          news.resetForm();
          this.notificationForm.controls.mediaArray.reset();
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          setTimeout(() => {
            this.router.navigateByUrl('/user-panel/news-and-events')
          }, 1000);
          Object.keys(this.notificationForm.controls).forEach((key) => {
            this.notificationForm.setErrors(null);
          });
        })

      }
      else {
        this.newsService.postNews(formData).subscribe((res: any) => {
          console.log(res);
          this.notificationForm.reset();
          news.resetForm();
          this.notificationForm.controls.mediaArray.reset();
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          setTimeout(() => {
            this.router.navigateByUrl('/user-panel/news-and-events')
          }, 1000);
          Object.keys(this.notificationForm.controls).forEach((key) => {
            this.notificationForm.setErrors(null);
          });
        })
      }
    }
  }
  // convert fakepath image to binary
  processImage(event: any, i) {
    var reader: any = new FileReader();
    this.selectedFile = event.target.files[0];
    // this.invoiceState = true;
    let localUrl: any = '';
    reader.readAsDataURL(event.target.files[0]);
    if (event.target.files[0].type == 'application/pdf') {
      reader.onloadend = (event: any) => {
        let base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        this.localUrl = base64String;
        this.f.mediaArray.controls[i].patchValue({
          localPath: base64String,
        });
      };
    } else
      if (event.target.files[0].type != 'application/pdf') {
        reader.onload = (event: any) => {
          let base64String = event.target.result

          this.localUrl = base64String;
          this.f.mediaArray.controls[i].patchValue({
            localPath: base64String,
          });
        };
      }
    this.f.mediaArray.controls[i].patchValue({
      media: this.selectedFile,
      docName: this.selectedFile.name,
      docState: true,
      docErrorState: false,
      docType: this.selectedFile.type == 'application/pdf' ? 'pdf' : 'img'
    });
    console.log(this.documents);
    // }
  }
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  onImageClick(url: any, type: any, name: any) {
    console.log(url, type);
    if (type != 'pdf') {
      this.imageData.url = url;
      this.imageData.boolean = true;
    } else if (type == 'pdf') {
      console.log(url);
      var pdf_url = null;
      if (url.startsWith('https://ucciback.brandtalks.in/storage/news/media/')) {
        pdf_url = url
      } else {
        var blob = this.b64toBlob(url, 'application/pdf');
        pdf_url = window.URL.createObjectURL(blob);

      }
      let a = document.createElement('a');
      document.body.appendChild(a);
      var anchor = document.createElement('a');
      anchor.href = pdf_url;
      anchor.target = '_blank';
      anchor.click();
    }
  }
  // Media Preview
  logoPreview(index: any) {
    this.confirmationService.confirm({
      message: 'Are you sure to change image?',
      header: 'Confirmation',
      accept: () => {
        this.f?.mediaArray?.controls[index].patchValue({
          media: null,
          localPath: null,
          docName: null,
          docState: false,
        });
      },
    });
  }
  // checkbox selection
  selectionNew(event: any) {
    if (event?.checked == true) {
      this.notificationForm.patchValue({ is_new: '1' })
    } else if (event?.checked == false) {
      this.notificationForm.patchValue({ is_new: '0' })
    }
  }
  onChange(event, index) {
    console.log(event, index);
    // debugger;
    this.mediatype = event?.value
    if (event?.value == 'Link') {
      this.notificationForm.controls['mediaArray'].controls[index]['controls']['media'].value = ''
      this.notificationForm.controls['mediaArray'].controls[index].patchValue({
        showInput: false
      });
      this.notificationForm?.controls['mediaArray'].controls[index]['controls']['media_alt'].setErrors(null);
    }
    else if (event?.value == 'File') {
      this.notificationForm.controls['mediaArray'].controls[index]['controls']['media_alt'].value = ''
      this.notificationForm.controls['mediaArray'].controls[index].patchValue({
        docState: false
      });

      console.log(this.notificationForm.value.mediaArray);
      this.notificationForm.controls['mediaArray'].controls[index]['controls']['media_alt'].setErrors({ 'incorrect': true })
      this.notificationForm.controls.mediaArray.controls.forEach(c => c.updateValueAndValidity());
    }
  }
  getDocumentArray() {
    return this.notificationForm.get('mediaArray') as FormArray;
  }
  onAddRow(string: any, indexat?: any) {
    let mediaDoc = this.getDocumentArray();
    if (string == 'add') {
      if (mediaDoc.valid) {
        this.docErrorState = false;
        mediaDoc.push(
          this.fb.group({
            media: new FormControl('', [Validators.required]),
            media_alt: new FormControl(''),
            media_title: new FormControl('', [Validators.required]),
            media_type: new FormControl('', [Validators.required]),
            showInput: new FormControl(false),
            docErrorState: new FormControl(false),
            localPath: new FormControl(null),
            docName: new FormControl(''),
            docState: new FormControl(false),
            docType: new FormControl(null)
          })
        );
      } else this.docErrorState = true;
    } else {
      mediaDoc.removeAt(indexat);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.notificationForm.controls;
  }
  // get notificationlist 
  getNotificationList() {
    this.newsService.getNotifications().subscribe((res: any) => {
      console.log(res);
      this.notificationList = res.data;
      this.datalength = this.notificationList.length;
      this.spinner.hide();
    })
  }

  // Get edit Data
  getDataByID() {
    console.log(this.slug.slug);

    this.newsService.getDataById(this.slug.slug).subscribe((res: any) => {
      console.log(res);
      this.getDataFromId = res.data;
      let documentArray = this.getDocumentArray();
      for (let i = 0; i < this.getDataFromId?.news_media?.length; i++) {
        console.log(i);
        let type = this.getDataFromId.news_media[i].media.split('.');
        console.log(type, this.getDataFromId.news_media[i].media);

        documentArray.push(
          this.fb.group({
            media: new FormControl(this.getDataFromId.news_media[i].media, [
              Validators.required,
            ]),
            media_alt: new FormControl(this.getDataFromId.news_media[i].media_alt),
            media_type: new FormControl(this.getDataFromId.news_media[i].media_type, [
              Validators.required,
            ]),
            media_title: new FormControl(this.getDataFromId.news_media[i].media_title, [
              Validators.required,
            ]),
            // showInput: new FormControl(),
            docState: new FormControl(this.getDataFromId.news_media[i].media_type == 'File' ? true : false),
            docName: new FormControl(null),
            localPath: new FormControl(environment.notification_meadia + this.getDataFromId.news_media[i].media),
            docType: new FormControl(type[type.length - 1] == 'pdf' ? 'pdf' : 'img')
          })
        );
        console.log(this.notificationForm.value.mediaArray);
      }
      this.notificationForm.patchValue({
        news_type: this.getDataFromId?.news_type,
        title: this.getDataFromId?.title,
        description: this.getDataFromId?.description,
        is_new: this.getDataFromId?.is_new,
      });
      this.isNew = this.getDataFromId?.is_new == '1' ? true : false;

      this.spinner.hide();
    });
  }
}
