import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackGuard implements CanActivate {
  loginAuth:any
  url: any;
  constructor(private router: Router, private activatedRoute : ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.loginAuth = localStorage.getItem('access_token');
    this.loginAuth = JSON.parse(this.loginAuth);
    this.url = state.url;
    // alert(this.url)
    if(this.loginAuth){  
      return true;
    }

    else{
      localStorage.setItem('redirect_url', this.url)
      localStorage.removeItem('access_token');  
      localStorage.removeItem('data');  
      this.router.navigateByUrl('/');
    }
  }
  
}
