// import { Component, OnInit } from '@angular/core';
// import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonClass } from 'src/app/common';
import { GroupByPipePipe } from 'src/app/pipes/group-by-pipe.pipe';
import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: ['./how-it-works-page.component.scss']
})
export class HowItWorksPageComponent implements OnInit {
    news: any = [];
    base_URL = environment.ucci_base_url

    constructor(private notificationService: NewsAndEventsServiceService) { }

    ngOnInit(): void {
        this.notificationService.getNotifications().subscribe((res: any) => {
            console.log(res);
            let news = res?.data?.filter(res => res?.news_type == 'Library' || res?.news_type == 'Archive')
            console.log(news);
            const filterPipe = new GroupByPipePipe();
            this.news = filterPipe.transform(news,'title');
            console.log(this.news)
        })
    }

    pageTitleContent = [
        {
            title: 'How it works',
            backgroundImage: 'assets/img/page-title/page-title5.jpg'
        }
    ]

    timelineContent = [
        {
            number: '1',
            title: 'Submit Listings',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Submit Now',
            buttonLink: '#'
        },
        {
            number: '2',
            title: 'Choose Your Package',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Choose Now',
            buttonLink: '#'
        },
        {
            number: '3',
            title: 'Login to My Account',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Login Now',
            buttonLink: '#'
        },
        {
            number: '4',
            title: 'Submit Listing - Free or Paid',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Choose Now',
            buttonLink: '#'
        },
        {
            number: '5',
            title: 'Admin Approves',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Browse Now',
            buttonLink: '#'
        }
    ]

}