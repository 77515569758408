import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { IndustryAreaMasterService } from 'src/app/services/industry-area-master.service';
import { InterestMasterService } from 'src/app/services/interest-master.service';
import { NatureOfBusinessMasterService } from 'src/app/services/nature-of-business-master.service';
import { PaymentService } from 'src/app/services/payment.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-edit-regular-member-by-id',
  templateUrl: './edit-regular-member-by-id.component.html',
  styleUrls: ['./edit-regular-member-by-id.component.scss'],
})
export class EditRegularMemberByIdComponent implements OnInit {
  imageData: any = { boolean: false };
  selectedState: string;
  selectedState1: string;
  selectedState2: string;
  selectedState3: string;
  turnoverData: any = {};
  classficationData: any = [];
  categoryData: any = {};
  resRegularMsg: string = '';
  resRegularMsgCheck: string = '';
  submitted = false;
  dob1filterDate: any;
  dob2filterDate: any;
  establishfilterDate: any;
  selectedFile: File;
  regularMemberFormFiles: any = {};
  communicationArray: any = [];
  pre1Array: any = [];
  pre2Array: any = [];
  pre3Array: any = [];
  preferred3: any = [];
  state: any = [];
  isShow: boolean = true;
  feeResponse: any = [];
  formResponse: any = [];
  interest: any = [];
  industryArea: any = [];
  natureofBusinessList: any = [];
  selectedInterest2: any[] = [];
  selectedInterest1: any[] = [];
  selectedRegistered: any[] = [];
  selectedCities3: any;
  today: any = new Date();
  invalidDates: Array<Date> = [];
  successState: boolean = false;
  logoState: boolean = true;
  profile1State: boolean = true;
  profile2State: boolean = true;
  eclosureState: boolean = true;
  dropdownState: boolean = true;
  constitution: any[] = [
    { id: 1, name: 'Limited Company' },
    { id: 2, name: 'Partnership Firm' },
    { id: 3, name: 'Proprietorship Firm' },
    { id: 4, name: 'Others' },
  ];
  registeredBy: any[] = [
    { id: 1, name: 'Registrar of Companies' },
    { id: 2, name: 'Registrar of Firms' },
    { id: 3, name: 'Registrar of Co-operatives' },
    { id: 4, name: 'Others' },
  ];
  averageEmp: any = [
    { name: 'Less than 20' },
    { name: 'Between 20 and 100' },
    { name: 'Between 100 and 500' },
    { name: 'More than 500' },
  ];
  applicant: any = [
    { id: 1, name: 'Exporter Availing Certificates of Origin' },
    { id: 2, name: 'Hazardous Waste Disposal' },
    { id: 3, name: 'Others' },
  ];
  investment: any = [
    { item: 'Upto Rs. 1 Crore' },
    { item: 'Above Rs. 1 and upto 10 crores' },
    { item: 'Above Rs. 10 and upto 50 crores' },
    { item: 'Above Rs. 50 crores' },
  ];
  isChecked: boolean = false;
  inputShow1: boolean = false;
  inputShow2: boolean = false;
  inputShow3: boolean = false;
  regularMemberList: any;
  datalength: any;
  oldData: any = [];
  company: boolean;
  newData: any = [];
  nominee2: boolean;
  nominee1: boolean;
  preferred_communication1: any;
  wappState: boolean;
  logoBaseUrl: string = environment.regularMemberImgs_url;
  nominee1ImageBaseUrl: string = environment.nominee1Image;
  nominee2ImageBaseUrl: string = environment.nominee2Image;
  env: any = environment;
  approveModalState: boolean = false;
  preferred_communication2: any;
  // modalStyle: boolean = false;
  logoPreviewState: boolean = true;
  nominee1PreviewState: boolean = true;
  nominee2PreviewState: boolean = true;

  appliedByForm = this.fb.group({
    userId: [null, [Validators.required]],
  });
  otpState: boolean = false;
  otp: any;
  orderId: any;
  amount: any;
  preferred2: any = [];
  preferred1: any = [];
  logo: any;
  profile1: any;
  profile2: any;
  enclosureDoc: any[] = [];
  formData: FormData;
  loggedInUserEmail: any;
  successMessage: { form: string; message: string };
  isAddressSame: boolean = false;
  showInputFile: boolean = true;
  docErrorState: boolean;
  documents: any = [];
  enclosures: any;
  oldEnclosures: any;
  role: any;
  slug: any;
  regularMemberData: any;
  nominee: any[] = [];
  userId: any;
  paymentId: any;
  docEncloErrorState: boolean = false;
  SelectedEnclosure: any[] = [];
  enclosure: any;
  memberShipStatus: any;
  forCompanies: any = [
    { id: 1, name: 'Self-attested Copy of Registration Certificate' },
    {
      id: 2,
      name: 'Audited Financial Report for the Last Two Financial Years',
      document: '',
      docError: false,
    },
    {
      id: 3,
      name: 'Self-attested Copy of the PAN Card of the Company/firm',
      document: '',
      docError: false,
    },
    {
      id: 4,
      name: 'Self-attested Copy of the GST Registration/Udyog Adhaar Card',
      document: '',
      docError: false,
    },
    {
      id: 5,
      name: 'Board/Paertners Resolution Authorizing the Signatory to this Form',
      document: '',
      docError: false,
    },
    {
      id: 6,
      name: '2 Passport Size Photos of 1st Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 7,
      name: '2 Passport Size Photos of 2nd Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 8,
      name: 'Self-attested Copy of the Aadhaar Card of the 1st Nominee',
      document: '',
      docError: false,
    },
    {
      id: 9,
      name: 'Self-attested Copy of the Aadhaar Card of the 2nd Nominee',
      document: '',
      docError: false,
    },
  ];
  forProfessionals: any = [
    {
      id: 1,
      name: 'Self-attested Copy of Professional Qualifications of 1st Nominee',
      document: '',
      docError: false,
    },
    {
      id: 2,
      name: 'Self-attested Copy of Professional Qualifications of 2nd Nominee',
      document: '',
      docError: false,
    },
    {
      id: 3,
      name: '2 Passport Size Photos of the 1st Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 4,
      name: '2 Passport Size Photos of the 2nd Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 5,
      name: 'Self-attested Copy of the Aadhaar Card of the 1st Nominee',
      document: '',
      docError: false,
    },
    {
      id: 6,
      name: 'Self-attested Copy of the Aadhaar Card of the 2nd Nominee',
      document: '',
      docError: false,
    },
    {
      id: 7,
      name: 'Self-attested Copy of the Certificate of Professional Registration',
      document: '',
      docError: false,
    },
  ];
  forTradeAssociations: any = [
    {
      id: 1,
      name: 'Self-attested Copy of the Constitution/Memorandum & Articles',
      document: '',
      docError: false,
    },
    {
      id: 2,
      name: 'Number of Members and their List with Classification',
      document: '',
      docError: false,
    },
    {
      id: 3,
      name: 'List of Present Office Bearers with Address and Contact Details',
      document: '',
      docError: false,
    },
    {
      id: 4,
      name: 'Audicted Financial Report for the Last two Financial Years',
      document: '',
      docError: false,
    },
    {
      id: 5,
      name: '2 Passport Size Photos of the 2nd Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 6,
      name: 'Self-attested Copy of the Aadhaar Card of the 1st Nominee',
      document: '',
      docError: false,
    },
    {
      id: 7,
      name: 'Self-attested Copy of the Aadhaar Card of the 2nd Nominee',
      document: '',
      docError: false,
    },
    {
      id: 8,
      name: 'Resolution Authorizing the Signatory to this Form',
      document: '',
      docError: false,
    },
    {
      id: 9,
      name: '2 Passport Size Photos of 1st Nominee with Name on Back',
      document: '',
      docError: false,
    },
    {
      id: 10,
      name: '2 Passport Size Photos of 2nd Nominee with Name on Back',
      document: '',
      docError: false,
    },
  ];
  constructor(
    private apiservice: ApiService,
    private utility: utility,
    private messageService: MessageService,
    private fb: FormBuilder,
    private paymentservice: PaymentService,
    private scroller: ViewportScroller,
    private router: Router,
    private confirmationService: ConfirmationService,
    private industryareamaster: IndustryAreaMasterService,
    private nobmaster: NatureOfBusinessMasterService,
    private interestMasterService: InterestMasterService,
    private spinner: NgxSpinnerService,
    private commonClass: CommonClass,
    private ActivatedRoute: ActivatedRoute,
    private _utility: utility
  ) { }

  ngOnInit(): void {
    this.role = this.commonClass.getLocalStorage().role;
    if (this.role == 'Admin' || this.role == 'UCCI Staff') {
      this.slug = this.ActivatedRoute.snapshot.params.slug;
      this.apiservice
        .getStateWithJson('assets/state.json')
        .subscribe((res: any) => {
          this.state = res.Data;
        });
      this.apiservice.getTurnoverData().subscribe((res: any) => {
        this.turnoverData = res;
      });
      this.apiservice.getMemberClassfication().subscribe((res: any) => {
        this.classficationData = res.data;
        // // console.log(this.classficationData);
      });
      this.apiservice.getMemberCategory().subscribe((res: any) => {
        this.categoryData = res;
      });
      if (localStorage.getItem('regularMemberValue')) {
        // this.registerForm.setValue(
        //   JSON.parse(localStorage.getItem('regularMemberValue'))
        // );
      }
      this.getAreaofInterest();
      this.getIndusrtArea();
      this.getNatureofBusiness();
      this.invalidDates.push(this.today);

      this.onAddRow('add');
      this.loggedInUserEmail = this.commonClass.getLocalStorage().useremail;
      this.getMemberById();
    }
    // console.log(this.loggedInUserEmail);
  }

  registerForm = this.fb.group({
    companyname: new FormControl('', [Validators.required]),
    others1: new FormControl(''),
    others2: new FormControl(''),
    others3: new FormControl(''),
    gstno: new FormControl(''),
    // uin: new FormControl('', [Validators.required]),
    cin: new FormControl('', [
      Validators.maxLength(21),
      Validators.pattern(
        '^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$'
      ),
    ]),
    avgemp: new FormControl(null),
    telephone: new FormControl('', [
      Validators.maxLength(10),
      Validators.pattern('((\\+91-?)|0)?[0-9]{10}$'),
    ]),
    residentaltel: new FormControl('', [
      Validators.maxLength(10),
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}'),
    ]),
    address: new FormControl('', [Validators.required]),
    state: new FormControl(''),
    city: new FormControl(''),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
    mainaddress: new FormControl('', []),
    mainstate: new FormControl('', []),
    maincity: new FormControl('', [Validators.maxLength(70)]),
    mainzipcode: new FormControl('', [
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
    registrationnumber: new FormControl('', [Validators.maxLength(6)]),
    capitalinvestment: new FormControl(null, []),
    website: new FormControl('', [Validators.maxLength(50)]),
    mainwebsite: new FormControl('', [Validators.maxLength(50)]),
    email: new FormControl(''),
    mainemail: new FormControl(''),
    businessnature: new FormControl(null),
    about: new FormControl('', [Validators.maxLength(200)]),
    logo: new FormControl('', []),
    memberclassficationid: new FormControl(null),
    membercategoryid: new FormControl(null),
    applicantconstitution: new FormControl(null, [
      Validators.required,
      Validators.maxLength(100),
    ]),
    establishdate: new FormControl(null, []),
    turnoverid: new FormControl(null),
    registerby: new FormControl(null),
    name1: new FormControl(''),
    pan1: new FormControl('', [
      Validators.maxLength(10),
      Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$'),
    ]),
    designation1: new FormControl('', [Validators.maxLength(100)]),
    dob1: new FormControl(null, []),
    // registered: new FormControl(null, [Validators.required]),
    registered: this.fb.array([]),
    email1: new FormControl(''),
    officephone1: new FormControl('', [
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      Validators.maxLength(10),
    ]),
    mobileno1: new FormControl('', [
      Validators.required,
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      Validators.maxLength(10),
    ]),
    address1: new FormControl('', []),
    city1: new FormControl('', [Validators.maxLength(70)]),
    state1: new FormControl(null, []),
    zipcode1: new FormControl('', [
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
    department1: new FormControl('', [Validators.maxLength(100)]),
    ia1: new FormControl(null),
    preferredcommunication1: this.fb.array([]),
    areaofinterest1: this.fb.array([]),
    nomineeimage1: new FormControl(''),
    info: new FormControl(''),
    biodata1: new FormControl('', [Validators.maxLength(250)]),
    name2: new FormControl('', [Validators.maxLength(50)]),
    pan2: new FormControl('', [
      Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'),
      Validators.maxLength(10),
    ]),
    designation2: new FormControl('', [Validators.maxLength(100)]),
    dob2: new FormControl(null, []),
    email2: new FormControl('', [Validators.email]),
    officephone2: new FormControl('', [
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      Validators.maxLength(10),
    ]),
    mobileno2: new FormControl('', [
      Validators.required,
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      Validators.maxLength(10),
    ]),
    address2: new FormControl('', []),
    city2: new FormControl('', [Validators.maxLength(70)]),
    state2: new FormControl(null, []),
    zipcode2: new FormControl('', [
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
    department2: new FormControl('', [Validators.maxLength(100)]),
    ia2: new FormControl(null),
    preferredcommunication2: this.fb.array([]),
    preferredcommunication3: this.fb.array([]),
    areaofinterest2: this.fb.array([]),
    nomineeimage2: new FormControl(''),
    oterprofession: new FormControl(''),
    oterprofession1: new FormControl(''),
    oterprofession2: new FormControl(''),
    biodata2: new FormControl('', [Validators.maxLength(250)]),
    supporting_doc: this.fb.array([]),
    name3: new FormControl(''),
    designation3: new FormControl(''),
    email3: new FormControl(''),
    mobile_no3: new FormControl(''),
    amendment_reason: new FormControl(''),
    eclosuredoc: this.fb.array([]),
    member_since: new FormControl(null, []),
  });

  onOtpResend() {
    // let emailObject = {
    //   email: this.appliedByForm.value.email,
    // };
    // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
    //   this.messageService.add({
    //     severity: 'success',
    //     detail: res.message,
    //   });
    // });
  }

  getDocumentArray() {
    return this.registerForm.get('supporting_doc') as FormArray;
  }
  onAddRow(string: any, indexat?: any) {
    let mediaDoc = this.getDocumentArray();
    if (string == 'add') {
      // debugger;
      if (mediaDoc.valid) {
        this.docErrorState = false;
        mediaDoc.push(
          this.fb.group({
            document: new FormControl(null),
            document_for: new FormControl(null),
          })
        );
      } else this.docErrorState = true;
    } else {
      mediaDoc.removeAt(indexat);
    }
  }
  onSendOtp() {
    if (this.appliedByForm.valid) {
      this.userId = this.appliedByForm.value.userId;
      // this.spinner.show();
      // this.apiservice.OTPVerification(this.otpForm.value).subscribe((res: any) => {
      //     console.log(res);
      //     this.spinner.hide();
      //     this.messageService.add({
      //       severity: 'success',
      //       detail: res.message,
      //     });
      this.submitData();
      // });
    }
    // }
  }
  submitData() {
    this.formData = new FormData();
    // format date code
    this.dob1filterDate = moment(this.registerForm.value.dob1).format(
      'YYYY/MM/DD'
    );
    this.registerForm.value.dob1 = this.dob1filterDate;
    this.dob2filterDate = moment(this.registerForm.value.dob2).format(
      'YYYY/MM/DD'
    );
    this.registerForm.value.dob2 = this.dob2filterDate;

    this.establishfilterDate = moment(
      this.registerForm.value.establishdate
    ).format('YYYY/MM/DD');
    // state dropdown
    let selectedState: any = [];
    selectedState.push(this.registerForm.value.state);
    let selectedState1: any = [];
    selectedState1.push(this.registerForm.value.state1);
    let selectedState2: any = [];
    selectedState2.push(this.registerForm.value.state2);
    let selectedState3: any = [];
    selectedState3.push(this.registerForm.value.mainstate);
    let data = this.registerForm.value;
    // // console.log(data);
    let oldCompanyData = JSON.stringify(this.oldData.company);
    let newCompanyData = JSON.stringify(this.newData.company);
    let oldnominee1Data = JSON.stringify(this.oldData.nominee1);
    let newnominee1Data = JSON.stringify(this.newData.nominee1);
    let oldnominee2Data = JSON.stringify(this.oldData.nominee2);
    let newnominee2Data = JSON.stringify(this.newData.nominee2);
    let changedItemes: any = [];

    console.log(
      oldCompanyData == newCompanyData,
      oldnominee1Data == newnominee1Data,
      oldnominee2Data == newnominee2Data
    );

    if (oldCompanyData !== newCompanyData) {
      console.log('Company');

      changedItemes.push('company');
    }
    if (oldnominee1Data !== newnominee1Data) {
      console.log('Nominee 1');
      changedItemes.push('nominee1');
    }
    if (oldnominee2Data !== newnominee2Data) {
      console.log(this.oldData.nominee2, this.newData.nominee2);
      changedItemes.push('nominee2');
    }

    this.formData.append('otp', this.otp ?? null);
    this.formData.append('membership_type', 'Regular');
    this.formData.append('changed_in', changedItemes ?? null);
    this.formData.append('company_name', data.companyname ?? null);
    this.formData.append('gst_number', data.gstno ?? null);
    this.formData.append('name3', data.name3 ?? null);
    this.formData.append('designation3', data.designation3 ?? null);
    this.formData.append('mobile_no3', data.mobile_no3 ?? null);
    this.formData.append('email3', data.email3 ?? null);

    this.formData.append('cin_number', data.cin ?? null);
    this.formData.append('avg_fulltime_emp', data.avgemp ?? null);
    this.formData.append('office_telephone', data.telephone ?? null);
    this.formData.append('residential_telephone', data.residentaltel ?? null);
    this.formData.append('address', data.address ?? null);
    this.formData.append('city', data.city ?? null);
    this.formData.append('state', (selectedState.isNotEmpty && selectedState[0]?.name != null) ? selectedState[0]!.name : null);
    this.formData.append('pin_code', data.zipcode ?? null);
    this.formData.append('main_address', data.mainaddress ?? null);
    this.formData.append('main_city', data.maincity ?? null);
    this.formData.append('main_state', (selectedState3.isNotEmpty && selectedState3[0]?.name != null) ? selectedState3[0]!.name : null);
    this.formData.append('main_pin_code', data.mainzipcode ?? null);
    this.formData.append('registration_number', data.registrationnumber ?? null);
    this.formData.append('capital_investment', data.capitalinvestment ?? null);
    this.formData.append('website', data.website ?? null);
    this.formData.append('email', data.email ?? null);
    this.formData.append('main_website', data.mainwebsite ?? null);
    this.formData.append('main_email', data.mainemail ?? null);
    this.formData.append('nature_of_business', data.businessnature ?? null);
    this.formData.append('about', data.about ?? null);
    this.formData.append('other_information', data.info ?? null);
    this.formData.append('other_profession', data.oterprofession ?? null);
    this.formData.append('other_profession1', data.oterprofession1 ?? null);
    this.formData.append('other_profession2', data.oterprofession2 ?? null);
    this.formData.append('amendment_reason', data.amendment_reason ?? null);

    if (!this.logoPreviewState) {
      this.formData.append('logo', this.regularMemberFormFiles?.logo ?? null);
    }
    if (!this.showInputFile) {
      this.formData.append(
        'enclosures',
        this.regularMemberFormFiles?.enclosureDoc ?? null
      );
    }
    this.formData.append(
      'member_classification_id',
      data.memberclassficationid ?? null
    );
    this.formData.append('member_category_id', data.membercategoryid ?? null);
    if (this.constitutionId != 4) {
      this.formData.append(
        'applicant_constitution',
        data.applicantconstitution?.name
      );
    } else if (this.constitutionId == 4) {
      this.formData.append('applicant_constitution', data.others1 ?? null);
    }
    if (this.registerById != 4) {
      this.formData.append('registered_by', data.registerby?.name ?? null);
    } else if (this.registerById == 4) {
      this.formData.append('registered_by', data.others2 ?? null);
    }

    this.formData.append('turnover_id', data.turnoverid ?? null);
    this.formData.append('nominee1_name', data.name1 ?? null);
    this.formData.append('nominee1_designation', data.designation1 ?? null);
    this.formData.append('nominee1_pan_number', data.pan1 ?? null);
    this.formData.append('nominee1_address', data.address1 ?? null);
    this.formData.append('nominee1_city', data.city1 ?? null);
    this.formData.append('nominee1_state', selectedState1.isNotEmpty ? selectedState1[0]?.name ?? null : null);
    this.formData.append('nominee1_pin_code', data.zipcode1 ?? null);
    this.formData.append('nominee1_office_phone', data.officephone1 ?? null);
    this.formData.append('nominee1_mobile_no', data.mobileno1 ?? null);
    this.formData.append('nominee1_email', data.email1 ?? null);
    this.formData.append('nominee1_department', data.department1 ?? null);
    this.formData.append('ia1', data.ia1 ?? null);

    if (this.establishfilterDate != 'Invalid date') {
      this.formData.append('establishment_date', this.establishfilterDate ?? null);
    } else {
      this.formData.append('establishment_date', null);
    }
    if (this.dob1filterDate != 'Invalid date') {
      this.formData.append('date_of_birth1', this.dob1filterDate ?? null);
    } else {
      this.formData.append('date_of_birth1', null);
    }
    if (this.dob2filterDate != 'Invalid date') {
      this.formData.append('date_of_birth2', this.dob2filterDate ?? null);
    } else {
      this.formData.append('date_of_birth2', null);
    }
    
    
    // append applicant registered
    let registered: any = [];
    if (this.registerId != 3) {
      if (this.selectedRegistered.length > 0) {
        for (var i = 0; i < this.selectedRegistered.length; i++) {
          console.log(this.selectedRegistered[i]);

          registered.push(this.selectedRegistered[i]?.name);
        }

        this.formData.append(
          'applicant_registered',
          JSON.stringify(registered ?? null)
        );
      }
    } else if (this.registerId == 3) {
      this.formData.append('applicant_registered', data.others3 ?? null);
    }
    let aoi1: any = [];

    if (this.selectedInterest1.length > 0) {
      for (var i = 0; i < this.selectedInterest1.length; i++) {
        aoi1.push(this.selectedInterest1[i].id);
      }

      this.formData.append('area_of_interest1', aoi1);
    } else if (this.selectedInterest1.length == 0) {
      var array: any = [];
      this.formData.append('area_of_interest1', array ?? null);
    }
    // append interest area 2
    let aoi2: any = [];
    if (this.selectedInterest2.length > 0) {
      for (var i = 0; i < this.selectedInterest2.length; i++) {
        aoi2.push(this.selectedInterest2[i].id);
      }
      console.log(aoi2);
      this.formData.append('area_of_interest2', aoi2 ?? null);
    } else if (this.selectedInterest2.length == 0) {
      var array: any = [];
      this.formData.append('area_of_interest2', array ?? null);
    }
    if (!this.nominee1PreviewState) {
      this.formData.append('profile1', this.regularMemberFormFiles.profile1 ?? null);
    }
    this.formData.append('biodata1', data.biodata1 ?? null);
    this.formData.append('name2', data.name2 ?? null);
    this.formData.append('pan_number2', data.pan2 ?? null);
    this.formData.append('designation2', data.designation2 ?? null);
    this.formData.append('nominee2_address', data.address2 ?? null);
    this.formData.append('nominee2_city', data.city2 ?? null);
    this.formData.append('nominee2_state', selectedState2.isNotEmpty ? selectedState2[0]?.name ?? null : null);
    this.formData.append('nominee2_pin_code', data.zipcode2 ?? null);
    this.formData.append('office_phone2', data.officephone2 ?? null);
    this.formData.append('mobile_no2', data.mobileno2 ?? null);
    this.formData.append('email2', data.email2 ?? null);
    this.formData.append('department2', data.department2 ?? null);
    this.formData.append('ia2', data.ia2 ?? null);
    
    this.formData.append(
      'nominee1_other_profession',
      data.oterprofession1 ?? ''
    );
    this.formData.append(
      'nominee2_other_profession',
      data.oterprofession2 ?? ''
    );
    this.registerForm.controls['supporting_doc'].value.map(
      (res: any, index: any) => {
        this.formData.append(
          `supporting_doc[document][${index}]`,
          this.documents[index] == undefined || this.documents[index] == null
            ? null
            : this.documents[index]
        );
        this.formData.append(
          `supporting_doc[document_for][${index}]`,
          res.document_for ?? null
        );
        console.log(res);
      }
    );
    this.preferred1 = [];
    console.log(this.pre1Array);

    for (var i = 0; i < this.pre1Array.value.length; i++) {
      this.preferred1.push(this.pre1Array.value[i]);
    }

    this.formData.append('preferred_communication1', this.preferred1 ?? null);
    this.preferred2 = [];
    for (var i = 0; i < this.pre2Array.value.length; i++) {
      this.preferred2.push(this.pre2Array.value[i]);
    }

    this.formData.append('preferred_communication2', this.preferred2 ?? null);
    if (!this.nominee1PreviewState) {
      this.formData.append('profile2', this.regularMemberFormFiles?.profile2 ?? null);
    }
    this.preferred3 = [];
    for (var i = 0; i < this.pre3Array?.value?.length; i++) {
      this.preferred3.push(this.pre3Array?.value[i]);
    }
    this.formData.append('preferred_communication3', this.preferred3 ?? null);
    this.formData.append('biodata2', data.biodata2 ?? null);

    var orderIdObj: any = {
      changed_in: changedItemes,
      membership_type: 'Regular',
      member_id: this.regularMemberData.id,
      payment_id: this.paymentId ?? null,
    };
    // this.spinner.show();
    console.log(this.userId);

    this.apiservice.getOrderId(orderIdObj).subscribe(
      (res: any) => {
        console.log(res);
        this.approveModalState = false;
        this.paymentId = res.data.payment_id;
        this.formData.append('payment_id', res?.payment_id ?? '');
        this.spinner.hide();

        if (res?.gatewayResponse != '') {
          setTimeout(() => {
            this.orderId = res.data.order_id;
            this.amount = res.data.amount;
            this.formData.append('order_id', this.orderId ?? null);
            console.log(this.amount, this.orderId);
            if (this.role != 'Admin' && this.role != 'UCCI Staff') {
              this.payment();
            } else if (this.role == 'Admin' || this.role == 'UCCI Staff') {
              this.formData.append('user_id', this.userId ?? null);
              this.formData.append('member_id', this.regularMemberData.id ?? null);
              this.apiservice
                .updateMemberInfo(this.formData)
                .subscribe((res: any) => {
                  // console.log(res);
                  this.messageService.add({
                    severity: 'success',
                    detail: res?.message,
                  });

                  // setTimeout(() => {
                  this.successMessage = {
                    form: 'regular-member-edit',
                    message: 'Form Submitted Successfully',
                  };
                  this.successState = true;
                  // }, 2000);
                });
            }
          }, 1500);
        } else if (res?.gatewayResponse == '') {
          this.formData.append('order_id', '');
          if (this.role == 'Admin' || this.role == 'UCCI Staff') {
            this.formData.append('user_id', this.userId ?? null);
            this.formData.append('member_id', this.regularMemberData.id ?? null);
          }
          this.apiservice
            .updateMemberInfo(this.formData)
            .subscribe((res: any) => {
              // console.log(res);
              this.messageService.add({
                severity: 'success',
                detail: res.message,
              });

              // setTimeout(() => {
              this.successMessage = {
                form: 'regular-member-edit',
                message: 'Form Submitted Successfully',
              };
              this.successState = true;
              // }, 2000);
            });
        }
      },
      (error: any) => {
        // console.warn(error);
        if (error == 'The amount must be atleast INR 1.00') {
          this.messageService.add({
            severity: 'success',
            detail: 'Data updated Successfully!',
          });
          this.approveModalState = false;
        }
        this.spinner.hide();
      }
    );
  }
  getMemberById() {
    if (this.slug) {
      this.apiservice.getMemberById(this.slug).subscribe((res: any) => {
        console.log(res);
        this.regularMemberData = res.data;
        this.regularMemberFormFiles = res.data;
        this.selectedFile;
        this.nominee = [];
        this.nominee.push(
          {
            nomineeName: this.regularMemberData.nominee1?.name ?? null,
            userId: this.regularMemberData.nominee1?.user_id ?? null,
          },
          {
            nomineeName: this.regularMemberData.nominee2?.name ?? null,
            userId: this.regularMemberData.nominee2?.user_id ?? null,
          }
        );
        this.getRegularMemberListFunction();
        this.getMemberClassification(res.data.nature_of_business);
        this.memberShipStatus = res.status ?? null;
      });
    }
  }
  onClose() {
    this.otpState = false;
  }
  get o() {
    return this.appliedByForm.controls;
  }

  get preferredcommunication1() {
    return this.registerForm.get('preferredcommunication1') as FormArray;
  }
  get preferredcommunication2() {
    return this.registerForm.get('preferredcommunication2') as FormArray;
  }
  get areaofinterest1() {
    return this.registerForm.get('areaofinterest1') as FormArray;
  }
  get areaofinterest2() {
    return this.registerForm.get('areaofinterest2') as FormArray;
  }
  get registered() {
    return this.registerForm.get('registered') as FormArray;
  }

  onImageClick(url: any) {
    this.imageData.url = url;
    this.imageData.boolean = true;
  }

  logoPreview(type: string, index: any) {
    if (type == 'company') {
      this.confirmationService.confirm({
        message: 'Are you sure to change image?',
        header: 'Confirmation',
        accept: () => {
          this.registerForm.patchValue({
            logo: null,
          });
          this.logoPreviewState = false;
          this.logo = '';
        },
      });
    } else if (type == 'nominee1') {
      this.confirmationService.confirm({
        message: 'Are you sure to change image?',
        header: 'Confirmation',
        accept: () => {
          this.registerForm.patchValue({
            nomineeimage1: null,
          });
          this.nominee1PreviewState = false;
        },
      });
    } else if (type == 'nominee2') {
      this.confirmationService.confirm({
        message: 'Are you sure to change image?',
        header: 'Confirmation',
        accept: () => {
          this.registerForm.patchValue({
            nomineeimage2: null,
          });
          this.nominee2PreviewState = false;
        },
      });
    } else if (type == 'enclosure') {
      this.confirmationService.confirm({
        message: 'Are you sure to change image?',
        header: 'Confirmation',
        accept: () => {
          this.oldEnclosures[index].enclosures_title = null;
          this.showInputFile = false;
        },
      });
    }
  }

  // Get classification

  getMemberClassification(event?: any) {
    console.log(event);

    this.apiservice.getMemberClassfication(event).subscribe((res: any) => {
      this.classficationData = res.data;
    });

    if (event?.value == 4) {
      this.enclosure = this.forProfessionals;
    } else if (event?.value == 6) {
      this.enclosure = this.forTradeAssociations;
    } else {
      this.enclosure = this.forCompanies;
    }
    console.log(event?.value);
  }

  // On Classification Selection

  onClassificationSelection(event) {
    console.log(event.value, this.classficationData);

    var selectedClassificationObj = this.classficationData.filter(
      (res: any) => {
        return res.id == event.value;
      }
    );
    if (selectedClassificationObj[0].vacant_seats == 0) {
      this.messageService.add({
        severity: 'warn',
        detail: 'No Vacant Seats Available!',
      });

      this.registerForm.patchValue({
        memberclassficationid: null,
      });
    }
  }

  // get regular member list api call
  getRegularMemberListFunction() {
    // this.apiservice.getregularMemberList().subscribe((res: any) => {
    console.log(this.regularMemberData);

    this.regularMemberList = this.regularMemberData;
    this.selectedInterest1 = this.regularMemberList.nominee1_interest;

    var nominee1Interest = [];

    for (i = 0; i < this.regularMemberList.nominee1_interest.length; i++) {
      nominee1Interest.push({
        id: this.regularMemberList.nominee1_interest[i].id,
        interest_area:
          this.regularMemberList.nominee1_interest[i].interest_area,
      });
    }

    var nominee2Interest = [];
    this.regularMemberList.nominee2_interest?.forEach((element) => {
      nominee2Interest.push({
        id: element.id,
        interest_area: element.interest_area,
      });
    });

    let constitution: any;
    let constitutionArray: any = [];
    this.constitution.forEach((res: any) => {
      if (res?.id != 4) {
        constitutionArray.push(res.name);
      }
    });

    let registerbyArray = [];

    this.registeredBy.forEach((res: any) => {
      if (res.id != 4) {
        registerbyArray.push(res?.name);
      }
    });
    var registerby: any = [];
    if (registerbyArray?.includes(this.regularMemberList?.registered_by)) {
      registerby = this.registeredBy.filter((res: any) => {
        if (res.name == this.regularMemberList?.registered_by) {
          this.registerById = res.id;
        }
        return res.name == this.regularMemberList?.registered_by;
      });
    } else if (
      !registerbyArray?.includes(this.regularMemberList?.registered_by)
    ) {
      registerby = [];
      this.inputShow2 = true;
      registerby.push(this.registeredBy[3]);
      this.registerById = 4;
      this.registerForm.patchValue({
        others2: this.regularMemberList?.registered_by,
      });
    }

    if (
      constitutionArray?.includes(
        this.regularMemberList?.applicant_constitution
      )
    ) {
      this.inputShow1 = false;
      constitution = this.constitution.filter((res: any) => {
        return res.name == this.regularMemberList?.applicant_constitution;
      });
      this.constitutionId = constitution[0]?.id;
    } else if (
      !constitutionArray?.includes(
        this.regularMemberList?.applicant_constitution
      )
    ) {
      constitution = [];
      this.inputShow1 = true;
      constitution.push(this.constitution[3]);
      this.constitutionId = 4;
      this.registerForm.patchValue({
        others1: this.regularMemberList?.applicant_constitution,
      });
    }

    var companyState = this.state.filter((res: any) => {
      return res.name === this.regularMemberList?.company_address[0]?.state;
    });
    var companyMainState = this.state.filter((res: any) => {
      return res.name === this.regularMemberList?.company_address[1]?.state;
    });

    var applicant_registered: any = this.commonClass.getStringValue(
      this.regularMemberList?.applicant_registered
    );

    // console.log();
    let data:any[] = [];
    if (typeof applicant_registered == 'string') {
      data.push(applicant_registered);
    } else {
      data = applicant_registered;
    }

    this.selectedRegistered = [];
    data?.forEach((element) => {
      let local_applicant_registered = [];
      local_applicant_registered = this.applicant.filter((res: any) => {
        console.log(res.name, element);

        return res.name == element;
      });
      if (local_applicant_registered[0] == undefined) {
        this.selectedRegistered.push(this.applicant[2]);
        this.registerId = this.selectedRegistered[0]?.id;
        this.inputShow3 = true;
        console.log(this.regularMemberList);

        this.registerForm.patchValue({
          others3: this.regularMemberList?.applicant_registered,
        });
      } else if (local_applicant_registered[0] != undefined) {
        this.selectedRegistered.push(local_applicant_registered[0]);
      }
    });

    console.log(this.selectedRegistered);

    // console.log(registerby);

    var nominee1State = this.state.filter((res: any) => {
      return res.name == this.regularMemberList?.nominee1_address?.state;
    });
    var nominee2State = this.state.filter((res: any) => {
      return res.name == this.regularMemberList?.nominee2_address?.state;
    });

    if (
      this.regularMemberList.nominee1?.preferred_communication?.includes(', ')
    ) {
      this.preferred_communication1 =
        this.regularMemberList?.nominee1?.preferred_communication?.split(', ');
    } else {
      this.preferred_communication1 =
        this.regularMemberList?.nominee1?.preferred_communication?.split(',');
    }

    console.log(this.preferred_communication1);

    for (var i = 0; i < this.preferred_communication1?.length; i++) {
      // console.log(this.preferred_communication1[i]);

      this.onChange(this.preferred_communication1[i], true, 'pre1');
    }

    if (
      this.regularMemberList?.nominee2?.preferred_communication?.includes(', ')
    ) {
      this.preferred_communication2 =
        this.regularMemberList?.nominee2?.preferred_communication?.split(', ');
    } else
      this.preferred_communication2 =
        this.regularMemberList?.nominee2?.preferred_communication?.split(',');

    for (var i = 0; i < this.preferred_communication2?.length; i++) {
      this.onChange(this.preferred_communication2[i], true, 'pre2');
    }

    // console.log(this.selectedInterest1);
    for (var i = 0; i < this.selectedInterest1?.length; i++) {
      this.areaofinterest1.push(
        this.fb.group({
          areaofinterest1: this.selectedInterest1[i],
        })
      );
    }

    // this.regularMemberList.nominee2_interest.forEach((element) => {
    //   let ai2: any;
    //   ai2 = this.interest.filter((res: any) => {
    //     return res.id == element.id;
    //   });

    //   this.selectedInterest2.push(ai2[0]);
    // });
    this.selectedInterest2 = this.regularMemberList?.nominee2_interest;

    for (var i = 0; i < this.selectedInterest2?.length; i++) {
      this.areaofinterest2.push(
        this.fb.group({
          areaofinterest2: this.selectedInterest2[i],
        })
      );
    }

    for (var i = 0; i < this.selectedRegistered?.length; i++) {
      this.registered.push(
        this.fb.group({
          applicant_registered: this.selectedRegistered[i],
        })
      );
    }

    this.getMemberClassification(this.regularMemberList?.nob?.id);
    let regAddress = {
      address: this.regularMemberList?.company_address[0]?.address,
      state: companyState[0],
      city: this.regularMemberList?.company_address[0]?.city,
      pinCode: this.regularMemberList?.company_address[0]?.pin_code,
      website: this.regularMemberList?.website,
      email: this.regularMemberList?.email,
    };
    let mainAddress = {
      address: this.regularMemberList?.company_address[1]?.address,
      state: companyMainState[0],
      city: this.regularMemberList?.company_address[1]?.city,
      pinCode: this.regularMemberList?.company_address[1]?.pin_code,
      website: this.regularMemberList?.main_website,
      email: this.regularMemberList?.main_email,
    };
    this.oldEnclosures = this.regularMemberList.enclosures;
    console.log('====================================');
    console.log(this.oldEnclosures);
    console.log('====================================');
    this.isAddressSame =
      JSON.stringify(regAddress) == JSON.stringify(mainAddress);

    this.registerForm.patchValue({
      companyname: this.regularMemberList?.company_name,
      memberclassficationid: this.regularMemberList?.member_classification_id,
      applicantconstitution: constitution[0],
      gstno: this.regularMemberList?.gst_number,
      cin:
        this.regularMemberList?.cin_number == 'null'
          ? ''
          : this.regularMemberList?.cin_number,
      telephone:
        this.regularMemberList?.office_telephone == 'null'
          ? ''
          : this.regularMemberList?.office_telephone,
      info:
        this.regularMemberList?.other_information == 'null'
          ? ''
          : this.regularMemberList?.other_information,
      oterprofession:
        this.regularMemberList?.other_profession == 'null'
          ? ''
          : this.regularMemberList?.other_profession,
      residentaltel:
        this.regularMemberList?.residential_telephone == 'null'
          ? ''
          : this.regularMemberList?.residential_telephone,
      avgemp:
        this.regularMemberList?.avg_no_of_fulltime_employee == 'null'
          ? ''
          : this.regularMemberList?.avg_no_of_fulltime_employee,
      website:
        this.regularMemberList?.website == 'null'
          ? ''
          : this.regularMemberList?.website,
      mainwebsite:
        this.regularMemberList?.main_website == 'null'
          ? ''
          : this.regularMemberList?.main_website,
      email:
        this.regularMemberList?.email == 'null'
          ? ''
          : this.regularMemberList?.email,
      mainemail:
        this.regularMemberList?.main_email == 'null'
          ? ''
          : this.regularMemberList?.main_email,
      address:
        this.regularMemberList?.company_address[0]?.address == 'null'
          ? ''
          : this.regularMemberList?.company_address[0]?.address,
      mainaddress:
        this.regularMemberList?.company_address[1]?.address == 'null'
          ? ''
          : this.regularMemberList?.company_address[1]?.address,
      state: companyState[0],
      mainstate: companyMainState[0],
      city:
        this.regularMemberList?.company_address[0]?.city == 'null'
          ? ''
          : this.regularMemberList?.company_address[0]?.city,
      logo: this.regularMemberList?.media,
      maincity:
        this.regularMemberList?.company_address[1]?.city == 'null'
          ? ''
          : this.regularMemberList?.company_address[1]?.city,
      zipcode:
        this.regularMemberList?.company_address[0]?.pin_code == 'null'
          ? ''
          : this.regularMemberList?.company_address[0]?.pin_code,
      mainzipcode:
        this.regularMemberList?.company_address[1]?.pin_code == 'null'
          ? ''
          : this.regularMemberList?.company_address[1]?.pin_code,
      capitalinvestment:
        this.regularMemberList?.capital_investment == 'null'
          ? ''
          : this.regularMemberList?.capital_investment,
      registrationnumber:
        this.regularMemberList?.membership_no == 'null'
          ? ''
          : this.regularMemberList?.membership_no,
      businessnature: this.regularMemberList?.nob?.id,
      about:
        this.regularMemberList?.about == 'null'
          ? ''
          : this.regularMemberList?.about,
      membercategoryid: this.regularMemberList?.member_category_id,
      turnoverid: this.regularMemberList?.turnover_id,
      name1: this.regularMemberList?.nominee1?.name,
      designation1:
        this.regularMemberList?.nominee1?.designation == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.designation,
      oterprofession1:
        this.regularMemberList?.nominee1?.other_profession == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.other_profession,
      pan1:
        this.regularMemberList?.nominee1?.pan_number == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.pan_number,
      email1:
        this.regularMemberList?.nominee1?.email == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.email,
      officephone1:
        this.regularMemberList?.nominee1?.office_phone == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.office_phone,
      mobileno1:
        this.regularMemberList?.nominee1?.mobile == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.mobile,
      address1:
        this.regularMemberList?.nominee1_address?.address == 'null'
          ? ''
          : this.regularMemberList?.nominee1_address?.address,
      state1: nominee1State[0],
      city1:
        this.regularMemberList?.nominee1_address?.city == 'null'
          ? ''
          : this.regularMemberList?.nominee1_address?.city,
      zipcode1:
        this.regularMemberList?.nominee1_address?.pin_code == 'null'
          ? ''
          : this.regularMemberList?.nominee1_address?.pin_code,
      department1:
        this.regularMemberList?.nominee1?.department == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.department,
      // preferredcommunication1: JSON.parse(this.preferred_communication1),
      preferredcommunication1: this.preferred_communication1,
      ia1: this.regularMemberList?.nominee1?.industry_area?.id,
      biodata1:
        this.regularMemberList?.nominee1?.biodata == 'null'
          ? ''
          : this.regularMemberList?.nominee1?.biodata,
      name2:
        this.regularMemberList?.nominee2?.name == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.name,
      designation2:
        this.regularMemberList?.nominee2?.designation == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.designation,
      oterprofession2:
        this.regularMemberList?.nominee2?.other_profession == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.other_profession,
      pan2:
        this.regularMemberList?.nominee2?.pan_number == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.pan_number,
      email2:
        this.regularMemberList?.nominee2?.email == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.email,
      officephone2:
        this.regularMemberList?.nominee2?.office_phone == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.office_phone,
      mobileno2:
        this.regularMemberList?.nominee2?.mobile == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.mobile,
      address2:
        this.regularMemberList?.nominee2_address?.address == 'null'
          ? ''
          : this.regularMemberList?.nominee2_address?.address,
      state2: nominee2State[0],
      city2:
        this.regularMemberList?.nominee2_address?.city == 'null'
          ? ''
          : this.regularMemberList?.nominee2_address?.city,
      zipcode2:
        this.regularMemberList?.nominee2_address?.pin_code == 'null'
          ? ''
          : this.regularMemberList?.nominee2_address?.pin_code,
      department2:
        this.regularMemberList?.nominee2?.department == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.department,
      ia2: this.regularMemberList?.nominee2?.industry_area?.id,
      biodata2:
        this.regularMemberList?.nominee2?.biodata == 'null'
          ? ''
          : this.regularMemberList?.nominee2?.biodata,
      registerby: registerby[0],
      nomineeimage2: this.regularMemberList?.nominee2?.media,
      nomineeimage1: this.regularMemberList?.nominee1?.media,
      // biodata1: this.regularMemberList?.nominee1?.biodata,
      // member_since: this.regularMemberList?.member_since,
    });
    const memberSinceDate = new Date(this.regularMemberList?.member_since);
    this.registerForm.get('member_since').setValue(memberSinceDate);
    const dob1 = new Date(this.regularMemberList?.nominee1?.date_of_birth);
    this.registerForm.get('dob1').setValue(dob1);
    const dob2 = new Date(this.regularMemberList?.nominee2?.date_of_birth);
    this.registerForm.get('dob2').setValue(dob2);
    const establishDate = new Date(this.regularMemberList?.establishment_date);
    this.registerForm.get('establishdate').setValue(establishDate);
    // this.registerForm.get('member_since').setValue(  this.regularMemberList?.member_since);
    let preferred_communication1 =
      this.regularMemberList?.nominee1?.preferred_communication?.split(', ');
    let preferred_communication2 =
      this.regularMemberList?.nominee2?.preferred_communication?.split(', ');
    this.oldData = {
      company: {
        company_name: this.regularMemberList?.company_name,
      },
      nominee1: {
        name: this.regularMemberList?.nominee1?.name,
        // designation: this.regularMemberList?.nominee1?.designation,
        // pan_number: this.regularMemberList?.nominee1?.pan_number,
        // date_of_birth: this.regularMemberList?.nominee1?.date_of_birth,
        // email: this.regularMemberList?.nominee1?.email,
        // office_phone: res.data[0].nominee1.office_phone,
        // mobile: res.data[0].nominee1.mobile,
        // address: res.data[0].nominee1_address.address,
        // state: res.data[0].nominee1_address.state,
        // city: res.data[0].nominee1_address.city,
        // pin_code: res.data[0].nominee1_address.pin_code,
        // department: this.regularMemberList?.nominee1?.department,
        // industry_area: this.regularMemberList?.nominee1?.industry_area?.name,
        // biodata: this.regularMemberList?.nominee1?.biodata,
        // nomineeimage1: this.regularMemberList?.nominee1?.media,
        // preferred_communication: 'old',
        // nominee1_interest: nominee1Interest,
        // oterprofession1: this.regularMemberList?.nominee1?.other_profession,
      },
      nominee2: {
        name: this.regularMemberList?.nominee2?.name,
        // designation: this.regularMemberList?.nominee2?.designation,
        // pan_number: this.regularMemberList?.nominee2?.pan_number,
        // date_of_birth: this.regularMemberList?.nominee2?.date_of_birth,
        // email: this.regularMemberList?.nominee2?.email,
        // office_phone: res.data[0].nominee2.office_phone,
        // mobile: res.data[0].nominee2.mobile,
        // address: res.data[0].nominee2_address.address,
        // state: res.data[0].nominee2_address.state,
        // city: res.data[0].nominee2_address.city,
        // pin_code: res.data[0].nominee2_address.pin_code,
        // department: this.regularMemberList?.nominee2?.department,
        // industry_area: this.regularMemberList?.nominee2?.industry_area?.name,
        // biodata: this.regularMemberList?.nominee2?.biodata,preferred_communication1
        // nomineeimage2: this.regularMemberList?.nominee2?.media,preferred_communication1
        // preferred_communication: 'old',
        // nominee2_interest: nominee2Interest,
        // oterprofession2: this.regularMemberList?.nominee2?.other_profession,
      },
    };

    this.spinner.hide();
    // });
  }

  // onChange(communication?: any, isChecked?: boolean, string?: any) {
  //   console.log(communication?.target?.value, isChecked);
  //   if (string == 'pre1') {
  //     this.pre1Array = <FormArray>(
  //       this.registerForm?.controls?.preferredcommunication1
  //     );
  //   } else if (string == 'pre2') {
  //     this.pre2Array = <FormArray>(
  //       this.registerForm?.controls?.preferredcommunication2
  //     );
  //   } else if (string == 'pre3') {
  //     this.pre3Array = <FormArray>(
  //       this.registerForm?.controls?.preferredcommunication3
  //     );
  //   }
  //   if (isChecked) {
  //     if (string == 'pre1') {
  //       this.pre1Array.push(new FormControl(communication));
  //     } else if (string == 'pre2') {
  //       this.pre2Array.push(new FormControl(communication));
  //     } else if (string == 'pre3') {
  //       this.pre3Array.push(new FormControl(communication));
  //     }
  //     // console.log(this.pre1Array, this.pre2Array);
  //   } else {
  //     let index: any;
  //     if (string == 'pre1') {
  //       index = this.pre1Array.controls.findIndex(
  //         (x) => x.value == communication
  //       );
  //       this.pre1Array.removeAt(index);
  //     } else if (string == 'pre2') {
  //       index = this.pre2Array.controls.findIndex(
  //         (x) => x.value == communication
  //       );
  //       this.pre2Array.removeAt(index);
  //     } else if (string == 'pre3') {
  //       index = this.pre3Array.controls.findIndex(
  //         (x) => x.value == communication
  //       );
  //       this.pre3Array.removeAt(index);
  //     }
  //   }
  //   console.log(this.pre1Array.value);
  //   // this.getPreferredcommunication1();
  // }

  // checkingDifferences(oldValues, newValues) {
  //   /* Messages */
  //   let messages = [];
  //   /* Filtered for removed old values */
  //   let difference_old_removed = oldValues.filter(
  //     (x) => !newValues.includes(x)
  //   );
  //   /* Filtered for new added values */
  //   let difference_new_added = newValues.filter((x) => !oldValues.includes(x));

  //   /* Removed old data message */
  //   if (difference_old_removed.length > 0) {
  //     for (let i = 0; i < difference_old_removed.length; i++) {
  //       messages.push(`${difference_old_removed[i]} has been removed`);
  //     }
  //   }

  //   /* New added data message */
  //   if (difference_new_added.length > 0) {
  //     for (let i = 0; i < difference_new_added.length; i++) {
  //       messages.push(`${difference_new_added[i]} has been added`);
  //     }
  //   }

  //   return messages;
  // }
  onChange(communication?: any, isChecked?: boolean, string?: any) {
    console.log(communication?.target?.value, isChecked);

    let formArray: FormArray | undefined;
    if (string === 'pre1') {
      this.pre1Array = <FormArray>(this.registerForm?.controls?.preferredcommunication1);
      formArray = this.pre1Array;
    } else if (string === 'pre2') {
      this.pre2Array = <FormArray>(this.registerForm?.controls?.preferredcommunication2);
      formArray = this.pre2Array;
    } else if (string === 'pre3') {
      this.pre3Array = <FormArray>(this.registerForm?.controls?.preferredcommunication3);
      formArray = this.pre3Array;
    }

    if (!formArray) return; // Return if formArray is undefined

    if (isChecked) {
      // Check for duplicate values before adding
      if (!this.isDuplicateValue(formArray, communication)) {
        formArray.push(new FormControl(communication));
      }
    } else {
      let index: number = formArray.controls.findIndex((x: FormControl) => x.value === communication);
      if (index !== -1) {
        formArray.removeAt(index);
      }
    }

    console.log(this.pre1Array.value, this.pre2Array.value, this.pre3Array.value);
  }

  isDuplicateValue(formArray: FormArray, value: any): boolean {
    return formArray.value.some((controlValue: any) => controlValue === value);
  }


  Register(register) {
    let ia1 = this.industryArea.filter((res: any) => {
      return res.id == this.registerForm?.value?.ia1;
    });
    let ia2 = this.industryArea?.filter((res: any) => {
      return res.id == this.registerForm?.value?.ia2;
    });

    var interest1: any = [];

    this.registerForm?.value?.areaofinterest1?.forEach((element: any) => {
      interest1.push({
        id: element?.areaofinterest1?.id,
        interest_area: element?.areaofinterest1?.interest_area,
      });
    });
    let preferredcommunication1 = [];
    this.registerForm?.value?.preferredcommunication1?.forEach(
      (element: any) => {
        console.log(element);

        preferredcommunication1?.push(element);
      }
    );
    var interest2: any = [];

    this.registerForm?.value?.areaofinterest2?.forEach((element: any) => {
      interest2.push({
        id: element.areaofinterest2?.id,
        interest_area: element?.areaofinterest2?.interest_area,
      });
    });

    let preferredcommunication2 = [];
    this.registerForm?.value?.preferredcommunication2?.forEach(
      (element: any) => {
        preferredcommunication2?.push(element);
      }
    );

    // const newPre1State = this.checkingDifferences(
    //   this.preferred_communication1,
    //   this.registerForm?.value?.preferredcommunication1
    // );
    // const newPre2State = this.checkingDifferences(
    //   this.preferred_communication2,
    //   this.registerForm?.value?.preferredcommunication2
    // );
    // console.log(newPre1State, newPre2State);
    Object.keys(this.registerForm.controls).forEach((res: any) => {
      console.log(res, this.registerForm.controls[res].status);
    });
    this.newData = {
      company: {
        company_name: this?.registerForm?.value?.companyname,
      },
      nominee1: {
        name: this.registerForm?.value?.name1,
        // designation: this.registerForm?.value?.designation1,
        // pan_number: this.registerForm?.value?.pan1,
        // date_of_birth: moment(this.registerForm?.value?.dob1).format(
        //   'YYYY-MM-DD'
        // ),
        // email: this.registerForm.value.email1,
        // office_phone: this.registerForm.value.officephone1,
        // mobile: this.registerForm.value.mobileno1,
        // address: this.registerForm.value.address1,
        // state: this.registerForm.value.state1.name,
        // city: this.registerForm.value.city1,
        // pin_code: this.registerForm.value.zipcode1,
        // department: this.registerForm?.value?.department1,
        // industry_area: ia1[0].name,
        // biodata: this.registerForm?.value?.biodata1,
        // nomineeimage1: this.registerForm?.value?.nomineeimage1,
        // preferred_communication: newPre1State?.length == 0 ? 'old' : 'new',
        // // preferred_communication: this.getPreferredcommunication1(),
        // nominee1_interest: interest1,
        // oterprofession1: this.registerForm?.value?.oterprofession1,
      },
      nominee2: {
        name: this.registerForm?.value?.name2,
        // designation: this.registerForm?.value?.designation2,
        // pan_number: this.registerForm?.value?.pan2,
        // date_of_birth: moment(this.registerForm?.value?.dob2).format(
        //   'YYYY-MM-DD'
        // ),
        // email: this.registerForm.value.email2,
        // office_phone: this.registerForm.value.officephone2,
        // mobile: this.registerForm.value.mobileno2,
        // address: this.registerForm.value.address2,
        // state: this.registerForm.value.state2.name,
        // city: this.registerForm.value.city2,
        // pin_code: this.registerForm.value.zipcode2,
        // department: this.registerForm?.value?.department2,
        // industry_area: ia2[0].name,
        // biodata: this.registerForm?.value?.biodata2,
        // nomineeimage2: this.registerForm?.value?.nomineeimage2,
        // // preferred_communication: preferredcommunication2,
        // preferred_communication: newPre2State?.length == 0 ? 'old' : 'new',
        // nominee2_interest: interest2,
        // oterprofession2: this.registerForm?.value?.oterprofession2,
      },
    };

    console.log(this.oldData, this.newData);

    let oldCompanyData = JSON.stringify(this.oldData.company);
    let newCompanyData = JSON.stringify(this.newData.company);
    if (oldCompanyData === newCompanyData) {
      this.company = false;
    } else if (oldCompanyData !== newCompanyData) {
      this.company = true;
    }
    let oldNominee1Data = JSON.stringify(this.oldData.nominee1);
    let newNominee1Data = JSON.stringify(this.newData.nominee1);
    if (oldNominee1Data === newNominee1Data) {
      this.nominee1 = false;
    } else if (oldNominee1Data !== newNominee1Data) {
      this.nominee1 = true;
    }
    let oldNominee2Data = JSON.stringify(this.oldData.nominee2);
    let newNominee2Data = JSON.stringify(this.newData.nominee2);
    if (oldNominee2Data === newNominee2Data) {
      this.nominee2 = false;
    } else if (oldNominee2Data !== newNominee2Data) {
      this.nominee2 = true;
    }

    if (this.registerForm.valid) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          // let email = this.commonClass.getLocalStorage();
          // this.appliedByForm.patchValue({
          //   email: this.loggedInUserEmail,
          // });
          // let emailObject = {
          //   email: this.appliedByForm.value.email,
          // };
          // this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          //   this.messageService.add({
          //     severity: 'success',
          //     detail: res.message,
          //   });
          // });
          if (
            this.role == 'Admin' ||
            this.role == 'UCCI Staff' ||
            this.memberShipStatus == 'In progress' ||
            this.memberShipStatus == 'Pending'
          ) {
            this.registerForm.controls.amendment_reason.setValidators(null);
            this.registerForm.controls.amendment_reason.updateValueAndValidity();
            this.adminSubmit();
          } else {
            this.approveModalState = true;
          }
        },
      });
    } else if (this.registerForm.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Please fill all details!',
      });
    }
    // if (
    //   this.logoState == false ||
    //   this.profile1State == false ||
    //   this.profile2State == false ||
    //   this.eclosureState == false
    // ) {
    //   this.messageService.add({
    //     severity: 'error',
    //     detail: 'Image Size Should Not Larger Then 2MB!',
    //   });
    // }
    this.submitted = true;
  }
  // send documents into binary
  async processImage(event: any, string: any) {
    this.selectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    if (string == 'logo') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.logo = event.target.result;
        };
        this.regularMemberFormFiles.logo = this.selectedFile;
        this.logoState = true;
        this.logoPreviewState = true;
        // console.log(this.selectedFile);
      } else if (this.selectedFile.size > 2300000) {
        this.logoState = false;
      }
    } else if (string == 'profile1') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.profile1 = event.target.result;
        };
        this.regularMemberFormFiles.profile1 = this.selectedFile;
        this.profile1State = true;
        this.nominee1PreviewState = true;
      } else if (this.selectedFile.size > 2300000) {
        this.profile1State = false;
      }
    } else if (string == 'profile2') {
      if (this.selectedFile.size <= 2300000) {
        reader.onload = (event: any) => {
          this.profile2 = event.target.result;
        };
        this.regularMemberFormFiles.profile2 = this.selectedFile;
        this.profile2State = true;
        this.nominee2PreviewState = true;
      } else if (this.selectedFile.size > 2300000) {
        this.profile2State = false;
      }
    } else if (string == 'enclosures') {
      let status = this._utility.fileTypeCheck('pdf', event);
      if (status) {
        this.enclosureDoc.push(event.target.files[0]);
        // reader.onload = (event: any) => {

        // };
        this.regularMemberFormFiles.enclosureDoc = this.selectedFile;
        this.eclosureState = true;
        this.showInputFile = true;

        this.eclosureState = false;
      }
    }
  }

  onDocUppload(event: any, index: number) {
    let selectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.documents.push(selectedFile);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  bloackAlphabet(event: any) {
    if (event.key == 'Tab') {
      return;
    }
    const keyCode = event.keyCode;
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }
  // payment process razorpay
  payment(orderId?: string, amount?: number, id?: any): void {
    this.approveModalState = false;
    let options = {
      key: environment.rezorpay_key,
      currency: 'INR',
      base_amount: this.amount,
      name: 'UCCI',
      // description: 'Web Development',
      image: environment.rezorpay_logo,
      order_id: this.orderId,
      handler: function (response: any) {
        var event = new CustomEvent('payment.success', {
          detail: response,
          bubbles: true,
          cancelable: true,
        });
        window.dispatchEvent(event);
      },
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#0ec6c6',
      },
    };

    var rzp1 = new Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      let statusDetail = {
        status: 'Payment Failed',
        payment_id: response.error.metadata.payment_id,
        razorpay_signature: '',
        description: response.error.description,
        payment_for: 'Member Edit',
        payment_for_id: this.regularMemberList?.id,
      };
      this.paymentservice.servicePayment(this.orderId, statusDetail);
    });
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      payment_for: 'Member Edit',
      payment_for_id: this.regularMemberList?.id,
    };
    this.paymentservice
      .servicePayment(this.orderId, statusDetail)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.apiservice
            .updateMemberInfo(this.formData)
            .subscribe((response: any) => {
              console.log(response);
              this.messageService.add({
                severity: 'success',
                detail: res.message,
              });
            });
          // setTimeout(() => {
          this.successMessage = {
            form: 'regular-member-edit',
            message: 'Form Submitted Successfully',
          };
          this.successState = true;
          // }, 2000);
        }
      });
  }
  // got to top when form submitted
  goToTop() {
    this.scroller.scrollToAnchor('backToTop');
  }

  // get area of interest list api function
  getAreaofInterest() {
    this.interestMasterService.getInterestArea().subscribe((res: any) => {
      this.interest = res.data;
    });
  }

  // get industry area list
  getIndusrtArea() {
    this.industryareamaster.getIndustryAreaList().subscribe((res: any) => {
      // console.log(res);
      this.industryArea = res.Data;
    });
  }

  // get nature of business list
  getNatureofBusiness() {
    this.nobmaster.getNatureofBusinessList().subscribe((res: any) => {
      this.natureofBusinessList = res.data;
    });
  }

  // area of interest change function
  getSelectedValue(value: string) {
    if (value == 'interest1') {
      this.registerForm.controls['areaofinterest1'].clear();
      for (let i = 0; i < this.selectedInterest1.length; i++) {
        this.registerForm.controls['areaofinterest1'].push(
          new FormControl(this.selectedInterest1[i].id)
        );
      }
      // this.selectedInterest1.forEach((res: any) => {

      // });
    } else if (value == 'interest2') {
      this.registerForm.controls['areaofinterest2'].clear();
      this.selectedInterest2.forEach((data: any) => {
        this.registerForm.controls['areaofinterest2'].push(
          new FormControl(data.id)
        );
      });
    }
  }
  // same as above functionality
  getValues(event: any) {
    // console.log(event);
    this.isChecked = false;
    if (event) {
      this.registerForm.patchValue({
        mainaddress: this.registerForm.value.address,
        mainstate: this.registerForm.value.state,
        maincity: this.registerForm.value.city,
        mainzipcode: this.registerForm.value.zipcode,
        mainemail: this.registerForm.value.email,
        mainwebsite: this.registerForm.value.website,
      });
    } else {
      this.registerForm.patchValue({
        mainaddress: '',
        mainstate: '',
        maincity: '',
        mainzipcode: '',
        mainemail: '',
        mainwebsite: '',
      });
    }
  }
  constitutionId: number;
  registerById: number;
  registerId: number;
  // getConstitutionId(value: string, item: any) {
  //   // // console.log(item);
  //   if (value == 'other1') {
  //     this.constitutionId = this.registerForm.value.applicantconstitution.id;

  //     if (this.constitutionId == 4) {
  //       this.inputShow1 = true;
  //       this.registerForm.controls['others1'].setValidators([
  //         Validators.required,
  //       ]);
  //       this.registerForm.controls['others1'].updateValueAndValidity();
  //     } else if (this.constitutionId != 4) {
  //       this.inputShow1 = false;
  //       this.registerForm.controls['others1'].clearValidators();
  //       this.registerForm.controls['others1'].updateValueAndValidity();
  //     }
  //   } else if (value == 'other2') {
  //     this.registerById = this.registerForm.value.registerby.id;
  //     if (this.registerForm.value.registerby.id == 4) {
  //       this.inputShow2 = true;
  //       this.registerForm.controls['others2'].setValidators([
  //         Validators.required,
  //       ]);
  //       this.registerForm.controls['others2'].updateValueAndValidity();
  //     } else if (this.registerForm.value.registerby.id != 4) {
  //       this.inputShow2 = false;
  //       this.registerForm.controls['others2'].clearValidators();
  //       this.registerForm.controls['others2'].updateValueAndValidity();
  //     }
  //   } else if (value == 'other3') {
  //     // console.log(item.value.length);

  //     this.registerId = item?.itemValue?.id;

  //     if (item.value.length == 3) {
  //       this.registerForm.controls.registered.clear();
  //       // this.selectedRegistered.forEach((res: any) => {
  //       this.registerForm.controls['registered'].push(
  //         new FormControl(this.selectedRegistered[0].name)
  //       );
  //       this.inputShow3 = true;
  //       this.registerForm.controls['others3'].setValidators([
  //         Validators.required,
  //       ]);
  //       this.registerForm.controls['others3'].updateValueAndValidity();
  //     } else if (item.itemValue?.id == 3) {
  //       this.selectedRegistered = this.selectedRegistered.filter((res: any) => {
  //         return res.id == 3;
  //       });
  //       this.registerForm.controls.registered.clear();
  //       this.registerForm.controls['registered'].push(
  //         new FormControl(this.selectedRegistered[0].name)
  //       );
  //       this.inputShow3 = true;
  //       this.registerForm.controls['others3'].setValidators([
  //         Validators.required,
  //       ]);
  //       this.registerForm.controls['others3'].updateValueAndValidity();
  //     } else if (item.itemValue?.id != 3) {
  //       this.inputShow3 = false;
  //       this.selectedRegistered = this.selectedRegistered.filter((res: any) => {
  //         return res.id != 3;
  //       });
  //       this.registerForm.controls.registered.clear();
  //       this.selectedRegistered.forEach((res: any) => {
  //         this.registerForm.controls['registered'].push(
  //           new FormControl(res.name)
  //         );
  //       });

  //       this.registerForm.controls['others3'].clearValidators();
  //       this.registerForm.controls['others3'].updateValueAndValidity();
  //     }
  //   }
  // }
  getConstitutionId(value: string, item: any) {
    // this.showDocFile = true;
    // console.log(item);
    if (value == 'other1') {
      this.constitutionId = this.registerForm.value.applicantconstitution.id;
      console.log(this.constitutionId);

      if (this.constitutionId == 4) {
        this.inputShow1 = true;
        this.registerForm.controls['others1'].setValidators([
          Validators.required,
        ]);
        this.registerForm.controls['others1'].updateValueAndValidity();
      } else if (this.constitutionId != 4) {
        this.inputShow1 = false;
        this.registerForm.controls['others1'].clearValidators();
        this.registerForm.controls['others1'].updateValueAndValidity();
        console.log(this.registerForm.value.applicantconstitution);
      }
    } else if (value == 'other2') {
      console.log(
        this.registerForm.value.registerby,
        this.registerForm.value.registerby.id
      );

      this.registerById = this.registerForm.value.registerby.id;
      if (this.registerForm.value.registerby.id == 4) {
        this.inputShow2 = true;
        this.registerForm.controls['others2'].setValidators([
          Validators.required,
        ]);
        this.registerForm.controls['others2'].updateValueAndValidity();
      } else if (this.registerForm.value.registerby.id != 4) {
        this.inputShow2 = false;
        this.registerForm.controls['others2'].clearValidators();
        this.registerForm.controls['others2'].updateValueAndValidity();
      }
    } else if (value == 'other3') {
      this.registerId = item?.itemValue?.id;
      if (item?.itemValue?.id == 3) {
        this.selectedRegistered = this.selectedRegistered.filter((res: any) => {
          return res.id == 3;
        });
        this.registerForm.controls.registered.clear();
        // this.selectedRegistered.forEach((res: any) => {
        this.registerForm.controls['registered'].push(
          new FormControl(this.selectedRegistered[0]?.name)
        );
        // });
        console.log(item.itemValue.id, this.registerForm.value.registered);
        this.inputShow3 = true;
        this.registerForm.controls['others3'].setValidators([
          Validators.required,
        ]);
        this.registerForm.controls['others3'].updateValueAndValidity();
      } else if (item?.itemValue?.id != 3) {
        this.inputShow3 = false;
        this.selectedRegistered = this.selectedRegistered.filter((res: any) => {
          return res.id != 3;
        });
        console.log(this.selectedRegistered);
        this.registerForm.controls.registered.clear();
        this.selectedRegistered.forEach((res: any) => {
          // debugger;
          this.registerForm.controls['registered'].push(
            new FormControl(res.name)
          );
        });
        console.log(this.registerForm.value.registered);

        this.registerForm.controls['others3'].clearValidators();
        this.registerForm.controls['others3'].updateValueAndValidity();
      }
    }
    // console.log(this.inputShow1);
  }
  restrictSpecialChar(event: KeyboardEvent): void {
    this.utility.restrictSpecialChar(event);
  }

  downLoadPdf(url: string) {
    // console.log('====================================');
    // console.log(url);
    // console.log('====================================');
    // if (url.endsWith('.pdf')) {
    let value = url;
    // console.log(data);
    var anchor = document.createElement('a');
    anchor.download = value;
    anchor.href = value;
    anchor.target = '_blank';
    anchor.click();
    // }
  }
  adminSubmit() {
    this.formData = new FormData();
    // format date code
    this.dob1filterDate = moment(this.registerForm.value.dob1).format(
      'YYYY/MM/DD'
    );
    this.registerForm.value.dob1 = this.dob1filterDate;
    this.dob2filterDate = moment(this.registerForm.value.dob2).format(
      'YYYY/MM/DD'
    );
    this.registerForm.value.dob2 = this.dob2filterDate;

    this.establishfilterDate = moment(
      this.registerForm.value.establishdate
    ).format('YYYY/MM/DD');
    // state dropdown
    let selectedState: any = [];
    selectedState.push(this.registerForm.value.state);
    let selectedState1: any = [];
    selectedState1.push(this.registerForm.value.state1);
    let selectedState2: any = [];
    selectedState2.push(this.registerForm.value.state2);
    let selectedState3: any = [];
    selectedState3.push(this.registerForm.value.mainstate);
    let data = this.registerForm.value;
    // // console.log(data);
    // let oldCompanyData = JSON.stringify(this.oldData.company);
    // let newCompanyData = JSON.stringify(this.newData.company);
    // let oldnominee1Data = JSON.stringify(this.oldData.nominee1);
    // let newnominee1Data = JSON.stringify(this.newData.nominee1);
    // let oldnominee2Data = JSON.stringify(this.oldData.nominee2);
    // let newnominee2Data = JSON.stringify(this.newData.nominee2);
    // let changedItemes: any = [];

    // console.log(
    //   oldCompanyData == newCompanyData,
    //   oldnominee1Data == newnominee1Data,
    //   oldnominee2Data == newnominee2Data
    // );

    // if (oldCompanyData !== newCompanyData) {
    //   console.log('Company');

    //   changedItemes.push('company');
    // }
    // if (oldnominee1Data !== newnominee1Data) {
    //   console.log('Nominee 1');
    //   changedItemes.push('nominee1');
    // }
    // if (oldnominee2Data !== newnominee2Data) {
    //   console.log(this.oldData.nominee2, this.newData.nominee2);
    //   changedItemes.push('nominee2');
    // }
    // this.formData.append('otp', this.otp);
    this.formData.append('membership_type', 'Regular');
    // this.formData.append('changed_in', changedItemes);
    this.formData.append('company_name', data.companyname ?? null);
    this.formData.append('gst_number', data.gstno ?? null);
    this.formData.append('name3', data.name3 ?? null);
    this.formData.append('designation3', data.designation3 ?? null);
    this.formData.append('mobile_no3', data.mobile_no3 ?? null);
    this.formData.append('email3', data.email3 ?? null);

    this.formData.append('cin_number', data.cin ?? null);
    this.formData.append('avg_fulltime_emp', data.avgemp ?? null);
    this.formData.append('office_telephone', data.telephone ?? null);
    this.formData.append('residential_telephone', data.residentaltel ?? null);
    this.formData.append('address', data.address ?? null);
    this.formData.append('city', data.city ?? null);
    this.formData.append('state', selectedState[0]?.name ?? null);
    this.formData.append('pin_code', data.zipcode ?? null);
    this.formData.append('main_address', data.mainaddress ?? null);
    this.formData.append('main_city', data.maincity ?? null);
    this.formData.append('main_state', selectedState3[0]?.name ?? null);
    this.formData.append('main_pin_code', data.mainzipcode ?? null);
    this.formData.append('registration_number', data.registrationnumber ?? null);
    this.formData.append('capital_investment', data.capitalinvestment ?? null);
    this.formData.append('website', data.website ?? null);
    this.formData.append('email', data.email ?? null);
    this.formData.append('main_website', data.mainwebsite ?? null);
    this.formData.append('main_email', data.mainemail ?? null);
    this.formData.append('nature_of_business', data.businessnature ?? null);
    this.formData.append('about', data.about ?? null);
    this.formData.append('other_information', data.info ?? null);
    this.formData.append('other_profession', data.oterprofession ?? '' ?? null);
    this.formData.append('other_profession1', data.oterprofession1 ?? '' ?? null);
    this.formData.append('other_profession2', data.oterprofession2 ?? '' ?? null);

    if (!this.logoPreviewState) {
      this.formData.append('logo', this.regularMemberFormFiles.logo ?? null);
    }
    // this.formData.append(
    //   'enclosures',
    //   this.regularMemberFormFiles.enclosureDoc
    // );

    this.formData.append(
      'member_classification_id',
      data.memberclassficationid ?? null
    );
    this.formData.append('member_category_id', data.membercategoryid);
    if (this.constitutionId != 4) {
      this.formData.append(
        'applicant_constitution',
        data.applicantconstitution.name ?? null
      );
    } else if (this.constitutionId == 4) {
      this.formData.append('applicant_constitution', data.others1 ?? null);
    }
    if (this.registerById != 4) {
      this.formData.append('registered_by', data.registerby.name ?? null);
    } else if (this.registerById == 4) {
      this.formData.append('registered_by', data.others2 ?? null);
    }
    this.formData.append('turnover_id', data.turnoverid ?? null);
    this.formData.append('name1', data.name1 ?? null);
    this.formData.append('designation1', data.designation1 ?? null);
    this.formData.append('nominee1_pan_number', data.pan1 ?? null);
    this.formData.append('nominee1_address', data.address1 ?? null);
    this.formData.append('nominee1_city', data.city1 ?? null);
    this.formData.append('nominee1_state', selectedState1[0]?.name ?? null);
    this.formData.append('nominee1_pin_code', data.zipcode1 ?? null);
    this.formData.append('nominee1_office_phone', data.officephone1 ?? null);
    this.formData.append('mobile_no1', data.mobileno1 ?? null);
    this.formData.append('email1', data.email1 ?? null);
    this.formData.append('nominee1_department', data.department1 ?? null);
    this.formData.append('ia1', data.ia1 ?? null);
    
    if (this.establishfilterDate != 'Invalid date') {
      this.formData.append('establishment_date', this.establishfilterDate ?? null);
    } else if (this.establishfilterDate == 'Invalid date') {
      this.formData.append('establishment_date', '');
    }
    if (this.dob1filterDate != 'Invalid date') {
      this.formData.append('date_of_birth1', this.dob1filterDate ?? null);
    } else if (this.dob1filterDate == 'Invalid date') {
      this.formData.append('date_of_birth1', '');
    }
    if (this.dob2filterDate != 'Invalid date') {
      this.formData.append('date_of_birth2', this.dob2filterDate ?? null);
    } else if (this.dob2filterDate == 'Invalid date') {
      this.formData.append('date_of_birth2', '');
    }
    // append applicant registered
    let registered: any = [];
    if (this.registerId != 3) {
      if (this.selectedRegistered.length > 0) {
        for (var i = 0; i < this.selectedRegistered.length; i++) {
          console.log(this.selectedRegistered[i]);

          registered.push(this.selectedRegistered[i]?.name);
        }

        this.formData.append(
          'applicant_registered',
          JSON.stringify(registered)
        );
      }
    } else if (this.registerId == 3) {
      this.formData.append('applicant_registered', data.others3 ?? null);
    }
    let aoi1: any = [];

    if (this.selectedInterest1.length > 0) {
      for (var i = 0; i < this.selectedInterest1.length; i++) {
        aoi1.push(this.selectedInterest1[i].id);
      }

      this.formData.append('area_of_interest1', aoi1);
    } else if (this.selectedInterest1.length == 0) {
      var array: any = [];
      this.formData.append('area_of_interest1', array);
    }
    // append interest area 2
    let aoi2: any = [];
    if (this.selectedInterest2.length > 0) {
      for (var i = 0; i < this.selectedInterest2.length; i++) {
        aoi2.push(this.selectedInterest2[i].id);
      }
      console.log(aoi2);
      this.formData.append('area_of_interest2', aoi2 ?? null);
    } else if (this.selectedInterest2.length == 0) {
      var array: any = [];
      this.formData.append('area_of_interest2', array ?? null);
    }
    if (!this.nominee1PreviewState) {
      this.formData.append('profile1', this.regularMemberFormFiles.profile1 ?? null);
    }
    this.formData.append('biodata1', data?.biodata1 ?? null);
    this.formData.append('name2', data?.name2 ?? null);
    this.formData.append('pan_number2', data?.pan2 ?? null);
    this.formData.append('designation2', data?.designation2 ?? null);
    this.formData.append('nominee2_address', data?.address2 ?? null);
    this.formData.append('nominee2_city', data?.city2 ?? null);
    this.formData.append('nominee2_state', selectedState2[0]?.name ?? null);
    this.formData.append('nominee2_pin_code', data?.zipcode2 ?? null);
    this.formData.append('office_phone2', data?.officephone2 ?? null);
    this.formData.append('mobile_no2', data?.mobileno2 ?? null);
    this.formData.append('email2', data?.email2 ?? null);
    this.formData.append('department2', data?.department2 ?? null);
    this.formData.append('ia2', data?.ia2 ?? null);
    
    this.formData.append(
      'nominee1_other_profession',
      data.oterprofession1 ?? ''
    );
    this.formData.append(
      'nominee2_other_profession',
      data.oterprofession2 ?? ''
    );
    let memberSince = moment(data?.member_since).format('YYYY-MM-DD');
    this.formData.append('member_since', memberSince ?? null);
    // this.formData.append('member_since', data?.member_since)
    this.registerForm.controls['supporting_doc'].value.map(
      (res: any, index: any) => {
        this.formData.append(
          `supporting_doc[document][${index}]`,
          this.documents[index] == undefined || this.documents[index] == null
            ? null
            : this.documents[index]
        );
        this.formData.append(
          `supporting_doc[document_for][${index}]`,
          res.document_for ?? null
        );
        console.log(res);
      }
    );
    this.preferred1 = [];
    console.log(this.pre1Array);

    for (var i = 0; i < this.pre1Array?.value?.length; i++) {
      this.preferred1.push(this.pre1Array?.value[i]);
    }

    // this.formData.append('preferred_communication1[]', this.preferred1);
    debugger;
    this.preferred1.forEach((item) =>
      this.formData.append('preferred_communication1[]', item ?? null)
    );
    this.preferred2 = [];
    for (var i = 0; i < this.pre2Array?.value?.length; i++) {
      this.preferred2.push(this.pre2Array?.value[i]);
    }

    // this.formData.append('preferred_communication2[]', this.preferred2);
    this.preferred2.forEach((item) =>
      this.formData.append('preferred_communication2[]', item ?? null)
    );
    if (!this.nominee1PreviewState) {
      this.formData.append('profile2', this.regularMemberFormFiles?.profile2 ?? null);
    }
    this.preferred3 = [];
    for (var i = 0; i < this.pre3Array?.value?.length; i++) {
      this.preferred3.push(this.pre3Array?.value[i]);
    }
    if (this.preferred3) {
      this.preferred3.forEach((item) =>
        this.formData.append('preferred_communication3[]', item ?? null)
      );
    } else {
      this.formData.append('preferred_communication3', null);
    }
    // if (!this.showInputFile) {
    //   this.formData.append('enclosures', this.regularMemberFormFiles.enclosureDoc);
    // }

    // this.formData.append('preferred_communication3[]', this.preferred3);
    this.formData.append('biodata2', data.biodata2 ?? null);
    this.formData.append('member_id', this.regularMemberData.id ?? null);
    // this.oldEnclosures.map((res: any, index: any) => {
    //   this.formData.append(
    //     `enclosure[enclosures_title][]`,
    //     res.enclosures_title
    //   );
    //   this.formData.append(`enclosure[document][]`, res.document);
    // });
    // const nameControl = this.getEnclosureDocumentArray().value;
    // if(nameControl.length > 0){
    //   if (Array.isArray(nameControl)) {
    //     nameControl.forEach((res: any, index: any) => {
    //       this.formData.append(`enclosure[enclosures_title][]`, res.name);
    //       // this.formData.append(`enclosure[document][]`, this.enclosureDoc);
    //     });
    //     this.enclosureDoc.forEach((res: any, index: any) => {
    //       this.formData.append(`enclosure[document][]`, res);
    //     });
    //   }
    // }
    // else{
    //   this.formData.append(`enclosure[enclosures_title][]`, null);
    //   this.formData.append(`enclosure[document][]`, null);
    // }

    this.apiservice
      .updateMemberInfoByAdmin(this.formData, this.regularMemberData.id)
      .subscribe((res: any) => {
        // console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });

        // setTimeout(() => {
        this.successMessage = {
          form: 'regular-member-edit',
          message: 'Form Submitted Successfully',
        };
        this.successState = true;
        // }, 2000);
      });
    // var orderIdObj: any = {
    //   changed_in: changedItemes,
    //   membership_type: 'Regular',
    //   member_id: this.regularMemberData.id,
    //   payment_id: this.paymentId ?? null,

    // };
    // this.spinner.show();
    // console.log(this.userId);

    // this.apiservice.getOrderId(orderIdObj).subscribe(
    //   (res: any) => {
    //     // console.log(res);
    //     // this.approveModalState = false;
    //     // this.paymentId = res.data.payment_id;
    //     // this.formData.append('payment_id', res?.payment_id ?? '');
    //     // this.spinner.hide();

    //     if (res?.gatewayResponse != '') {
    //       setTimeout(() => {
    //         this.orderId = res.data.order_id;
    //         this.amount = res.data.amount;
    //         this.formData.append('order_id', this.orderId);
    //         console.log(this.amount, this.orderId);
    //         if (this.role != 'Admin' && this.role != 'UCCI Staff') {
    //           this.payment();
    //         } else if (this.role == 'Admin' || this.role == 'UCCI Staff') {
    //           this.formData.append('user_id', this.userId);
    //           this.formData.append('member_id', this.regularMemberData.id);

    //         }
    //       }, 1500);
    //     } else if (res?.gatewayResponse == '') {
    //       this.formData.append('order_id', '');
    //       if (this.role == 'Admin' || this.role == 'UCCI Staff') {
    //         this.formData.append('user_id', this.userId);
    //         this.formData.append('member_id', this.regularMemberData.id);
    //       }
    //       this.apiservice
    //         .updateMemberInfo(this.formData)
    //         .subscribe((res: any) => {
    //           // console.log(res);
    //           this.messageService.add({
    //             severity: 'success',
    //             detail: res.message,
    //           });

    //           // setTimeout(() => {
    //           this.successMessage = {
    //             form: 'regular-member-edit',
    //             message: 'Form Submitted Successfully',
    //           };
    //           this.successState = true;
    //           // }, 2000);
    //         });
    //     }
    //   },
    //   (error: any) => {
    //     // console.warn(error);
    //     if (error == 'The amount must be atleast INR 1.00') {
    //       this.messageService.add({
    //         severity: 'success',
    //         detail: 'Data updated Successfully!',
    //       });
    //       this.approveModalState = false;
    //     }
    //     this.spinner.hide();
    //   }
    // );
  }
  getEnclosureDocumentArray() {
    return this.registerForm.get('eclosuredoc') as FormArray;
  }
  onEncloAddRow(event?: any) {
    let mediaDoc = this.getEnclosureDocumentArray();
    this.docEncloErrorState = false;
    if (event.value.indexOf(event.itemValue) > -1) {
      mediaDoc.push(
        this.fb.group({
          name: new FormControl(event.itemValue.name, [Validators.required]),
          document: new FormControl(event.itemValue.document, [
            Validators.required,
          ]),
          docError: new FormControl(false),
        })
      );
    } else if (event.value.indexOf(event.itemValue) == -1) {
      mediaDoc.value.forEach((res: any, index: any) => {
        if (res?.name == event?.itemValue?.name) {
          mediaDoc.removeAt(index);
        }
      });
    }
  }
  onRemove(index: any) {
    this.dropdownState = false;
    this.getEnclosureDocumentArray().removeAt(index);
    this.SelectedEnclosure.splice(index, 1);
    setTimeout(() => {
      this.dropdownState = true;
    }, 1);
  }
  removeEnclosure(index: number) {
    this.oldEnclosures.splice(index, 1);
  }

  // getMemberEncloClassification(event?: any) {
  //   if (event != null) {
  //     this.apiservice.getMemberClassfication(event).subscribe((res: any) => {
  //       this.classficationData = res.data;
  //     });
  //     if (event == 4) {
  //       this.enclosure = this.forProfessionals;
  //     } else if (event == 6) {
  //       this.enclosure = this.forTradeAssociations;
  //     } else {
  //       this.enclosure = this.forCompanies;
  //     }
  //   } else {
  //     this.classficationData = [];
  //   }
  // }
}
