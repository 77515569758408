import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-user-permission-action-page',
    templateUrl: './user-permission-action-page.component.html',
    styleUrls: ['./user-permission-action-page.component.scss'],
    providers: [MessageService],
})

export class UserPermissionActionPage implements OnInit {
    @ViewChild('dt1') table: Table;
    breadcrumb: any[] = [
        {
            title: 'Permissions',
            subTitle: 'Masters',
        },
    ];
    slug: any;
    checkAll: any = false;

    allPermissions: any = [];

    groupByAllPermissions: any = [];

    selectedValue: any = []

    filterVal: any;
    userPermissions: any = [];
    constructor(private ApiService: ApiService, private router: ActivatedRoute, private route: Router, private MessageService: MessageService,   public commonFunction: CommonClass,) { }

    async ngOnInit(): Promise<void> {
        this.slug = this.router.snapshot;
        await this.ApiService.getAllPermissions().then((res: any) => {
            this.allPermissions = res?.data;
            let resultObject = this.groupBy(res?.data, 'title');

            for (const property in resultObject) {
                this.groupByAllPermissions.push(resultObject[property])
            }
        })
        await this.ApiService.getUserPermission(this.slug?.params?.slug, this.slug?.params?.slug1).then((res: any) => {
            this.userPermissions = res.data;
            this.groupByAllPermissions.forEach((response: any, index: any) => {
                console.log(response);
                this.selectedValue.push([])
                response.forEach(element => {
                    let permission = res?.data?.filter(resp => resp.permission_id == element.id);
                    if (permission?.length > 0) {
                        this.selectedValue[index].push(true);
                    }
                    else {
                        this.selectedValue[index].push(false);
                    }
                });
            });
            if (res?.data?.length == this.allPermissions?.length) {
                this.checkAll = true;
            }
        }).catch((error: any) => {
            for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
                this.selectedValue.push([])
                for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
                    this.selectedValue[i].push(false);
                }
            }
        })
    }

    onCheckboxAction(event: any, i: number, index: number, data?: any) {
        if (event.checked) {
            this.selectedValue[i][index] = true;
        }
        else if (event.checked == false) {
            this.selectedValue[i][index] = false
        }
    }

    checkAllPermission() {
        if (this.checkAll == true) {
            for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
                for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
                    this.selectedValue[i][j] = true;
                }
            }
        }
        if (this.checkAll == false) {
            for (let i = 0; i < this.groupByAllPermissions?.length; i++) {
                for (let j = 0; j < this.groupByAllPermissions[i]?.length; j++) {
                    this.selectedValue[i][j] = false;
                }
            }
        }
    }

    setPermission() {
        let sendArray = [];
        console.log(this.selectedValue);
        for (let i = 0; i < this.selectedValue?.length; i++) {
            for (let j = 0; j < this.selectedValue[i]?.length; j++) {
                if (this.selectedValue[i][j] == true) {
                    sendArray.push(this.groupByAllPermissions[i][j].id);
                }
            }
        }
        let object = {
            "user_id": Number(this.slug.params.slug),
            "role_id": this.slug?.params?.slug1,
            "permission_ids": sendArray
        }
        this.ApiService.setPermission(object).then((res: any) => {
            this.MessageService.add({
                severity: 'success',
                detail: res.message,
            });
            setTimeout(() => {
                this.route.navigateByUrl('/user-panel/member-permissions');
            }, 1000);
        })
    }
    groupBy(array, key) {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };
}