import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  providers: [MessageService]
})
export class FeedbackFormComponent implements OnInit {

  slug:any;
  localStorageValue:any;
  customFromData:any = []

  constructor(
    private activatedRoute : ActivatedRoute, 
    private commonFunction : CommonClass, 
    private route : Router, 
    private _eventService: EventService, 
    private messageService : MessageService,
    private spinner: NgxSpinnerService,
    ) { }

  async ngOnInit() {
    localStorage.removeItem('redirect_url')
    this.slug = this.activatedRoute.snapshot.params;
    // console.log(this.slug);
    this.localStorageValue = this.commonFunction.getLocalStorage();
    // if(this.localStorageValue) {
      this.spinner.show();
      let form_id : any;
      await this._eventService.getEventById(this.slug.slug).then((res:any)=> {
        form_id = res.data.feedback_form_id;
      })

      await this._eventService.getFeedbackFrom(form_id).then((res:any) => {
        // console.log(res);
        this.customFromData = JSON.parse(JSON.parse(res.data.form_fields));
        // console.log(this.customFromData);
        // this.spinner.hide()
      })
    // }
    // else {
    //   this.messageService.add({
    //     severity: 'Danger',
    //     detail: 'Please login first!',
    //   });
    //   localStorage.setItem('feedback_id', this.slug.slug);
    //   this.route.navigateByUrl('/') 
    // }

  }

  //submit feedback form
  getFormResponse(event:any) {
    if(event.string == 'submit') {
      let Object = {
        event_id : this.slug.slug,
        response : JSON.stringify(event.Obj)
      } 
      this.spinner.show();
      this._eventService.storeFeedbackResponse(Object).then((res:any)=> {
        // console.log(res);
        this.spinner.hide();
        this.messageService.add({
          severity: 'success',
          detail: 'Event Feedback Submitted!',
        });
        setTimeout(() => {
          this.route.navigateByUrl('/')
        }, 1500);
      })

    }
  }

}
