<!-- <app-navbar-style-two></app-navbar-style-two> -->
<!-- <app-header></app-header> -->
<!-- Start Page Title Area -->
<!-- <div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area pt-40 pb-40">
    <div class="container Enquiry">
        <div class="d-flex" style="white-space: no-wrap;justify-content: space-between;">
            <h1 style="font-size: 22px; margin-bottom:20px;">
                Enquiry Details
            </h1>
            <!-- <input style="width: 250px; border-radius: 5px;" type="text" class="form-control" id="searchProduct" [(ngModel)]="searchString" name="searchBoxTxt"
                aria-describedby="productName" /> -->
        </div>
        <div class="d-flex align-items-center mb-3">
            <input style="width: 250px; border-radius: 5px;" type="text" class="form-control"
                placeholder="Search Keyword" [(ngModel)]="searchString">
            <p-button type="button" class="mx-2" (click)="reset()" pTooltip="Clear Filter" tooltipPosition="bottom"
                icon="bx bx-reset"></p-button>
        </div>
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col" style="max-width: 120px;">Product Name</th>
                            <th *ngIf="slug.slug == 'myinquiry'" scope="col">Company Name</th>
                            <th *ngIf="slug.slug == 'allInquiry'" scope="col">Inquirer</th>
                            <th *ngIf="slug.slug == 'allInquiry'" scope="col">Email</th>
                            <th *ngIf="slug.slug == 'allInquiry'" scope="col">Mobile</th>
                            <th scope="col">Description</th>
                            <th *ngIf="slug.slug == 'myinquiry'" scope="col">Regular Price</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="allInquiries.length > 0">
                        <ng-container *ngFor="let item of allInquiries | search:searchString">
                            <tr *ngIf="(slug?.id) ? (item.product_id == slug?.id) : true">
                                <!-- {{item.product_id   | json}}
                                {{ slug?.id | json}} -->
                                <td class="product-thumbnail">
                                    <a>
                                        <img [src]="getImageUrl(item.media ? item.media : item.product?.medias[0].media)"
                                            alt="item">
                                    </a>
                                </td>

                                <td class="product-name" style="max-width: 120px;">
                                    <a class="small-text" pTooltip="{{item?.product_name ? item?.product_name :
                                        item?.product?.product_name}}" tooltipPosition="bottom">{{item?.product_name ?
                                        item?.product_name :
                                        item?.product?.product_name}}</a>
                                </td>

                                <td *ngIf="slug.slug == 'myinquiry'" class="product-subtotal">
                                    <span
                                        class="subtotal-amount">{{item?.product?.member_products?.company_name}}</span>
                                </td>

                                <td *ngIf="slug.slug == 'allInquiry'" class="product-price">
                                    <span class="unit-amount">{{item?.name}}</span>
                                </td>

                                <td *ngIf="slug.slug == 'allInquiry'" class="product-subtotal">
                                    <span class="subtotal-amount">{{item?.email}}</span>
                                </td>

                                <td *ngIf="slug.slug == 'allInquiry'" class="product-subtotal">
                                    <span class="subtotal-amount">{{item?.mobile}}</span>
                                </td>


                                <td class="product-subtotal">
                                    <span pTooltip="{{item?.description}}" tooltipPosition="bottom"
                                        class="subtotal-amount">{{item?.description.slice(0,20)}}</span>
                                </td>


                                <td *ngIf="slug.slug == 'myinquiry'" class="product-subtotal">
                                    <span class="subtotal-amount">{{item?.product?.regular_price |
                                        indianCurrency}}</span>
                                </td>
                            </tr>

                        </ng-container>
                    </tbody>
                </table>
                <tr *ngIf="productLength == 0 && slug.slug == 'allInquiry'">
                    <td style="padding-top: 10px;">No Enquiry</td>
                </tr>
                <tr *ngIf="allInquiries == 0 && slug.slug == 'myinquiry'">
                    <td style="padding-top: 10px;">No Enquiry</td>
                </tr>
                <div *ngIf="(allInquiries | search: searchString).length === 0" class="text-center mt-2">
                    <small>No Product Found ... </small>
                </div>
            </div>
        </form>

    </div>
</section>
<!-- End Cart Area -->
<app-footer></app-footer>
<!-- <app-footer-style-two></app-footer-style-two> -->