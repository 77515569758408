<div class="main-content d-flex flex-column">
    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <ng-container *ngIf="data?.banner">
        <app-homeone-banner [image]="data?.banner"></app-homeone-banner>
    </ng-container>


    <div class="template_one" *ngIf="data.template_type=='list'">
        <ng-container *ngFor="let items of data?.page_json  index as index">
            <div class="template_one_top_section" *ngIf="items?.heading || items?.content">
                <h1 [ngClass]="items?.content ? 'aboutHeading text-center' : 'aboutHeading text-center m-0'">
                    {{items.heading}}</h1>

                <div class="aboutContent" *ngIf="items?.content" [innerHTML]="items.content"></div>
            </div>
            <ng-container *ngIf="items?.cta">
                <app-call-to-action [callToAction]="items?.cta" [CTAURL]="items?.ctaURL"></app-call-to-action>
            </ng-container>
            <ng-container *ngFor="let item of items?.grid index as index">
                <ng-container *ngIf="item?.grid_image || item?.grid_heading || item?.grid_content && getURL(item?.grid_content) == 0">
                    <div class="template_one_bottom_section" *ngIf="index % 2 == 0">
                        <div class="row m-0">
                            <div [ngClass]="item?.grid_image ? ((item?.grid_heading || item?.grid_content) ? 'col-md-6 col-12' : 'col-12') : 'col-0'"
                                style="padding: 0 15px">
                                <div class="image_div" *ngIf="item?.grid_image">
                                    <img [src]="item.grid_image" alt="" width="100%">
                                </div>
                            </div>
                            <div [ngClass]="item?.grid_image ? 'col-md-6 col-12' : 'col-12'" style="padding: 0 15px">
                                <h2 class="cardHeading" *ngIf="item?.grid_heading && index === 0"
                                    id="redirectId {{item?.grid_heading}} ">
                                    <div></div>{{item.grid_heading}}
                                </h2>
                                <p class="cardHeading" *ngIf="item?.grid_heading && index !== 0">{{item.grid_heading}}
                                </p>
                                <ng-container >
                                    <p class="cardContent" *ngIf="item?.grid_content && data?.slug != 'contact'"
                                    [innerHTML]="item?.grid_content"></p>
                                </ng-container>
                               
                                   
                                <div class="cardContent" *ngIf="item?.grid_content && data?.slug == 'contact'">
                                    <div class="table-responsive">
                                        <table class="table" style="white-space: nowrap;">
                                            <!-- <tr>
                                                <td>UCCI Helpline Number </td>
                                                <td>info@ucciudaipur.com
                                                </td>
                                                <td>6377205389
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td>Senior Executive Officer</td>
                                                <td>
                                                    uccisec@ucciudaipur.com
                                                </td>
                                                <td>
                                                    9351917008
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Executive Officer</td>
                                                <td>
                                                    uiwmrc@ucciudaipur.com
                                                </td>
                                                <td>8000156416
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Head – Industrial Help Desk</td>
                                                <td>helpdesk@ucciudaipur.com
                                                </td>
                                                <td>9351917002
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-6 col-sm-6 mt-4" *ngIf="showVideo">
                                    <iframe width="560" height="315" [src]="youtubeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div> -->


                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="template_one_bottom_section" *ngIf="index % 2 != 0 ">
                        <div class="row m-0 flex-direction">
                            <div [ngClass]="item?.grid_image ? 'col-md-6 col-12' : 'col-12'" style="padding: 0 15px">
                                <p class="cardHeading" *ngIf="item?.grid_heading">{{item.grid_heading}}</p>
                                <p class="cardContent" *ngIf="item?.grid_content" [innerHTML]="item?.grid_content"></p>     
                            </div>
                            <div [ngClass]="item?.grid_image ? ((item?.grid_heading || item?.grid_content) ? 'col-md-6 col-12' : 'col-12') : 'col-0'" style="padding: 0 15px">
                                <div class="image_div" *ngIf="item?.grid_image">
                                    <img [src]="item.grid_image" alt="" width="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="container">
          <ng-container *ngIf="showVideo"> 
            <div class="row  justify-content-center w-100">
                <div class="col-sm-6 col-lg-4 col-xxl my-3 " *ngFor="let urls of allURLS">
                    <div class="videoBox">
                        <iframe width="100%" height="246px" [src]="urls" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <button class="videoBtn" data-bs-toggle="modal" data-bs-target="#videoPopup" (click)="openVideo(urls)">Open Video</button>
                        <!-- <a href="{{urls}}">Process of Membership Renewal for HWM Member</a> -->
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="videoPopup" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close-button" data-bs-dismiss="modal" aria-label="Close"></button>
                        <iframe width="100%" height="550" [src]="popUpyoutubeurl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
             </div>
            </ng-container>
        </div>
    </div>









    <div class="template_one" *ngIf="data.template_type=='Aao Factory Dekhein'">
        <ng-container *ngFor="let items of data?.page_json  index as index">
            <div class="template_one_top_section" *ngIf="items?.heading || items?.content">
                <h1  *ngIf="data.slug =='aao-factory-dekhein'" [ngClass]="items?.content ? 'aboutHeading text-center' : 'aboutHeading text-center m-0'">
                    {{data.template_type}}</h1>
                    

                    <h1  *ngIf="data.slug !='aao-factory-dekhein'" [ngClass]="items?.content ? 'aboutHeading text-center' : 'aboutHeading text-center m-0'">
                        {{items.heading}}</h1>

                <p class="aboutContent" *ngIf="items?.content && data.slug == 'aao-factory-dekhein'" [innerHTML]="items.content"></p>


                <div class="container" *ngIf="data.slug != 'aao-factory-dekhein'">
                    <div class="row">
                        <div class="col-md-6 order-md-2" [ngStyle]="{'display': items.grid[0].grid_image === '' ? 'contents' : 'initial'}">
                            <p class="aboutContent" *ngIf="items?.content" [innerHTML]="items.content"></p>
                        </div>
                        
                        <div class="col-md-6 order-md-1">
                            <ng-container *ngFor="let item of items?.grid; let i = index">
                                <div class="image-div" *ngIf="item.grid_image">
                                    <img [src]="item.grid_image" alt="Image {{i + 1}}" width="90%">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    
                </div>
    
            </div>

            <ng-container *ngIf="items?.cta">
                <app-call-to-action [callToAction]="items?.cta" [CTAURL]="items?.ctaURL"></app-call-to-action>
            </ng-container>

            <ng-container *ngIf="data.template_type=='Aao Factory Dekhein' && data.slug == 'aao-factory-dekhein'">
                <section class="blog-area bg-f9f9f9 ptb-100">
                    <div class="container">
                        <div *ngFor="let year of sortedUniqueYears">
                            <h3 style="display: flex; align-items: center; justify-content: center;"
                                class="yearContent">
                                {{items.heading}}</h3>

                            <div class="row" *ngIf="factoryData?.length>0">
                                <div class="col-lg-4 col-md-6"
                                    *ngFor="let items of getDataForYear(year) | paginate: { itemsPerPage: 3, currentPage: AFDpage }">

                                    <div class="single-blog-post">
                                        <div class="post-image">
                                            <a class="d-block" style="cursor: pointer;" (click)="navigate(items.slug)">
                                                <img [src]="items.page_json[0]?.grid[0].grid_image" alt="">
                                            </a>
                                        </div>
                                        <div class="post-content">
                                            
                                            <h3 style="white-space:  nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                <a (click)="navigate(items.slug)"
                                                    style="cursor: pointer;">{{items.name}}</a>
                                            </h3>

                                            <a class="link-btn" style="cursor: pointer;"
                                                (click)="navigate(items.slug)"><i class="flaticon-right-arrow"></i></a>
                                                <!-- <p class="aboutContent" [innerHTML]="items?.page_json[0]?.content | slice: 0:200"> -->
                                                <!-- </p>-->
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="pagination-area text-center">
                                    <pagination-controls (pageChange)="AFDpage = $event"></pagination-controls>
                                </div>
                            </div>
                            <div class="row m-0" *ngIf="(factoryData?.length==0)">
                                <div class="col-12">
                                    No event found
                                </div>
                            </div>
                        </div>
                    </div>


                </section>


                <!-- <section class="blog-area bg-f9f9f9 ptb-100" *ngIf= "showPastEvents">
                    <div class="container">
                        <div class="row" *ngIf="pastEventList?.length>0">
                            <div class="col-lg-4 col-md-6"
                                *ngFor="let Content of pastEventList | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                                <div class="single-blog-post">
                                    <div class="post-image">
                                        <a (click)="navigateToDetailPage(Content.id, 'home')" class="d-block"
                                            style="cursor: pointer;">
                                            <img [src]="Content.coverage_image" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-content">
                                        <ul class="post-meta d-flex align-items-center">
                                            <li><i class="flaticon-calendar"></i> {{Content.date | date : 'dd/MM/yyyy'}}</li>
                                            <li>
                                                <i class="flaticon-clock"></i> {{Content.date | date : 'hh:mm:ss a'}}
                                            </li>
                                        </ul>
                                        <h3 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a
                                                (click)="navigateToDetailPage(Content.id, 'home')"
                                                style="cursor: pointer;">{{Content.event_name}}</a></h3>
                                        <a (click)="navigateToDetailPage(Content.id, 'home')" class="link-btn"
                                            style="cursor: pointer;"><i class="flaticon-right-arrow"></i></a>
                                    </div>
                                </div>
                            </div>
            
                            <div class="col-lg-12 col-md-12">
                                <div class="pagination-area text-center">
                                    <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                                </div>
                            </div>
            
            
                           
                        </div>
                        <div class="row m-0" *ngIf="(eventList?.length==0)">
                            <div class="col-12">
                                No event found
                            </div>
                        </div>
                    </div>
                </section> -->



                
            </ng-container>
    <!-- </div> -->


    