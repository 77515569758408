<div class="main-content d-flex flex-column">
    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <ng-container *ngIf="data?.banner">
        <app-homeone-banner [image]="data?.banner"></app-homeone-banner>
    </ng-container>

    <div class="template_two">
        <ng-container *ngFor="let item of data?.page_json">
            <div class="template_two_top_section" *ngIf="item?.heading || item?.content">
                <p [ngClass]="item?.content ? 'template_two_heading' : 'template_two_heading m-0'" *ngIf="item?.heading">{{item.heading}}</p>
                <p class="template_two_content" *ngIf="item?.content" [innerHTML]="item.content"></p>
            </div>
            <div class="template_two_bottom_section">
                <div class="table-responsive">
                    <table class="table">
                        <tr style="font-weight : 600;white-space : nowrap">
                            <td>S. No.</td>
                            <td>Name</td>
                            <td *ngIf="item?.grid[0]?.designation">Designation</td>
                            <td *ngIf="item?.grid[0]?.company_name">Organisation</td>
                            <td>E - mail</td>
                            <td>Mobile</td>
                        </tr>
                        <tr style="white-space : nowrap;" *ngFor="let row of item?.grid; index as gridIndex">
                            <td>{{gridIndex+1}}</td>
                            <td>{{row?.grid_heading}}</td>
                            <td *ngIf="row?.designation">{{row?.designation}}</td>
                            <td *ngIf="row?.company_name">{{row?.company_name}}</td>
                            <td>{{row?.grid_sub_heading}}</td>
                            <td>{{row?.grid_contact}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>
</div>