<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- ********************** Main Content ******************** -->

<!-- <div class="main-content d-flex flex-column"> -->

<!-- <app-dashboard-navbar></app-dashboard-navbar> -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
        <li class="item">{{Content.title}}</li>
    </ol>
</div>

<!-- ********************** End Breadcrumb Area  ******************** -->
<!-- ********************** Add Master Form Area  ******************** -->
<div class="add-listings-box">
    <div class="theader">
        <div class="d-flex justify-content-between w-100">
            <div class="d-flex w-100">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                            'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                </span>
                <p-button type="button" class="mx-2" (click)="reset(dt2)" pTooltip="Clear Filter"
                    tooltipPosition="bottom" icon="bx bx-reset"></p-button>
            </div>
            <div>
                <p-button type="button" class="mr-2" routerLink="/user-panel/create-committee" icon="bx bx-plus"
                    label="Add" iconPos="right">
                </p-button>
            </div>
        </div>
    </div>
    <p-table #dt2 [value]="templates" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [tableStyle]="{'min-width': '50rem', 'width' : '100%'}" styleClass="p-datatable-sm p-datatable-customers"
        [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"  scrollable="true" scrollHeight="600px" [paginator]="true" currentPageReportTemplate="{totalRecords} of {{templates.length == 0 ? 0 : templates.length}}
            records" [globalFilterFields]="['name','slug']">

        <ng-template pTemplate="header">
            <tr>
                <th>S. No.</th>
                <th>Page Name</th>
                <th>Slug</th>
                <th>Actions</th>
                <th>Preview</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-template let-i="rowIndex">
            <tr>
                <td>{{i+1}}</td>
                <td>{{template.name}}</td>
                <td>{{template.slug}}</td>
                <td>
                    <div class="d-flex align-items-center">

                        <button *ngIf="localStorage.role == 'Admin' && template.is_active == 1" pButton pRipple icon="bx bx-check" pTooltip="Approve" tooltipPosition="bottom" class="p-button-rounded p-button-text
                                        p-button-success mr-2" (click)="approvePage(template)"></button>
                                        
                        <button pButton pRipple icon="bx bx-pencil" pTooltip="Edit" tooltipPosition="bottom" class="p-button-rounded p-button-text
                                    p-button-primary mr-2" (click)="editPage(template)"></button>

                        <button pButton pRipple icon="bx bx-trash" class="p-button-rounded p-button-text
                                    p-button-danger" pTooltip="Delete" tooltipPosition="bottom"
                            (click)="deletePage(template.id)"></button>
                    </div>
                </td>
                <td><button pButton pRipple icon="far fa-eye" class="p-button-rounded p-button-text
                    p-button-info" pTooltip="View" tooltipPosition="bottom"
            (click)="pagePreview(template.slug)"></button></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No Data Found.</td>
            </tr>
        </ng-template>
    </p-table>

</div>

<!-- ********************** End Master View Area  ******************** -->