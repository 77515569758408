import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grid-listings-left-sidebar',
  templateUrl: './grid-listings-left-sidebar.component.html',
  styleUrls: ['./grid-listings-left-sidebar.component.scss'],
})
export class GridListingsLeftSidebarComponent implements OnInit {
  getDataFromId: any = [];
  localStorage: any;
  pageTitleContent = [
    {
      title: 'Marketplace',
    },
  ];

  // Category Select
  singleSelect: any = [];
  getMarketPlaceData: any = [];
  searchProduct : string = '';
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  brandList: any = [];
  categoryList: any = [];
  allProducts: any = [];
  menufacturerList: any = [];
  editid: any;
  userQuestionUpdate = new Subject<string>();
  marketplace_logo_url = environment?.marketplace_media_logo
  public marketplace_media_url = environment.marketplace_media_url;
  config = {
    displayKey: 'name',
    search: true,
  };
  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    items: 1,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
  };
  approvedProducts: any = [];
  constructor(
    private marketPlaceApi: MarketplaceService,
    private apiService: ApiService,
    private activateroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private Router: Router,
    private commonFunction: CommonClass
  ) {
    this.userQuestionUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        console.log("value", value);
        this.searchProducts();
      });
   }
  ngOnInit(): void {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.getFilters();
    // this.getAllProducts();
    this.getAllMarketplaceProducts();
    this.editid = this.activateroute.snapshot.params;
    console.log(this.editid);
  }

  gridListings: number = 1;
  // get all products api
  approvedProductBackup : any = [];
  getAllMarketplaceProducts() {
    this.spinner.show();
    this.marketPlaceApi.getMarketplaceProducts().subscribe(async (res: any) => {
      this.spinner.hide();
      // console.log(res);
      this.allProducts = res.data;
      await this.allProducts.map((resp: any) => {
        if (resp.status == 'Approved')
          this.approvedProducts.push(resp);
        // console.log(resp, this.approvedProducts);
      })

      this.approvedProductBackup = Object.assign([] , this.approvedProducts);
      console.log(this.approvedProductBackup);
    });
  }
  getFilters() {
    this.menufacturerList = [];
    // this.marketPlaceApi.getBrandMasterList().subscribe((res: any) => {
    //   // console.log(res);
    //   this.brandList = res.data;
    // });
    this.marketPlaceApi.getMarketplaceCategoryList().subscribe((res: any) => {
      this.categoryList = res.data;
      for (var i = 0; i < this.categoryList.length; i++) {
        this.categoryList[i].isChecked = false;
        this.categoryList[i].isSubCategory = false;
        for (var j = 0; i < this.categoryList[j].subcategory; j++) {
          this.categoryList[i].subcategory[j].isChecked = false;
        }
      }
      console.log(this.categoryList);
    });
    if(this.localStorage){
      this.apiService.getMemberList().subscribe((res: any) => {
        this.menufacturerList = res.data.member_list;
        console.log(this.menufacturerList);
      });

      this.menufacturerList.forEach((res:any)=>{
        res.isChecked = false;
      })
    }
  }

  getImageUrl(url: any) {
    return this.marketplace_logo_url + 'logo/' + url;
  }

  categoryFilter: any = [];
  brandFilter: any = [];
  menufacturerFilter: any = [];
  filterProducts(event: any, name: any, object1?: any , index?:any , index1?:any) {
    this.categoryFilter = [];
    this.spinner.show();
    // debugger;
    console.log(this.brandFilter, event.target.value, this.brandFilter.indexOf(event.target.value), name)
    if (name == 'category') {
      if (event.target.checked) {
        //category check
        this.categoryList.forEach((res: any) => {
          if (res.id == event.target.value) {
            if (event.target.checked) {
              res.isChecked = true;
              res.subcategory.forEach((res: any) => {
                res.isChecked = true;
              })
            }
            else {
              res.isChecked = false;
              res.subcategory.forEach((res: any) => {
                res.isChecked = false;
              })
            }
          }
        })

        // console.log(this.categoryList); 
      }
      else {
        //category check
        this.categoryList.forEach((res: any) => {
          if (res.id == event.target.value) {
            res.isChecked = false;
            res.subcategory.forEach((res: any) => {
              res.isChecked = false;
            })
          }
        })
      }
    } else if (name == 'brand') {
      if (event.target.checked) {
        this.brandFilter.push(Number(event.target.value));
      } else {
        this.brandFilter.splice(this.brandFilter.indexOf(Number(event.target.value)), 1);
      }
    } else if (name == 'menufacturer') {
      if (event.target.checked) {
        this.menufacturerFilter.push(Number(event.target.value));
      } else {
        this.menufacturerFilter.splice(
          this.menufacturerFilter.indexOf(Number(event.target.value)), 1
        );
      }
    } else if (name == 'subcategory') {

      this.categoryList[index].subcategory[index1].isChecked = event.target.checked;


      let categoryChecked : boolean = false;
      this.categoryList[index].subcategory.forEach((res:any)=>{
        if(!res.isChecked){
          categoryChecked = true;
        }
      })

      if(categoryChecked){
        this.categoryList[index].isChecked = false;
      }

      else{
        this.categoryList[index].isChecked = true;
      }
    }

    // console.log(this.categoryFilter, this.brandFilter, this.menufacturerFilter);

    let object: any = {};

    // this.categoryFilter.forEach((res:any)=>{
    //     parent_ids.push(res.parent_id);
    // })
    // this.brandFilter.forEach((res:any)=>{
    //     brand_id.push(res.brand_id);
    // })
    // this.menufacturerFilter.forEach((res:any)=>{
    //     member_id.push(res.member_id);
    // })

    this.categoryList.forEach((res:any)=>{
      if(res.isChecked){
        this.categoryFilter.push(Number(res.id))
      }

      else{
        res.subcategory.forEach((resp:any)=>{
          if(resp.isChecked){
            this.categoryFilter.push(Number(resp.id))
          }
        })
      }
    })

    console.log(this.categoryFilter);

    object['parent_ids'] = this.categoryFilter;
    object['category_ids'] = this.categoryFilter;
    // object['brand_id'] = this.brandFilter;
    object['member_id'] = this.menufacturerFilter;
    // if (this.categoryFilter.length > 0) {
    //     object['parent_ids'] = this.categoryFilter;
    // }

    // if (this.brandFilter.length > 0) {
    //     object['brand_id'] = this.brandFilter;
    // }

    // if (this.menufacturerFilter.length > 0) {
    //     object['member_id'] = this.menufacturerFilter;
    // }

    this.spinner.hide();
    // console.log(object);

    this.marketPlaceApi
      .approveMarketplace(object)
      .subscribe((res: any) => {
        // console.log(res);
        if (res.code == 200) {
          this.approvedProducts = res.data;
        }
      });
  }

  categoryLength: any = { length: 5, category: 'Show All' };
  menufacturerLength: any = { length: 5, category: 'Show All' };
  brandLength: any = { length: 5, category: 'Show All' };
  showAllData(string: any) {
    if (string == 'category') {
      if (this.categoryLength['category'] == 'Show All') {
        this.categoryLength = {
          length: this.categoryList.length,
          category: 'Show Less',
        };
      } else {
        this.categoryLength = { length: 5, category: 'Show All' };
      }
    } else if (string == 'brand') {
      if (this.brandLength['category'] == 'Show All') {
        this.brandLength = {
          length: this.brandList.length,
          category: 'Show Less',
        };
      } else {
        this.brandLength = { length: 5, category: 'Show All' };
      }
    } else if (string == 'member') {
      if (this.menufacturerLength['category'] == 'Show All') {
        this.menufacturerLength = {
          length: this.menufacturerList.length,
          category: 'Show Less',
        };
      } else {
        this.menufacturerLength = { length: 5, category: 'Show All' };
      }
    }
  }
  showInquiry(value: string) {
    console.log(value);
    this.Router.navigateByUrl('/cart/' + value)
  }


  subCategory: any = [];
  subCategoryBoolean: boolean = false;
  getSubCategory(category, index: any) {
    this.subCategoryBoolean = true;
    console.log(category.id, category);
    this.categoryList[index].isSubCategory = !this.categoryList[index].isSubCategory;
    // if(this.subCategoryBoolean){
    //   this.categoryList.filter((res: any) => {
    //     if (res.id == category.id) {
    //       for (var i = 0; i < res.subcategory.length; i++) {
    //         res.subcategory[i].isChecked = category.isChecked;
    //       }
    //       this.subCategory = res.subcategory;
    //     }
    //   })
    // }

    // else{
    //   this.subCategory = [];
    // }
  }

  searchProducts(){
    if(this.searchProduct){
      this.approvedProducts = [];
      console.log(this.searchProduct , this.approvedProductBackup);
      this.approvedProductBackup.forEach((res:any)=>{
        if((res.business_name).toLowerCase().includes(this.searchProduct)){
            console.log(res);
            this.approvedProducts.push(res);
        }
      });
      console.log(this.approvedProducts);
    }

    else{
      this.approvedProducts = this.approvedProductBackup;
    }
  }

  clearFilter(){
    this.categoryList.forEach((res:any)=>{
      res.subcategory.forEach((resp:any)=>{
        resp.isChecked = false;
      })
    })
    this.menufacturerList.forEach((res:any)=>{
        res.isChecked = false;
    })

    this.searchProduct = '';

    this.approvedProducts = this.approvedProductBackup;
  }
}


