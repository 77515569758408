import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService implements OnInit {

  constructor(private http : HTTPApi) {
   }
  modalCheck = new BehaviorSubject({});
  navigateOption = new BehaviorSubject({});
  homePageData = new BehaviorSubject({});
  allPages = new BehaviorSubject([]);
  footerData = new BehaviorSubject({});
  menu = new BehaviorSubject([]);
  afdData:any;
  //get all website page for listing

  ngOnInit() {
  }

  getAllPages() {
    return this.http.get('get-all-pages').toPromise();
  }

  //get page by id
  getPageById(id:any) {
    return this.http.get('get-page/'+id).toPromise();
  }

  //create page
  createPage(object:any) {
    return this.http.post('store-page',object).toPromise();
  }

  //upload image on change
  getImageURL(object) {
    return this.http.post('upload-image',object).toPromise();
  }

  //delete page
  deletePage(id:any) {
    return this.http.get('delete-page/'+id).toPromise()
  }

  //update page
  updatePage(id:any, object:any) {
    return this.http.post('update-page/'+id, object).toPromise()
  }

  getMenus():Promise<any> {
    return this.http.get('menu').toPromise();
  }

  //store menus
  storeMenu(object:any) {
    return this.http.post('menu/add', object).toPromise()
  }

  //get menu by id to edit the menu
  getMenuById(id:any) {
    return this.http.get('menu/edit/'+id).toPromise()
  }

  //delete menu
  deleteMenu(id:any) {
    return this.http.post('menu/delete/'+id).toPromise()
  }

  //delete menu
  updateMenu(id:any, object:any) {
    return this.http.post('menu/update/'+id, object).toPromise()
  }

  //get page by slug
  getPageBySlug(slug : any) {
    return this.http.get('getPageBySlug/'+slug).toPromise();
  }

  submitContactInfo(object : any) {
    return this.http.post('contactPage',object).toPromise();
  }
  
  newsletter(object : any) {
    return this.http.post('newsLetter',object).toPromise();
  }

  pageApproval(id:any, data : any) {
    return this.http.post('activate-page/'+id, data).toPromise();
  }
  //blog create
  createBlogPage(object:any) {
    return this.http.post('blogs/add',object).toPromise();
  }
  // get blog data
  getBlogPage(){
    return this.http.get('blogs/').toPromise();
  }
  deleteBlog(id){
    return this.http.post('blogs/delete/'+id).toPromise() 
  }
  editBlog(id,object){
    return this.http.post('blogs/update/'+id, object).toPromise()
  }
  getBlogById(id){
    return this.http.get('blogs/show/' + id).toPromise();
  }
  getBlogBySlug(slug){
    return this.http.get('blogs/blog-by-slug/' + `${slug}`).toPromise();
  }
}
