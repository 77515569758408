import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-enrolled-event',
  templateUrl: './enrolled-event.component.html',
  styleUrls: ['./enrolled-event.component.scss']
})
export class EnrolledEventComponent implements OnInit {
  displayModal: boolean = false;

  customFromData: any = []
  pdfData: any[];
  cols: { field: string; header: string; }[];
  localStorage: any;
  filteredPDFData: any[];
  slug: any;

  constructor(
    private __eventservice: EventService,
    private apiService: ApiService,
    private _formBuilderService: FormBuilderService,
    public route: Router,
    private messageService: MessageService,
    private activated: ActivatedRoute,
    private paymentService: PaymentService
  ) { }

  @ViewChild('dt2') dt2: Table;

  breadcrumb: any[] = [
    {
      title: 'Enrolled Events',
      subheader: 'Dashboard',
    },
  ];

  enrolledEvent: any = [];

  filterVal: any = '';

  exportColumns = []
  payStatus: boolean = false;
  paymentResponse: { formState: boolean; merchantRequest: string; };
  message: any;

  ngOnInit() {
    this.localStorage = localStorage.getItem('access_token')
    this.getData();
    this.slug = this.activated.snapshot.params;
    console.log(this.slug);
    if (this.slug?.id && this.slug?.status && this.slug?.order_id) {
      let statusDetail = {
        status: this.slug?.status == 'Success' ? 'Payment Success' : 'Payment Failed',
        payment_for: 'Event',
      }
      this.paymentService.servicePayment(this.slug.id && this.slug.order_id, statusDetail).subscribe((res: any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        this.message = {
          form: 'event',
          message: res?.message,
        };
      })
    }
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'event_name', header: 'Event Name' },
      { field: 'payment_date', header: 'Date' },
      { field: 'amount', header: 'Amount' }
    ];
    this.exportColumns = this.cols.map((col) => ({
      header: col.header,
      dataKey: col.field,
    }));
  }

  getData() {
    this.__eventservice.getPastEvents().subscribe((res: any) => {
      console.log(res);
      this.enrolledEvent = res?.data;
      this.enrolledEvent.forEach(element => {
        element.event_name = element?.event?.event_name;
        element.status = ((element?.payment?.payment_status == 'Payment Success') ? 'Paid' : (element?.payment?.payment_status == 'Pending' ? 'Pay Now' : ''))
        // let paymentOption = JSON.parse(element?.data)?.payment_required;
        // if(paymentOption == '1') {
        //   element.status = 'Paid'
        // }
        // else if(paymentOption == '0') {
        //   element.status = 'Unpaid';
        // }
        // this.customFromData = JSON.parse(parsedData.form_response);
      });
      console.log(this.enrolledEvent)
    })
  }

  reset(dt1) {
    dt1.reset();
    this.filterVal = null;
  }

  onViewPress(index: any) {
    let parsedData = JSON.parse(this.enrolledEvent[index].data)
    console.log(JSON.parse(parsedData.form_response));
    this.customFromData = JSON.parse(parsedData.form_response);
    this.displayModal = true;
  }

  getDialogData(event: any) {
    this.displayModal = event;
  }

  onXlExport() {
    let data: any = []
    let dataForExcel: any = []
    if (this.dt2.filteredValue != null) {
      data = this.dt2.filteredValue;
    }
    else {
      data = this.enrolledEvent;
    }
    data.forEach((response?: any, index?: any) => {
      let date = moment(response?.updated_at).format('DD-MM-YYYY');
      dataForExcel.push({
        'Sr No': index + 1,
        'Event Name': response?.event?.event_name,
        'Date': date,
        'Payment Status': (response?.status ?? '-'),
        'Amount': (localStorage?.role == 'Nominee 1' || localStorage?.role == 'Nominee 2') ? (response?.event?.member_fee) : (response?.event?.non_member_fee)
      })
    });
    this.apiService.exportExcel(dataForExcel, 'Enrolled-Events')
  }

  onPdfClick() {
    this.pdfData = [];
    this.filteredPDFData = [];
    if (this.dt2.filteredValue != null) {
      this.filteredPDFData = this.dt2.filteredValue;
    } else if (this.dt2.filteredValue == null) {
      this.filteredPDFData = this.enrolledEvent;
    }
    for (let i = 0; i < this.filteredPDFData?.length; i++) {
      let date = moment(this.filteredPDFData[i]?.updated_at).format('DD-MM-YYYY');
      console.log(date);

      this.pdfData.push({
        sr_no: i + 1,
        event_name: this.filteredPDFData[i]?.event?.event_name,
        payment_date: date,
        status: (this.filteredPDFData[i]?.status ?? '-'),
        amount: (localStorage?.role == 'Nominee 1' || localStorage?.role == 'Nominee 2') ? (this.filteredPDFData[i]?.event?.member_fee) : (this.filteredPDFData[i]?.event?.non_member_fee),
      });
    }
    this.apiService.exportPdf(this.pdfData, this.exportColumns, 'Enrolled-Events');
  }

  payment(data: any): void {
    console.log(data);

    let payload = {
      payment_id: data?.payment_id,
      id: data.id,
      url: environment.frontURL + '/user-panel/enrolled-events/' + data.id,
      module: 'Event'
    }
    console.log(payload);

    this.apiService.paymentOnListing(data.payment_id, payload).subscribe((res: any) => {
      console.log(res);
      let gatewayResponse: string = res.gatewayResponse;
      this.payStatus = true;
      this.paymentResponse = {
        formState: true,
        merchantRequest: gatewayResponse,
      };
    });
    // this.paymentId = '';
    // this.errors = '';

    // let options = {
    //   key: environment.rezorpay_key,
    //   amount: amount,
    //   name: 'UCCI',
    //   // description: 'Web Development',
    //   image: environment.rezorpay_logo,
    //   order_id: orderId,
    //   handler: function (response: any) {
    //     var event = new CustomEvent('payment.success', {
    //       detail: response,
    //       bubbles: true,
    //       cancelable: true,
    //     });
    //     window.dispatchEvent(event);
    //   },
    //   prefill: {
    //     name: '',
    //     email: '',
    //     contact: '',
    //   },
    //   notes: {
    //     address: '',
    //   },
    //   theme: {
    //     color: '#3399cc',
    //   },
    // };

    // var rzp1 = new Razorpay(options);
    // rzp1.open();

    // rzp1.on('payment.failed', (response: any) => {
    //   console.log(response);
    //   console.log(response.error.code);
    //   console.log(response.error.description);
    //   console.log(response.error.source);
    //   console.log(response.error.step);
    //   console.log(response.error.reason);
    //   console.log(response.error.metadata.order_id);
    //   console.log(response.error.metadata.payment_id);
    //   let statusDetail = {
    //     status: 'Payment Success',
    //     payment_id: response.error.metadata.payment_id,
    //     razorpay_signature: '',
    //     description: response.error.description,
    //   };
    // this._formBuilderService
    //   .updatePaymentStatus(response.error.metadata.order_id, statusDetail)
    //   .subscribe((res: any) => {
    //     console.log(res);
    //     this.messageService.add({
    //       severity: 'danger',
    //       detail: 'Payment Failed!',
    //     });
    //     setTimeout(() => {
    //       this.route.navigateByUrl('/');
    //     }, 1500);
    //   });
    //   this.errors = response.error.reason;
    // });
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    console.log(event);
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
    };
    this._formBuilderService
      .updatePaymentStatus(event.detail.razorpay_order_id, statusDetail)
      .subscribe((res: any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          detail: 'Event Registration Successful!',
        });
        this.getData();
      });
  }

}
