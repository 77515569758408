import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { GroupByPipePipe } from 'src/app/pipes/group-by-pipe.pipe';

@Component({
  selector: 'app-dashboard-invoice',
  templateUrl: './dashboard-invoice.component.html',
  styleUrls: ['./dashboard-invoice.component.scss'],
})
export class DashboardInvoiceComponent implements OnInit {
  @ViewChild('calendar') private calendar: any;
  accordianIndex: any;
  breadcrumb = [
    {
      title: 'Renewal Reports',
      subTitle: 'Dashboard',
    },
  ];
  cols: any = [];
  list: any = [];
  Datalength: any;
  filterVal: string = '';
  tooltipText: string = environment.resetTooltip;
  filteredXLSData: any[];
  filteredPDFData: any[];
  pdfData: any[];
  excelData: any[];
  @ViewChild('dt1') dataTable: Table;
  exportColumns: any = [];
  renewalHistory: any = [];
  memberdata: any = [];
  hasmember: string = '';
  totalAmount = 0;
  localStorage: any;
  roleName: any;
  from: string;
  to: string;
  totalData: any = [];
  voterYear: any;
  status: boolean = false;
  ucciBaseUrl = environment.ucci_base_url;
  slug: any;
  rangeDates: Date[];
  duplicateMember:any = []
  yearJson: any = [
    {
      name: 'Select Year',
    },
    {
      name: 2023,
    },
    {
      name: 2024,
    },
    {
      name: 2025,
    },
    {
      name: 2026,
    },
    {
      name: 2027,
    },
    {
      name: 2028,
    },
    {
      name: 2029,
    },
    {
      name: 2030,
    },
    {
      name: 2031,
    },
    {
      name: 2032,
    },
    {
      name: 2033,
    },
  ];
  pdfData2: any=[];
  pdfData3: any;
  tableData2:any=[]
  tableData3:any=[]

  yearsOfRenewal: any=[];
  tableData4: any=[];
  excelData2: any;
  excelData3: any;
  excelData4: any;
  year: any;

  constructor(
    private api_service: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    // this.localStorage = JSON.parse(localStorage.getItem('UserObject'));
    // this.roleName = this.localStorage.roleName
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'companyName', header: 'Company Name' },
      { field: 'membershipNo', header: 'Membership No' },
      { field: 'amount', header: 'Amount' },
      { field: 'membershipYear', header: 'Membership Year' },
      { field: 'membershipType', header: 'Membership Type' },
      { field: 'email', header: 'Email' },
      { field: 'expiringDate', header: 'Expiring Date' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    console.log(this.route.snapshot.params.slug);
    this.CheckSlug();
    this.slug = this.route.snapshot.params.slug;
  }

  // function for checking slug and call api according slug
  async CheckSlug() {
    let membership_name = {
      membership_name: '',
    };
    switch (this.route.snapshot.params.slug) {
      case 'Hwm':
        membership_name.membership_name = 'HWM Member';
        this.breadcrumb[0].title = 'HWM Renewal Reports';
        this.spinner.show();
        await this.api_service.getMemberRenewalHistory(membership_name).then(
          (res: any) => {
            this.list = res?.data;
            
            const filterPipe = new GroupByPipePipe();
            this.renewalHistory = filterPipe.transform(
              res?.data,
              'membership_year'
            );
            this.yearsOfRenewal=[]
            this.yearsOfRenewal=this.renewalHistory
            this.spinner.hide();
          },
          (err: any) => {
            console.log(err);
          }
        );

        break;
      case 'Regular':
        membership_name.membership_name = 'Regular Member';
        this.breadcrumb[0].title = 'Regular Renewal Reports';
        this.spinner.show();
        await this.api_service.getMemberRenewalHistory(membership_name).then(
          (res: any) => {
            console.log(res);
            this.list = res?.data;
            const filterPipe = new GroupByPipePipe();
            
            this.renewalHistory = filterPipe.transform(
              res?.data,
              'membership_year'
            );
            this.yearsOfRenewal=[]
            this.yearsOfRenewal=this.renewalHistory
            console.log(this.renewalHistory);
            this.spinner.hide();
          },
          (err: any) => {
            console.log(err);
          }
        );

        break;
      case 'hwm-work-que':
        this.hasmember = 'hwm-work-que';
        console.log(this.breadcrumb)
        this.breadcrumb[0].title = 'HWM Work Que Reports';
        this.spinner.show();
        await this.api_service.getHwmWork().then(
          (res: any) => {
            // console.log(res)
            this.list = res?.data;
            const filterPipe = new GroupByPipePipe();
            this.memberdata = res?.data;
            this.spinner.hide();
          },
          (err: any) => {
            console.log(err);
          }
        );
        break;
      case 'membership-approved':
        this.hasmember = 'membership-approved';
        this.breadcrumb[0].title = 'New Membership Approved Reports'
        //  this.spinner.show();

        // let date = {
        //     from: this.from,
        // to: this.to
        // }

        // await this.api_service.getApprovedHwmMember(date).then((res: any) => {
        //     // console.log(res)
        //     this.list = res?.data
        //     const filterPipe = new GroupByPipePipe();
        //     this.memberdata = res?.data;
        //     this.spinner.hide();
        // },
        //     (err: any) => {
        //         console.log(err);
        //     })
        break;
      case 'membership-status':
        this.hasmember = 'membership-status';
        this.breadcrumb[0].title = 'Membership Status Reports'
        break;
      case 'hwm-renewal-fee':
        this.hasmember = 'hwm-renewal-fee';
        break;
      case 'voter-list':
        this.hasmember = 'voter-list';
        this.breadcrumb[0].title = 'Eligible Voter List Reports'
        break;

      case 'member-data':
        this.hasmember = 'member-data';
        this.breadcrumb[0].title = 'Member Data Reports'
        break;
    }
  }

  async GetMembership(event: any, string: any) {
    this.list = [];
      this.from = moment(this.rangeDates[0]).format('YYYY-MM-DD');
      this.to = moment(this.rangeDates[1]).format('YYYY-MM-DD');    
   
    console.log(this.to, this.from, this.rangeDates);
    if (this.to != "Invalid date") {
      this.calendar.overlayVisible = false;
      // this.customerReport["rangeDates"] = this.rangeDates;
      // this.getFormValue.emit(this.rfqList);       
    }   

   
    this.spinner.show();
    let date = {
      from: this.to,
      to: this.from,
    };
    console.log(this.rangeDates)
    if (string === 'getApprovedHwmMember'){
        await this.api_service.getApprovedHwmMember(date).then(
            (res: any) => {
              console.log(res);
              this.list = res?.data;
              const filterPipe = new GroupByPipePipe();
              this.totalData.push(res?.data);
              this.memberdata = res?.data?.member_data;
              console.log(this.memberdata);
              console.log(this.renewalHistory);
              this.spinner.hide();
              this.status = true;
            },
            (err: any) => {
              console.log(err);
            }
          );
    }else if(string === 'member-data'){
      let dateto = {
        from: this.from,
        to: this.to,
      };
        await this.api_service.getMemberData(dateto).then(
            (res: any) => {
              console.log(res);
              this.list = res?.data;
              this.status = true;
              const filterPipe = new GroupByPipePipe();
              this.totalData.push(res?.data);
              this.memberdata = res?.data;
              console.log(this.memberdata);
              console.log(this.renewalHistory);
              this.spinner.hide();
            },
            (err: any) => {
              console.log(err);
            }
          );
    }
     
  }
  changeEvent(event: any, string: any) {
    this.totalData = [];
    this.year=event.value
    let object = {
      year: event.value,
    };
    if (string === 'voter-list') {
      this.spinner.show();

      this.api_service.getvoterlist(object).then(
        (res: any) => {
          this.status = true;
          console.log(res);
          this.list = res?.data;
          const filterPipe = new GroupByPipePipe();
          this.memberdata = res?.data;
          console.log(this.memberdata);
          console.log(this.renewalHistory);
          this.spinner.hide();
           
        },
        (err: any) => {
          console.log(err);
        }
      );
    } else if (string === 'membership-status') {
      this.api_service.getMebershipStatus(object).then(
        (res: any) => {
          // console.log(res)
          // this.list = res?.data
          this.status = true;
          const filterPipe = new GroupByPipePipe();
          this.memberdata = res?.data;
          this.totalData.push(res?.data);
          this.list = this.totalData
          console.log('totalData', this.totalData);
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }
  groupByData: any = [];
  getStringName(event: any, value: any) {
    console.log(event, value);
    this.totalAmount = null;
    this.renewalHistory.map((renewal) => {
      if (renewal?.key == value) {
        this.groupByData = renewal.value;
        this.Datalength = this.groupByData.length;
        this.groupByData.map((item) => {
          this.totalAmount += Math.trunc(item.amount);
          // if (event.index == 0) {
          // }
        });
        console.log(this.groupByData, this.totalAmount);
      }
    });
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  getData(event) {
    console.log(event);
    this.totalAmount = null;
    this.renewalHistory.map((renewal) => {
      if (renewal?.key == event) {
        // this.groupByData = renewal.value;
        this.groupByData = renewal.value.filter(item => item.status === 'Approved');
        this.Datalength = this.groupByData.length;
        this.groupByData.map((item) => {
          this.totalAmount += Math.trunc(item.amount);
        });
        console.log(this.groupByData, this.totalAmount);
      }
    });
  }
  // XLS Export Function
  onXLSExport() {
    console.log(this.slug)
    this.excelData = [];
    this.filteredXLSData = [];
    if (this.slug == 'Hwm' || this.slug == 'Regular') {
      if (this.dataTable.filteredValue != null) {
        this.filteredXLSData = this.dataTable.filteredValue;
      } else if (this.dataTable.filteredValue == null) {
        this.filteredXLSData = this.list;
      }
    
      const cols = [
        { field: 'sr_no', header: 'Sr No' },
        { field: 'company_name', header: 'Company Name' },
        { field: 'membership_no', header: 'Membership No' },      
      ];
    
      this.yearsOfRenewal.forEach(item => {
        let years = item.key.match(/\d{4}/g);
        if (years && years.length === 2) {
          let startYear = years[0];
          let endYear = years[1];
          cols.push({ field: `Renewal List ${startYear}-${endYear}`, header: `${startYear}-${endYear.slice(2)}` });
        }
      });
      cols.push({ field: `total`, header: `Total` });
    
      let index = 1;
      for (let i = 0; i < this.filteredXLSData.length; i++) {
        let rowData = {};
        if ((this.filteredXLSData[i].status == "Approved" || this.filteredXLSData[i].status == "Terminated") || (this.filteredXLSData[i].status == "Provisionally Terminated")) {
          let total = 0;
          cols.forEach(col => {
            if (col.field === 'sr_no') {          
              rowData[col.field] = index++;
            } else if (col.field === this.filteredXLSData[i].membership_year) {
              let headerYears = this.extractYear(col.field);
              let fieldYears = this.extractYear(this.filteredXLSData[i].membership_year);
              if (headerYears == fieldYears) {
                if (typeof parseFloat(this.filteredXLSData[i]?.amount) === 'number' && !isNaN(this.filteredXLSData[i]?.amount)) {
                  total += parseFloat(this.filteredXLSData[i]?.amount);
                }
                rowData[col.field] = this.filteredXLSData[i]?.amount || null;
              } 
            } else if (col.field === 'company_name' || col.field === 'membership_no') {
              rowData[col.field] = this.filteredXLSData[i]?.[col.field] || null;
            } else if (col.field == 'total') {
              rowData[col.field] = total;
            } else { 
              this.duplicateMember = this.extractYear(col.field, this.filteredXLSData[i]);
              rowData[col.field] = this.duplicateMember?.amount ? this.duplicateMember?.amount : null;
              
              if (typeof parseFloat(this.duplicateMember?.amount) === 'number' && !isNaN(this.duplicateMember?.amount)) {
                total += parseFloat(this.duplicateMember?.amount);
              }
            }      
          });
          this.excelData.push(rowData);
        }
      }


      let index2 = 1;
      this.excelData2 = [];
      
      for (let i = 0; i < this.filteredXLSData.length; i++) {
          let rowData = {};
          if (this.filteredXLSData[i].status == "Approved") {
              let total = 0;
              cols.forEach(col => {
                  if (col.field === 'sr_no') {          
                      rowData[col.field] = index2++;
                  } else if (col.field === this.filteredXLSData[i].membership_year) {
                      let headerYears = this.extractYear(col.field);
                      let fieldYears = this.extractYear(this.filteredXLSData[i].membership_year);
                      if (headerYears == fieldYears) {
                          if (typeof parseFloat(this.filteredXLSData[i]?.amount) === 'number' && !isNaN(this.filteredXLSData[i]?.amount)) {
                              total += parseFloat(this.filteredXLSData[i]?.amount);
                          }
                          rowData[col.field] = this.filteredXLSData[i]?.amount || null;
                      } 
                  } else if (col.field === 'company_name' || col.field === 'membership_no') {
                      rowData[col.field] = this.filteredXLSData[i]?.[col.field] || null;
                  } else if (col.field == 'total') {
                      rowData[col.field] = total;
                  } else { 
                      this.duplicateMember = this.extractYear(col.field, this.filteredXLSData[i]);
                      rowData[col.field] = this.duplicateMember?.amount ? this.duplicateMember?.amount : null;
                      
                      if (typeof parseFloat(this.duplicateMember?.amount) === 'number' && !isNaN(this.duplicateMember?.amount)) {
                          total += parseFloat(this.duplicateMember?.amount);
                      }
                  }      
              });
              this.excelData2.push(rowData);
          }
      }



      let index3 = 1;
      this.excelData3 = [];
      
      for (let i = 0; i < this.filteredXLSData.length; i++) {
          let rowData = {};
          if (this.filteredXLSData[i].status == "Provisionally Terminated") {
              let total = 0;
              cols.forEach(col => {
                  if (col.field === 'sr_no') {          
                      rowData[col.field] = index3++;
                  } else if (col.field === this.filteredXLSData[i].membership_year) {
                      let headerYears = this.extractYear(col.field);
                      let fieldYears = this.extractYear(this.filteredXLSData[i].membership_year);
                      if (headerYears == fieldYears) {
                          if (typeof parseFloat(this.filteredXLSData[i]?.amount) === 'number' && !isNaN(this.filteredXLSData[i]?.amount)) {
                              total += parseFloat(this.filteredXLSData[i]?.amount);
                          }
                          rowData[col.field] = this.filteredXLSData[i]?.amount || null;
                      } 
                  } else if (col.field === 'company_name' || col.field === 'membership_no') {
                      rowData[col.field] = this.filteredXLSData[i]?.[col.field] || null;
                  } else if (col.field == 'total') {
                      rowData[col.field] = total;
                  } else { 
                      this.duplicateMember = this.extractYear(col.field, this.filteredXLSData[i]);
                      rowData[col.field] = this.duplicateMember?.amount ? this.duplicateMember?.amount : null;
                      
                      if (typeof parseFloat(this.duplicateMember?.amount) === 'number' && !isNaN(this.duplicateMember?.amount)) {
                          total += parseFloat(this.duplicateMember?.amount);
                      }
                  }      
              });
              this.excelData3.push(rowData);
          }
      }



      let index4 = 1;
      this.excelData4 = [];
      
      for (let i = 0; i < this.filteredXLSData.length; i++) {
          let rowData = {};
          if (this.filteredXLSData[i].status == "Terminated") {
              let total = 0;
              cols.forEach(col => {
                  if (col.field === 'sr_no') {          
                      rowData[col.field] = index4++;
                  } else if (col.field === this.filteredXLSData[i].membership_year) {
                      let headerYears = this.extractYear(col.field);
                      let fieldYears = this.extractYear(this.filteredXLSData[i].membership_year);
                      if (headerYears == fieldYears) {
                          if (typeof parseFloat(this.filteredXLSData[i]?.amount) === 'number' && !isNaN(this.filteredXLSData[i]?.amount)) {
                              total += parseFloat(this.filteredXLSData[i]?.amount);
                          }
                          rowData[col.field] = this.filteredXLSData[i]?.amount || null;
                      } 
                  } else if (col.field === 'company_name' || col.field === 'membership_no') {
                      rowData[col.field] = this.filteredXLSData[i]?.[col.field] || null;
                  } else if (col.field == 'total') {
                      rowData[col.field] = total;
                  } else { 
                      this.duplicateMember = this.extractYear(col.field, this.filteredXLSData[i]);
                      rowData[col.field] = this.duplicateMember?.amount ? this.duplicateMember?.amount : null;
                      
                      if (typeof parseFloat(this.duplicateMember?.amount) === 'number' && !isNaN(this.duplicateMember?.amount)) {
                          total += parseFloat(this.duplicateMember?.amount);
                      }
                  }      
              });
              this.excelData4.push(rowData);
          }
      }
      
      let exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
    
     
      let seenMembershipNumbers: { [key: number]: boolean } = {};

      this.excelData = this.excelData.filter(obj => {
        if (!seenMembershipNumbers[obj.membership_no]) {
            seenMembershipNumbers[obj.membership_no] = true;
            return true; // Keep the object
        }
        return false; // Discard the object
        
    });
    seenMembershipNumbers = {};
    this.excelData2 = this.excelData2.filter(obj => {
      if (!seenMembershipNumbers[obj.membership_no]) {
          seenMembershipNumbers[obj.membership_no] = true;
          return true; // Keep the object
      }
      return false; // Discard the object
    });
    seenMembershipNumbers = {};
    this.excelData3 = this.excelData3.filter(obj => {
      if (!seenMembershipNumbers[obj.membership_no]) {
          seenMembershipNumbers[obj.membership_no] = true;
          return true; // Keep the object
      }
      return false; // Discard the object
    });
    seenMembershipNumbers = {};
    this.excelData4 = this.excelData4.filter(obj => {
      if (!seenMembershipNumbers[obj.membership_no]) {
          seenMembershipNumbers[obj.membership_no] = true;
          return true; // Keep the object
      }
      return false; // Discard the object
    });

    let indexs1 = 1;
    this.excelData.forEach(obj => {
        obj.sr_no = indexs1++;
    });
    let indexs2 = 1;
    this.excelData2.forEach(obj => {
        obj.sr_no = indexs2++;
    });

    let indexs3 = 1;
    this.excelData3.forEach(obj => {
        obj.sr_no = indexs2++;
    });

    let indexs4 = 1;
    this.excelData4.forEach(obj => {
        obj.sr_no = indexs4++;
    });
      this.api_service.exportMultipleExcelForRenewal(
        this.excelData,
        this.excelData2,
        this.excelData3,
        this.excelData4,
        this.route.snapshot.params.slug == 'Hwm' ? 'HWM-Membership-Renewal-Report' : 'Regular-Membership-Renewal-Report',
      );
    }
    
  //  else if(this.slug == 'membership-approved'){
  //   const data = this.list;
  //   this.filteredXLSData = data.member_data
  // }
  else if (this.slug == 'membership-approved') {
    const tableData = [];

    const memberData = this.list.member_data.map((data, index) => ({
        'Sr No': index + 1,
        'Company Name': data.company_name || '-',
        'Membership No': data.membership_no || '-',
        'Place': data.city || '-',
        'Amount': data.amount || '-',
        'Status': data.status || '-',
        'Created at': data.created_at || '-',
        'Updated at': data.updated_at || '-'
    }));

    tableData.push({
        data: memberData,
        name: 'Membership Approved Reports'
    });
    const memberDataApproved = [
      {
        'S.No': 1,
        Particulars: 'Total Amount Received',
        Details: this.list?.total_amount || '-',
      },
      {
        'S.No': 2,
        Particulars: '20% Amount To Be Retained By UCCI',
        Details: this.list?.retained_amount || '-',
      },
      {
        'S.No': 3,
        Particulars: 'Balance Amount Payable To ReSL',
        Details: this.list?.balance_amount || '-',
      },
    ];

    tableData.push({
      data: memberDataApproved,
      name: 'Membership Total ',
    });
    this.api_service.exportMultipleExcel(
        tableData,
        this.rangeDates,
        'Membership Approved Reports'
    );
}
  else if(this.slug == 'voter-list'){
   
    // const data = this.list;
    //  this.filteredXLSData = data.member_data

    for (let i = 0; i < this.memberdata.length; i++) {
      this.excelData.push({
        'Sr No': i + 1,
        'Membership No': this.memberdata[i]?.membership_no,
        'Company Name': this.memberdata[i]?.company_name,
        'First Nominee': this.memberdata[i]?.nominee1?.name,
        'First Nominee Mobile No.': this.memberdata[i]?.nominee1?.mobile,
        'First Nominee Email': this.memberdata[i]?.nominee1?.email,
        'Second Nominee': this.memberdata[i]?.nominee2?.name,
        'Second Nominee Mobile No.': this.memberdata[i]?.nominee2?.mobile,
        'Second Nominee Email': this.memberdata[i]?.nominee2?.email,
        'Address':this.memberdata[i]?.company_address[i]?.address,
        'City':this.memberdata[i]?.company_address[i]?.city,
        'Grade': this.memberdata[i]?.member_category?.code,
        'Category': this.memberdata[i]?.member_category?.name,
        'Classification': this.memberdata[i]?.member_classification?.name,
        'Code': this.memberdata[i]?.member_classification?.code,
        'Year of joining':this.memberdata[i]?.member_since,     
        'Created at':this.memberdata[i]?.created_at,
        'Updated at':this.memberdata[i]?.updated_at
      });
    }
    this.api_service.exportExcel(
      this.excelData,
      this.route.snapshot.params.slug == 'voter-list'
      ? 'Eligible Voter List Reports'
      : 'Eligible Voter List Reports',
      this.voterYear
    );
     
   
  }
  else if (this.slug == 'membership-status') {
    const tableData = [];

    const memberStatusData = [
      {
        'S. No.': 1,
        Particulars: 'New Memberships',
        'Previous Financial Year':
          this.memberdata?.previous_fn_new_member_count || '-',
        'Current Financial Year':
          this.memberdata?.current_fn_new_member_count || '-',
      },
      {
        'S. No.': 2,
        Particulars: 'Revision in Memberships',
        'Previous Financial Year':
          this.memberdata?.previous_fn_revision_count || '-',
        'Current Financial Year':
          this.memberdata?.current_fn_revision_count || '-',
      },
    ];

    tableData.push({
      data: memberStatusData,
      name: 'Membership Status',
    });

    const financialUpdatesData = [
      {
        'S.No': 1,
        Particulars: 'New Membership Fee',
        Details: `Rs. ${
          this.totalData[0]?.current_fn_new_membership_total_amount.replace(
            '.00',
            ''
          ) || '-'
        }`,
      },
      {
        'S.No': 2,
        Particulars: 'Membership Revision Amount',
        Details: `Rs. ${
          this.totalData[0]?.current_fn_revision_total_amount.replace(
            '.00',
            ''
          ) || '-'
        }`,
      },
      {
        'S.No': 3,
        Particulars: 'Membership Renewal Amount',
        Details: `Rs. ${
          this.totalData[0]?.current_fn_renewal_amt.replace('.00', '') || '-'
        }`,
      },
      {
        'S.No': 4,
        Particulars: 'Trust Development Fee',
        Details: `Rs. ${
          this.totalData[0]?.current_fn_trust_development_total_amount.replace(
            '.00',
            ''
          ) || '-'
        }`,
      },
    ];

    tableData.push({
      data: financialUpdatesData,
      name: 'Financial Updates',
    });

    // Third Table: MEMBERSHIP RENEWAL STATUS
    const membershipRenewalStatusData = [
      {
        'S.No': 1,
        Particulars: 'Total Members',
        Details: this.totalData[0]?.total_members || '-',
      },
      {
        'S.No': 2,
        Particulars: 'Fully Paid',
        Details: this.totalData[0]?.fully_paid || '-',
      },
      {
        'S.No': 3,
        Particulars: 'Due',
        Details: this.totalData[0]?.due || '-',
      },
    ];

    tableData.push({
      data: membershipRenewalStatusData,
      name: 'Membership Renewal Status',
    });
    this.api_service.exportMultipleExcel(
      tableData,
      this.rangeDates,
      'Membership Status Report'
    );
  } 

  else if(this.slug == 'member-data'){
   
    // const data = this.list;
    //  this.filteredXLSData = data.member_data

    for (let i = 0; i < this.memberdata.length; i++) {
      let rowData = {
          membership_no: this.memberdata[i]?.member?.membership_no ?? '-',
          name: this.parsedData(this.memberdata[i]?.member_data_json, 'member_data') ?? '-',
          member_since: this.memberdata[i]?.member?.member_since ?? '-',
          email: `${this.parsedData(this.memberdata[i]?.member_data_json, 'email1') ?? '-'}, ${this.parsedData(this.memberdata[i]?.member_data_json, 'email2') ?? '-'}`,
          contact: `${this.parsedData(this.memberdata[i]?.member_data_json, 'contact1') ?? '-'}, ${this.parsedData(this.memberdata[i]?.member_data_json, 'contact2') ?? '-'}`,
          factory_address: this.memberdata[i]?.member?.hwm_details[0]?.factory_address?.address ?? '-',
          registered_address: this.memberdata[i]?.member?.company_address[1]?.address ?? '-',
          waste_disposal_details: this.parsedData(this.memberdata[i]?.member?.hwm_details[0]?.hw_details, 'hwm_details') ?? '-',
          year: this.memberdata[i]?.created_at ? (new Date(this.memberdata[i]?.created_at)).getFullYear() : '-',
          amendment_done: this.parsedData(this.memberdata[i]?.changed_in, 'amendment') ?? '-',
          documents_uploaded: this.memberdata[i]?.member?.supporting_docs?.document ?? '-',
          existing_membership_letters: 'N_A',
          payment_invoices: 'N_A'
      };
      this.excelData.push(rowData);
  }
    this.api_service.exportExcel(
      this.excelData,
      'Member Data',
      this.rangeDates
    );
     
   
  }


   else{
    this.filteredXLSData = this.list;
  }
  // this.api_service.exportExcel(
  //   this.filteredXLSData,
  //  'reports',
  //  this.rangeDates
  // );
   // this.route.snapshot.params.slug == 'Hwm'
      //   ? 'HWM-Membership-Renewal-Report'
      //   : 'Regular-Membership-Renewal-Report'
}

  // PDF Export Function
  // onPDFExport() {
  //   this.filteredPDFData = [];
  //   this.pdfData = [];
  //   if (this.dataTable.filteredValue != null) {
  //     this.filteredPDFData = this.dataTable.filteredValue;
  //   } else if (this.dataTable.filteredValue == null) {
  //     this.filteredPDFData = this.list;
  //   }
  //   for (let i = 0; i < this.filteredPDFData.length; i++) {
  //     this.pdfData.push({
  //       sr_no: i + 1,
  //       companyName: this.filteredPDFData[i]?.company_name,
  //       membershipNo: this.filteredPDFData[i]?.membership_no,
  //       amount: this.filteredPDFData[i]?.amount,
  //       membershipYear: this.filteredPDFData[i]?.membership_year,
  //       membershipType: this.filteredPDFData[i]?.membership_name,
  //       email: this.filteredPDFData[i]?.email,
  //       expiringDate: moment(this.filteredPDFData[i]?.expire_at).format(
  //         'DD/MM/YYYY'
  //       ),
  //     });
  //   }
  //   this.api_service.exportPdf(
  //     this.pdfData,
  //     this.exportColumns,
  //     this.route.snapshot.params.slug == 'Hwm'
  //       ? 'HWM-Membership-Renewal-Report'
  //       : 'Regular-Membership-Renewal-Report'
  //   );
  // }

  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
  
    if(this.slug == 'Hwm' || this.slug == 'Regular'){
      if (this.dataTable.filteredValue != null) {
        this.filteredPDFData = this.dataTable.filteredValue;

      } else if (this.dataTable.filteredValue == null) {
        this.filteredPDFData = this.list;
      }
       const cols = [
        { field: 'sr_no', header: 'Sr No' },
        { field: 'company_name', header: 'Company Name' },
        { field: 'membership_no', header: 'Membership No' },      
      ];

      this.yearsOfRenewal.forEach(item => {
        let years = item.key.match(/\d{4}/g);
        if (years && years.length === 2) {
          let startYear = years[0];
          let endYear = years[1];
         cols.push({ field: `Renewal List ${startYear}-${endYear}`, header: `${startYear}-${endYear.slice(2)}` });
          // cols.push({ field: `membership_year`, header: `${startYear}-${endYear.slice(2)}` });
          // cols.push({ field: `membership_year `, header: `${startYear}-${endYear}` });
        }
      });
      cols.push({ field: `total`, header: `Total` });

      
      let index = 1;

      for (let i = 0; i < this.filteredPDFData.length; i++) {
          let rowData = {};
          
          if (this.filteredPDFData[i].status === "Approved" || this.filteredPDFData[i].status === "Terminated" || this.filteredPDFData[i].status === "Provisionally Terminated") {
              let total = 0;      
              cols.forEach(col => {
                  if (col.field === 'sr_no') {          
                      rowData[col.field] = index++;
                  } else if (col.field === this.filteredPDFData[i].membership_year) {
                      let headerYears = this.extractYear(col.field);
                      let fieldYears = this.extractYear(this.filteredPDFData[i].membership_year);
      
                      if (headerYears === fieldYears) {
                          if (this.filteredPDFData[i]?.amount && !isNaN(parseFloat(this.filteredPDFData[i]?.amount))) {
                              total += parseFloat(this.filteredPDFData[i]?.amount);
                          }
                          rowData[col.field] = this.filteredPDFData[i].amount || null;
                      }
                  } else if (col.field === 'company_name' || col.field === 'membership_no') {
                      rowData[col.field] = this.filteredPDFData[i][col.field] || null;
                  } else if (col.field === 'total') {
                      rowData[col.field] = total;
                  } else {
                      this.duplicateMember = this.extractYear(col.field, this.filteredPDFData[i]);
                      rowData[col.field] = this.duplicateMember?.amount || null;
      
                      if (this.duplicateMember?.amount && !isNaN(parseFloat(this.duplicateMember?.amount))) {
                          total += parseFloat(this.duplicateMember?.amount);
                      }
                  }
              });
      
              this.pdfData.push(rowData);
          }
      }
      
      
    let exportColumns = cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));


    let index2 = 1; // Initialize index to 1

    for (let i = 0; i < this.filteredPDFData.length; i++) {
        let rowData2 = {};
    
        if (this.filteredPDFData[i].status === "Approved") {
            let total = 0;
    
            cols.forEach(col => {
                if (col.field === 'sr_no') {
                    rowData2[col.field] = index2++;
                } else if (col.field === this.filteredPDFData[i].membership_year) {
                    let headerYears = this.extractYear(col.field);
                    let fieldYears = this.extractYear(this.filteredPDFData[i].membership_year);
    
                    if (headerYears === fieldYears) {
                      if (this.filteredPDFData[i]?.amount && !isNaN(parseFloat(this.filteredPDFData[i]?.amount))) {
                        total += parseFloat(this.filteredPDFData[i]?.amount);
                      }
                        rowData2[col.field] = this.filteredPDFData[i]?.amount || null;
                    }
                } else if (col.field === 'company_name' || col.field === 'membership_no') {
                    rowData2[col.field] = this.filteredPDFData[i]?.[col.field] || null;
                } else if (col.field === 'total') {
                    rowData2[col.field] = total;
                } else {
                    this.duplicateMember = this.extractYear(col.field, this.filteredPDFData[i]);
                    rowData2[col.field] = this.duplicateMember?.amount || null;
    
                    if (this.duplicateMember?.amount && !isNaN(parseFloat(this.duplicateMember?.amount))) {
                      total += parseFloat(this.duplicateMember?.amount);
                    }
                }
            });
    
            this.tableData2.push(rowData2);
        }
    }
    
  let exportColumns2 = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  let index3 = 1; // Initialize index3 to 1

  for (let i = 0; i < this.filteredPDFData.length; i++) {
      let rowData3 = {};
      if (this.filteredPDFData[i].status === "Provisionally Terminated") {
          let total = 0;
          cols.forEach(col => {
              if (col.field === 'sr_no') {
                  rowData3[col.field] = index3++;
              } else if (col.field === this.filteredPDFData[i].membership_year) {
                  let headerYears = this.extractYear(col.field);
                  let fieldYears = this.extractYear(this.filteredPDFData[i].membership_year);
                  if (headerYears === fieldYears) {
                    if (this.filteredPDFData[i]?.amount && !isNaN(parseFloat(this.filteredPDFData[i]?.amount))) {
                      total += parseFloat(this.filteredPDFData[i]?.amount);
                    }
                      rowData3[col.field] = this.filteredPDFData[i]?.amount || null;
                  }
              } else if (col.field === 'company_name' || col.field === 'membership_no') {
                  rowData3[col.field] = this.filteredPDFData[i]?.[col.field] || null;
              } else if (col.field === 'total') {
                  rowData3[col.field] = total;
              } else {
                  this.duplicateMember = this.extractYear(col.field, this.filteredPDFData[i]);
                  rowData3[col.field] = this.duplicateMember?.amount || null;
                  if (this.duplicateMember?.amount && !isNaN(parseFloat(this.duplicateMember?.amount))) {
                    total += parseFloat(this.duplicateMember?.amount);
                  }
              }
          });
          this.tableData3.push(rowData3);
      }
  }
  
  let exportColumns3 = cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
  }));
  
  let index4 = 1;

  for (let i = 0; i < this.filteredPDFData.length; i++) {
    let rowData4 = {};
    if (this.filteredPDFData[i].status == "Terminated") {
      let total = 0;
      cols.forEach(col => {
        if (col.field === 'sr_no') {
          rowData4[col.field] = index4++;
        } else if (col.field === this.filteredPDFData[i].membership_year) {
          let headerYears = this.extractYear(col.field);
          let fieldYears = this.extractYear(this.filteredPDFData[i].membership_year);
          if (headerYears == fieldYears) {
            if (this.filteredPDFData[i]?.amount && !isNaN(parseFloat(this.filteredPDFData[i]?.amount))) {
              total += parseFloat(this.filteredPDFData[i]?.amount);
            }
            rowData4[col.field] = this.filteredPDFData[i]?.amount || null;
          }
        } else if (col.field === 'company_name' || col.field === 'membership_no') {
          rowData4[col.field] = this.filteredPDFData[i]?.[col.field] || null;
        } else if (col.field === 'total') {
          rowData4[col.field] = total;
        } else {
          this.duplicateMember = this.extractYear(col.field, this.filteredPDFData[i]);
          rowData4[col.field] = this.duplicateMember?.amount ? this.duplicateMember?.amount : null;
          if (this.duplicateMember?.amount && !isNaN(parseFloat(this.duplicateMember?.amount))) {
            total += parseFloat(this.duplicateMember?.amount);
          }
        }
      });
      this.tableData4.push(rowData4);
    }
  }
  
let exportColumns4 = cols.map((col) => ({
title: col.header,
dataKey: col.field,
}));


    
// Dictionary to store seen membership numbers
let seenMembershipNumbers: { [key: number]: boolean } = {};

// Remove duplicates from pdfData
this.pdfData = this.pdfData.filter(obj => {
    if (!seenMembershipNumbers[obj.membership_no]) {
        seenMembershipNumbers[obj.membership_no] = true;
        return true; // Keep the object
    }
    return false; // Discard the object
});
let indexs1 = 1;
// Update the sr_no field in pdfData
this.pdfData.forEach(obj => {
    obj.sr_no = indexs1++;
});
// Clear the dictionary for the next iteration
seenMembershipNumbers = {};

// Remove duplicates from tableData2
this.tableData2 = this.tableData2.filter(obj => {
    if (!seenMembershipNumbers[obj.membership_no]) {
        seenMembershipNumbers[obj.membership_no] = true;
        return true; // Keep the object
    }
    return false; // Discard the object
});
let indexs2 = 1;
// Update the sr_no field in pdfData
this.tableData2.forEach(obj => {
    obj.sr_no = indexs2++;
});
// Clear the dictionary for the next iteration
seenMembershipNumbers = {};

// Remove duplicates from tableData3
this.tableData3 = this.tableData3.filter(obj => {
    if (!seenMembershipNumbers[obj.membership_no]) {
        seenMembershipNumbers[obj.membership_no] = true;
        return true; // Keep the object
    }
    return false; // Discard the object
});
let indexs3 = 1;
// Update the sr_no field in pdfData
this.tableData3.forEach(obj => {
    obj.sr_no = indexs3++;
});
seenMembershipNumbers = {};

// Remove duplicates from tableData3
this.tableData4 = this.tableData4.filter(obj => {
    if (!seenMembershipNumbers[obj.membership_no]) {
        seenMembershipNumbers[obj.membership_no] = true;
        return true; // Keep the object
    }
    return false; // Discard the object
});

let indexs4 = 1;
// Update the sr_no field in pdfData
this.tableData4 .forEach(obj => {
    obj.sr_no = indexs4++;
});
      this.api_service.exportPdfForRenewal(
        this.pdfData,
        exportColumns,
        this.tableData2,
        exportColumns2,
        this.tableData3,
        exportColumns3,
        this.tableData4,
        exportColumns4,
        this.route.snapshot.params.slug == 'Hwm'
          ? 'HWM-Membership-Renewal-Report'
          : 'Regular-Membership-Renewal-Report'
      );
      
    }
    else{
      if( this.slug == 'hwm-work-que'){
        let cols = [
          { field: 'sr_no', header: 'S. No.' },
          { field: 'name', header: 'Name of Company' },
          { field: 'request', header: 'Type of Request' },
          { field: 'membership_no', header: 'M. No' },
          { field: 'MF', header: 'MF' },
          { field: 'TDF', header: 'TDF' },
          { field: 'RNF', header: 'RNF' },
          { field: 'REF', header: 'REF' },
          { field: 'document_missing', header: 'Document missing' },
          { field: 'ACM', header: 'Any communication made' },
          { field: 'remark', header: 'Any specific remarks' },
          { field: 'location', header: 'Location' },
          { field: 'remark2', header: 'Remark' },
      ];
      
        let exportColumns = cols.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));

        for (let i = 0; i < this.memberdata.length; i++) {
          this.pdfData.push({
              sr_no: i + 1,
              name: this.memberdata[i]?.name,
              request: this.memberdata[i]?.request,
              membership_no: this.memberdata[i]?.membership_no,
              ACM: 'Not Available',
              remark: this.memberdata[i]?.location,
              location: this.memberdata[i]?.location,
              ref: this.memberdata[i]?.ref,
              MF: this.memberdata[i]?.mf,
              TDF: this.memberdata[i]?.tdf,
              RNF: this.memberdata[i]?.rnf,
              REF: this.memberdata[i]?.ref,
              document_missing: this.memberdata[i]?.document_missing,
              remark2: this.memberdata[i]?.remark2
          });
      }
      

        this.api_service.exportPdf(
          this.pdfData,
          exportColumns,
          'reports'
        );  
      } 


        if( this.slug == 'voter-list'){
  
  
        let cols = [
    { field: 'sr_no', header: 'Sr No.' },
    { field: 'membership_no', header: 'Membership Number' },
    { field: 'company_name', header: 'Company Name' },
    { field: 'nominee1.name', header: 'First Nominee' },
    { field: 'nominee1.mobile', header: 'First Nominee Mobile No' },
    { field: 'nominee1.email', header: 'First Nominee Email' },
    { field: 'nominee2.name', header: 'Second Nominee' },
    { field: 'nominee2.mobile', header: 'Second Nominee Mobile No' },
    { field: 'nominee2.email', header: 'Second Nominee Email' },
    { field: 'company_address.address', header: 'Address' },
    { field: 'company_address.city', header: 'City' },
    { field: 'member_category.code', header: 'Grade ' },
    { field: 'member_category.name', header: 'Category ' },
    { field: 'member_classification.name', header: 'Classification' },
    { field: 'member_classification.code', header: 'Code' },
    { field: 'member_since', header: 'Year of Joining' },

    // { field: 'email', header: 'Email' },
    // { field: 'website', header: 'Website' },
    // { field: 'office_telephone ', header: 'Office Telephone' }
          ];
         let exportColumns: { title: string; dataKey: string }[];

           exportColumns = cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
          }));
  
          for (let i = 0; i < this.memberdata.length; i++) {
            let rowData = {};
            cols.forEach(col => {
                let value;
                if (col.field === 'sr_no') {
                    value = i + 1;
                } else {
                    let fields = col.field.split('.'); // Split field by dot to handle nested properties
                    value = this.memberdata[i];
                    // Traverse nested properties
                    for (let field of fields) {
                        value = value ? value[field] : null;
                    }
                }
                rowData[col.field] = value || '';
            });
            this.pdfData.push(rowData);
        }
        
        
          this.api_service.exportPdfforVoter(
            this.pdfData,
            exportColumns,
            'Voter-List',
             this.voterYear
          );  
        } 


        if (this.slug == 'membership-approved') {
          let cols = [
              { field: 'sr_no', header: 'S. No.' },
              { field: 'month', header: 'Month' },
              { field: 'membership_no', header: 'Membership No.' },
              { field: 'company_name', header: 'Name of Company' },
              { field: 'city', header: 'Place' },
              { field: 'amount', header: 'Membership Fee' }
          ];
      
          
           let exportColumnss = cols.map(col => ({
            title: col.header,
            dataKey: col.field
        }));
      
          this.pdfData = [];
      
          for (let i = 0; i < this.memberdata.length; i++) {
              let rowData = {};
              cols.forEach(col => {
                  if (col.field === 'sr_no') {
                      rowData[col.field] = i + 1;
                  } else {
                      rowData[col.field] = this.memberdata[i]?.[col.field] || '';
                  }
              });
              this.pdfData.push(rowData);
          }
      
          // Second table data
          let cols1 = [
              { field: 'sr_no', header: 'S. No.' },
              { field: 'particulars', header: 'Particulars' },
              { field: 'details', header: 'Details' }
          ];
      
          let rowData2 = [
              { sr_no: 1, particulars: 'Total Amount Received', details: this.totalData?.total_amount ?? '-' },
              { sr_no: 2, particulars: '20% Amount to be retained by UCCI', details: this.totalData[1]?.retained_amount ?? '-' },
              { sr_no: 3, particulars: 'Balance amount payable to ReSL', details: this.totalData[1]?.balance_amount ?? '-' }
          ];
          let exportColumns2 = cols1.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
          // Populate PDF data for the second table
          rowData2.forEach(data => {
              this.pdfData2.push(data);
          });
          // Combine the export columns for both tables
          let exportColumns = [...exportColumnss, ...exportColumns2];
      
          // Export PDF for the first table
          this.api_service.exportPdfforApprovedMember(
              this.pdfData,
              exportColumnss,
              this.pdfData2,
              exportColumns2,
              'Membership-Approved',
              this.rangeDates

          );
      }
      


      if (this.slug == 'member-data') {
        let cols = [
            { field: 'membership_no', header: 'M.No' },
            { field: 'name', header: 'Name of Member' },
            { field: 'member_since', header: 'Joining Date' },
            { field: 'email', header: 'Email' },
            { field: 'contact', header: 'Contact' },
            { field: 'factory_address', header: 'Factory Address' },
            { field: 'registered_address', header: 'Registered Address' },
            { field: 'waste_disposal_details', header: 'Waste Disposal Details' },
            { field: 'year', header: 'Year' },
            { field: 'amendment_done', header: 'Amendment done' },
            { field: 'documents_uploaded', header: 'Documents uploaded' },
            { field: 'existing_membership_letters', header: 'Existing and Previous Membership Letters' },
            { field: 'payment_invoices', header: 'Payment Invoices' }
        ];
    
        // Mapping columns for the table
        let exportColumns = cols.map(col => ({
            title: col.header,
            dataKey: col.field
        }));
    
        // Populate PDF data for the table
        let pdfData = [];
        for (let i = 0; i < this.memberdata.length; i++) {
            let rowData = {
                membership_no: this.memberdata[i]?.member?.membership_no ?? '-',
                name: this.parsedData(this.memberdata[i]?.member_data_json, 'member_data') ?? '-',
                member_since: this.memberdata[i]?.member?.member_since ?? '-',
                email: `${this.parsedData(this.memberdata[i]?.member_data_json, 'email1') ?? '-'}, ${this.parsedData(this.memberdata[i]?.member_data_json, 'email2') ?? '-'}`,
                contact: `${this.parsedData(this.memberdata[i]?.member_data_json, 'contact1') ?? '-'}, ${this.parsedData(this.memberdata[i]?.member_data_json, 'contact2') ?? '-'}`,
                factory_address: this.memberdata[i]?.member?.hwm_details[0]?.factory_address?.address ?? '-',
                registered_address: this.memberdata[i]?.member?.company_address[1]?.address ?? '-',
                waste_disposal_details: this.parsedData(this.memberdata[i]?.member?.hwm_details[0]?.hw_details, 'hwm_details') ?? '-',
                year: this.memberdata[i]?.created_at ? (new Date(this.memberdata[i]?.created_at)).getFullYear() : '-',
                amendment_done: this.parsedData(this.memberdata[i]?.changed_in, 'amendment') ?? '-',
                documents_uploaded: this.memberdata[i]?.member?.supporting_docs?.document ?? '-',
                existing_membership_letters: 'N_A',
                payment_invoices: 'N_A'
            };
            pdfData.push(rowData);
        }
        // Export PDF for the table
        this.api_service.exportPdf(
            pdfData,
            exportColumns,
            'member-data',
            this.rangeDates
        );
    }
    
    
    if (this.slug == 'membership-status') {
      let cols = [
          { field: 'sr_no', header: 'S. No.' },
          { field: 'particular', header: 'Particular' },
          { field: 'Previous', header: 'Previous' },
          { field: 'Current', header: 'Current' },   
      ];
  
      let rowDataa = [
        { sr_no: 1, particular: 'New Memberships', Previous: this.memberdata?.previous_fn_new_member_count ?? '-' ,Current: this.memberdata?.previous_fn_revision_count ?? '-'},
        { sr_no: 2, particular: 'Revision in Memberships', Previous: this.memberdata?.current_fn_new_member_count ?? '-',Current: this.memberdata?.current_fn_revision_count?? '-' },
       
    ];
       let exportColumnss = cols.map(col => ({
        title: col.header,
        dataKey: col.field
    }));
    this.pdfData = [];
    rowDataa.forEach(data => {
      this.pdfData.push(data);
  });
  
  
      // for (let i = 0; i < this.memberdata.length; i++) {
      //     let rowData = {};
      //     cols.forEach(col => {
      //         if (col.field === 'sr_no') {
      //             rowData[col.field] = i + 1;
      //         } else {
      //             rowData[col.field] = this.memberdata[i]?.[col.field] || '';
      //         }
      //     });
      //     this.pdfData.push(rowData);
      // }
  
      // Second table data
      let cols1 = [
          { field: 'sr_no', header: 'S. No.' },
          { field: 'particulars', header: 'Particulars' },
          { field: 'details', header: 'Details' }
      ];
  
      let rowData2 = [
          { sr_no: 1, particulars: 'New Membership Fee', details: this.memberdata?.current_fn_new_membership_total_amount ?? '-' },
          { sr_no: 2, particulars: 'Membership Revision Amount', details: this.memberdata?.current_fn_revision_total_amount ?? '-' },
          { sr_no: 3, particulars: 'Membership Renewal Amount', details: this.memberdata?.current_fn_renewal_amt ?? '-' },
          { sr_no: 4, particulars: 'Trust Development Fee', details:this.memberdata?.current_fn_trust_development_total_amount ?? '-' }

      ];
      let exportColumns2 = cols1.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));
      rowData2.forEach(data => {
        this.pdfData2.push(data);
    });


//third table
      let cols3 = [
        { field: 'sr_no', header: 'S.No.' },
        { field: 'particulars', header: 'Particulars' },
        { field: 'details', header: 'Details' }
    ];
    
    let rowData3 = [
        { sr_no: 1, particulars: 'Total Members', details: this.memberdata?.total_members ?? '-' },
        { sr_no: 2, particulars: 'Fully Paid', details: this.memberdata?.fully_paid ?? '-' },
        { sr_no: 3, particulars: 'Due', details: this.memberdata?.due ?? '-' }
    ];
     

    let exportColumns3 = cols3.map((col) => ({
      title: col.header,
      dataKey: col.field,
  }));
    

       this.pdfData3=[]
    rowData3.forEach(data => {
      this.pdfData3.push(data);
  });
      // Populate PDF data for the second table
      
      // Combine the export columns for both tables
  
      // Export PDF for the first table
      this.api_service.exportPdfformemberStatus(
          this.pdfData,
          exportColumnss,
          this.pdfData2,
          exportColumns2,
          this.pdfData3,
          exportColumns3,
          'MEMBERSHIP-STATUS',
          this.year
          
      );
  }
    
    }

   
   
   
   


   
   
  }
 
  getMonthName(timestamp: string): string {
    if (!timestamp) return '-'; // Return '-' if timestamp is not available

    const date = new Date(timestamp);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    return monthName;
  }
  parsedData(data : any, string:any){
    if(!data){
       return
    }
    else{
      const d = JSON.parse(data);
      if(string === 'hwm_details'){
          return d?.waste_quantity
      }else if(string === 'member_data'){
          return d?.name1
      }else if (string === 'amendment'){
          return d
      }
      else if (string === 'email1'){
        return d?.email1
    }
    else if (string === 'email2'){
      return d?.email2
  }
    else if (string === 'contact1'){
      return d?.mobile_no1
  }
  else if (string === 'contact2'){
    return d?.mobile_no1
  }
    }
   
    
  }

  extractYear(item: string, data?:any): string {

    if (!item) return '';


    if(data){
      let matchingObjects = this.list.filter(obj => obj.membership_no === data.membership_no);

      for (let obj of matchingObjects) {
          let formattedYearRange =item ;
          if (formattedYearRange === obj.membership_year) {

              return obj; // Return the entire object if membership_year matches\

          }
      }
  
      return null; // Return null if no matching object found
  }
    
    

    let parts = item.split(' ');

    let yearRange = parts[parts.length - 1];

    let years = yearRange.split('-');

     let secondYear = years[1].slice(2);

    let formattedYearRange = years[0] + '-' + years[1];

    return formattedYearRange;
  }

  removeDuplicates(item :any){

  }

}
