<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" header="Confirmation"></p-confirmDialog>

<!-- *********************************************************************************************************************** -->
<!-- regular membership form section -->
<!-- *********************************************************************************************************************** -->
<div *ngIf="!successState">
  <div class="breadcrumb-area">
    <h1>Regular Membership Edit Form</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">Regular Membership Edit Form</li>
    </ol>
  </div>
  <section class="regular-member-form-section">
    <div class="container" *ngIf="isShow" id="backToTop">
      <form [formGroup]="registerForm" (ngSubmit)="Register(register)" #register="ngForm">
        <!-- basic information -->
        <!-- [ngStyle]="{height: !successState ? '100%' : '0'}" -->
        <div class="add-listings-box">
          <h3>Basic Informations <span
              style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 50px;">
              Any Information Filled in the Form is your Responsibility</span></h3>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Main Nature of Business:<span class="required">*</span>
                    <i class="bx bx-info-circle" pTooltip="Main
                        Nature
                        of Business (applicant drives
                        more than 50% of its Revenue from)" tooltipPosition="bottom"></i>
                  </label>
                </div>
                <p-dropdown [options]="natureofBusinessList"
                  [class.is-invalid]="(register.submitted && registerForm.get('businessnature')?.invalid) || registerForm.get('businessnature')?.invalid && registerForm.get('businessnature').touched"
                  formControlName="businessnature"
                  [styleClass]="(register.submitted && registerForm.get('businessnature')?.invalid) || registerForm.get('businessnature')?.invalid && registerForm.get('businessnature').touched ? 'error' : ''"
                  (onChange)="getMemberClassification($event.value)" placeholder="Select
              Nature of Business" optionLabel="name" optionValue="id" [showClear]="false" [resetFilterOnHide]="true"
                  required></p-dropdown>

                <div *ngIf="register.submitted && f.businessnature.errors?.required || f.businessnature.touched"
                  class="alert alert-danger">
                  <div *ngIf="f.businessnature.errors?.required">
                    Nature of Business is Required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Memeber Classfication:<span class="required">*</span></label>
                <p-dropdown [options]="classficationData" [filter]="true" filterBy="name" [class.is-invalid]="(register.submitted &&
                registerForm.get('memberclassficationid')?.invalid) ||
                registerForm.get('memberclassficationid')?.invalid &&
                registerForm.get('memberclassficationid').touched" formControlName="memberclassficationid"
                  [styleClass]="(register.submitted &&
                registerForm.get('memberclassficationid')?.invalid) ||
                registerForm.get('memberclassficationid')?.invalid &&
                registerForm.get('memberclassficationid').touched ? 'error' : ''"
                  placeholder="Select Member Classfication" (onChange)="onClassificationSelection($event)"
                  optionLabel="name" optionValue="id" [showClear]="false" [resetFilterOnHide]="true" required>
                  <ng-template let-item pTemplate="item">
                    <div class="country-item">
                      <div><span> {{item.name}} | Vacant Seats: {{item?.vacant_seats}}</span></div>
                    </div>
                  </ng-template>
                </p-dropdown>

                <div *ngIf="register.submitted &&
                                  f.memberclassficationid.errors?.required ||
                                  f.memberclassficationid.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.memberclassficationid.errors?.required">
                    Memeber Classfication is Required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>Name of the Applicant Firm/Company:</label>
                  <!-- <p
                    style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px;">
                    Chargeable</p> -->
                </div>
                <input type="text" scriptInjection class="form-control capitalize" formControlName="companyname"
                  maxlength="70" placeholder="Name of the Applicant Firm/Company" [class.is-invalid]="(register.submitted &&
                                  registerForm.get('companyname')?.invalid) ||
                                  registerForm.get('companyname')?.invalid &&
                                  registerForm.get('companyname').touched" (keypress)="restrictSpecialChar($event)">
                <!-- <div *ngIf="register.submitted &&
                                  f.companyname.errors?.required ||
                                  f.companyname.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.companyname.errors?.required">
                    Company Name is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Constitution of the Applicant:</label>
                <p-dropdown [options]="constitution" [ngModelOption]="{standalone: true}"
                  [(ngModel)]="applicantconstitution" [class.is-invalid]="(register.submitted &&
                registerForm.get('applicantconstitution')?.invalid) ||
                registerForm.get('applicantconstitution')?.invalid &&
                registerForm.get('applicantconstitution').touched" formControlName="applicantconstitution"
                  [styleClass]="(register.submitted && registerForm.get('applicantconstitution')?.invalid) || registerForm.get('applicantconstitution')?.invalid && registerForm.get('applicantconstitution').touched ? 'error' : ''"
                  (onChange)="getConstitutionId('other1', $event)" placeholder="Select Member Constitution"
                  optionLabel="name" [showClear]="false" [resetFilterOnHide]="true"></p-dropdown>

                <!-- <div *ngIf="register.submitted &&
                                  f.applicantconstitution.errors?.required ||
                                  f.applicantconstitution.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.applicantconstitution.errors?.required">
                    Constitution of The Applicant is Required
                  </div>
                </div> -->
                <input type="text" scriptInjection class="form-control" placeholder="Please Describe" maxlength="200"
                  [class.is-invalid]="(register.submitted &&
                                  registerForm.get('others1')?.invalid) ||
                                  registerForm.get('others1')?.invalid &&
                                  registerForm.get('others1').touched" formControlName="others1" *ngIf="inputShow1">
                <!-- <div *ngIf="register.submitted &&
                                  f.others1.errors?.required ||
                                  f.others1.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.others1.errors?.required">
                    Please Describe
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>GST Number:</label>
                <!-- <span class="form-control d-flex align-items-center">{{registerForm.value.gstno}}</span> -->
                <input type="text" scriptInjection class="form-control" formControlName="gstno" maxlength="15"
                  oninput="this.value= this.value.toUpperCase()"
                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" placeholder="GST Number">
                <!-- <div *ngIf="register.submitted &&
                                f.gstno.errors?.required ||
                                f.gstno.touched" class="alert alert-danger">
                  <div *ngIf="f.gstno.errors?.required">
                    GST Number is Required
                  </div>
                </div> -->
                <div *ngIf="f.gstno.errors?.pattern" class="alert alert-danger">
                  Please Enter Valid GST Number
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>CIN Number:</label>
                <input type="text" scriptInjection class="form-control" formControlName="cin" maxlength="21"
                  pattern="^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
                  oninput="this.value= this.value.toUpperCase()" placeholder="CIN Number">
                <div *ngIf="register.submitted &&
                                  f.cin.errors?.required ||
                                  f.cin.touched" class="alert alert-danger">
                  <!-- <div *ngIf="f.cin.errors?.required">
                    CIN Number is Required
                  </div> -->
                  <div *ngIf="f.cin.errors?.pattern">
                    Please enter valid CIN number
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Office Telephone:</label>
                <input type="text" scriptInjection class="form-control" formControlName="telephone" maxlength="10"
                  pKeyFilter="int" pattern="^((\\+91-?)|0)?[0-9]{10}$" placeholder="Office Telephone">
                <div *ngIf="register.submitted &&
                                  f.telephone.errors?.required ||
                                  f.telephone.touched" class="alert alert-danger">
                  <!-- <div *ngIf="f.telephone.errors?.required">
                    Office Telephone is Required
                  </div> -->
                  <div *ngIf="f.telephone.errors?.pattern">
                    Please enter 10 digits office telephone
                    number
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Residental
                  Telephone:</label>
                <input type="text" scriptInjection class="form-control" pKeyFilter="int" maxlength="10"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$" formControlName="residentaltel"
                  placeholder="Residental Telephone">
                <!-- <div *ngIf="register.submitted &&
                                  f.residentaltel.errors?.required ||
                                  f.residentaltel.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.residentaltel.errors?.required">
                    Residental Telephone is Required
                  </div>
                </div> -->
                <div *ngIf="f.residentaltel.errors?.pattern" class="alert
                alert-danger">
                  Residental telephone
                  number is not valid
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Average Number of Fulltime:
                    <i class="bx bx-info-circle"
                      pTooltip="Average Number of Fulltime Employees Employed bt the Applicant"
                      tooltipPosition="bottom"></i>
                  </label>
                </div>

                <p-dropdown [options]="averageEmp" formControlName="avgemp" placeholder="Select Member Constitution"
                  optionLabel="name" optionValue="name" [showClear]="false" [resetFilterOnHide]="true"></p-dropdown>
                <!-- <p-dropdown [options]="averageEmp" [class.is-invalid]="(register.submitted &&
                registerForm.get('avgemp')?.invalid) || registerForm.get('avgemp')?.invalid &&
                registerForm.get('avgemp').touched" formControlName="avgemp" [styleClass]="(register.submitted &&
                registerForm.get('avgemp')?.invalid) || registerForm.get('avgemp')?.invalid &&
                registerForm.get('avgemp').touched ? 'error' : ''" placeholder="Select Member Constitution"
                  optionLabel="name" optionValue="name" [showClear]="false" [resetFilterOnHide]="true"></p-dropdown> -->

                <!-- <div *ngIf="register.submitted &&
                                  f.avgemp.errors?.required ||
                                  f.avgemp.touched" class="alert alert-danger">
                  <div *ngIf="f.avgemp.errors?.required">
                    Average Number of Fulltime
                    Employees Employed bt the Applicant is
                    Required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- address section -->
        <div class="add-listings-box">
          <h3>Address and Contact Details</h3>
          <h3>Registered/Main/Head Office</h3>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Postal Address:</label>
                <textarea [class.is-invalid]="(register.submitted &&
                                  registerForm.get('address')?.invalid) ||
                                  registerForm.get('address')?.invalid &&
                                  registerForm.get('address').touched" type="text" scriptInjection class="form-control"
                  formControlName="address" placeholder="Registered/Main/Head Office"></textarea>
                <!-- <div *ngIf="register.submitted &&
                                  f.address.errors?.required ||
                                  f.address.touched" class="alert alert-danger">
                  <div *ngIf="f.address.errors?.required">
                    Registered/Main/Head Office is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>State:</label>
                <p-dropdown [options]="state" formControlName="state" optionLabel="name" [filter]="true" filterBy="name"
                  [showClear]="false" [resetFilterOnHide]="true" placeholder="Please select
                                  state" [(ngModel)]="selectedState" [class.is-invalid]="(register.submitted &&
                                  registerForm.get('state')?.invalid) ||
                                  registerForm.get('state')?.invalid &&
                                  registerForm.get('state').touched">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="registerForm.value.state.name">
                      <div>{{registerForm.value.state.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.state.errors?.required ||
                                  f.state.touched" class="alert alert-danger">
                  <div *ngIf="f.state.errors?.required">
                    State is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label> City:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('city')?.invalid) ||
                                  registerForm.get('city')?.invalid &&
                                  registerForm.get('city').touched" type="text" scriptInjection class="form-control"
                  formControlName="city" [maxLength]="70" placeholder="City">
                <!-- <div *ngIf="register.submitted &&
                                  f.city.errors?.required ||
                                  f.city.touched" class="alert alert-danger">
                  <div *ngIf="f.city.errors?.required">
                    City is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Pin Code:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('zipcode')?.invalid) ||
                                  registerForm.get('zipcode')?.invalid &&
                                  registerForm.get('zipcode').touched" placeholder="Pin Code" type="text"
                  scriptInjection [maxLength]="6" pKeyFilter="int" class="form-control" formControlName="zipcode">
                <!-- <div *ngIf="register.submitted &&
                                  f.zipcode.errors?.required ||
                                  f.zipcode.touched" class="alert alert-danger">
                  <div *ngIf="f.zipcode.errors?.required">
                    Pin Code is Required
                  </div>
                </div> -->
                <div *ngIf="f.zipcode.errors?.minlength" class="alert alert-danger">
                  Please Enter Valid Pin Code
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Website:</label>
                <input pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                  type="text" scriptInjection class="form-control" placeholder="Website" [maxLength]="50"
                  formControlName="website">
                <!-- <div *ngIf="register.submitted &&
                                  f.website.errors?.required ||
                                  f.website.touched" class="alert alert-danger">
                  <div *ngIf="f.website.errors?.required">
                    Website is Required
                  </div>
                </div> -->
                <div class="alert alert-danger" *ngIf="f.website.errors?.pattern">
                  Please enter valid Website Name
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>E-mail:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('email')?.invalid) ||
                                  registerForm.get('email')?.invalid &&
                                  registerForm.get('email').touched" type="email" class="form-control"
                  placeholder="E-mail" formControlName="email" maxlength="50"
                  pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$">
                <!-- <div *ngIf="register.submitted &&
                                  f.email.errors?.required ||
                                  f.email.touched" class="alert alert-danger">
                  <div *ngIf="f.email.errors?.required">
                    E-mail is Required
                  </div>
                </div> -->
                <div *ngIf="f.email.errors?.pattern" class="alert
                                  alert-danger">
                  Invalid E-mail address
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex" style="align-items: baseline;">
            <h3>Main Unit</h3>
            <input type="checkbox" class="form-check-input mr-2" id="mainUnit" name="check1"
              (change)="getValues($event.target.checked)" [checked]="isAddressSame"> <label for="mainUnit">Same as
              Above</label>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Postal Address:</label>
                <textarea type="text" scriptInjection class="form-control" formControlName="mainaddress"
                  placeholder="Registered/Main/Head Office"></textarea>
                <!-- <div *ngIf="register.submitted &&
                                  f.address.errors?.required ||
                                  f.address.touched" class="alert alert-danger">
                  <div *ngIf="f.address.errors?.required">
                    Registered/Main/Head Office is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>State:</label>
                <p-dropdown [options]="state" formControlName="mainstate" optionLabel="name" [filter]="true"
                  filterBy="name" [showClear]="false" [resetFilterOnHide]="true" placeholder="Please select
                                  state" [(ngModel)]="selectedState3">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="registerForm.value.mainstate.name">
                      <div>{{registerForm.value.mainstate.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.mainstate.errors?.required ||
                                  f.mainstate.touched" class="alert alert-danger">
                  <div *ngIf="f.mainstate.errors?.required">
                    State is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>City:</label>
                <input type="text" scriptInjection class="form-control" formControlName="maincity" [maxLength]="70"
                  placeholder="City">
                <!-- <div *ngIf="register.submitted &&
                                  f.maincity.errors?.required ||
                                  f.maincity.touched" class="alert alert-danger">
                  <div *ngIf="f.maincity.errors?.required">
                    City is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Pin Code:</label>
                <input placeholder="Pin Code" pKeyFilter="int" type="text" scriptInjection [maxLength]="6"
                  class="form-control" formControlName="mainzipcode">
                <!-- <div *ngIf="register.submitted &&
                                  f.mainzipcode.errors?.required ||
                                  f.mainzipcode.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.mainzipcode.errors?.required">
                    Pin Code is Required
                  </div>
                </div> -->
                <div *ngIf="f.mainzipcode.errors?.minlength" class="alert alert-danger">
                  Please Enter Valid Pin Code
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Website:</label>
                <input pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                  type="text" scriptInjection class="form-control" placeholder="Website" [maxLength]="50"
                  formControlName="mainwebsite">
                <div *ngIf="register.submitted &&
                                  f.mainwebsite.errors?.required ||
                                  f.mainwebsite.touched" class="alert
                                  alert-danger">
                  <!-- <div *ngIf="f.mainwebsite.errors?.required">
                    Website is Required
                  </div> -->
                  <div *ngIf="f.mainwebsite.errors?.pattern">
                    Please enter valid Website Name
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>E-mail:</label>
                <input type="email" class="form-control" placeholder="E-mail" formControlName="mainemail" maxlength="50"
                  pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$">
                <!-- <div *ngIf="register.submitted &&
                                  f.mainemail.errors?.required ||
                                  f.mainemail.touched" class="alert alert-danger">
                  <div *ngIf="f.mainemail.errors?.required">
                    E-mail is Required
                  </div>
                </div> -->
                <div *ngIf="f.mainemail.errors?.pattern" class="alert
                                  alert-danger">
                  Invalid E-mail address
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company details -->
        <div class="add-listings-box">
          <h3>Company Details</h3>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <label class="d-flex align-items-center">Capital Investment:
                      <i class="bx bx-info-circle"
                        pTooltip="Capital Investment in Plant, Machinery & Equipment at the end of the Last Completed Financial Year?"
                        tooltipPosition="bottom"></i>
                    </label>
                  </div>
                </div>
                <p-dropdown [options]="investment" formControlName="capitalinvestment"
                  placeholder="Select Capital Investment" optionLabel="item" optionValue="item" [showClear]="false"
                  [resetFilterOnHide]="true"></p-dropdown>

                <!-- <div *ngIf="register.submitted &&
                                  f.capitalinvestment.errors?.required ||
                                  f.capitalinvestment.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.capitalinvestment.errors?.required">
                    Capital Investment is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="form-group">
                <label>Membership
                  Number:</label>
                <input type="text" scriptInjection formControlName="registrationnumber" class="form-control"
                  pKeyFilter="int" placeholder="Membership Number">
                <!-- <div *ngIf="register.submitted &&
                                  f.registrationnumber.errors?.required ||
                                  f.registrationnumber.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.registrationnumber.errors?.required">
                    Registration Number is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>About:</label>
                <input type="text" scriptInjection class="form-control" placeholder="About" [maxLength]="200"
                  formControlName="about">
                <!-- <div *ngIf="register.submitted &&
                                  f.about.errors?.required ||
                                  f.about.touched" class="alert alert-danger">
                  <div *ngIf="f.about.errors?.required">
                    About is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">

              <div class="form-group">
                <ng-container *ngIf="logoPreviewState">
                  <label for="file">Logo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Logo Preview"
                      (click)="onImageClick(logo ? logo : logoBaseUrl + regularMemberList?.media)"
                      tooltipPosition="bottom">{{(logo ? logo : regularMemberList?.media) | slice:0:10 }}</span>
                    <span (click)="logoPreview('company')" style="color: red" pTooltip="Remove Preview and Change Logo"
                      tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.boolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!logoPreviewState">
                  <label for="file">Upload Logo:</label>
                  <input type="file" formControlName="logo" accept="image/x-png,image/gif,image/jpeg" name="logofile"
                    id="logofile" (change)="processImage($event, 'logo')" class="inputfile form-control px-2 py-1" />
                  <div *ngIf="register.submitted &&
                                  f.logo.errors?.required ||
                                  f.logo.touched" class="alert alert-danger">
                    <!-- <div *ngIf="f.logo.errors?.required">
                      Logo is Required
                    </div> -->
                    <div class="alert alert-danger" *ngIf="!logoState">
                      Logo Size Should Not Larger Then 2MB
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Memeber Category<span class="required">*</span>:</label>
                <p-dropdown [options]="categoryData.data" [filter]="true" filterBy="name" [class.is-invalid]="(register.submitted &&
                registerForm.get('membercategoryid')?.invalid) || registerForm.get('membercategoryid')?.invalid &&
                registerForm.get('membercategoryid').touched" formControlName="membercategoryid" [styleClass]="(register.submitted &&
                registerForm.get('membercategoryid')?.invalid) || registerForm.get('membercategoryid')?.invalid &&
                registerForm.get('membercategoryid').touched ? 'error' : ''" placeholder="Select Member Category"
                  optionLabel="name" optionValue="id" [showClear]="false" [resetFilterOnHide]="true"
                  required></p-dropdown>

                <div *ngIf="register.submitted &&
                                  f.membercategoryid.errors?.required ||
                                  f.membercategoryid.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.membercategoryid.errors?.required">
                    Memeber Category is Required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Is the Applicant Registered with UCCI Under:</label>
                <p-multiSelect [options]="applicant" [resetFilterOnHide]="true" [(ngModel)]="selectedRegistered"
                  (onChange)="getConstitutionId('other3', $event)" defaultLabel="Select Applicant Registered"
                  [ngModelOptions]="{standalone: true}" optionLabel="name" display="chip">
                </p-multiSelect>
                <div *ngIf="f.registered.errors?.required" class="alert alert-danger">
                  Applicant Registered is Required
                </div>
                <input *ngIf="inputShow3" type="text" scriptInjection class="form-control" placeholder="Please Describe"
                  maxlength="200" [class.is-invalid]="(register.submitted &&
                                  registerForm.get('others3')?.invalid) ||
                                  registerForm.get('others3')?.invalid &&
                                  registerForm.get('others3').touched" formControlName="others3">
                <!-- <div *ngIf="register.submitted &&
                                  f.others3.errors?.required ||
                                  f.others3.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.others3.errors?.required">
                    Please Describe
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Date of Establishment:</label>
                <p-calendar formControlName="establishdate" showClear="true" dateFormat="dd/mm/yy"
                  placeholder="DD/MM/YYYY" [maxDate]="today" [disabledDates]="invalidDates" [showIcon]="true">
                </p-calendar>
                <!-- <div *ngIf="register.submitted &&
                                  f.establishdate.errors?.required ||
                                  f.establishdate.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.establishdate.errors?.required">
                    Establishment Date is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Sales Turnover:
                    <i class="bx bx-info-circle" pTooltip="Sales Turnover of the Applicant for the Last
                    Completed Financial Year" tooltipPosition="bottom"></i>
                  </label>
                </div>

                <p-dropdown [options]="turnoverData.data" [class.is-invalid]="(register.submitted &&
                registerForm.get('turnoverid')?.invalid) || registerForm.get('turnoverid')?.invalid &&
                registerForm.get('turnoverid').touched" formControlName="turnoverid" [styleClass]="(register.submitted &&
                registerForm.get('turnoverid')?.invalid) || registerForm.get('turnoverid')?.invalid &&
                registerForm.get('turnoverid').touched ? 'error' : ''" placeholder="Select Turnover"
                  optionLabel="component" optionValue="id" [showClear]="false" [resetFilterOnHide]="true"></p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.turnoverid.errors?.required ||
                                  f.turnoverid.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.turnoverid.errors?.required">
                    Turnover is Required
                  </div>
                </div> -->
              </div>
            </div>


            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Registered By:</label>
                <p-dropdown [options]="registeredBy" [class.is-invalid]="(register.submitted &&
                registerForm.get('registerby')?.invalid) || registerForm.get('registerby')?.invalid &&
                registerForm.get('registerby').touched" formControlName="registerby" [styleClass]="(register.submitted &&
                registerForm.get('registerby')?.invalid) || registerForm.get('registerby')?.invalid &&
                registerForm.get('registerby').touched ? 'error' : ''" placeholder="Select Registered By"
                  optionLabel="name" (onChange)="getConstitutionId('other2', $event)" [showClear]="false"
                  [resetFilterOnHide]="true"></p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.registerby.errors?.required ||
                                  f.registerby.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.registerby.errors?.required">
                    Registered By is Required
                  </div>
                </div> -->
                <input type="text" scriptInjection class="form-control" placeholder="Please Describe" maxlength="200"
                  [class.is-invalid]="(register.submitted &&
                                  registerForm.get('others2')?.invalid) ||
                                  registerForm.get('others2')?.invalid &&
                                  registerForm.get('others2').touched" formControlName="others2" *ngIf="inputShow2">
                <!-- <div *ngIf="register.submitted &&
                                  f.others2.errors?.required ||
                                  f.others2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.others2.errors?.required">
                    Please Describe
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Other Information:
                    <i class="bx bx-info-circle" pTooltip="Any Other Information that the Applicant
                    Considers May be Useful to Assist the Evaluation
                    of this Application" tooltipPosition="bottom"></i>
                  </label>
                </div>
                <textarea type="text" scriptInjection placeholder="Information" formControlName="info" [maxLength]="250"
                  class="form-control">
                              </textarea>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Nominee a Member of any OtherAssociations:
                    <i class="bx bx-info-circle" pTooltip="Is the Applicant or any of it's Nominee a
                    Member of any Other Associations or Professional Body?
                    Please Provide Details:" tooltipPosition="bottom"></i>
                  </label>
                </div>
                <textarea type="text" scriptInjection placeholder="Information" formControlName="oterprofession"
                  [maxLength]="250" class="form-control">
                              </textarea>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Member Since:</label>
                <p-calendar showClear="true" scriptInjection formControlName="member_since" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
              </div>
            </div>
          </div>
        </div>
        <!-- nominee one details -->
        <div class="add-listings-box">
          <h3>Primary Nominee Details</h3>
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label for="">Name:</label>
                  <!-- <p
                    style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 5px">
                    Chargeable</p> -->
                </div>
                <input type="text" scriptInjection class="form-control" placeholder="Name" formControlName="name1"
                  [maxLength]="50">
                <!-- <div *ngIf="register.submitted &&
                                  f.name1.errors?.required ||
                                  f.name1.touched" class="alert alert-danger">
                  <div *ngIf="f.name1.errors?.required">
                    Name is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Designation:</label>
                <input type="text" scriptInjection class="form-control" placeholder="Designation"
                  formControlName="designation1" [maxLength]="100">
                <!-- <div *ngIf="register.submitted &&
                                  f.designation1.errors?.required ||
                                  f.designation1.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.designation1.errors?.required">
                    Designation is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">PAN:</label>
                <input oninput="this.value= this.value.toUpperCase()" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" maxlength="10"
                  type="text" scriptInjection class="form-control" placeholder="PAN" formControlName="pan1"
                  [maxLength]="100">
                <div *ngIf="register.submitted &&
                                  f.pan1.errors?.required ||
                                  f.pan1.touched" class="alert
                                  alert-danger">
                  <!-- <div *ngIf="f.pan1.errors?.required">
                    PAN is Required
                  </div> -->
                  <div *ngIf="f.pan1.errors?.pattern">
                    Please enter valid PAN
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Date of Birth:</label>
                <p-calendar formControlName="dob1" showClear="true" [maxDate]="today" dateFormat="dd/mm/yy"
                  [disabledDates]="invalidDates" placeholder="DD/MM/YYYY" [showIcon]="true">
                </p-calendar>
                <!-- <div *ngIf="register.submitted &&
                                  f.dob1.errors?.required ||
                                  f.dob1.touched" class="alert alert-danger">
                  <div *ngIf="f.dob1.errors?.required">
                    Date of Birth is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('email1')?.invalid) ||
                                  registerForm.get('email1')?.invalid &&
                                  registerForm.get('email1').touched" type="email" class="form-control"
                  placeholder="E-Mail" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email1"
                  [maxLength]="100">
                <div *ngIf="register.submitted &&
                                  f.email1.errors?.required ||
                                  f.email1.touched" class="alert alert-danger">
                  <!-- <div *ngIf="f.email1.errors?.required">
                    E-Mail is Required
                  </div> -->
                  <div *ngIf="f.email1.errors?.pattern">
                    Invalid E-mail address
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Office Phone:</label>
                <input type="text" scriptInjection formControlName="officephone1" class="form-control"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$" pKeyFilter="int" maxlength="10" placeholder="Office Phone">
                <div *ngIf="register.submitted &&
                                  f.officephone1.errors?.required ||
                                  f.officephone1.touched" class="alert
                                  alert-danger">
                  <!-- <div *ngIf="f.officephone1.errors?.required">
                    Office Phone is Required
                  </div> -->
                  <div *ngIf="f.officephone1.errors?.pattern">
                    Please enter 10 digits office phone number
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('mobileno1')?.invalid) ||
                                  registerForm.get('mobileno1')?.invalid &&
                                  registerForm.get('mobileno1').touched" type="text" scriptInjection
                  placeholder="Mobile Number" class="form-control" pattern="^((\\+91-?)|0)?[0-9]{10}$" pKeyFilter="int"
                  maxlength="10" formControlName="mobileno1">
                <!-- <div *ngIf="register.submitted &&
                                  f.mobileno1.touched" class="alert alert-danger">
                  <div *ngIf="f.mobileno1.errors?.pattern">
                    Please enter 10 digits mobile number
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Address:</label>
                <input type="text" scriptInjection class="form-control" formControlName="address1"
                  placeholder="Address">
                <!-- <div *ngIf="register.submitted &&
                                  f.address1.errors?.required ||
                                  f.address1.touched" class="alert alert-danger">
                  <div *ngIf="f.address1.errors?.required">
                    Address is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>State:</label>
                <p-dropdown [options]="state" formControlName="state1" optionLabel="name" [filter]="true"
                  filterBy="name" [showClear]="false" [resetFilterOnHide]="true" placeholder="Please Select State"
                  [(ngModel)]="selectedState1">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="registerForm.value.state1.name">
                      <div>{{registerForm.value.state1.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.state1.errors?.required ||
                                  f.state1.touched" class="alert alert-danger">
                  <div *ngIf="f.state1.errors?.required">
                    State is Required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>City:</label>
                <input type="text" scriptInjection formControlName="city1" placeholder="City" class="form-control"
                  [maxLength]="70">
                <!-- <div *ngIf="register.submitted &&
                                  f.city1.errors?.required ||
                                  f.city1.touched" class="alert alert-danger">
                  <div *ngIf="f.city1.errors?.required">
                    City is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Pin Code:</label>
                <input placeholder="Pin Code" type="text" scriptInjection pKeyFilter="int" [maxLength]="6"
                  class="form-control" formControlName="zipcode1">
                <!-- <div *ngIf="register.submitted &&
                                  f.zipcode1.errors?.required ||
                                  f.zipcode1.touched" class="alert alert-danger">
                  <div *ngIf="f.zipcode1.errors?.required">
                    Pin Code is Required
                  </div>
                </div> -->
                <div *ngIf="f.zipcode1.errors?.minlength" class="alert alert-danger">
                  Please Enter Valid Pin Code
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Department:</label>
                <input type="text" scriptInjection class="form-control" placeholder="Department" [maxLength]="100"
                  formControlName="department1">
                <!-- <div *ngIf="register.submitted &&
                                  f.department1.errors?.required ||
                                  f.department1.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.department1.errors?.required">
                    Department is Required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Industry Area:<span class="required">*</span></label>

                <p-dropdown [options]="industryArea" [class.is-invalid]="(register.submitted &&
                registerForm.get('ia1')?.invalid) || registerForm.get('ia1')?.invalid &&
                registerForm.get('ia1').touched" formControlName="ia1" [styleClass]="(register.submitted &&
                registerForm.get('ia1')?.invalid) || registerForm.get('ia1')?.invalid &&
                registerForm.get('ia1').touched ? 'error' : ''" placeholder="Select Industry Area" optionLabel="name"
                  optionValue="id" [showClear]="false" [resetFilterOnHide]="true" required></p-dropdown>
                  <div *ngIf="register.submitted &&
                  f.ia1.errors?.required ||
                  f.ia1.touched" class="alert
                  alert-danger">
                    <div *ngIf="f.ia1.errors?.required">
                      Industry Area is Required
                    </div>
                  </div>
                </div>
             
            </div>
            <!-- <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Biodata:</label>
                <textarea type="text" placeholder="Biodata" formControlName="biodata1" 
                  class="form-control">
              </div>
            </div> -->
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Biodata:</label>
                <textarea type="text" scriptInjection placeholder="Biodata" formControlName="biodata1" [maxLength]="250"
                  class="form-control">
                              </textarea>
                <!-- <div *ngIf="register.submitted &&
                                  f.biodata2.errors?.required ||
                                  f.biodata2.touched" class="alert alert-danger">
                  <div *ngIf="f.biodata2.errors?.required">
                    Biodata is Required
                  </div>
                </div> -->
              </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <ng-container *ngIf="nominee1PreviewState">
                  <label for="file">Upload Photo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Image Preview" tooltipPosition="bottom"
                      (click)="onImageClick(profile1 ? profile1 : (nominee1ImageBaseUrl + regularMemberList?.nominee1?.media))">{{(profile1
                      ? profile1 : regularMemberList?.nominee1?.media) | slice:0:10}}</span>
                    <span (click)="logoPreview('nominee1')" style="color: red"
                      pTooltip="Remove Preview and Change Photo" tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.boolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!nominee1PreviewState">
                  <label for="file">Upload Photo:</label>
                  <input formControlName="nomineeimage1" type="file" accept="image/x-png,image/gif,image/jpeg"
                    name="photofile" id="nomineephotofile" (change)="processImage($event, 'profile1')"
                    class="inputfile form-control px-2 py-1" />
                  <!-- <div *ngIf="register.submitted &&
                                    f.nomineeimage1.errors?.required ||
                                    f.nomineeimage1.touched" class="alert
                                    alert-danger">
                    <div *ngIf="f.nomineeimage1.errors?.required">
                      Photo is Required
                    </div>
                  </div> -->
                  <div class="alert alert-danger" *ngIf="!profile1State">
                    Profile Image Size Should Not Larger Then 2MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication:</label>
                <div class="form-check">
                  <input type="checkbox" [checked]="preferred_communication1?.includes('Email')" id="checkbox-2"
                    (change)="onChange($event.target.value,$event.target.checked,
                                      'pre1')" name="check1" value="Email" class="form-check-input">
                  <label class="form-check-label" for="checkbox-2">Email</label>
                  <input type="checkbox" id="checkbox-3" [checked]="preferred_communication1?.includes('Telephone')"
                    (change)="onChange($event.target.value,$event.target.checked,
                                      'pre1')" name="check1" value="Telephone" class="form-check-input">
                  <label class="form-check-label" for="checkbox-3">Telephone</label>
                  <input type="checkbox" [checked]="preferred_communication1?.includes('Whatsapp')" (change)="onChange($event.target.value,$event.target.checked, 
                                      'pre1')" class="form-check-input" id="checkbox-1" name="check1" value="Whatsapp">
                  <label class="form-check-label" for="checkbox-1">Whatsapp</label>
                </div>
                <!-- <div *ngIf="register.submitted &&
                                  f.preferredcommunication1.errors?.required ||
                                  f.preferredcommunication1.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.preferredcommunication1.errors?.required">
                    Preferred Communication is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Area of Interest:</label>
                <div class="areacheckbox">
                  <p-multiSelect [options]="interest" [resetFilterOnHide]="true" [(ngModel)]="selectedInterest1"
                    (onChange)="getSelectedValue('interest1')" defaultLabel="Select Area of Interest"
                    [ngModelOptions]="{standalone: true}" optionLabel="interest_area" display="chip">
                  </p-multiSelect>

                  <ul style="max-height: 50px;
                    overflow-y: auto;" class="m-0" *ngIf="selectedInterest1?.length > 0">
                    <li *ngFor="let item of selectedInterest1">{{item?.interest_area}}</li>
                  </ul>
                </div>
                <!-- <div *ngIf="register.submitted &&
                                  f.areaofinterest1.errors?.required ||
                                  f.areaofinterest1.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.areaofinterest1.errors?.required">
                    Area of interest is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Other Information:
                    <i class="bx bx-info-circle" pTooltip="Is the Applicant or any of it's Nominee a
                    Member of any OtherAssociations or Professional Body?
                    Please Provide Details:" tooltipPosition="bottom"></i>
                  </label>
                </div>
                <textarea type="text" scriptInjection placeholder="Information" formControlName="oterprofession1"
                  [maxLength]="250" class="form-control">
                              </textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- second nominee details -->
        <div class="add-listings-box">
          <h3>Secondary Nominee Details</h3>
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label for="">Name:</label>
                  <!-- <p
                    style="border: 1px solid #f00; font-size: 12px; padding: 2px; color: #f00; border-radius: 3px; width: 72px; margin-left: 5px;">
                    Chargeable</p> -->
                </div>
                <input type="text" scriptInjection class="form-control" placeholder="Name" formControlName="name2"
                  [maxLength]="50">
                <!-- <div *ngIf="register.submitted &&
                                  f.name2.errors?.required ||
                                  f.name2.touched" class="alert alert-danger">
                  <div *ngIf="f.name2.errors?.required">
                    Name is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Designation:</label>
                <input type="text" scriptInjection class="form-control" [maxLength]="100" placeholder="Designation"
                  formControlName="designation2">
                <!-- <div *ngIf="register.submitted &&
                                  f.designation2.errors?.required ||
                                  f.designation2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.designation2.errors?.required">
                    Designation is Required
                  </div>
                </div> -->
              </div>
            </div>


            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">PAN:</label>
                <input oninput="this.value= this.value.toUpperCase()" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" maxlength="10"
                  type="text" scriptInjection class="form-control" placeholder="PAN" formControlName="pan2"
                  [maxLength]="100">
                <div *ngIf="register.submitted &&
                                  f.pan2.errors?.required ||
                                  f.pan2.touched" class="alert
                                  alert-danger">
                  <!-- <div *ngIf="f.pan2.errors?.required">
                    PAN is Required
                  </div> -->
                  <div *ngIf="f.pan2.errors?.pattern">
                    Please enter valid PAN
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Date of Birth:</label>
                <p-calendar formControlName="dob2" showClear="true" placeholder="DD/MM/YYYY" [maxDate]="today"
                  dateFormat="dd/mm/yy" [disabledDates]="invalidDates" [showIcon]="true"></p-calendar>
                <!-- <div *ngIf="register.submitted &&
                                  f.dob2.errors?.required ||
                                  f.dob2.touched" class="alert alert-danger">
                  <div *ngIf="f.dob2.errors?.required">
                    Date of Birth is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('email2')?.invalid) ||
                                  registerForm.get('email2')?.invalid &&
                                  registerForm.get('email2').touched" type="email" [maxLength]="70"
                  class="form-control" placeholder="E-Mail" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  formControlName="email2">
                <div *ngIf="register.submitted &&
                                  f.email2.errors?.required ||
                                  f.email2.touched" class="alert alert-danger">
                  <!-- <div *ngIf="f.email2.errors?.required">
                    E-Mail is Required
                  </div> -->
                  <div *ngIf="f.email2.errors?.pattern">
                    Invalid E-mail address
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Office Phone:</label>
                <input type="text" scriptInjection formControlName="officephone2" class="form-control" maxlength="10"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$" pKeyFilter="int" placeholder="Office Phone">
                <div *ngIf="register.submitted &&
                                  f.officephone2.errors?.required ||
                                  f.officephone2.touched" class="alert
                                  alert-danger">
                  <!-- <div *ngIf="f.officephone2.errors?.required">
                    Office Phone is Required
                  </div> -->
                  <div *ngIf="f.officephone2.errors?.pattern">
                    Please enter 10 digits office phone number
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number:</label>
                <input [class.is-invalid]="(register.submitted &&
                                  registerForm.get('mobileno2')?.invalid) ||
                                  registerForm.get('mobileno2')?.invalid &&
                                  registerForm.get('mobileno2').touched" type="text" scriptInjection
                  placeholder="Mobile Number" class="form-control" pattern="^((\\+91-?)|0)?[0-9]{10}$" pKeyFilter="int"
                  maxlength="10" formControlName="mobileno2">
                <!-- <div *ngIf="register.submitted &&
                                  f.mobileno2.touched" class="alert alert-danger">

                  <div *ngIf="f.mobileno2.errors?.pattern">
                    Please enter 10 digits mobile number
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Address:</label>
                <input type="text" scriptInjection class="form-control" formControlName="address2"
                  placeholder="Address">
                <!-- <div *ngIf="register.submitted &&
                                  f.address2.errors?.required ||
                                  f.address2.touched" class="alert alert-danger">
                  <div *ngIf="f.address2.errors?.required">
                    Address is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>State:</label>
                <p-dropdown [options]="state" formControlName="state2" optionLabel="name" [filter]="true"
                  filterBy="name" [showClear]="false" [resetFilterOnHide]="true" placeholder="Please Select State"
                  [(ngModel)]="selectedState2">
                  <ng-template pTemplate="selectedItem">
                    <div class="state-item state-item-value" *ngIf="registerForm.value.state2.name">
                      <div>{{registerForm.value.state2.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="state-item">
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <!-- <div *ngIf="register.submitted &&
                                  f.state2.errors?.required ||
                                  f.state2.touched" class="alert alert-danger">
                  <div *ngIf="f.state2.errors?.required">
                    State is Required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>City:</label>
                <input type="text" scriptInjection formControlName="city2" placeholder="City" class="form-control"
                  [maxLength]="70">
                <!-- <div *ngIf="register.submitted &&
                                  f.city2.errors?.required ||
                                  f.city2.touched" class="alert alert-danger">
                  <div *ngIf="f.city2.errors?.required">
                    City is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Pin Code:</label>
                <input placeholder="Pin Code" type="text" scriptInjection [maxLength]="6" pKeyFilter="int"
                  class="form-control" formControlName="zipcode2">
                <!-- <div *ngIf="register.submitted &&
                                  f.zipcode2.errors?.required ||
                                  f.zipcode2.touched" class="alert alert-danger">
                  <div *ngIf="f.zipcode2.errors?.required">
                    Pin Code is Required
                  </div>
                </div> -->
                <div *ngIf="f.zipcode2.errors?.minlength" class="alert alert-danger">
                  Please Enter Valid Pin Code
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Department:</label>
                <input type="text" scriptInjection class="form-control" placeholder="Department" [maxLength]="100"
                  formControlName="department2">
                <!-- <div *ngIf="register.submitted &&
                                  f.department2.errors?.required ||
                                  f.department2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.department2.errors?.required">
                    Department is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Industry Area:<span class="required">*</span></label>

                <p-dropdown [options]="industryArea" [class.is-invalid]="(register.submitted &&
                registerForm.get('ia2')?.invalid) || registerForm.get('ia2')?.invalid &&
                registerForm.get('ia2').touched" formControlName="ia2" [styleClass]="(register.submitted &&
                registerForm.get('ia2')?.invalid) || registerForm.get('ia2')?.invalid &&
                registerForm.get('ia2').touched ? 'error' : ''" placeholder="Select Industry Area" optionLabel="name"
                  optionValue="id" [showClear]="false" [resetFilterOnHide]="true" required></p-dropdown>
                <div *ngIf="register.submitted &&
                                  f.ia2.errors?.required ||
                                  f.ia2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.ia2.errors?.required">
                    Industry Area is Required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Biodata:</label>
                <textarea type="text" scriptInjection placeholder="Biodata" formControlName="biodata2" [maxLength]="250"
                  class="form-control">
                              </textarea>
                <!-- <div *ngIf="register.submitted &&
                                  f.biodata2.errors?.required ||
                                  f.biodata2.touched" class="alert alert-danger">
                  <div *ngIf="f.biodata2.errors?.required">
                    Biodata is Required
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">

                <ng-container *ngIf="nominee2PreviewState">
                  <label for="file">Upload Photo:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Image Preview"
                      (click)="onImageClick(profile2 ? profile2 : (nominee2ImageBaseUrl + regularMemberList?.nominee2?.media))"
                      tooltipPosition="bottom">{{(profile2 ? profile2 : regularMemberList?.nominee2?.media) |
                      slice:0:10}}</span>
                    <span (click)="logoPreview('nominee2')" style="color: red"
                      pTooltip="Remove Preview and Change Photo" tooltipPosition="bottom">x</span>
                  </span>
                  <ng-container *ngIf="imageData.boolean">
                    <app-image-preview [imageData]="imageData"></app-image-preview>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!nominee2PreviewState">
                  <label for="file">Upload Photo:</label>
                  <input formControlName="nomineeimage2" type="file" accept="image/x-png,image/gif,image/jpeg"
                    name="photofile" id="photofile" (change)="processImage($event, 'profile2')"
                    class="inputfile form-control px-2 py-1" />
                  <!-- <div *ngIf="register.submitted &&
                                  f.nomineeimage2.errors?.required ||
                                  f.nomineeimage2.touched" class="alert
                                  alert-danger">
                    <div *ngIf="f.nomineeimage2.errors?.required">
                      Photo is Required
                    </div>
                  </div> -->
                  <div class="alert alert-danger" *ngIf="!profile2State">
                    Profile Image Size Should Not Larger Then 2MB
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication:</label>
                <div class="form-check">
                  <input type="checkbox" id="checkbox-22" (change)="onChange($event.target.value,$event.target.checked,
                                      'pre2')" [checked]="preferred_communication2?.includes('Email')" name="check1"
                    value="Email" class="form-check-input">
                  <label class="form-check-label" for="checkbox-22">Email</label>
                  <input type="checkbox" [checked]="preferred_communication2?.includes('Telephone')" id="checkbox-33"
                    (change)="onChange($event.target.value,$event.target.checked,
                                      'pre2')" name="check1" value="Telephone" class="form-check-input">
                  <label class="form-check-label" for="checkbox-33">Telephone</label>
                  <input type="checkbox" [checked]="preferred_communication2?.includes('Whatsapp')" (change)="onChange($event.target.value,$event.target.checked,
                  'pre2')" class="form-check-input" id="checkbox-11" name="check1" value="Whatsapp">
                  <label class="form-check-label" for="checkbox-11">Whatsapp</label>
                </div>
                <!-- <div *ngIf="register.submitted &&
                                  f.preferredcommunication2.errors?.required ||
                                  f.preferredcommunication2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.preferredcommunication2.errors?.required">
                    Preferred Communication is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Area of Interest:</label>
                <div class="areacheckbox">
                  <p-multiSelect [options]="interest" [resetFilterOnHide]="true" [(ngModel)]="selectedInterest2"
                    (onChange)="getSelectedValue('interest2')" defaultLabel="Select Area of Interest"
                    [ngModelOptions]="{standalone: true}" optionLabel="interest_area" display="chip">
                  </p-multiSelect>
                  <ul style="max-height: 50px; overflow-y: auto;" class="m-0" *ngIf="selectedInterest2?.length > 0">
                    <li *ngFor="let item of selectedInterest2">{{item?.interest_area}}</li>
                  </ul>
                </div>
                <!-- <div *ngIf="register.submitted &&
                                  f.areaofinterest2.errors?.required ||
                                  f.areaofinterest2.touched" class="alert
                                  alert-danger">
                  <div *ngIf="f.areaofinterest2.errors?.required">
                    Area of interest is Required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center">Other Information:
                    <i class="bx bx-info-circle" pTooltip="Is the Applicant or any of it's Nominee a
                    Member of any Other Associations or Professional Body?
                    Please Provide Details:" tooltipPosition="bottom"></i>
                  </label>
                </div>
                <textarea type="text" scriptInjection placeholder="Information" formControlName="oterprofession2"
                  [maxLength]="250" class="form-control">
                              </textarea>
              </div>
            </div>
            <!-- <div class="col-lg-12 col-md-12" *ngIf="oldEnclosures?.length > 0">
              <div class="form-group">
                <ng-container *ngFor="let item of oldEnclosures; let i = index">
                  <label for="file">{{item?.enclosures_title}}:</label>
                  <span id="myImg" class="form-control d-flex align-items-center justify-content-between">
                    <span pTooltip="Click to Show Certificate Preview" (click)="downLoadPdf(env?.enclosure + item?.document)" tooltipPosition="bottom">{{item?.document | slice:0:25}}</span>
                    <button type="button" class="btn btn-danger btn-sm" (click)="removeEnclosure(i)">-</button>
                  </span>
                </ng-container>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 col-12 mt-3">
              <div class="form-group">
                  <label>Enclosures Needed (tick all those attached to
                      the application):</label>
                  <p-multiSelect [options]="enclosure" *ngIf="dropdownState"
                      [(ngModel)]="SelectedEnclosure" [resetFilterOnHide]="true"
                 
                      (onChange)="onEncloAddRow($event)"
                      defaultLabel="Enclosures Needed (tick all those attached to the application)"
                      [ngModelOptions]="{standalone: true}" optionLabel="name" display="chip"
                      >
                  </p-multiSelect>
                  <p style="font-weight:500; color: #F59E0B; font-size: 13px;">Note: File type must be
                      in PDF and File size
                      must not exceed from 1024KB</p>
                 
              </div>
          </div>
          <div class="form-group">
            <label *ngIf="getEnclosureDocumentArray().value.length> 0">Upload
                Document:</label>
            <div formArrayName="eclosuredoc">
                <ng-container *ngFor="let item of
            getEnclosureDocumentArray().controls; index as i; last as
            last">
                    <div class="row" [formGroupName]="i">
                        <div class="col-lg-6 col-sm-12">
                            <label for="">{{item.value?.name}}</label>
                        </div>
                        <div class="col-lg-6 col-sm-12 mb-1">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <input type="file" class="inputfile form-control px-2 py-1"
                                        formControlName="document"
                                        [class.is-invalid]="(register.submitted && registerForm.get('eclosuredoc').controls[i].controls['document']?.invalid)|| registerForm.get('eclosuredoc').controls[i].controls['document']?.invalid && registerForm.get('eclosuredoc').controls[i].controls['document']?.touched"
                                        (change)="processImage($event, 'enclosures', item.value.name, i)">
                                    <div class="invalid-feedback">Document
                                        is
                                        Required</div>
                                    <p style="font-weight:500; color: #F59E0B; font-size: 13px;">
                                        Note: File size
                                        must not exceed from 1024KB</p>
                                </div>
                                <div class="remove">
                                    <p-button type="button" class="ml-2 mt-1" icon="bx bx-minus"
                                        iconPos="right" pTooltip="Remove" tooltipPosition="bottom"
                                        (click)="onRemove(i)">
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div> -->
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>Supporting Document: </label>
                <div formArrayName="supporting_doc">
                  <ng-container *ngFor="let item of
                      getDocumentArray().controls; index as i; last as
                      last">
                    <div class="row" [formGroupName]="i">
                      <div class="col-lg-5 col-sm-12">
                        <input type="text" class="form-control px-2 py-1" formControlName="document_for"
                          placeholder="Document Name" accept="application/pdf"
                          [class.is-invalid]="(register.submitted &&
                          registerForm.get('supporting_doc').controls[i].controls['document_for']?.invalid)
                              ||
                              registerForm.get('supporting_doc').controls[i].controls['document_for']?.invalid
                          &&
                          registerForm.get('supporting_doc').controls[i].controls['document_for']?.touched || (registerForm.get('supporting_doc').controls[i].controls['document_for']?.invalid )">
                        <div class="invalid-feedback">Document Name is Required</div>
                      </div>
                      <div class="col-lg-5 col-sm-12">
                        <input type="file" class="inputfile form-control px-2 py-1" formControlName="document"
                          placeholder="Document" accept="application/pdf" (change)="onDocUppload($event, i)"
                          [class.is-invalid]="(register.submitted &&
                          registerForm.get('supporting_doc').controls[i].controls['document']?.invalid)
                              ||
                              registerForm.get('supporting_doc').controls[i].controls['document']?.invalid
                          &&
                          registerForm.get('supporting_doc').controls[i].controls['document']?.touched || (registerForm.get('supporting_doc').controls[i].controls['document']?.invalid )">
                        <div class="invalid-feedback">Document is Required</div>
                      </div>
                      <div class="col-lg-2 form-group d-flex mt-1">
                        <p-button type="button" icon="bx bx-plus" (click)="onAddRow('add', last)" iconPos="right"
                          class="mr-2"></p-button>
                        <div class="remove">
                          <p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
                            (click)="onAddRow('delete', i)" iconPos="right"></p-button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- register form -->

        </div>
        <!-- nominee 3 details -->
        <div class="add-listings-box">
          <h3>Services Operator Details(Optional)</h3>
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <div class="d-flex">
                  <label for="">Name:</label>
                </div>
                <input type="text" scriptInjection class="form-control" placeholder="Name" formControlName="name3"
                  [maxLength]="50">
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">Designation:</label>
                <input type="text" scriptInjection class="form-control" [maxLength]="100" placeholder="Designation"
                  formControlName="designation3">
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label for="">E-Mail:</label>
                <input type="email" [maxLength]="70" class="form-control" placeholder="E-Mail"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email3">
                <div class="alert alert-danger" *ngIf="f.email3.errors?.pattern">
                  Invalid E-mail address
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Mobile Number:</label>
                <input type="text" scriptInjection placeholder="Mobile Number" class="form-control"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$" pKeyFilter="int" maxlength="10" formControlName="mobile_no3">
                <!-- <div class="alert alert-danger" *ngIf="f.mobile_no3.errors?.pattern">
                  Please enter 10 digits mobile number
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="form-group">
                <label>Preferred Communication:</label>
                <div class="form-check">
                  <input type="checkbox" id="checkbox-22" (change)="onChange($event.target.value,$event.target.checked,
                                              'pre3')" [checked]="preferred_communication3?.includes('Email')"
                    name="check1" value="Email" class="form-check-input">
                  <label class="form-check-label" for="checkbox-22">Email</label>
                  <input type="checkbox" [checked]="preferred_communication3?.includes('Telephone')" id="checkbox-33"
                    (change)="onChange($event.target.value,$event.target.checked,
                                              'pre3')" name="check1" value="Telephone" class="form-check-input">
                  <label class="form-check-label" for="checkbox-33">Telephone</label>
                  <input type="checkbox" [checked]="preferred_communication3?.includes('Whatsapp')" (change)="onChange($event.target.value,$event.target.checked,
                          'pre3')" class="form-check-input" id="checkbox-11" name="check1" value="Whatsapp">
                  <label class="form-check-label" for="checkbox-11">Whatsapp</label>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-12">
              <div class="add-listings-btn">
                <button type="submit" class="button" (click)="goToTop()">Submit</button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- reason of amendment in membership -->
        <div class="add-listings-box">
          <div class="row"
            *ngIf="role !== 'Admin' && role !== 'UCCI Staff' && memberShipStatus !== 'In progress' && memberShipStatus !== 'Pending'">

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <div class="d-flex">
                  <label for="">Reason for amendment</label>
                </div>
                <textarea type="text" scriptInjection class="form-control" placeholder="Reason for amendment"
                  formControlName="amendment_reason" [class.is-invalid]="(register.submitted &&
                registerForm.get('amendment_reason')?.invalid) ||
                registerForm.get('amendment_reason')?.invalid &&
                registerForm.get('amendment_reason').touched"></textarea>
                <!-- <div *ngIf="register.submitted &&
                f.amendment_reason.errors?.required ||
                f.amendment_reason.touched" class="alert
                alert-danger">
                  <div *ngIf="f.amendment_reason.errors?.required">
                    Reason for amendment is required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-lg-12">
              <div class="add-listings-btn">
                <button type="submit" class="button" (click)="goToTop()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- *********************************************************************************************************************** -->
    <!-- payment section if form submitted successfully -->
    <!-- *********************************************************************************************************************** -->
    <div class="payment-section" *ngIf="!isShow">
      <!-- <button (click)="onPress()">Click</button> -->
      <div class="payment-totals">
        <h3>Payment Detail</h3>
        <ul>
          <ng-container *ngFor="let data of feeResponse">
            <li>{{data.fee_type}}<span>₹{{data.amount}}</span></li>
            <li>Tax<span>{{data.tax_percentage}}%</span></li>
          </ng-container>
          <li>Total <span>₹{{formResponse.total_fee}}</span></li>
        </ul>
        <div class="add-listings-btn mt-5">
          <button type="submit" class="button" (click)="payment(formResponse)">Proceed to Pay</button>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-container *ngIf="successState">
  <app-success [statusMsg]="successMessage"></app-success>
</ng-container>


<p-dialog header="Selection" [breakpoints]="{'850px' : '90vw'}" [(visible)]="approveModalState" [modal]="true"
  [style]="{width: '30vw'}" [draggable]="false" [resizable]="false" [closable]="true" [closeOnEscape]="true"
  (onHide)="onClose()" #dialog>
  <div>

    <form [formGroup]="appliedByForm" (ngSubmit)="onSendOtp()" #otpVerificationForm="ngForm" #form>
      <div class="row justify-content-end" style="min-height: 240px;">
        <div class="col-lg-12 col-md-12 mb-2">
          <div class="form-group">
            <label>Applied By:</label>
            <p-dropdown [options]="nominee" placeholder="Please Applied By" [class.is-invalid]="(role.submitted &&
            appliedByForm.get('userId')?.invalid) ||
            appliedByForm.get('userId')?.invalid &&
            appliedByForm.get('userId').touched" formControlName="userId" optionLabel="nomineeName"
              optionValue="userId" [showClear]="false" [resetFilterOnHide]="true">
            </p-dropdown>
            <!-- <span class="form-control d-flex align-items-center mt-2">{{loggedInUserEmail}}</span> -->
          </div>
        </div>
        <div class="col-lg-12 d-flex justify-content-end align-items-end ">
          <div class="add-listings-btn" style="text-align: right;">
            <button type="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>