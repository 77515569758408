<div class="main-content d-flex flex-column" *ngIf="pageData?.slug != 'resources'">
    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <ng-container *ngIf="data?.banner">
        <app-homeone-banner [image]="data?.banner"></app-homeone-banner>
    </ng-container>

    <div class="template_two">
        <ng-container *ngFor="let item of data?.page_json">
            <div class="template_two_top_section" *ngIf="item?.heading || item?.content">
                <p [ngClass]="item?.content ? 'template_two_heading' : 'template_two_heading m-0'"
                    *ngIf="item?.heading">{{item.heading}}</p>
                <p class="template_two_content" *ngIf="item?.content" [innerHTML]="item.content"></p>
            </div>
            <ng-container *ngIf="item?.cta">
                <app-call-to-action [callToAction]="item?.cta"></app-call-to-action>
            </ng-container>
            <div class="template_two_bottom_section"
                *ngIf="item?.grid[0]?.grid_image || item?.grid[0]?.grid_heading || item?.grid[0]?.grid_content">
                <div class="row m-0">
                    <div class="col-lg-2 col-md-3 col-sm-6 col-12 grid_col"
                        *ngFor="let item of item?.grid; index as index">
                        <div [ngStyle]="{'cursor' : item.slug ? 'pointer' : 'unset'}" (click)="navigate(item.slug)">
                            <ng-container>
                                <!-- {{item?.grid_image}} -->
                                <div class="image_div" >
                                    <img [src]="item.grid_image" alt="" *ngIf="item?.grid_image">
                                </div>
                                <div>
                                    <p class="card_heading m-0" *ngIf="item?.grid_heading">{{item.grid_heading}}</p>
                                    <p class="card_content m-0" *ngIf="item?.grid_content"
                                        [innerHTML]="item?.grid_content"></p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>

</div>
<div *ngIf="pageData?.slug == 'resources'">
    <section class="events-area bg-f9f9f9 pt-100 pb-70">
        <div class="container">
            <h3>News & Notifications</h3>
            <div style="text-align-last: center; margin-bottom: 50px;">
                <button _ngcontent-rga-c266="" class="homeBUtton" routerLink="/archive"
                    routerLinkActive="router-link-active">Library</button>
            </div>
            <!-- <div class="row"> -->
            <!-- <div class="col-lg-6 col-md-12"> -->
            <div class="events-item-list" *ngIf="news?.length > 0">
                <div class="single-events-box1">
                    <div class="row m-0">
                        <!-- <div class="col-lg-4 col-md-4 p-0">
                                    <div class="image" style="background-image: url({{Content.img}});">
                                        <img [src]="Content.img" alt="image">
                                        <a routerLink="/{{Content.link}}" class="link-btn"></a>
                                    </div>
                                </div> -->
                        <ng-container *ngFor="let Content of news;">
                            <div class="col-lg-6 col-md-6">
                                <div class="content single-events-box">
                                    <!-- <span class="meta"><i class="flaticon-calendar"></i> {{Content.created_at | date:'dd-MM-yyyy'}}</span> -->
                                    <h6>{{Content.title}}</h6>
                                    <!-- <p *ngIf="Content?.news_media[0]?.media.includes('https://')">
                                        <a href="{{Content?.news_media[0]?.media}}" target="_blank"
                                            rel="noopener noreferrer">
                                            {{Content.description}}
                                        </a>
                                    </p>
                                    <p *ngIf="!Content?.news_media[0]?.media.includes('https://')">
                                        <a href="{{Content?.news_media[0]?.media}}" target="_blank"
                                            rel="noopener noreferrer">
                                            {{Content.description}}
                                        </a>
                                    </p> -->
                                    <ul class="links" *ngFor="let item of Content?.news_media">
                                        <li *ngIf="item?.media.includes('https://')"><a href="{{item.media}}"
                                                target="_blank" rel="noopener noreferrer"
                                                style="word-wrap:break-word">{{item?.media_title}}</a></li>
                                        <li *ngIf="!item?.media.includes('https://')"><a
                                                href="{{base_URL}}/storage/news/media/{{item.media}}" target="_blank"
                                                rel="noopener noreferrer"
                                                style="word-wrap:break-word">{{item?.media_title}}</a></li>
                                    </ul>
                                </div>
                                <!-- {{Content?.news_media | json}} -->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- </div> -->
            <!-- </div> -->
        </div>
    </section>
</div>