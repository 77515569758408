import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonClass } from './common';
import { ApiService } from './services/api.service';
import { WebsiteService } from './services/website.service';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent {
  location: any;
  loadercheck: boolean = false;
  routerSubscription: any;
  localStorage: any;
  authModalState: boolean = environment.authModalState;

  constructor(
    private router: Router,
    private websiteService: WebsiteService,
    private activatedRoute: ActivatedRoute,
    private loading: NgxSpinnerService,
    public commonFunction: CommonClass,
    public apiService: ApiService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    // debugger;
    if (event instanceof NavigationStart) {
      // console.log(event.url);
      if (event.url == '/user-panel' || event.url == '/user-panel/dashboard') {
        this.loadercheck = true;
        this.loading.show();
      } else if (
        event.url == '/marketplace-products' ||
        '/productDetail/'.includes(event.url) ||
        event.url == '/cart/myinquiry' ||
        '/event-grid'.includes(event.url) ||
        '/event-details'.includes(event.url)
      ) {
        this.localStorage = this.commonFunction.getLocalStorage();
        if (this.localStorage) {
          this.websiteService.navigateOption.next('website');
        } else {
          this.websiteService.navigateOption.next('dashboard');
        }
      } else {
        this.loadercheck = false;
        this.loading.hide();
      }
    }
    if (event instanceof NavigationEnd) {
      this.loading.hide();
      this.loadercheck = false;
    }
  }

  ngOnInit() {
    this.recallJsFuntions();
    let slug = this.activatedRoute.snapshot.params;
    // console.log(this.activatedRoute.snapshot);
    let strings = window.location.href.split(window.location.host);
    // console.log(strings);
    this.onSessionTimeOut();
    this.localStorage = this.commonFunction.getLocalStorage();
    if (this.localStorage) {
      this.websiteService.navigateOption.next('website');
    } else {
      this.websiteService.navigateOption.next('dashboard');
    }
  }
  onSessionTimeOut() {
    this.apiService.authModalState.subscribe((res: any) => {
      this.authModalState = res;
    });
  }

  ngOnChanges() {
    let strings = window.location.href.split(window.location.host);

    // console.log(strings);
    // console.log(this.router);
  }
  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        $.getScript('assets/js/main.js');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
