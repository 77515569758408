<p-toast></p-toast>
<div class="add-listings-box" *ngIf="state == false">
  <div class="wrapper red">
    <div class="header__wrapper">
      <div class="header">
        <div class="sign"><span></span></div>
      </div>
    </div>
    <h1>Whooops</h1>
    <p>Something went wrong, please try again.</p>

    <button>Dismiss</button>
  </div>
</div>

<div class="add-listings-box" *ngIf="state == true">

  <div class="wrapper green">
    <div class="header__wrapper">
      <div class="header">
        <div class="sign"><span></span></div>
      </div>
    </div>
    <!-- <h1>Yeah</h1> -->
    <div style="margin-top: 55px;">
      <p>{{statusMsg?.message | titlecase}}</p>
      <a>{{this.redirectMessage | titlecase}} </a>
      <p style="font-size : 18px">Payment Status: <b>{{response?.payment_status}}</b></p>
      <p style="font-size : 18px">Paid Amount: <b>₹ {{response?.amount}}</b></p>
      <p style="font-size : 18px">Paid For: <b>{{response?.paid_for}}</b></p>
      <p style="font-size : 18px">Order No: <b>{{response?.order_id}}</b></p>
      <!-- <p style="font-size : 12px;word-break: break-all;">{{response?.status_api_res | json}}</p> -->
      <!-- <a routerLink="/">Go to Home Page</a> -->
    </div>
    <span style="font-size: larger; font-weight: 400;">Your payment status will be updated within 3 working days. In case of any queries, please reach out to info@ucciupadipur.com.</span>
    <!-- <div style="margin-top: 55px;" *ngIf="statusMsg?.form == 'add product'">
      <p *ngIf="role != 'Admin' && role != 'UCCI Staff'">{{statusMsg?.message | titlecase}}</p>
      <a *ngIf="role == 'Admin' || role == 'UCCI Staff'">Redirecting To My Products .... </a> -->
    <!-- <a *ngIf="role != 'Admin' && role != 'UCCI Staff'" routerLink="/">Go to Home Page</a> -->
    <!-- </div> -->
    <!-- <button routerLink="">Go to home page</button> -->
  </div>
</div>