import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsiteService } from 'src/app/services/website.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-template-one',
  templateUrl: './template-one.component.html',
  styleUrls: ['./template-one.component.scss']
})
export class TemplateOneComponent implements OnInit {

  bannerData : any = [{bannerImage: '../../../../../assets/img/main-banner-bg1.jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'}];
  alterSection : any = [{sectionImage: './../../../../../assets/img/about-img (1).jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'},{sectionImage: './../../../../../assets/img/about-img (1).jpg', heading: 'About UCCI', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eveniet, voluptate modi ipsa possimus quaerat fugiat dicta quasi blanditiis repellendus facilis velit distinctio ullam eaque similique dolorum. Nostrum, adipisci mollitia.'}];
  factoryData:any =[]
  uniqueYears:any
  sortedUniqueYears:any
  popUpyoutubeurl: any;
  allURLS:any= []
  constructor(private http: HttpClient,private websiteService: WebsiteService, public route: Router, private sanitizer: DomSanitizer) { }

  data:any = {page_json : []}
  AFDpageData:any=[]
  showVideo: boolean = false;
  youtubeUrl: any;
  @Input() pageData : any;
  videoUrl = 'https://www.youtube.com/watch?v=mKdjycj-7eE'; // Example YouTube video URL
  videoTitle: string;

  ngOnInit() {

    this.websiteService.getAllPages().then((res: any) => {
      console.log(res); 
  
      this.AFDpageData=res.data
      this.getData();
      this.getoutubeVideo();
     //this.getVideoTitle();
    })
    console.log(this.pageData)
    this.data = this.pageData
    console.log(this.data);

    if(this.data?.page_json) {
      this.data.page_json = JSON.parse(this.data?.page_json);
      console.log(this.data)
    }
}

// getVideoTitle() {
//   const videoIdMatch = this.videoUrl.match(/[?&]v=([^&]+)/);
//   if (videoIdMatch && videoIdMatch.length > 1) {
//     const videoId = videoIdMatch[1];
//     this.http.get(`https://www.youtube.com/watch?v=${videoId}`)
//       .subscribe((response: string) => {
//         const titleMatch = response.match(/<title>(.*?)<\/title>/);
//         if (titleMatch && titleMatch.length > 1) {
//           this.videoTitle = titleMatch[1];
//           console.log(this.videoTitle)
//         } else {
//           console.error('Title not found.');
//         }
//       }, error => {
//         console.error('Error fetching video data:', error);
//       });
//   } else {
//     console.error('Invalid YouTube video URL');
//   }
// }
navigate(slug: any) {
  console.log(slug);
  this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  this.route.onSameUrlNavigation = 'reload';
  this.route.navigate([slug]);
}

getData(){
   this.factoryData= this.AFDpageData.filter((event) => {
    return  event.slug!=='aao-factory-dekhein' && event.template_type==="Aao Factory Dekhein";
});
console.log(this.factoryData)

const allYears = this.factoryData.map((event) => new Date(event.created_at).getFullYear());
this.uniqueYears = [...new Set(allYears)];
this.sortedUniqueYears = this.uniqueYears.slice().sort((a, b) => b - a);

}

getDataForYear(year: number): any[] {
  this.factoryData = this.factoryData.map(item => {
    try {
      item.page_json = JSON.parse(item.page_json);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
    return item;
  });

  return this.factoryData.filter((item) => new Date(item.created_at).getFullYear() === year);
}
getoutubeVideo(){
  for(let i = 0 ; i< this.data?.page_json.length ; i++ ){
    this.data?.page_json[i].grid.forEach((element: any) => {
      if(element?.grid_content?.includes('iframe')){
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  
        // Executing the regular expression to find matches
        const matches = element?.grid_content.match(regex);
        
        // Extracting the URL from the matches
        const youtubeId = matches ? matches[1] : null;
        if (youtubeId) {
          const youtubeURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${youtubeId}`);
          this.showVideo = true;
          this.allURLS.push(youtubeURL)
         // this.youtubeUrl = youtubeURL;
         // console.log(this.youtubeUrl)
        }
      } else {
        // this.showVideo = false;
      }
    });
  }
 
  console.log(this.allURLS)
}

getURL(url:any){
  if(url.includes('iframe') && url != undefined){
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;

    // Executing the regular expression to find matches
    const matches = url.match(regex);
    
    // Extracting the URL from the matches
    const youtubeId = matches ? matches[1] : null;
    if (youtubeId) {
      const youtubeURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${youtubeId}`);
      this.showVideo = true;
      this.youtubeUrl = youtubeURL;
      console.log(this.youtubeUrl)
      return 1
    }
  }
  else{
    return 0

  }
}
openVideo(urls:any) {
  console.log(this.allURLS)
  // const videoPopup = document.getElementById('videoPopup');
  // videoPopup.style.display = 'block';
   this.popUpyoutubeurl =urls; 
   }

closeVideo() {
  // this.youtubeurl= ' '
  const videoPopup = document.getElementById('videoPopup');
  videoPopup.style.display = 'none';
}

}
