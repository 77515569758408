<p-toast></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<app-spinner [msg]="spinnerMsg"></app-spinner>

<!-- ********************** Main Content ******************** -->


<!-- ********************** Breadcrumb Area  ******************** -->

<div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{Content.title}}</li>
    </ol>
</div>


<!-- ***************************************************************News Master List*************************************************************** -->
<div class="Master-view-box">
    <div class="theader">
        <div class="flex">
            <div class="d-flex align-items-center">
                <div>
                    <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" scriptInjection (input)="dt1.filterGlobal($event.target.value,
                                'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                    </span>
                    <p-button pTooltip="Reset Filter" tooltipPosition="bottom" type="button" class="mx-2"
                        (click)="reset(dt1)" icon="bx bx-reset"></p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXlExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()"
                        class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff'">
            <p-button type="button" class="ml-2" routerLink="/user-panel/news-and-events-form" icon="bx bx-plus"
                label="Add" iconPos="right"></p-button>
        </ng-container>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <p-table #dt1 dataKey="id" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm
            p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
                currentPageReportTemplate="{totalRecords} of {{datalength}}
            records" [globalFilterFields]="['company_name',
            'email', 'website']" [value]="notificationList" [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 62px">S. No.</th>
                        <th style="min-width: 100px;">
                            Action
                        </th>
                        <th>News Title</th>
                        <th>News Type</th>
                        <th>Description</th>
                    </tr>


                </ng-template>
                <ng-template pTemplate="body" let-notificationList let-i="rowIndex">
                    <tr>
                        <td>
                            {{ i + 1 }}
                        </td>
                        <td style="min-width: 100px;" class="view-buttons">
                            <div class="d-flex">

                            <button pButton pRipple class="p-button-rounded
                            p-button-text
                            p-button-info" (click)="showMaximizableDialog(notificationList)" pTooltip="View"
                                tooltipPosition="bottom">
                                <i class="far fa-eye"></i>
                            </button>
                            <ng-container *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff'">
                                <button pButton pRipple class="p-button-rounded
                                p-button-text p-button-info" (click)="editFormDetails(notificationList?.id)"
                                    pTooltip="Edit" tooltipPosition="bottom" icon="bx bx-pencil"></button>
                                <button pButton pRipple icon="bx bx-trash" class="p-button-rounded p-button-text
                                    p-button-danger" (click)="onDeletePress(notificationList?.id)"></button>
                            </ng-container>
                            </div>
                        </td>
                        <td>{{ notificationList?.title }}</td>
                        <td>{{ notificationList?.news_type }}</td>
                        <td tooltipPosition="bottom" pTooltip="{{notificationList?.description}}"
                            tooltipPosition="bottom">{{ notificationList?.description.slice(0,60) }}</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<!-- ***************************************************************************************************************** -->
<!----------------------------------------------------------- view dialog --------------------------------------------------------->
<!-- ***************************************************************************************************************** -->
<p-dialog header="News * Notification Details" [(visible)]="displayMaximizable" [modal]="true"
    [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
    <!-- Start -->
    <ng-container *ngFor="let data of notificationDetails">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <div class="my-profile-box">
                    <h3>Notification Details</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Ttile :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                            data?.title}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>News Type :</label>
                                    <input type="text" readonly class="form-control" value="{{
                                            data?.news_type}}" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Description :</label>
                                    <textarea style="min-height: 80px;" type="text" readonly class="form-control" value="{{
                                            data?.description}}">
                                    </textarea>
                                </div>
                            </div>
                            <ul *ngFor="let media of data?.news_media" class="row" style="list-style: auto;">
                                <li class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Media Type:</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                media?.media_type}}" />
                                    </div>
                                </li>
                                <li class="col-lg-4 col-md-6 col-12">  
                                    <div class="form-group">
                                        <label>Media Title:</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                media?.media_title}}" />
                                    </div>
                                </li>
                                <li class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Media:</label>
                                        <ng-container
                                            *ngIf="media.media_type == 'File'; then firstblock else secondblock">
                                        </ng-container>
                                        <ng-template #firstblock>
                                            <a [href]="getImage(media.media)" style=" font-size: 0.8rem;
                                                                color: var(--mainColor);  overflow-wrap: break-word;;
                                                                font-weight: 700; text-decoration: underline;"
                                                target="_blank" [download]="getImage(media.media)">{{media.media}}</a>
                                        </ng-template>
                                        <ng-template #secondblock>
                                            <a style=" font-size: 0.8rem;
                                                                color: var(--mainColor);
                                                                font-weight: 700; text-decoration: underline; cursor: pointer; overflow-wrap: break-word;"
                                                target="_blank" [href]="media.media">{{media.media}}</a>
                                        </ng-template>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
</p-dialog>